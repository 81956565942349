<template>
  <f7-page name="ExportWorkers">
    <button
      name="btnExport"
      @click="exportExcel"
    >
      {{ $t("ExportWorkerList_btnExport") }}
    </button>
  </f7-page>
</template>
<style scoped>

</style>
<script lang="ts">

import XLSX from 'xlsx';

export default {
  name: 'ExportWorkers',
  methods: {
    exportExcel() {
      const data = XLSX.utils.json_to_sheet(this.items);
      const workbook = XLSX.utils.book_new();
      const filename = 'devsExport-excel.xlsx';
      XLSX.utils.book_append_sheet(workbook, data, 'devsExport-excel');
      XLSX.writeFile(workbook, filename);
    },
  },
};
</script>
