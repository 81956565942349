<template>
  <f7-page resizable>
    <navbar :text="currentURL === '/cockpit/' ? $t('AgriFarmCockpit_navbar_title') : $t('Home_navbar_title')" />
    <f7-block class="content-devx main-cockpit content__reporting">
      <div class="content__reporting__locationselector">
        <DxLocationSelector
          :start-level="0"
          :events="['updateGIS', 'updateEffort', 'updateLocations']"
        />
      </div>
      <div class="content__reporting__weather">
        <DxWeatherForecast />
        <DxWeatherStatus />
      </div>
      <div class="content__reporting__giseffort">
        <GisEffort />
      </div>
      <DxPlannReal />
      <DxStackedBarPlaned />
      <div class="content__reporting__tableeffort margin-top">
        <DxEffort />
      </div>
    </f7-block>
  </f7-page>
</template>

<script>

import navbar from '../../components/NavBar.vue';
import DxEffort from '../../components/reporting/DxEffort.vue';
import DxWeatherForecast from '../../components/reporting/DxWeatherForecast.vue';
import DxWeatherStatus from '../../components/reporting/DxWeatherStatus.vue';
import GisEffort from '../../components/reporting/GisEffort.vue';
import DxLocationSelector from '../../components/reporting/DxLocationSelector.vue';
import DxPlannReal from '../../components/reporting/DxPlannReal.vue';
import DxStackedBarPlaned from '../../components/chartPlanned/DxStackedBarPlaned.vue';

export default {
  components: {
    navbar,
    DxEffort,
    DxWeatherForecast,
    DxWeatherStatus,
    GisEffort,
    DxLocationSelector,
    DxPlannReal,
    DxStackedBarPlaned,
  },

  computed: {
    currentURL() {
      return this.$f7route.url;
    },
  },

};
</script>

<style lang="scss">
.content__reporting {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.content__reporting__locationselector {
  width: 100%;
}
.content__reporting__locationselector div {
    max-width: 500px;
}

.content__reporting__weather {
  position: relative;
  min-width: 320px;
  width: 500px;
  max-width: 500px;
  min-height: 30%;
}

.content__reporting__giseffort {
  width: calc(100% - 500px);
  iframe {
    border: none;
    width: 100%;
  }
}

.content__weatherstatus .label,
.content__weatherforescast .label {
  font-size: 11px;
  line-height: 1.2;
  padding: 4px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--fr-chocolat);
  margin: 15px 0 3px;
}

@media (min-width: 990px) and (max-width: 1280px) {
  .content__reporting__weather {
    min-width: 100%;
    display: flex;
    .content__weatherforescast {
      width: 50%;
      min-width: 370px;
    zoom: 0.9;
    }
    .content__weatherstatus {
      width: 50%;
    }
  }
  .content__reporting__giseffort {
      width: 100%;
  }
}

@media (max-width: 989px) {
  .content__reporting__weather {
    min-width: 100%;
    .content__weatherforescast {
      width: 100%;
      min-width: 400px;
      overflow: hidden;
    }
    .content__weatherstatus {
      width: 100%;
      min-width: 400px;
      overflow: hidden;
    }
  }
  .content__reporting__giseffort {
    width: 100%;
    min-width: 400px;
    overflow: hidden;
  }
}
.content__reporting{
  .dx-tab.dx-tab-selected span {
    background: var(--fr-chocolat);
    color: white!important;
    margin: 1px 0px 0;
    text-transform: uppercase;
    font-weight: 600;
  }
}

</style>
