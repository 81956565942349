<template>
  <f7-page>
    <navbar :text="$t('Costs_title')" />
    <DxCostsDatabase />
  </f7-page>
</template>

<script>

import DxCostsDatabase from '../../components/costs/DxCostsDatabase.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxCostsDatabase,
    navbar,
  },
};
</script>

<style>

</style>
