var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxForm',{attrs:{"id":"form","col-count":1}},[_c('DxSimpleItem',{attrs:{"editor-options":{
      items: _vm.nameSupervisors,
      searchEnabled: true,
      searchTimeout: _vm.searchTimeoutOption,
      minSearchLength: _vm.minSearchLengthOption,
      showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      onItemClick: _vm.onSupervisorChange,
      value: _vm.nameSupervisors[0], // TODO: Esto habrá que cambiarlo cuando los supervisores empiecen a venir con localizaciones posibles
    },"editor-type":"dxSelectBox","caption":_vm.$t('SelectSupervisor_supervisor_caption')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }