<template>
  <div>
    <f7-link
      v-if="textEnable"
      :href="info.urlDestiny"
    >
      {{ info.text }}
    </f7-link>
    <f7-link
      v-if="iconEnable"
      :href="info.urlDestiny"
    >
      <f7-icon
        :class="`icon-menu ${info.urlImage}`"
      >
        <f7-badge
          v-if="bagdeEnable"
        >
          {{ info.notificationsNumber }}
        </f7-badge>
      </f7-icon>
    </f7-link>
  </div>
</template>

<script>

export default {
  name: 'ExtraElement',
  props: {
    textEnable: { type: Boolean, default: false },
    iconEnable: { type: Boolean, default: false },
    bagdeEnable: { type: Boolean, default: false },
    info: { type: Object, default: null },
  },
};
</script>

<style lang="scss" scoped>
.icon-menu{
  width: 30px;
  height: 30px;
}
.icon-nfc {
  background-image: url('../static/img/icon_nfc.svg');
}
.icon-export {
  background-image: url('../static/img/icon_export.svg');
}
.icon-new {
  background-image: url('../static/img/icon_new.svg');
}
</style>
