var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-devx content-dx-daily-planning",staticStyle:{"margin":"5px 15px 25px 15px"}},[_c('DxDataGrid',{ref:"toolsTable",attrs:{"id":"toolsTable","data-source":_vm.actualTaskBadget.tools,"column-min-width":70,"allow-column-reordering":true,"show-borders":true,"show-column-lines":true,"column-auto-width":true,"show-row-lines":false,"row-alternation-enabled":true},on:{"row-inserted":_vm.onInsertedRow,"row-updated":_vm.onChangeRow,"row-removed":_vm.onRemovedRow}},[_c('DxStateStoring',{attrs:{"enabled":true,"type":"localStorage","storage-key":"DxToolsMonthlyTable"}}),_vm._v(" "),_c('DxExport',{attrs:{"enabled":false}}),_vm._v(" "),_c('DxPaging',{attrs:{"page-size":18}}),_vm._v(" "),_c('DxEditing',{attrs:{"allow-updating":true,"allow-deleting":true,"allow-adding":true,"mode":"form"}},[_c('DxForm',[_c('DxItem',{attrs:{"col-count":1,"col-span":2,"item-type":"group"}},[_c('DxItem',{attrs:{"data-field":"type","caption":_vm.$t('DxToolsMonthlyTable_type_caption')}}),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{ showSpinButtons: false,
                                 mode: 'number',
                                 placeholder: '0',
                                 showClearButton: true,
                                 min: 0,
                                 step: 1,
              },"editor-type":"dxNumberBox","data-field":"count"}}),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{ showSpinButtons: false,
                                 mode: 'number',
                                 placeholder: '0',
                                 showClearButton: true,
                                 min: '0',
                                 step: '1',
              },"editor-type":"dxNumberBox","data-field":"consumption","caption":_vm.$t('DxToolsMonthlyTable_consumption_caption')}}),_vm._v(" "),_c('DxItem',{attrs:{"data-field":"consumption_type","camption":_vm.$t('DxToolsMonthlyTable_consumption_type_caption')}}),_vm._v(" "),_c('DxItem',{attrs:{"data-field":"consumption_unit","camption":_vm.$t('DxToolsMonthlyTable_consumption_unit_caption')}})],1)],1)],1),_vm._v(" "),_c('DxSearchPanel',{attrs:{"visible":true,"width":200,"placeholder":_vm.$t('Search_placeholder')}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"type","name":"type","alignment":"left","caption":_vm.$t('DxMachinery_dxColumn_TypeMachinery')}},[_c('DxLookup',{attrs:{"data-source":_vm.selectTool,"display-expr":"name","value-expr":"name"}}),_vm._v(" "),_c('DxRequiredRule')],1),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"count","alignment":"right","name":"count","data-type":"number","caption":_vm.$t('MonthlyComponent_quantity_caption')}},[_c('DxRequiredRule')],1),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"consumption","alignment":"right","name":"consumption","data-type":"number","caption":_vm.$t('MonthlyComponent_consumption_caption')}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"consumption_unit","alignment":"left","name":"consumptionUnit","caption":_vm.$t('MonthlyComponent_consumptionunit_caption')}},[_c('DxLookup',{attrs:{"data-source":_vm.consumptionUnit}})],1),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"consumption_type","name":"consumptionType","alignment":"left","caption":_vm.$t('MonthlyComponent_typeconsumption_caption')}},[_c('DxLookup',{attrs:{"data-source":_vm.typeCosumptionAvailable,"display-expr":_vm.dysplayConsumptionType}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }