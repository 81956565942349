<template>
  <DxForm
    v-if="element.length !== 0"
    style="background-color:white"
  >
    <DxGroupItem>
      <!--Attachemnts-->
      <DxSimpleItem
        v-for="(item, index) in element"
        :key="index"
        :data-field="item.nameAttachment"
        :name="item.name"
        :editor-options="{ value: item.nameVehicle, disabled:true}"
      />
    </DxGroupItem>
  </DxForm>
  <f7-block
    v-else
    name="sin_items"
  >
    {{ $t('DetailTabAttachemnt_not_selected') }}
  </f7-block>
</template>

<script>

import { DxForm, DxSimpleItem, DxGroupItem } from 'devextreme-vue/form';

export default {
  components: { DxForm, DxSimpleItem, DxGroupItem },
  props: {
    attachmentsSelected: { type: Array, default: () => [] },
  },
  data() {
    return {
      element: [],
    };
  },
  beforeMount() {
    this.getAttachmentData();
  },
  methods: {
    // Obtenemos el nombre del atachment y el vehiculo asociado.
    getAttachmentData() {
      if (this.attachmentsSelected.length !== null) {
        for (let i = 0; i < this.attachmentsSelected.length; i += 1) {
          this.element.push({
            nameAttachment: this.attachmentsSelected[i].attachment_data.description,
            nameVehicle: this.attachmentsSelected[i].vehicle_data.description,
          });
        }
      }
    },
  },
};
</script>
