<template>
  <div
    class="content-devx content-dx-daily-planning main-dx-information-prescription"
  >
    <DxForm
      id="formNewPrecription"
    >
      <DxGroupItem :col-count="6">
        <DxGroupItem
          css-class="content-combo"
          :col-span="3"
        >
          <DxSimpleItem
            name="name"
            :data-field="$t('DxInformationPrescription_name_caption')"
            :editor-options="{
              value: actualName,
              onValueChanged: onChangeName,
            }"
          >
            <DxRequiredRule :message="$t('DxInformationPrescription_name_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="active"
            :data-field="$t('DxInformationPrescription_active_caption')"
            editor-type="dxCheckBox"
            :editor-options="{
              value: actualActive,
              onValueChanged: onChangeActive,
            }"
          />
          <DxSimpleItem
            name="dateofactivation"
            :data-field="$t('DxInformationPrescription_dateofactivation_caption')"
            editor-type="dxDateBox"
            :editor-options="{
              value: actualDate,
              onValueChanged: onChangeDate,
              displayFormat: 'dd/MM/yyyy',
              disabledDates:disableSundays,
            }"
          >
            <DxRequiredRule :message="$t('DxInformationPrescription_date_required')" />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem
          :col-span="3"
          css-class="content-combo"
        >
          <DxSimpleItem
            name="typeofPrescription"
            :data-field="$t('DxInformationPrescription_typeofprescription_caption')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: typePrescription,
              value: actualTypePrescription,
              displayExpr: 'name',
              valueExpr:'id',
              onItemClick: onChangeTypePrescription,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
            }"
          />
          <DxSimpleItem
            name="methodofapplication"
            :data-field="$t('DxInformationPrescription_methodofapplication_caption')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: listMethodsFormated,
              value: actualMethodOfApplication,
              displayExpr: 'name',
              valueExpr:'id',
              onItemClick: onChangeMethodOfApplication,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
            }"
          >
            <DxRequiredRule :message="$t('DxInformationPrescription_methodofapplication_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="notes"
            :data-field="$t('DxInformationPrescription_notes_caption')"
            editor-type="dxTextArea"
            :editor-options="{
              value: actualNotes,
              onValueChanged: onChangeNotes,
              maxLength: 110,
            }"
          />
        </DxGroupItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import moment from 'moment';

export default {
  name: 'DxInformationPrescription',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
      // UTILITIES
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      // FORM VALUES
      actualName: '',
      actualActive: true,
      actualDate: moment(new Date()).format('MM/DD/YYYY'),
      actualTypePrescription: '',
      actualMethodOfApplication: '1',
      actualNotes: '',
      listMethodsFormated: [],
    };
  },
  computed: {
    ...mapState('Prescription', ['prescription', 'listTypes', 'listMethods', 'activePrescription', 'typePrescription']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.translateMethods();
    if (this.prescription.name !== '') { // IF NOT UNDEFINED, WE ARE EDITING
      this.setValuesActualPrescription();
    } else {
      this.setNewPrescription();
      this.setDateActivationPrescription(moment().format('YYYY-MM-DD'));
    }
  },
  mounted() {
    if (this.prescription.active === 'No') {
      this.actualActive = false;
    }
  },
  methods: {
    // SET VALUES ACTUAL PRESCRIPTION
    setValuesActualPrescription() {
      this.actualName = this.prescription.name;
      this.actualDate = this.prescription.dateActivation;
      this.actualTypePrescription = this.prescription.type;
      this.actualMethodOfApplication = this.prescription.method;
      this.actualNotes = this.prescription.notes;
    },
    // EVENTS HANDLER ITEMS FORM
    onChangeName(e) {
      this.actualName = e.value;
      this.setNamePrescription(e.value);
    },
    onChangeActive(e) {
      this.actualActive = e.value;
      if (e.value === false) {
        this.setActivePrescription('No');
      } else {
        this.setActivePrescription('Yes');
      }
    },
    onChangeDate(e) {
      this.actualDate = e.value;
      this.setDateActivationPrescription(moment(e.value).format('YYYY-MM-DD'));
    },
    onChangeTypePrescription(e) {
      this.actualTypePrescription = e.itemData.id;
      this.setTypePrescription((e.itemData.id));
      this.setMethodPrescription(this.actualMethodOfApplication);
    },
    onChangeMethodOfApplication(e) {
      this.actualMethodOfApplication = e.itemData.id;
      this.setMethodPrescription(e.itemData.id);
    },
    onChangeNotes(e) {
      this.actualNotes = e.value;
      this.setNotesPrescription(e.value);
    },
    translateMethods() {
      for (const method of this.listMethods) {
        this.listMethodsFormated.push({
          id: method.id,
          name: this.$t(method.name),
        });
      }
    },
    disableSundays(e) {
      if (e) {
        return [0].indexOf(e.date.getDay()) >= 0;
      }
      return undefined;
    },
    ...mapActions('Prescription', ['setNewPrescription', 'setNamePrescription', 'setActivePrescription', 'setDateActivationPrescription', 'setTypePrescription', 'setMethodPrescription', 'setNotesPrescription']),
  },
};
</script>

<style lang="scss">

  .content-combo{
    .dx-item{
      .dx-item-content{
        label{
          min-width: 160px;
        }
      }
    }
  }
</style>
