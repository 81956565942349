<template>
  <DxForm
    v-if="nameProducts.length !== 0"
    style="background-color:white"
  >
    <DxGroupItem>
      <!-- Products-->
      <DxSimpleItem
        v-for="(item, index) in nameProducts"
        :key="index"
        :data-field="item.type"
        :name="item.type"
        :editor-options="{ value: item.count, disabled:true}"
      />
    </DxGroupItem>
  </DxForm>
  <f7-block
    v-else
    name="sin_items"
  >
    {{ $t('DetailTabProduct_no_seleccionado') }}
  </f7-block>
</template>

<script>

import { DxForm, DxSimpleItem, DxGroupItem } from 'devextreme-vue/form';

export default {
  components: { DxForm, DxSimpleItem, DxGroupItem },
  props: {
    productsSelected: { type: Array, default: () => [] },
  },
  data() {
    return {
      nameProducts: [],
    };
  },
  beforeMount() {
    this.getproductsData();
  },
  methods: {
    // Obtenemos los datos necesarios para mostrar Productos.
    getproductsData() {
      if (this.productsSelected.length !== 0) {
        for (let i = 0; i < this.productsSelected.length; i += 1) {
          this.nameProducts.push({
            count: `Dosis total: ${this.productsSelected[i].dosis_total} ${this.productsSelected[i].dosisUt}  Solution total: ${this.productsSelected[i].solution_total} ${this.productsSelected[i].solutionUt}`,
            type: this.productsSelected[i].product.description,
          });
        }
      }
    },
  },
};
</script>
