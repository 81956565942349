<template>
  <f7-page>
    <navbar
      :text="`${currentFarmCode} - ${$t('locationSelect_navbar_text')}`"
      :path-back="pathBack"
    />
    <div class="container">
      <f7-searchbar
        id="searcher locationSelection"
        search-container="#searchLocation-list"
        search-item="li"
        search-in=".item-title"
        :placeholder="$t('Search_placeholder')"
      />

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found" />
      </f7-list>

      <f7-list
        id="searchLocation-list"
        class="searchbar-found"
        media-list
        virtual-list
        :virtual-list-params="vlParams"
      >
        <ul>
          <f7-list-item
            v-for="(location, index) in vlData.items"
            :key="index"
            media-item
            radio
            name="demo-radio"
            :title="location.name"
            :style="`top: ${location.topPosition}px`"
            @click="changeStatebtnDisable(location)"
          />
        </ul>
      </f7-list>

      <f7-block>
        <f7-button
          name="btnContinue"
          class="botonPrimary"
          col
          button
          button-large
          button-raised
          fill
          :disabled="btnDisable"
          @click="createTaskFLow()"
        >
          {{ $t("Nfc_navbarpresence_btnContinue") }}
        </f7-button>
      </f7-block>
      <SheetNFC :tag="code" />
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import navbar from '../components/NavBar.vue';
import Api from '../services/Api';
import SheetNFC from '../components/SheetNFC.vue';

export default {
  components: {
    navbar,
    SheetNFC,
  },
  props: {
    employees: { type: Array, default: () => [] },
    callbackSelected: { type: Function, default: () => {} },
  },
  data() {
    return {
      code: '',
      vlData: {
        items: [],
      },
      vlParams: {
        items: this.employees,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
      },
      btnDisable: true,
      pathBack: '/tasks/list/',
    };
  },
  computed: {
    ...mapState('AccessControl', ['currentFarmCode']),
    ...mapState('TaskManager', ['locations', 'locationId', 'id', 'newTask']),
  },
  created() {
    for (let i = 0; i < this.locations.length; i += 1) {
      this.employees.push({
        id: this.locations[i].id,
        name: this.locations[i].name,
      });
    }
    this.vlData.items = this.employees;
  },
  methods: {
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (item.name.toLowerCase().includes(lowerQuery)) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    backto() {
      this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
    },
    changeStatebtnDisable(item) {
      this.setLocationId(item.id);
      this.setLocationName(item.name);
      this.btnDisable = false;
    },
    async createTaskFLow() {
      this.$f7.preloader.show();
      try {
        const json = await Api.createTask(this.newTask, this.locationId);
        this.setId(JSON.parse(json.response).id);
        this.setTaskCode(JSON.parse(json.response).task_code);
        this.setWorkers(JSON.parse(json.response).workers);
        this.$f7.views.main.router.navigate('/tasks/workersSelection/', { reloadCurrent: true });
      } catch (error) {
        const event = { type: 'ERROR', status: error, error };
        this.setEvent(event);
        this.$f7.sheet.open('.demo-sheet');
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('TaskManager', ['setLocationId', 'setLocationName', 'setWorkers', 'setId', 'setTaskCode']),
    ...mapActions('worker', [
      'changeUpSheet',
      'setSelectedWorker',
      'setEvent',
      'setType',
      'setCode',
    ]),
  },
};
</script>
<style lang="scss" scoped>

.container {
  background: #f4f5f7;
  padding-top: 10px;
}
.searchbar-input-wrap {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
}
.searchbar {
  height: 40px;
}
.searchbar-not-found {
  text-align: center;
}
.list .item-subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #999;
  mix-blend-mode: normal;
  opacity: 0.8;
}
.searchbar-found {
  height: 338px !important;
  overflow: scroll;
  background: white;
}
</style>
