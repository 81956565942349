export default {
  namespaced: true,
  state: {
    actualFilter: {},
  },

  getters: {
    actualFilter: (state) => state.actualFilter,
  },

  actions: {
    setActualFilter(context, value) {
      context.commit('setActualFilter', value);
    },
    setClearFilter(context, value) {
      context.commit('setClearFilter', value);
    },
  },
  mutations: {
    setActualFilter(state, value) {
      state.actualFilter[value.keyName] = { init: value.init, end: value.end };
    },
    setClearFilter(state, value) {
      state.actualFilter[value] = { init: '', end: '' };
    },
  },
};
