<template>
  <div v-if="loaded">
    <f7-row>
      <f7-col>
        <DxForm
          id="form"
          :col-count="1"
          caption="Employee Data"
        >
          <DxGroupItem>
            <DxGroupItem
              :caption="$t('EmployeeComponent_caption_text')"
            >
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_name_caption')"
                name="name"
                :caption="$t('EmployeeComponent_name_caption')"
                :editor-options="{
                  value: actualName,
                  mode: 'text',
                  placeholder: $t('EmployeeComponent_name_caption'),
                  showClearButton: true,
                  onValueChanged: setNameChanged,
                }"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_name_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_surname_caption')"
                name="surname"
                :caption="$t('EmployeeComponent_surname_caption')"
                :editor-options="{
                  value: actualSurname,
                  mode: 'text',
                  placeholder: $t('EmployeeComponent_surname_caption'),
                  showClearButton: true,
                  onValueChanged: setSurnameChanged,
                }"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_surname_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_identificationNumber_caption')"
                name="identificationNumber"
                :caption="$t('EmployeeComponent_identificationNumber_caption')"
                :editor-options="{
                  value: actualIdentificationNumber,
                  mode: 'text',
                  placeholder: $t('EmployeeComponent_identificationNumber_caption'),
                  showClearButton: true,
                  onValueChanged: setIdentificationNumberChanged,
                }"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_ID_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_status_caption')"
                :editor-options="{
                  items: itemsStatus,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onItemClick: setStatusEvent,
                  value: actualstatusName,
                }"
                editor-type="dxSelectBox"
                name="status"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_status_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_role_caption')"
                :editor-options="{
                  items: itemsRole,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onItemClick: setRoleEvent,
                  value: actualRole,
                }"
                editor-type="dxSelectBox"
                name="role"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_role_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_type_caption')"
                :editor-options="{
                  items: itemsType,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onItemClick: setTypeEvent,
                  value: actualType,
                }"
                editor-type="dxSelectBox"
                name="type"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_type_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_drive_caption')"
                :editor-options="{
                  onValueChanged: setDriveChenged,
                  value: actualDrive,
                }"
                editor-type="dxCheckBox"
                name="drive"
              />
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_group_caption')"
                name="group"
                :caption="$t('EmployeeComponent_group_caption')"
                :editor-options="{
                  value: actualGroup,
                  mode: 'text',
                  placeholder: $t('EmployeeComponent_group_caption'),
                  showClearButton: true,
                  onValueChanged: setGroupChanged,
                }"
              />
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_shift_caption')"
                :editor-options="{
                  items: itemsShift,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onItemClick: setShiftEvent,
                  value: actualShift,
                }"
                editor-type="dxSelectBox"
                name="shift"
              />
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_email_caption')"
                name="email"
                :caption="$t('EmployeeComponent_email_caption')"
                :visible="isSupervisor"
                :editor-options="{
                  value: actualEmail,
                  mode: 'email',
                  placeholder: $t('EmployeeComponent_email_caption'),
                  showClearButton: true,
                  onValueChanged: setEmailChanged,
                }"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_email_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_password_caption')"
                name="password"
                :visible="isSupervisor"
                :caption="$t('EmployeeComponent_password_caption')"
                :editor-options="{
                  value: actualPassword,
                  mode: 'text',
                  placeholder: $t('EmployeeComponent_password_caption'),
                  showClearButton: true,
                  onValueChanged: setPasswordChanged,
                }"
              />
            </DxGroupItem>
            <DxGroupItem>
              <DxGroupItem
                name=""
                :caption="$t('EmployeeComponent_identification_title')"
              >
                <DxSimpleItem
                  :data-field="$t('EmployeeComponent_typeIdentification_caption')"
                  :editor-options="{
                    dataSource: ['NFC'],
                    searchEnabled: true,
                    searchTimeout: searchTimeoutOption,
                    minSearchLength: minSearchLengthOption,
                    showDataBeforeSearch: showDataBeforeSearchOption,
                    onItemClick: setTypeIdentificationEvent,
                    value: actualTypeIdentification,
                  }"
                  editor-type="dxSelectBox"
                  name="typeIdentification"
                  :caption="$t('EmployeeComponent_typeIdentification_caption')"
                />
                <DxSimpleItem
                  :data-field="$t('EmployeeComponent_code_caption')"
                  name="codeIdentification"
                  :caption="$t('EmployeeComponent_code_caption')"
                  :editor-options="{
                    value: actualCode,
                    mode: 'text',
                    placeholder: $t('EmployeeComponent_code_caption'),
                    showClearButton: true,
                    onValueChanged: setCodeChanged,
                  }"
                />
              </DxGroupItem>
            </DxGroupItem>
          </DxGroupItem>
        </DxForm>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxEmployeeForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
      loaded: false,
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      isSupervisor: false,
      actualName: '',
      actualSurname: '',
      actualIdentificationNumber: '',
      actualGroup: '',
      actualType: '',
      actualTypeIdentification: 'NFC',
      actualCode: '',
      actualRole: 'OPERATOR', // TODO: Sustituir el literal por el enumerado obtenido del back
      actualShift: '',
      actualEmail: '',
      actualPassword: '',
      itemsStatus: [this.$t('DxEmployeeForm_active'), this.$t('DxEmployeeForm_noActive')],
      itemsRole: ['OPERATOR', 'SUPERVISOR'], // TODO: Sustituir los literales por los enumerados obtenidos del back
      itemsType: ['TEMPORARY', 'PERMANENT', 'CONTRATIST'], // TODO: Sustituir los literales por los enumerados obtenidos del back
      itemsDrive: [this.$t('No'), this.$t('Yes')],
      itemsShift: ['', 'Shift 1', 'Shift 2', 'Shift 3'],
    };
  },
  computed: {
    ...mapState('EmployeeManager', ['employee']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    if (this.employee === null) {
      this.actualstatusName = this.$t('DxEmployeeForm_active');
      this.actualType = this.$t('TEMPORARY');
      this.actualDrive = false;
    } else {
      this.actualName = this.employee.name;
      this.actualSurname = this.employee.surname;
      this.actualstatusName = this.employee.status;
      this.actualType = this.employee.type;
      this.actualDrive = this.employee.drive;
      this.actualRole = this.employee.supervisor ? 'SUPERVISOR' : 'OPERATOR'; // TODO: Sustituir los literales por los enumerados obtenidos del back
      this.actualIdentificationNumber = this.employee.code;
      this.getAuthNFC();
      this.actualShift = this.getShiftEmployee();
      this.actualGroup = this.employee.group;
      this.actualRole = this.employee.supervisor === true ? this.itemsRole[1] : this.itemsRole[0];
      this.actualEmail = this.employee.email;
      this.isSupervisor = this.employee.supervisor;
    }
    this.setName(this.actualName);
    this.setSurname(this.actualSurname);
    this.setIdentificationNumber(this.actualIdentificationNumber);
    if (this.actualGroup.length === 0) {
      this.actualGroup = '';
    }
    this.setGroup(this.actualGroup);
    this.setCodeIndentification(this.actualCode);
    this.setTypeIdentification(this.actualTypeIdentification);
    this.setEmail(this.actualEmail);
    this.setPassword('');
    this.setDriver(this.actualDrive);
    this.setStatus(this.actualstatusName);
    this.setRole(this.actualRole);
    this.setType(this.actualType);
    this.setShift(this.actualShift);
    this.loaded = true;
    this.$f7.preloader.hide();
  },
  methods: {
    getAuthNFC() {
      for (let i = 0; i < this.employee.auth.length; i += 1) {
        if (this.employee.auth[i].type === 'NFC') {
          this.actualTypeIdentification = this.employee.auth[i].type;
          this.actualCode = this.employee.auth[i].code;
        }
      }
    },
    getShiftEmployee() {
      for (let i = 0; i < this.employee.properties.length; i += 1) {
        if (this.employee.properties[i].key === 'shift') {
          return this.employee.properties[i].value;
        }
      }
      return '';
    },
    setSurnameChanged(e) {
      if (e.value === null) {
        e.value = '';
      }
      this.actualSurname = e.value;
      this.setSurname(e.value);
    },
    setNameChanged(e) {
      if (e.value === null) {
        e.value = '';
      }
      this.actualName = e.value;
      this.setName(e.value);
    },
    setIdentificationNumberChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.actualIdentificationNumber = e.value;
      this.setIdentificationNumber(e.value);
    },
    setDriveChenged(e) {
      if (e.value === null) {
        e.value = false;
      }
      this.actualDrive = e.value;
      this.setDriver(e.value);
    },
    setGroupChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.actualGroup = e.value;
      this.setGroup(e.value);
    },
    setCodeChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.actualCode = e.value;
      this.setCodeIndentification(e.value);
    },

    setEmailChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.actualEmail = e.value;
      this.setEmail(e.value);
    },
    setPasswordChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.actualPassword = e.value;
      this.setPassword(e.value);
    },
    setStatusEvent(e) {
      this.actualstatusName = e.itemData;
      this.setStatus(this.actualstatusName);
    },
    setRoleEvent(e) {
      if (e.itemData === 'SUPERVISOR') { // TODO: Sustituir el literal de SUPERVISOR por el enumerado obtenido del back
        this.isSupervisor = true;
      } else {
        this.isSupervisor = false;
      }
      this.actualRole = e.itemData;
      this.setRole(this.actualRole);
    },
    setTypeEvent(e) {
      this.actualType = e.itemData;
      this.setType(this.actualType);
    },
    setShiftEvent(e) {
      this.actualShift = e.itemData;
      this.setShift(e.itemData);
    },
    setTypeIdentificationEvent(e) {
      this.actualTypeIdentification = e.itemData;
      this.setTypeIdentification(this.actualTypeIdentification);
    },
    ...mapActions('EmployeeManager',
      [
        'setName',
        'setSurname',
        'setIdentificationNumber',
        'setGroup',
        'setStatus',
        'setRole',
        'setType',
        'setDriver',
        'setShift',
        'setEmail',
        'setPassword',
        'setTypeIdentification',
        'setCodeIndentification',
      ]),
  },
};
</script>

<style lang="scss">
#form-container {
  margin: 10px 10px 30px;
}

.long-title h3 {
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.content-activity{
  .dx-box-flex.dx-box.dx-widget.dx-visibility-change-handler.dx-collection{
    padding-top: 0;
    margin-top: -5px;
  }
  &__area{
    background-color: #EAE3D8;
    border-radius: 3px;
    padding: 8px 10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 15px;
    span, input{
      color: #6F4343;
      opacity: 1;
      text-transform: none;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
    }
    input{
      font-weight: normal;
    }
  }
}
.content-machinery{
  .content-devx{
    margin: 0 0 15px 0!important;
  }
  .dx-form-group-caption{
    padding: 0 0 10px 0!important;
    display: block;
  }
}
</style>
