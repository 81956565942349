<template>
  <f7-page
    name="recoveryPassword"
    login-screen
  >
    <recoveryPassword />
  </f7-page>
</template>

<script>
import recoveryPassword from '../../components/accounts/RecoveryPassword.vue';

export default {
  components: {
    recoveryPassword,
  },
  data() {
    return {
      recoveryPassword,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
