const dictionary = {
  en: {
    translation: {
      // Palabras reservadas INIT: NO TRADUCIR A OTROS LENGUAJES
      // hasta que se cambie la forma de generar los formularios
      // por enumerados y no se le envien los valores al back
      DIESEL: 'DIESEL',
      GASOLINE: 'GASOLINE',
      TEMPORARY: 'TEMPORARY',
      PERMANENT: 'PERMANENT',
      CONTRATIST: 'CONTRATIST',
      HARVESTER: 'HARVESTER',
      TRACTOR: 'TRACTOR',
      'CAR & VEHICLE': 'CAR & VEHICLE',
      'SOIL GRADER': 'SOIL GRADER',
      EXCAVATOR: 'EXCAVATOR',
      FORKLIFT: 'FORKLIFT',
      TRUCK: 'TRUCK',
      BULLDOZER: 'BULLDOZER',
      SPRAYER: 'SPRAYER',
      Yes: 'Yes',
      No: 'No',
      DxMachineryDatabase_column_active: 'Active',
      DxMachineryDatabase_column_inactive: 'No Active',
      activeSustance: 'Active',
      active: 'Active',
      noActive: 'No Active',
      DxEmployeeForm_operator: 'Operator',
      DxEmployeeForm_active: 'Active',
      DxEmployeeForm_noActive: 'No Active',
      // Palabras reservadas END: NO TRADUCIR A OTROS LENGUAJES
      Login_txtWelcome: 'Login',
      Login_inpUsername_placeholder: 'Username',
      Login_inpPassword_label: 'Password',
      Login_inpPassword_placeholder: 'Password',
      Login_chkRememberMe: 'Remember me',
      Login_txtRestorePass: 'Restore Password',
      Login_txtNeedHelp: 'Do you need some help?',
      Login_btnSignIn: 'SIGN IN',
      Login_btnSignUp_question: 'Not registered yet?',
      Login_btnSignUp_link: 'Sign up here',
      Login_msgEmail_title: 'Information',
      Login_msgEmail_content: 'You will shortly receive an email to change your password',
      Login_msgEmail_btnClose: 'Close',
      loggOut_Message: 'Are you sure you want to exit?',
      RecoveryPassword_txtDataAccess: 'Enter your email',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Reset password',
      RecoveryPassword_btnGoBack: 'Go back',
      ChangePassword_txtDataAccess: 'Enter your new password',
      ChangePassword_inpEmail_placeholder: 'Password',
      ChangePassword_inpRepeatEmail_placeholder: 'Repeat password',
      ChangePassword_btnChangePassword: 'Change password',
      Register_registerText: 'REGISTER',
      Register_btnEnter: 'ENTER',
      Register_inpName_name: 'Name',
      Register_inpName_placeholder: 'Name',
      Register_inpSurnames_name: 'Surnames',
      Register_inpSurnames_placeholder: 'Surnames',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Password',
      Register_inpPassword_placeholder: 'Password',
      Register_inpPasswordVerification_name: 'RepeatPassword',
      Register_inpPasswordVerification_placeholder: 'Repeat password',
      Register_inpUsername_name: 'Username',
      Register_inpUsername_placeholder: 'Username',
      Nfc_into_error_404: 'The employee is not registered',
      Nfc_into_error_400: 'The nfc format is invalid',
      Nfc_into_error_406: 'The current task is already created in this location',
      Nfc_into_error_409: 'The worker is already working in another task',
      Nfc_info_txtTitle: 'Card reading',
      Nfc_info_txtSubTitle: 'Approach the card for reading',
      Nfc_navbarpresence_incidence: 'NFC scanning',
      Nfc_navbarTask_checkout: 'Productivity',
      Nfc_navbarpresence_manual: 'Add NFC manually',
      Nfc_navbarpresence_btnContinue: 'Continue',
      NfcManually_navbarpresence_btnContinue: 'Continue',
      NfcManually_listInput_placeholder: 'Name or surname',
      Profile_info_profile: 'Profile info',
      Profile_name_placeholder: 'Name',
      Profile_surname_label: '',
      Profile_surname_placeholder: '',
      Profile_email_label: '',
      Profile_email_placeholder: '',
      Profile_mobile_label: '',
      Profile_mobile_placeholder: '',
      Profile_password_profile: '',
      Profile_oldPass_label: '',
      Profile_oldPass_placeholder: '',
      Profile_newPass_label: '',
      Profile_newPass_placeholder: '',
      Profile_checkNewPass_label: '',
      Profile_checkNewPass_placeholder: '',
      Profile_change_password: '',
      Profile_photo_profile: '',
      Profile_update_photo: '',
      Profile_setup_profile: '',
      Profile_language_label: '',
      Profile_theme_label: '',
      Profile_save_changes: '',
      Home_info_txtTitle: 'No workers',
      ExportWorkerList_btnExport: '',
      ExportExcel_thNombre: '',
      ExportExcel_thTipo: '',
      ExportExcel_thFecha: '',
      ExportExcel_captionListaEmpleados: '',
      SheetExport_sheet_export_ok: 'Downloaded file: ',
      SheetExport_sheet_export_error: 'Error into download',
      Start_order: 'Select Workers',
      TasksList_navbar_text: 'Work Orders',
      TasksList_lstNewTasks_title: 'Init new task',
      TasksList_lstInProgressTasks_title: 'In progress tasks',
      TasksList_lstCompletedTasks_title: 'Completed tasks',
      TasksList_Workers_title: 'Workers',
      TasksList_WorkersSelection_title: 'No workers in task',
      TasksList_WorkersSelection_subtitle: 'Click the top button to link workers',
      AccordionList_workers: 'workers',
      AccordionList_worker: 'worker',
      Home_conductor: 'Tractor driver',
      ManualList_listItem_title: 'No employee found',
      checkOut_Productivity_placeholder: 'Field Number',
      checkOut_Productivity: 'Productivity',
      checkOut_Task: 'Task',
      list_content_farm_img_alt: 'Change farm',
      farmSelection_navbar_text: 'Select Farm',
      activitySelection_navbar_text: 'Select Activity',
      locationSelect_navbar_text: 'Select Location',
      ActivitySelection_navbarTask_text: 'Select task',
      ActivitySelection_txtSearchCodeTask: 'Start task to code: ',
      ActivitySelection_txtSearchActivity: 'Choose an activity: ',
      ActivitySelection_taskError: 'Task not found',
      TasksList_filterTask_title: 'Select task',
      DxDailyPlanning_text: 'Create new planning',
      DxMonthlyPlanning_text: 'Create new monthly planning',
      DxAddEmployee_text: 'Add new Employee',
      DxTaskOrders_text: 'Register new work order',
      DetailTabs_machinary_title: 'Machinery',
      DetailTabs_attachment_title: 'Attachments',
      DetailTabs_tools_title: 'Tools',
      DetailTabs_products_title: 'Products',
      DetailTabs_workers_title: 'Workers',
      DetailTabs_locations_title: 'Locations',
      DxDailyPlanning_date_caption: 'Date',
      DxListEmployee_name_caption: 'Name',
      DxListEmployee_surname_caption: 'Surname',
      DxListEmployee_code_caption: 'Id Number',
      DxListEmployee_status_caption: 'Status',
      DxListEmployee_type_caption: 'Type',
      DxListEmployee_nfc_caption: 'Badge id',
      DxListEmployee_driver_caption: 'Driver',
      DxListEmployee_info_caption: 'Info',
      DxDailyPlanning_task_caption: 'Task',
      DxDailyPlanning_activity_caption: 'Activity',
      DxDailyPlanning_ha_caption: 'Ha',
      DxDailyPlanning_workers_caption: 'Workers',
      DxDailyPlanning_location_caption: 'Location',
      DxDailyPlanning_workingArea_caption: 'Working area (HA)',
      DxMonthlyPlanning_goal_caption: 'Goal',
      DxSelectSupervisor: 'At least one supervisor is needed',
      DxDailyPlanning_workingarea_caption: 'Working Area (HA)',
      DxDailyPlanning_dialogDelete_title: 'Delete planning',
      DxDailyPlanning_dialogDelete_text: 'Are you sure to delete the planning?',
      DxDailyPlanning_dialogDelete_confirm: 'Confirm',
      DxDailyPlanning_dialogDelete_cancel: 'Cancel',
      DxDailyWorkOrders_dialogDelete_title: 'Delete work order',
      DxDailyWorkOrders_dialogDelete_text: 'Are you sure to delete the work order?',
      DxDailyWorkOrders_dialogDelete_confirm: 'Confirm',
      DxDailyWorkOrders_dialogDelete_cancel: 'Cancel',
      DxDailyWorkOrders_dialogDelete_noOpen: 'Cannot delete a work order already started.',
      DxDailyWorkOrders_dialogUpdate_noOpen: 'Cannot edit a work order already started.',
      PlanningComponent_caption_text: 'Activity & Task',
      EmployeeComponent_caption_text: 'Data of Employee',
      PlanningComponent_activityName_caption: 'Activity Name',
      PlanningComponent_activitycode_caption: 'Activity Code',
      PlanningComponent_workingarea_caption: 'Working Area',
      EmployeeComponent_name_caption: 'Name',
      EmployeeComponent_status_caption: 'Status',
      EmployeeComponent_surname_caption: 'Surname',
      EmployeeComponent_identificationNumber_caption: 'Id number',
      EmployeeComponent_role_caption: 'Role',
      EmployeeComponent_type_caption: 'Type',
      EmployeeComponent_drive_caption: 'Driver',
      EmployeeComponent_group_caption: 'Group',
      EmployeeComponent_shift_caption: 'Shift',
      EmployeeComponent_email_caption: 'Email',
      EmployeeComponent_password_caption: 'New Password',
      EmployeeComponent_identification_title: 'Identification of Employee',
      EmployeeComponent_typeIdentification_caption: 'Type',
      EmployeeComponent_code_caption: 'Code',
      DxEmployeeForm_surname_require: 'Surname is required',
      DxEmployeeForm_ID_require: 'identification number is required',
      DxEmployeeForm_name_require: 'Name is required',
      DxEmployeeForm_status_require: 'Status is required',
      DxEmployeeForm_role_require: 'Role is required',
      DxEmployeeForm_type_require: 'Type is required',
      DxEmployeeForm_email_require: 'Email is required',
      DxEmployeeForm_password_require: 'Password is required',
      PlanningComponent_taskName_caption: 'Task Name',
      PlanningComponent_taskCode_caption: 'Task Code',
      DxPlannReal_plannWorkers_caption: 'Planned workers',
      DxPlannReal_workers_caption: 'Workers in',
      DxPlannReal_Totalworkers_caption: 'Total workers',
      DxWorkOrders_PlannMachinary_caption: 'Planned machinery',
      DxWorkOrders_WorkedMachinary_caption: 'Working machinery',
      DxWorkOrders_totalMachinary_caption: 'Total machinery',
      PlanningComponent_date_caption: 'Date',
      PlanningComponent_numberWorkers_caption: 'Number of Workers',
      PlanningComponent_family_caption: 'Family',
      PlanningComponent_addMachineButton_caption: 'Add Machinery',
      PlanningComponent_shift_caption: 'Shift',
      PlanningComponent_groupName_caption: 'Group Name',
      CreatePlanning_ButtonSave_caption: 'Save',
      PlanningComponent_dailyPlanning: 'DAILY PLAN',
      PlanningComponent_harvesting: 'HARVESTING',
      PlanningComponent_edit_dailyPlanning: 'Edit daily plan',
      StoreProduct_Buttoncancel_caption: 'Cancel',
      StoreProduct_Buttonsave_caption: 'Save',
      CreatePlanning_Title: 'CREATE DAILY PLAN',
      CreateMonthlyPlanning_Title: 'CREATE MONTHLY PLAN',
      EditMonthlyPlanning_Title: 'EDIT MONTHLY PLAN',
      CreatePlanning_ButtonCancel_caption: 'Cancel',
      CreatePlanning_ValidationDate: 'Date is required.',
      CreatePlanning_ValidationTaskName: 'taskName is required.',
      CreatePlanning_ValidationActivityName: 'ActivityName is required.',
      CreatePlanning_ValidationNumberOfWorker: 'The number of workers cannot be zero.',
      CreatePlanning_ValidationNumberOfDriver: 'The number of drivers cannot be zero.',
      CreatePlanning_ValidationActualLocationsSelected: 'Location is necessary',
      CreateEmployee_ValidationIdentificationNumber: 'Identificacion number is required',
      CreateOrder_ValidationSupervisorsLocation: 'There are locations without an assigned supervisor.',
      CreateOrder_ValidationItemsWorkOrder: 'There are unassigned items.',
      DxMonthlyMachinary_text: 'Add new machinery',
      CreateOrder_ValidationLocationExists: 'Task locations exists with this date.',
      CreateEmployee_ValidationName: 'Name is required.',
      CreateEmployee_ValidationSurname: 'Surname is required.',
      CreateEmployee_ValidationEmail: 'Email is required.',
      CreateEmployee_ValidationPassword: 'Password is required.',
      CreateEmployee_Title: 'CREATE EMPLOYEE',
      EditEmployee_Title: 'EDIT EMPLOYEE',
      DxDailyPlanning_edit_text: 'Edit',
      DxDailyPlanning_edit_hint: 'Edit',
      DxDailyPlanning_new_order_text: 'New order',
      DxDailyPlanning_new_order_hint: 'New order',
      DxDailyPlanning_task_status_caption: 'Task Status',
      DxDailyPlanning_work_order_status_caption: 'Work Order Status',
      DxDailyPlanning_supervisors_caption: 'Supervisors',
      MonthlyComponent_type_caption: 'Type',
      MonthlyComponent_performance_caption: 'Performance',
      MonthlyComponent_performanceunit_caption: 'Performance Unit',
      MonthlyComponent_consumption_caption: 'Consumption',
      MonthlyComponent_consumptionunit_caption: 'Consumption Unit',
      MonthlyComponent_quantity_caption: 'Quantity',
      MonthlyComponent_typeconsumption_caption: 'Consumption Type',
      MonthlyComponent_notes_caption: 'Notes',
      DetailTabs_supervisors_title: 'Supervisors',
      DetailTabAttachemnt_not_selected: 'No attachment selected',
      DetailTabProduct_no_seleccionado: 'No product selected',
      SelectSupervisor_supervisor_caption: 'Supervisor',
      SelectSupervisor_location_caption: 'Location',
      WorkOrder_title: 'WORK ORDER STATUS',
      FuelRegistration_title: 'FUEL REGISTRATION',
      Employee_title: 'LIST EMPLOYEES',
      WorkOrderDetail_select_supervisor: 'Select supervisor',
      WorkOrderDetail_select_maquinaria: 'Select machinery',
      WorkOrderDetail_order: 'ORDER',
      DxWorkOrders_edit_text: 'Edit',
      DxWorkOrders_edit_hint: 'Edit',
      DxWorkOrders_modal_title: 'Edit Work Order',
      DxWorkOrders_machinery_caption: 'Machinery',
      DxWorkOrders_driver_caption: 'Driver',
      DxWorkOrders_km_start_caption: 'Km start',
      DxWorkOrders_km_finish_caption: 'Km end',
      DxWorkOrders_horometer_start_caption: 'Hourmeter start',
      DxWorkOrders_horometer_finish_caption: 'Hourmeter end',
      DxWorkOrders_fuel_consumption_caption: 'Fuel consumption (L)',
      DxWorkOrders_fuel_type_caption: 'Fuel type',
      DxWorkOrders_working_area_caption: 'Working area',
      DxWorkOrders_aditional_info_caption: 'Additional info',
      DxProductPerformance_unit: 'Unit',
      DxProductPerformance_products_name: 'Product',
      DxProductPerformance_products_qtyCollected: 'Quantity collected',
      DxProductPerformance_products_qtyPlanned: 'Quantity planned',
      DxProductPerformance_products_qtyTask: 'Quantity returned to warehouse',
      DxProductPerformance_products_qtyUsed: 'Quantity used',
      DxProductPerformance_products_qtyReturnWarehouse: 'Quantity returned to warehouse',
      DxProductPerformance_products_totalRealPrescription: 'Total real prescription (L/Ha)',
      DxProductPerformance_products_realSolution: 'Real solution',
      DxProductPerformance_products_realDilutionFactor: 'Real dilution factor',
      DxProductPerformance_products_appliedDose: 'Applied dose',
      DxProductPerformance_products_theoricalDose: 'Theorical Dose',
      EditOrder_ButtonSave_caption: 'Save',
      EditOrder_ButtonCancel_caption: 'Cancel',
      CreateOrder_ButtonSave_caption: 'Save',
      CreateOrder_ButtonCancel_caption: 'Cancel',
      CreateOrder_ValidationOutDate: 'You cannot create a work order with an out of date schedule',
      DxSelectedItems_ya_esta_seleccionado_alert: 'Is already selected',
      DxDailyPlanning_delete_text: 'Delete',
      DxDailyPlanning_delete_hint: 'Delete',
      DxWorkOrder_Button_View_text: 'View',
      DxWorkOrder_Button_View_hint: 'View',
      DetailTabWorkers_sin_trabajadores: 'No workers selected',
      DetailTabAttachemnt_no_selected: 'No Attachment selected',
      DetailTabProduct_not_selected: 'No product selected',
      WorkOrderDetail_select_date: 'Select date',
      DxSelectDate_date_caption: 'Date',
      PlanningComponent_typeUnit_caption: 'Type unit',
      DxDailyPlanning_performanceha_caption: 'Goal',
      DxDailyPlanning_totalArea: 'Total area (ha)',
      PlanningComponent_performanceUnit_caption: 'Productivity Unit',
      PlanningComponent_performanceCount_caption: 'Productivity Goal',
      PlanningComponent_distributed_caption: 'Distributed',
      Status_Open: 'PENDING',
      Status_InProgress: 'IN PROGRESS',
      Status_Completed: 'COMPLETED',
      Status_Closed: 'Closed',
      Status_notReally_Closed: 'Not Closed',
      Status_reallyNot_Closed: 'Not Closed',
      Status_Pending: 'Pending',
      Status_Deleted: 'Deleted',
      DetailTabWorkers_sin_items: 'No items selected',
      DetailTab_sin_seleccion: 'Nothing selected',
      WorkOrderDetail_additional_info: 'Additional information',
      WorkOrderDetail_select_driver: 'Select driver',
      CreateOrder_ValidationItemsDriversWorkOrder: 'There are unassigned drivers.',
      Login_inpUsername_validate: 'Complete this field',
      Login_inpPassword_validate: 'Complete this field',
      DxEffort_level_caption: 'Level',
      DxEffort_activity_caption: 'Activity',
      DxEffort_task_caption: 'Task',
      DxEffort_hrHours_caption: 'HR Hours',
      DxEffort_totalHours_caption: 'Total Hours',
      DxEffort_totalHoursHa_caption: 'Total hours/Ha',
      DxEffort_totalHoursTotalHa_caption: '% of total hours vs total all Ha',
      DxEffort_progressCompleted_caption: 'Progress (% Complete)',
      EffortAgriFarmCockpit_navbar_title: 'REPORTING EFFORT AGRI FARM COCKPIT',
      WeatherFarmCockpit_navbar_title: 'CLIMATE GRAPHS',
      AgriFarmCockpit_navbar_title: 'REPORTING AGRI FARM COCKPIT',
      EffortTask_navbar_title: 'REPORTING EFFORT TASK',
      PrecisionFarming_navbar_title: 'PRECISION FARMING',
      DxDailyPlanning_days_caption: 'Days',
      DxDailyPlanning_p_caption: 'Permanent',
      DxDailyPlanning_t_caption: 'Temporary',
      DxDailyPlanning_c: 'Contratist',
      DxDailyPlanning_coste: 'Cost',
      LeftPanel_subtitle_harvesting: 'Harvesting',
      LeftPanel_title_reporting: 'REPORTING',
      LeftPanel_title_plan: 'PLAN',
      LeftPanel_title_execution: 'EXECUTION',
      LeftPanel_title_prescriptions: 'PRESCRIPTIONS',
      LeftPanel_title_weather: 'WEATHER',
      LeftPanel_title_warehouse: 'WAREHOUSE',
      LeftPanel_subtitle_fuelRegistration: 'Fuel Registration',
      LeftPanel_title_precisionFarming: 'PRECISION FARMING',
      LeftPanel_title_fertilizers: 'FERTILIZERS',
      LeftPanel_title_information: 'INFORMATION',
      LeftPanel_title_dataConfiguration: 'DATA CONFIGURATION',
      LeftPanel_subtitle_home: 'Home',
      LeftPanel_subtitle_AgriFarmCockpit: 'Agri Farm Cockpit',
      LeftPanel_subtitle_effortAgriFarm: 'Effort Agri Farm Cockpit',
      LeftPanel_subtitle_dailyPlanning: 'Daily Plan',
      LeftPanel_subtitle_workOrders: 'Work Order Status',
      LeftPanel_subtitle_unplannedWorkOrders: 'Manual register  WO',
      LeftPanel_subtitle_postWork: 'Post Work',
      LeftPanel_subtitle_prescriptionManager: 'Prescription Manager',
      LeftPanel_subtitle_fertilizersPrescriptions: 'Fertilizers Prescriptions',
      LeftPanel_subtitle_activeIngredients: 'Active Ingredients authorized EU',
      LeftPanel_subtitle_weatherFarmCockpit: 'Climate Graphs',
      LeftPanel_subtitle_precisionFarming: 'Precision Farming',
      LeftPanel_subtitle_logOut: 'Sign out',
      LeftPanel_subtitle_phytosanitariesLocalDatabase: 'Phytosanitaries Local Database',
      LeftPanel_subtitle_employee: 'Employee',
      LeftPanel_subtitle_productsLocalDatabase: 'Products Local Database',
      Search_placeholder: 'Search',
      WeatherForecast_title: 'Weather Forecast',
      WeatherStatus_title: 'Weather Status',
      GisMap_title: 'Map',
      PlanningComponent_machinary_caption: 'Machinery',
      DxProducts_dxColumn_Unit: 'Unit',
      DxAttachment_dxColumn_TypeAttachment: 'Type',
      DxAttachment_dxColumn_QuantityAttachment: 'Quantity',
      DxMachinery_dxColumn_TypeMachinery: 'Type',
      DxMachinery_dxColumn_QuantityMachinery: 'Quantity',
      DxProducts_dxColumn_activeIngredients: 'Active ingredients',
      DxProducts_dxColumn_productName: 'Product name',
      DxProducts_dxColumn_dosis: 'Dose',
      DxProducts_dxColumn_dosisUt: 'Dose Ut',
      DxProducts_dxColumn_dosisTotal: 'Quantity Planned',
      DxProducts_dxColumn_solution: 'Solution',
      DxProducts_dxColumn_solutionUt: 'Solution UoM',
      DxProducts_dxColumn_dilutionFactor: 'Dilution Factor',
      DxProducts_dxColumn_unit: 'Unit',
      DxProducts_dxColumn_dilutionFactorUt: 'Unit',
      DxProducts_dxColumn_solutionTotal: 'Water planned (L)',
      DxProducts_dxColumn_caution: 'Caution',
      DxProducts_dxColumn_restrictedEntryInterval: 'Entry Interval',
      DxProducts_dxColumn_preharvestInterval: 'PreHarvest Interval',
      ActiveIngredients_navbar_title: 'Active Ingredients authorized EU',
      PrescriptionManager_navbar_title: 'PRESCRIPTION MANAGER',
      DxCreatePrescription_button_text: 'Create new Prescription',
      DxCreatePrescription_column_active: 'Active',
      DxCreatePrescription_column_name: 'Name of prescription',
      DxCreatePrescription_column_type: 'Type ',
      DxCreatePrescription_column_method: 'Orchard/Nursery',
      ORCHARD: 'Orchard',
      NURSERY: 'Nursery',
      DxCreatePrescription_column_disease: 'Disease',
      DxCreatePrescription_column_activeSince: 'Active since',
      DxCreatePrescription_column_notes: 'Notes',
      CreatePrescription_Title: 'CREATE NEW PRESCRIPTION',
      EditPrescription_Title: 'EDIT PRESCRIPTION',
      EditProduct_navbar_create: 'EDIT PRODUCT',
      EditProduct_navbar_edit: 'CREATE NEW PRODUCT',
      DxFormProduct_code_caption: 'SAP ID',
      DxFormProduct_code_required: 'Code is required',
      DxFormProduct_status_caption: 'STATUS',
      DxFormProduct_comment_caption: 'COMMENT',
      DxFormProduct_description_caption: 'DESCRIPTION',
      DxFormProduct_component_caption: 'ACTIVE INGREDIENT',
      DxFormProduct_family_caption: 'GROUP NAME SAP',
      DxFormProduct_subfamily_caption: 'SUBGROUP',
      DxFormProduct_sapunit_caption: 'U.M SAP',
      DxFormProduct_toxicity_caption: 'TOXICITY',
      DxFormProduct_description_required: 'Description is required.',
      DxInformationPrescription_name_caption: 'NAME',
      DxInformationPrescription_active_caption: 'ACTIVE',
      DxInformationPrescription_dateofactivation_caption: 'DATE OF ACTIVATION',
      DxInformationPrescription_typeofprescription_caption: 'TYPE OF PRESCRIPTION',
      DxInformationPrescription_methodofapplication_caption: 'ORCHARD / NURSERY',
      DxInformationPrescription_notes_caption: 'NOTES',
      DxInformationPrescription_name_required: 'Name is required',
      DxInformationPrescription_date_required: 'Date is required',
      DxInformationPrescription_typeofprescription_required: 'Type of prescription is required',
      DxInformationPrescription_methodofapplication_required: 'The field Orchard/Nursery is required',
      totalArea_field: 'Total area (ha)',
      totalArea_title: 'Summary of selected Plots',
      DxAttachment_Title: 'Attachments',
      DxMachineryPerformance_Title: 'Machinery Performance',
      DxProductsPerformance_Title: 'Products Performance',
      DxMachinery_Title: 'Machinery',
      DxTools_Title: 'Tools',
      DxProducts_Title: 'Products',
      DxTools_dxColumn_TypeTool: 'Type',
      DxTool_dxColumn_QuantityTool: 'Quantity',
      CreatePrescription_ValidationName: 'Name is required',
      CreatePrescription_ValidationNameDuplicated: 'Name already in use',
      CreatePrescription_ValidationProducts: 'You need to add at least one product',
      CreatePrescription_ValidationMethod: 'Method of prescription is required',
      CreatePrescription_ValidationType: 'Type of prescription is required',
      CreatePrescription_ValidationDate: 'A valid date is required',
      CreatePrescription_ValidationActive: 'A new prescription must be active',
      DxPlanningProducts_prescription: 'Name Prescription',
      DxPlanningProducts_prescription_required: 'Prescription is required',
      DxPlanningProducts_typePrescription: 'Type Prescription',
      DxPlanningProducts_typePrescription_required: 'Type Prescription is required',
      DxPlanningProducts_typeOfPrescription: 'Type of presc.',
      DxPlanningProducts_typeOfPrescription_required: 'Type of presc. is required',
      // DxActiveIngredientsVademecum i18next desde vuex.
      active_substance_id: 'Active substance id',
      approval_date: 'Approval date',
      approval_expiration: 'Approval expiration',
      authorisation_in_progress: 'Authorization in progress',
      authorised: 'Authorized',
      category: 'Category',
      organic_approved: 'Organic approved',
      remark: 'Remark',
      status: 'Status',
      substitution_candidate: 'Substitution candidate',
      countries: 'Countries',
      //
      FertilizersPrescriptions_title: 'FERTILIZERS PRESCRIPTIONS',
      ProductsDatabase_title: 'PRODUCTS DATABASE',
      DxAttachmentMachinary_dxColumn_Machinary: 'Machinery',
      DxAttachmentMachinary_dxColumn_Attachment: 'Attachment',
      DxWeatherElementLinesGraph_average: 'Average',
      DxWeatherElementLinesGraph_averageForecasted: 'Average Forecasted',
      DxWeatherElementLinesGraph_totalForecasted: 'Total Forecasted',
      DxWeatherElementLinesGraph_total: 'Total',
      DxProduts_rule_range: 'Range should be up 0',
      DxProduts_rule_solution_required: 'Solution is required',
      DxProduts_rule_dosis_required: 'Dose is required',
      DxProdutsDataBase_button_text: 'Create new product',
      DxProdutsDataBase_edit_text: 'Edit',
      DxProdutsDataBase_edit_hint: 'Edit',
      DxEmployeeTable_Title: 'Employees',
      DxPostWorkEmployeeTable_rule_range: 'Range should be up 0',
      DxPostWorkEmployeeTable_Name: 'Name',
      DxPostWorkEmployeeTable_Surname: 'Surname',
      DxPostWorkEmployeeTable_Checkin: 'Checkin',
      DxPostWorkEmployeeTable_Checkout: 'Checkout',
      DxPostWorkEmployeeTable_Productivity: 'Productivity',
      DxPostWorkEmployeeTable_Unit: 'Unit',
      DxPostWorkEmployeeTable_Notes: 'Notes',
      DxTaskOrders_navbar_Name: 'REGISTER WORK ORDER',
      PlanningComponent_datepicker_multiple_placeholder: 'Select dates',
      CreatePostWork_Navbar_Title: 'REGISTER NEW WORK ORDER',
      ViewPostWork_Navbar_Title: 'VIEW WORK ORDER',
      DxPostWorkEmployeeTable_dialogCreate_wrongHour: 'Checkin must be before checkout.',
      CreatePostWork_Validation_PrescriptionNeeded: 'You must add a prescription in this task',
      DxCreatePrescription_column_family: 'Family',
      DxProducts_dxColumn_productGroup: 'Type',
      DxProducts_dxColumn_productFamily: 'Group',
      DxPlanningForm_activity_name: 'Activity name is required',
      DxPlanningForm_Task_name: 'Task name is required',
      DxPostWorkEmployeeTable_dialogCreate_wrongCheckIn: 'Check in must be same as task date',
      LeftPanel_title_machinery: 'MACHINERY',
      LeftPanel_subtitle_machineryDataBase: 'Machinery Database',
      Machinery_title: 'MACHINERY',
      CreateMachinery_Title: 'CREATE MACHINERY',
      EditMachinery_Title: 'EDIT MACHINERY',
      DxMachineryDatabase_column_status: 'Status',
      DxMachineryDatabase_column_family: 'Family',
      DxMachineryDatabase_column_subfamily: 'Subfamily',
      DxMachineryDatabase_column_description: 'Item Description SAP',
      DxMachineryDatabase_column_brand: 'Brand',
      DxMachineryDatabase_column_code: 'Internal Code',
      DxMachineryDatabase_column_attachment: 'Attachment',
      DxMachineryDatabase_button_text: 'Create new machinery',
      DxMachineryForm_code_caption: 'SAP Code',
      DxMachineryForm_code_required: 'Code is required',
      DxMachineryForm_description_caption: 'Description',
      DxMachineryForm_description_required: 'Description is required',
      DxMachineryForm_brand_caption: 'Brand',
      DxMachineryForm_brand_required: 'Brand is required',
      DxMachineryForm_model_caption: 'Model',
      DxMachineryForm_model_required: 'Model is required',
      DxMachineryForm_serial_number_caption: 'Serial Number',
      DxMachineryForm_serial_number_required: 'Serial Number required',
      DxMachineryForm_plate_caption: 'Plate',
      DxMachineryForm_plate_required: 'Plate is required',
      DxMachineryForm_internal_code_caption: 'Internal Code',
      DxMachineryForm_internal_code_required: 'Internal Code is required',
      DxMachineryForm_family_caption: 'Family',
      DxMachineryForm_family_required: 'Family is required',
      DxMachineryForm_subfamily_caption: 'Subfamily',
      DxMachineryForm_subfamily_required: 'Subfamily is required',
      DxMachineryForm_attachment_caption: 'Attachment',
      DxMachineryForm_purchase_caption: 'Purchase',
      DxMachineryForm_purchase_required: 'Purchase The field',
      DxMachineryForm_status_caption: 'Status',
      Machinery_ValidationCode: 'The field Code is required',
      Machinery_ValidationBrand: 'The field Brand is required',
      Machinery_ValidationModel: 'The field Model is required',
      Machinery_ValidationSerialNumber: 'The field Serial Number is required',
      Machinery_ValidationPlate: 'The field Plate is required',
      Machinery_ValidationDescription: 'The field Description is required',
      Machinery_ValidationFamily: 'The field Family is required',
      Machinery_ValidationSubfamily: 'The field Subfamily is required',
      Machinery_ValidationPurchase: 'The field Purchase is required',
      StoreProduct_ValidationComponent: 'Component is required',
      StoreProduct_ValidationCode: 'Code is required',
      StoreProduct_ValidationFamily: 'Family is required',
      StoreProduct_ValidationSubfamily: 'Subfamily is required',
      StoreProduct_ValidationSapunit: 'SAP unit is required',
      StoreProduct_ValidationDescription: 'Description is required',
      StoreProduct_ValidationCodeDuplicated: 'Code is duplicated',
      // Generics
      Button_cancel_text: 'Cancel',
      Button_save_text: 'Save',
      LeftPanel_subtitle_productsDataBase: 'Products Database',
      DxProductsDataBase_status_caption: 'Status',
      DxProductsDataBase_description_caption: 'Item Description SAP',
      DxProductsDataBase_code_caption: 'SAP code',
      DxProductsDataBase_family_caption: 'Group Name SAP',
      DxProductsDataBase_subfamily_caption: 'Subgroup',
      DxProductsDataBase_component_caption: 'Active Ingredient',
      LeftPanel_subtitle_reportingWorker: 'Workers',
      ReportingWorker_navbar_title: 'REPORTING WORKERS',
      ReportingTimeWorked_navbar_title: 'CLOCK IN / OUT',
      DxWorker_date_caption: 'Date',
      DxWorker_task_caption: 'Task',
      DxWorker_typeTask_caption: 'Type',
      DxWorker_codeTask_caption: 'Task Code',
      DxWorker_workerName_caption: 'Worker',
      DxWorker_name_caption: 'Name',
      DxWorker_workerSurname_caption: 'Surname',
      DxWorker_idNumber_caption: 'Id Number',
      DxWorker_typeWorker_caption: 'Type',
      DxWorker_territorialUnit_caption: 'Territorial Unit',
      DxWorker_totalHours_caption: 'Nº Hours',
      DxWorker_workingDay_caption: 'Working Day',
      DxWorker_performanceUnit_caption: 'Prformance Unit',
      DxWorker_performanceCount_caption: 'Performance',
      DxWorker_note_caption: 'Note',
      CreateMonthlyPlanning_ValidationDate: 'The number of working days must correspond to the number of dates selected',
      CreateMonthlyPlanning_ValidationTotalWorkingDays: 'You must plan at least one work day',
      DxMonthlyForm_activityTask: 'Activity Task',
      DxMonthlyForm_goalUnit: 'Goal Unit',
      DxMonthlyForm_DateProgrammed: 'Date Programmed',
      DxMonthlyForm_numberWorkers_caption: 'Number of Workers',
      DxMonthlyForm_performanceWorker_caption: 'PERFORMANCE/WORKER',
      DxMonthlyForm_workersDaysRequired: 'WORKERS DAYS REQUIRED',
      DxMonthlyForm_machineryDaysRequired: 'MACHINERY DAYS REQUIRED',
      DxMonthlyForm_toolsConsumptionTotal: 'TOOLS CONSUMPTION TOTAL (L)',
      DxMonthlyForm_estimatedCostActivity: 'ESTIMATED COST ACTIVITY',
      DxMonthlyForm_taskDuration: 'TASK DURATION',
      DxToolsMonthlyTable_type_caption: 'Type',
      DxToolsMonthlyTable_consumption_caption: 'Consumption',
      DxToolsMonthlyTable_consumption_type_caption: 'Consumption Type',
      DxToolsMonthlyTable_consumption_unit_caption: 'Consumption Unit',
      DxMachineryMonthlyTableHADAY: 'HA/DAY',
      DxMachineryMonthlyTableUNDIA: 'UN/DAY',
      Home_navbar_title: 'HOME',
      // CRUD Costes.
      Costs_title: 'Costs',
      DxCostsDatabase_column_name: 'Name',
      DxCostsDatabase_edit_text: 'Edit',
      DxCostsDatabase_edit_hint: 'Edit',
      LeftPanel_subtitle_costsDataBase: 'Costs Database',
      DxCostsDatabase_column_code: 'Code',
      DxCostsDatabase_column_id: 'id',
      DxCostsDatabase_column_price: 'Price',
      DxCostsDatabase_column_currency: 'Currency',
      DxCostsDatabase_column_currency_code: 'Currency Code',
      DxCostsDatabase_column_unit: 'Unit',
      DxCost_button_text: 'Create new Costs',
      DxCost_title_creation: 'Create Costs',
      DxCostForm_code_required: 'Code is required',
      DxCostForm_name_required: 'Name is required',
      DxCostForm_price_required: 'Price is required',
      DxCostForm_Unit_required: 'Unit is required',
      DxCostForm_Currency_required: 'Currency is required',
      DxCostForm_Id_required: 'Id is required',
      CreacteCost_ValidationCode: 'The code field is required',
      CreacteCost_ValidationName: 'The name field is required',
      CreacteCost_ValidationPrecio: 'The Price field is required',
      CreacteCost_ValidationUnit: 'The unit field is required',
      CreacteCost_ValidationCurrency: 'The currency field is required',
      CreacteCost_ValidationCurrencyCode: 'The currency code field is required',
      CreacteCost_ValidationId: 'The ID field is required',
      DeleteCost_dialogDelete_title: 'Delete Cost',
      DeleteCost_dialogDelete_text: 'Are you sure to delete the Selected Cost?',
      LeftPanel_subtitle_reportingBpas: 'Phytosanitary Use Report',
      LeftPanel_subtitle_reportingMachinery: 'Machinery',
      LeftPanel_subtitle_timeWorked: 'Clock in / out',
      // Summary BPAS
      DxDxBpas_Date_caption: 'Date',
      DxDxBpas_Location_caption: 'Territorial Units',
      DxDxBpas_ActiveIngredients_caption: 'Active Ingredients',
      DxDxBpas_dosisUt_caption: 'dose',
      DxDxBpas_attachment_caption: 'Implement used',
      DxDxBpas_machineryfamily_caption: 'Machinery family',
      DxDxBpas_attachmentFamily_caption: 'Attachment family',
      DxDxBpas_Machinary_caption: 'Machinery',
      DxDxBpas_Name_caption: 'Product name',
      DxDxBpas_QtyUsed_caption: 'Quantity used',
      DxDxBpas_SolutionTotal_caption: 'Solution',
      DxDxBpas_Variety_caption: 'Variety',
      DxDxBpas_Worker_caption: 'Applicator',
      DxDxBpas_Task_caption: 'Task',
      DxDxBpas_CodeTask_caption: ' Task Code',
      DxDxBpas_TypeTask_caption: 'Type',
      DxDxBpas_Formulas_caption: 'Prescription name',
      DxListEmployee_role_caption: 'Role',
      DxPrescriptionManager_dosis: 'Dose',
      DxPrescriptionManager_solution: ' Solution',
      DxPrescriptionManager_description: 'Item Description SAP',
      DxMachineryDatabase_company: 'Farm',
      // Summary Machinery
      DxMachinery_Date_caption: 'Date',
      DxMachinery_Location_caption: 'Territorial Units',
      DxMachinery_Attachment_caption: 'Attachment',
      DxMachinery_unit_caption: 'unit',
      DxMachinery_init_caption: 'km start',
      DxMachinery_end_caption: 'km end',
      DxMachinery_total_caption: 'km made',
      DxMachinery_Name_caption: 'Machine',
      DxMachinery_workingArea_caption: 'Working area',
      DxMachinery_SolutionTotal_caption: 'Solution',
      DxMachinery_Variety_caption: 'Variety',
      DxMachinery_Worker_caption: 'Driver',
      DxMachinery_Task_caption: 'Task',
      DxMachinery_typeFuel_caption: 'Fuel Type',
      DxMachinery_quantityFuel_caption: 'fuel quantity',
      DxMachinery_quantity_caption: 'Quantity',
      DxMachinery_CodeTask_caption: 'Task Code',
      DxMachinery_InternalCode_caption: 'Internal Code',
      DxMachinery_TypeTask_caption: 'Type',
      DxMachinery_performance_caption: 'Performance',
      DxMachinery_Formulas_caption: 'Formulation',
      DxMachinery_comment_caption: 'Notes',
      DxWorker_ActivityCode_caption: 'Activity Code',
      DxWorker_group_caption: 'Group',
      DxWorker_shift_caption: 'Shift',
      DxWorker_Activity_caption: 'Activity',
      DxWorker_location_caption: 'Location',
      DxWorker_initDate_caption: 'Clock in',
      DxWorker_endDate_caption: 'Clock out',
      DxWorker_checkIn_caption: 'Check in',
      DxWorker_checkOut_caption: 'Check out',
      DxWorker_jornada_caption: 'workday',
      ReportingMachinery_navbar_title: 'Reporting Machinery',
      ReportingBPAS_navbar_title: 'Phytosanitary Use Report',
      DxDxBpas_activity_caption: 'Activity',
      DxDxBpas_activityCode_caption: 'Activity Code',
      DxMachinery_Activity_caption: 'Activity',
      DxMachinery_ActivityCode_caption: 'Activity Code',
      DxMonthlyPlanning_totalCost: 'Total Cost',
      DxPostWorkEmployeeTable_WorkingArea: 'Working Area',
      CreatePlanning_ValidationTotalWorkingDays: 'The number of working days for machinery exceeds the number of total working days. Please adjust the performance of the machinery.',
      CreateOrder_ValidationAttachments: 'There are unassigned machinery in attachments',
      UploadHarvestingPlan_preloader: 'Uploading the file. This may take a little time depending on the size of the file',
      UploadHarvestingPlan_success: 'File uploaded successfully. We are processing the works orders. It may take a while to see them',
      UploadHarvestingPlan_error: 'The following error has occurred:',
      UploadHarvestingPlan_button: 'Upload HDS file',
      UploadHarvestingPlan_empty: 'Please, select an HDS file',
      LeftPanel_subtitle_logs: 'Harvest Logs',
      ReportingLogs_navbar_title: 'LOGS',
      DxErrorLogs_Date_caption: 'Date',
      DxErrorLogs_Time_caption: 'Time',
      DxErrorLogs_HDSID_caption: 'HDS Id',
      DxErrorLogs_Description_caption: 'Description',
      DxSelectSupervisorPerformance_SelectSupervisor_required: 'Supervisor required',
      CalendarInitEndDate_btnClear: 'Clear',
      CalendarInitEndDate_btnSearch: 'Search',
      PlanningComponent_seasional_caption: 'Number',
      DxProductPerformance_machinery_total_real_consumption: 'Total area prescription',
      DxDxBpas_totalREalPrescription_caption: 'Water Used',
      DxWorkOrders_notes_caption: 'Notes',
      DxWorkOrders_variety_caption: 'Variety',
      ReportingEmployeeEffectiveness_navbar_title: 'Employee Effectiveness',
      ReportingEmployeeEffectiveness_date: 'Date',
      ReportingEmployeeEffectiveness_task: 'Task',
      ReportingEmployeeEffectiveness_taskCode: 'Task Code',
      ReportingEmployeeEffectiveness_Activity: 'Activity',
      ReportingEmployeeEffectiveness_activityCode: 'Activity Code',
      ReportingEmployeeEffectiveness_performance: 'Performance',
      ReportingEmployeeEffectiveness_timeSpent: 'Time Spent',
      ReportingEmployeeEffectiveness_fullname: 'Full Name',
      ReportingEmployeeEffectiveness_type: 'Type',
      ReportingEmployeeEffectiveness_IdTask: 'Id Task',
      ReportingEmployeeEffectiveness_SumaParcial: 'Parcial Sum',
      HOURS: 'Hours',
      ReportingEmployeeEffectiveness_ratioTimeSpent: '% Time Spent',
      ReportingEmployeeEffectiveness_ratioTotalPerformance: '% Total Performance',
      ReportingEmployeeEffectiveness_ratioPerformanceGoal: '% Goal Performance',
      ReportingWorkOrders_navbar_title: 'Reporting Work Orders',
      LeftPanel_subtitle_reportingWorkOrders: 'Work Orders',
      DxListTaskGroupedActivivty_taskCode: 'Task Code',
      DxListTaskGroupedActivivty_performanceUnit: 'Performance Unit',
      DxListTaskGroupedActivivty_task: 'Tasks',
      DxListTaskGroupedActivivty_Activity: 'Activity',
      DxListTaskGroupedActivivty_NavBarName: 'Activity and Tasks',
      LeftPanel_subtitle_ListTaskGroupedActivivty: 'Activity and Tasks',
      PlanningComponent_variety_caption: 'Variety',
      DxPlanningForm_variety_required: 'Variety required',
      DxDailyPlanning_realGoal_caption: 'Real goal',
      DxDailyPlanning_goalVsReal_caption: '% Completed Goal',
      DxDailyPlanning_realCost: 'Real cost',
      DxDailyPlanning_CostVsReal: '% Completed Cost',
      planned_title: 'PLANNED VS REAL',
      ReportingplannedVsReal_navbar_title: 'Planned vs Real',
      Variety_title: 'Variety',
      LeftPanel_subtitle_varietyDatabase: 'Variety Database',
      DxVarietyDatabase_column_code: 'Code',
      DxVarietyDatabase_column_name: 'Name',
      DxMachineryDatabase_sapid: 'SAP ID',
      DxProductsDataBase_UMSAP_caption: 'U.M SAP',
      DxProductTable_dialogCreate_solutionNotEmpty: 'The solution field cannot be empty or less than 0',
      DxDetailTabs_products_quantityPlanned: 'Quantity Planned',
      DxDetailTabs_products_waterPlanned: 'Water Planned',
      CreatePlanning_ValidationPrescription: 'You must select a prescription',
      DxBpas_Machinary_InternalCodecaption: 'Internal Code',
      DxDxBpas_QtyUsedUnit_caption: 'Quantity unit',
      DxWorkOrders_kmTotal_caption: 'km performed',
      DxWorkOrders_HorometerTotal_caption: 'Horometer performed',
      DxProductPerformance_products_doseUsed: 'Doses used',
      PlanningComponent_grouped_caption: 'Grouped',
      Workers_StackedBarPlanned: 'Workers',
      machinary_StackedBarPlanned: 'Machineries',
      ChartPlanned_StackedBarPlanned: 'Planned vs Working',
      plannWorkers_StackedBarPlanned: 'Planned Workers',
      plannMachinery_StackedBarPlanned: 'Planned machineries',
      DxPostWorkMachinaryDriverTable_machinery_validating: 'Machinery is already selected',
      dxSideBySideBar_totalWorkerByActivity: 'Total Workers By Activity',
      dxSideBySideBar_Worker: 'Workers',
      dxSideBySideBarDayWorked_totalWorkerByActivity: 'Days Worked per Activity',
      dxSideBySideBarDayWorked_totalWorkerByActivity_Worked: 'Days Worked',
      DxToolsDatabase_column_status: 'Status',
      DxToolsDatabase_column_code: 'Code',
      DxToolsDatabase_column_family: 'Family',
      DxToolsDatabase_column_subfamily: 'Subfamily',
      DxToolsDatabase_column_description: 'Description',
      DxToolsDatabase_column_brand: 'Brand',
      DxToolsDatabase_column_id: 'id',
      DxToolsDatabase_column_fuel: 'fuel',
      DxToolsDatabase_column_itemId: 'item Id',
      LeftPanel_subtitle_tools: 'Tools',
      editTool_Title: 'EDIT TOOL',
      createTool_Title: 'CREATE TOOL',
      Tools_ValidationCode: 'The field Code is required',
      Tools_ValidationBrand: 'The field Brand is required',
      Tools_Validationid: 'The field id is required',
      Tools_ValidationitemID: 'The field itemID is required',
      Tools_ValidationDescription: 'The field Description is required',
      Tools_ValidationFamily: 'The field Family is required',
      Tools_ValidationSubfamily: 'The field Subfamily is required',
      Tools_ValidationFuel: 'The field Fuel is required',
      DxWorkOrder_short_id: 'ID',
      DxManualPerformance_total_performance: 'Total Performance',
      DxPopupWorkOrder_Title_supervisor: 'Supervisor',
      DxPopupWorkOrder_Title_totalPerformance: 'Performance',
      DxPopupWorkOrder_Title_machinery: 'Machinery',
      DxPopupWorkOrder_Title_product: 'Products',
      DxWorkOrders_manualPerformance_caption: 'Total Performance',
      PlanningComponent_manualPerformance_caption: 'Total Performance',
      ProductWarehouse_navbar_title: 'Product Collection',
      LeftPanel_subtitle_productCollection: 'Product Collection',
      ReturnedProduct_navbar_title: 'Returned Product',
      LeftPanel_subtitle_returnedProduct: 'Returned Product',
      DxProductWarehouse_column_date: 'Date',
      DxProductWarehouse_shortId: 'ID',
      DxProductWarehouse_column_activity: 'Activity Name',
      DxProductWarehouse_column_activityCode: 'Activity Code',
      DxProductWarehouse_column_task: 'Task',
      DxProductWarehouse_column_taskCode: 'Task Code',
      DxProductWarehouse_column_productName: 'Product Name',
      DxProductWarehouse_column_supervisor: 'Supervisor',
      DxProductWarehouse_column_productWarehouse: 'Quantity',
      DxProductWarehouse_column_statusCollection: 'Status',
      DxProductWarehouse_column_statusConfirmed: 'Sent',
      DxProductWarehouse_column_statusNotConfirmed: 'Not Sent',
      DxProductWarehouse_column_cost_center: 'Cost Center',
      DxProductWarehouse_column_cost_center_required: 'Cost Center required',
      DxProductWarehouse_column_warehouse: 'Warehouse',
      DxProductWarehouse_column_warehouse_required: 'Warehouse required',
      // Greenhouse
      DxBins_dxColumn_Code: 'Code',
      DxBins_dxColumn_NameBins: 'Name',
      DxBins_dxColumn_CreationDate: 'Creation Date',
      BatchesForm_edit_batches_info: 'Batch Info',
      BatchesForm_create_batches_info: 'Batch Info',
      CreateBatch_Title: 'Create batch',
      EditBatch_Title: 'Edit batch',
      CreateBatch_ButtonSave_caption: 'CREATE',
      DxBatches_dxColumn_BinsList: 'Content Bins',
      DxBatches_dxColumn_NameBatch: 'Batch Name',
      DxBatches_dxColumn_CreationDate: 'Creation Date',
      DxBatches_dxColumn_Code: 'Code',
      LeftPanel_subtitle_create_batches: 'Create Batch',
      LeftPanel_title_batches: 'BATCHES',
      LeftPanel_subtitle_batches: 'Consult Batches',
      LeftPanel_title_bins: 'BINS',
      LeftPanel_subtitle_create_bins: 'Create Bins',
      LeftPanel_subtitle_bins: 'Consult Bins',
      LeftPanel_title_stock_management: 'STOCK MANAGEMENT',
      LeftPanel_subtitle_received_bins: 'Received bins list',
      LeftPanel_subtitle_reclassification: 'Reclassification',
      Batches_Title: 'BATCHES',
      Bins_create_navbar_title: 'CREATE BIN',
      Bins_reclassify_navbar_title: 'RECLASSIFY BIN',
      Bins_edit_navbar_title: 'EDIT BIN',
      BinsForm_bins_info: 'Bins Data',
      DxBinsInfoForm_date_caption: 'Date',
      DxBinsInfoForm_name_caption: 'Name',
      DxBinsInfoForm_bins_location_caption: 'Farm',
      Bins_content_title: 'Bins Content',
      DxBinsContentGrid_code: 'Code',
      DxBinsContentGrid_parent_code: 'Parent Code',
      DxBinsContentGrid_location_level_0_caption: 'Level 0',
      DxBinsContentGrid_location_level_last_caption: 'Last Level',
      DxBinsContentGrid_variety_caption: 'Variety',
      DxBinsContentGrid_category_caption: 'Category',
      DxBinsContentGrid_classification_caption: 'Classification',
      DxBinsContentGrid_plants_count_caption: 'Nº of Plants',
      Bins_Title: 'BINS',
      Reclassification_Title: 'RECLASSIFICATION',
      DxBinsTable_State_Created: 'Created',
      DxBinsTable_State_Registered: 'Registered',
      DxBinsTable_State_Closed: 'Closed',
      DxBinsTable_State_Sent: 'Sent',
      DxBinsTable_State_Received: 'Received',
      DxBinsTable_State_ToReceive: 'To receive',
      DxBinsTable_dxColumn_Code: 'Code',
      DxBinsTable_dxColumn_Name: 'Nº Bins',
      DxBinsTable_dxColumn_Origin: 'Farm Origin',
      DxBinsTable_dxColumn_LotName: 'Nº Batch',
      DxBinsTable_dxColumn_Destination: 'Farm Destination',
      DxBinsTable_dxColumn_State: 'State',
      DxDetailTabBins_dxColumn_Variety: 'Variety',
      DxDetailTabBins_dxColumn_NumPlants: 'Nº Plants',
      DxDetailTabBins_dxColumn_Category: 'Category',
      DxDetailTabBins_dxColumn_Classification: 'Classification',
      DxDetailTabsBins_BinsData: 'Bin´s data',
      DxDetailTabsBins_Origin: 'Origin',
      DxDetailTabsBins_Destination: 'Destination',
      DxDetailTabBins_dxColumn_Code: 'Code',
      DxDetailTabBins_dxColumn_LastLevel: 'Last Level',
      DxBinsTable_ButtonText_Register: 'Register',
      DxBinsTable_ButtonText_Send: 'Send',
      DxBinsTable_ButtonText_Move: 'Move',
      DxBinsTable_ButtonText_ConfirmReception: 'Confirm Reception',
      DxBinsTable_ButtonText_Close: 'Close',
      DxSelectLevel0Location_modal_title: 'Select destination:',
      DxSelectLevel0Location_requiredRule_farm: 'This field is required',
      DxSelectLevel0Location_farm: 'Destination',
      DxBinsTable_button_send: 'Send',
      DxBinsTable_button_cancel: 'Cancel',
      CreateBin_ButtonSave_caption: 'CREATE',
      ReclassifyBin_ButtonSave_caption: 'RECLASSIFY',
      MoveBin_ButtonSave_caption: 'MOVE',
      EditBin_ButtonSave_caption: 'SAVE',
      EditBatch_ButtonSave_caption: 'SAVE',
      ButtonCancel_caption: 'CANCEL',
      DxBinsContentGridPlanning_bin: 'Bin',
      DxBinsContentGridPlanning_variety: 'Variety',
      DxBinsContentGridPlanning_category: 'Category',
      DxBinsContentGridPlanning_classification: 'Classification',
      DxBinsContentGridPlanning_content: 'Content',
      DxBinsContentGridPlanning_plants_count: 'Nº Plants',
      DxNursery_Title: 'Nursery',
      CreatePlanning_Duplicated_Nursery_Rows: 'You have duplicated one or more Nursery rows',
      Nursery_OnlyOneLastLevelMessage: '* CAUTION: Select only one last level',
      DetailTabs_nursery_title: 'Nursery',
      BatchesInfoForm_name: 'Name',
      DxProductWarehouse_edit_validation: 'Product is already sent',
      Bins_move_navbar_title: 'Move Bins Content',
      LeftPanel_subtitle_monthlyPlanning: 'Annual Budget',
      AnnualBudgets_Title: 'Annual Budget',
      DxAnnualBudgets_year_caption: 'Year',
      DxAnnualBudgets_version_caption: 'Version',
      DxAnnualBudgets_status_caption: 'Status',
      DxAnnualBudgets_createAnnualBudget_text: 'Create Annual Budget',
      CreateAnnualBudget_Title: 'Create Annual Budget',
      AnnualBudget_version_validation: 'The field Version is required',
      AnnualBudget_year_validation: 'The field Year is required',
      DxAnnualBudgetForm_version_caption: 'Version',
      DxAnnualBudgetForm_year_caption: 'Year',
      PlanningComponent_monthlyPlanning: 'Annual Budget',
      CloneAnnualBudget_Title: 'Clone Annual Budget Version',
      AnnualBudget_catch_NAME_EXIST_case: 'Two versions cannot have the same name',
      AnnualBudget_catch_NOT_CLOSED_case: 'All versions must be closed',
      AnnualBudget_catch_VERSION_NOT_FOUND_case: 'Version not found',
      AnnualBudget_catch_BADGET_NOT_FOUND_case: 'Badget not found',
      AnnualBudget_catch_ERROR_BADGET_MANY_VERSIONS_case: 'Badget cannot have more than 3 versions',
    },
  },
};
export default dictionary;
