<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-worker"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="worker-grid"
      :data-source="workerFormated"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxWorker"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="date"
        :caption="$t('DxWorker_date_caption')"
        :allow-header-filtering="false"
        alignment="left"
        :width="100"
      />
      <DxColumn
        data-field="activityCode"
        :caption="$t('DxWorker_ActivityCode_caption')"
        :allow-header-filtering="true"
        alignment="right"
        :width="110"
      />
      <DxColumn
        data-field="activity"
        :caption="$t('DxWorker_Activity_caption')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="taskCode"
        :caption="$t('DxWorker_codeTask_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="task"
        :caption="$t('DxWorker_task_caption')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="workerName"
        :caption="$t('DxWorker_workerName_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="group"
        :caption="$t('DxWorker_group_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="shift"
        :caption="$t('DxWorker_shift_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="typeWorker"
        :caption="$t('DxWorker_typeWorker_caption')"
        :allow-header-filtering="true"
        alignment="left"
        :width="100"
      />
      <DxColumn
        data-field="locations"
        :caption="$t('DxWorker_territorialUnit_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="initDate"
        :caption="$t('DxWorker_checkIn_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="endDate"
        :caption="$t('DxWorker_checkOut_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="totalHoursForMethod"
        :caption="$t('DxWorker_totalHours_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="performanceCount"
        :caption="$t('DxWorker_performanceCount_caption')"
        :allow-header-filtering="false"
        alignment="right"
        :calculate-cell-value="checkPerformance"
      />
      <DxColumn
        data-field="performanceUnit"
        :caption="$t('DxWorker_performanceUnit_caption')"
        :allow-header-filtering="true"
        alignment="left"
        :calculate-cell-value="checkPerformanceUnit"
      />
      <DxColumn
        data-field="ratioTimeSpent"
        :caption="$t('ReportingEmployeeEffectiveness_ratioTimeSpent')"
        :allow-header-filtering="false"
        alignment="center"
        width="100"
      />
      <DxColumn
        data-field="ratioTotalPerformance"
        :caption="$t('ReportingEmployeeEffectiveness_ratioTotalPerformance')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="ratioPerformanceGoal"
        :caption="$t('ReportingEmployeeEffectiveness_ratioPerformanceGoal')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="note"
        :caption="$t('DxWorker_note_caption')"
        :allow-header-filtering="false"
        alignment="left"
        :width="200"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
    <div>
      <DxSideBySideBar :reporting-worker="reportingWorker" />
    </div>
    <div>
      <DxSideBySideBarDayWorked :reporting-worker="reportingWorker" />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';
import DxSideBySideBar from '../chartPlanned/DxSideBySideBar.vue';
import DxSideBySideBarDayWorked from '../chartPlanned/DxSideBySideBarDayWorked.vue';

export default {
  name: 'DxWorker',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
    DxSideBySideBar,
    DxSideBySideBarDayWorked,
  },
  data() {
    return {
      loaded: false,
      workerFormated: [],
      locationsTree: [],
      locationsTreeFormated: [],
      actualDate: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      keyName: 'reportingWorker',
      pageSizes: [5, 10, 20],
      ratiosPerformances: [],
      reportingWorker: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      let xhr = await Api.getLocationsTree();
      this.locationsTree = JSON.parse(xhr.response);
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      this.ratiosPerformances = await this.getRatioPerformance(checkedInitEndDate.init, checkedInitEndDate.end);
      xhr = await Api.getWorkersSummary(checkedInitEndDate.init, checkedInitEndDate.end);
      this.reportingWorker = JSON.parse(xhr.response).data;
      for (let i = 0; i < this.reportingWorker.length; i += 1) {
        let workerName = '';
        let workerId = '';
        let idNumber = '';
        let typeWorker = '';
        let endDate = '';
        let initDate = '';
        let totalHoursForMethod = '';
        let performanceCount = '';
        let performanceUnit = '';
        let workingArea = '';
        let note = '';
        for (let j = 0; j < this.reportingWorker[i].workers.length; j += 1) {
          workerName = `${this.reportingWorker[i].workers[j].name} ${this.reportingWorker[i].workers[j].surname}`;
          workerId = this.reportingWorker[i].workers[j].id;
          idNumber = this.reportingWorker[i].workers[j].code;
          typeWorker = this.reportingWorker[i].workers[j].type;
          if (this.reportingWorker[i].workers[j].end_date !== null) {
            endDate = moment(this.reportingWorker[i].workers[j].end_date).format('HH:mm');
          } else {
            endDate = '';
          }
          initDate = moment(this.reportingWorker[i].workers[j].init_date).format('HH:mm');
          totalHoursForMethod = this.getTotalWorkingHours(this.reportingWorker[i].workers[j].init_date,
            this.reportingWorker[i].workers[j].end_date);
          performanceCount = this.reportingWorker[i].workers[j].performance;
          performanceUnit = this.reportingWorker[i].workers[j].unit;
          workingArea = this.reportingWorker[i].workers[j].working_area;
          note = this.reportingWorker[i].workers[j].comment;
        }
        const ratioPerformance = this.getWorkerRatioPerformanceByFullName(workerId);
        this.workerFormated.push({
          date: this.reportingWorker[i].date,
          task: this.reportingWorker[i].task,
          typeTask: this.reportingWorker[i].task_type,
          taskCode: this.reportingWorker[i].task_code,
          activity: this.reportingWorker[i].activity,
          activityCode: this.reportingWorker[i].task_code.substr(0, 2),
          locations: this.getNamesByBdIds(this.reportingWorker[i].locations),
          workerName,
          workerId,
          idNumber,
          typeWorker,
          endDate,
          initDate,
          totalHoursForMethod,
          jornada: parseFloat((totalHoursForMethod / 9)).toFixed(2),
          performanceCount, // The second productivity
          performanceUnit,
          workingArea, // The first productivity
          note,
          group: this.reportingWorker[i].group,
          shift: this.reportingWorker[i].shift,
          ratioTimeSpent: ratioPerformance.ratioTimeSpent.toFixed(2),
          ratioTotalPerformance: ratioPerformance.ratioTotalPerformance.toFixed(2),
          ratioPerformanceGoal: ratioPerformance.ratioPerformanceGoal.toFixed(2),
        });
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    checkPerformance(rowData) {
      return (rowData.performanceUnit === '') ? rowData.workingArea : rowData.performanceCount;
    },
    checkPerformanceUnit(rowData) {
      return (rowData.performanceUnit === '') ? 'HA' : rowData.performanceUnit;
    },
    getTotalWorkingHours(init, end) {
      if (end === null) {
        const ms = moment(this.actualDate).diff(moment(init));
        const d = moment.utc(ms).format('HH:mm:ss');
        return `${d}`;
      }
      const ms = moment(end).diff(moment(init));
      const d = moment.utc(ms).format('HH:mm:ss');
      return `${d}`;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ReportingWorkers');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReportingWorkers.xlsx');
          });
      });
      e.cancel = true;
    },
    setLocationTreeFormat(item) {
      this.locationsTreeFormated.push({
        bd_id: item.bd_id,
        children: item.children,
        name: item.name,
      });
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    getNamesByBdIds(ids) {
      const names = [];
      for (const id of ids) {
        names.push(this.getNameById(id));
      }
      return names;
    },
    getNameById(id) {
      for (const locationTreeFormated of this.locationsTreeFormated) {
        if (locationTreeFormated.bd_id === id) {
          return locationTreeFormated.name;
        }
      }
      return '';
    },
    async getRatioPerformance(init, end) {
      const xhr = await Api.getDailyTasks(true, [], [], init, end);
      const dailyTasks = JSON.parse(xhr.response);
      const tasksCompleted = this.getTasksCompleted(dailyTasks.detail);
      const ratioPerformanceFormated = [];
      for (const taskCompleted of tasksCompleted) {
        let totalTime = 0;
        let totalPerformance = 0;
        const performanceGoal = taskCompleted.planner.goal_performance.count;
        for (const worker of taskCompleted.workers) {
          totalTime += this.getTotalWorkingHoursRatioPerformance(worker.init_date, worker.end_date);
          totalPerformance += parseFloat(worker.performance, 10).toFixed(2);
        }
        for (const worker of taskCompleted.workers) {
          const workerId = worker.id;
          const timeSpent = this.getTotalWorkingHoursRatioPerformance(worker.init_date, worker.end_date);
          const performance = parseFloat(worker.performance, 10).toFixed(2);
          const ratioTimeSpent = (100 * timeSpent) / totalTime;
          const ratioTotalPerformance = (100 * performance) / totalPerformance;
          const ratioPerformanceGoal = (100 * performance) / performanceGoal;
          ratioPerformanceFormated.push({
            workerId,
            ratioTimeSpent: this.returnDataNotNaN(ratioTimeSpent),
            ratioTotalPerformance: this.returnDataNotNaN(ratioTotalPerformance),
            ratioPerformanceGoal: this.returnDataNotNaN(ratioPerformanceGoal),
          });
        }
      }
      return ratioPerformanceFormated;
    },
    getTasksCompleted(details) {
      for (const detail of details) {
        if (detail.status === 'COMPLETED') {
          return detail.tasks;
        }
      }
      return [];
    },
    returnDataNotNaN(element) {
      if (isNaN(element) || !isFinite(element)) {
        return 0;
      }
      return element;
    },
    getTotalWorkingHoursRatioPerformance(init, end) {
      if (end === null) {
        const ms = moment().diff(moment(init));
        const d = moment.duration(ms);
        return `${d.hours()}`;
      }
      const ms = moment(end).diff(moment(init));
      const d = moment.duration(ms);
      return `${d.hours()}`;
    },
    getWorkerRatioPerformanceByFullName(workerId) {
      for (const ratioPerformance of this.ratiosPerformances) {
        if (ratioPerformance.workerId === workerId) {
          return {
            ratioTimeSpent: ratioPerformance.ratioTimeSpent,
            ratioTotalPerformance: ratioPerformance.ratioTotalPerformance,
            ratioPerformanceGoal: ratioPerformance.ratioPerformanceGoal,
          };
        }
      }
      return {
        ratioTimeSpent: 0,
        ratioTotalPerformance: 0,
        ratioPerformanceGoal: 0,
      };
    },
  },
};
</script>
