<template>
  <f7-page>
    <navbar :text="$t('ProductsDatabase_title')" />
    <DxProductsDataBase />
  </f7-page>
</template>

<script>
import DxProductsDataBase from '../../components/product/DxProductsDataBase.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ProductsLocalDatabase',
  components: {
    DxProductsDataBase,
    navbar,
  },
};
</script>
