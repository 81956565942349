<template>
  <f7-page>
    <navbar :text="$t('ReportingBPAS_navbar_title')" />
    <DxBpas />
  </f7-page>
</template>

<script>
import DxBpas from '../../components/reporting/DxBpas.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ReportingBpas',
  components: {
    DxBpas,
    navbar,
  },
};
</script>