<template>
  <div
    v-if="loaded"
    class="main-binstable"
  >
    <div
      class="content-devx"
    >
      <DxDataGrid
        id="binsTable"
        ref="binsTable"
        :data-source="bindata"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        :column-auto-width="true"
      >
        <DxSelection
          mode="single"
        />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          :storage-key="'DxBinsTable'"
        />
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          type="buttons"
          data-field=""
          :allow-header-filtering="false"
          cell-template="buttonsTemplate"
        />
        <DxColumn
          data-field="code"
          name="code"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBinsTable_dxColumn_Code')"
        />
        <DxColumn
          data-field="name"
          name="name"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBinsTable_dxColumn_Name')"
        />
        <DxColumn
          data-field="lotName"
          name="lotName"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBinsTable_dxColumn_LotName')"
        />
        <DxColumn
          data-field="origin"
          name="origin"
          alignment="left"
          :caption="$t('DxBinsTable_dxColumn_Origin')"
          :allow-header-filtering="true"
        />
        <DxColumn
          data-field="destination"
          name="destination"
          alignment="left"
          :caption="$t('DxBinsTable_dxColumn_Destination')"
          :allow-header-filtering="true"
        />
        <DxColumn
          data-field="state"
          name="state"
          alignment="center"
          :caption="$t('DxBinsTable_dxColumn_State')"
          :allow-header-filtering="true"
          :header-filter="{ dataSource: dataStatus }"
          cell-template="statusTemplate"
        />
        <template #statusTemplate="{ data: bindata }">
          <div :class="bindata.data.statusTemplateClass">
            {{ bindata.data.state }}
          </div>
        </template>
        <DxMasterDetail
          :enabled="true"
          template="master-detail"
        />
        <template #master-detail="{ data: bindata }">
          <!-- <DxDetailTabsBins :master-detail-data="bindata" /> -->
          <DxContentBin :master-detail-data="bindata" />
        </template>
        <template #buttonsTemplate="{ data: bindata }">
          <!-- CREADO -->
          <div
            v-if="bindata.data.statusTemplateClass == 'created'"
            class="templateButtons"
          >
            <!-- editar -->
            <button
              class="dx-icon-edit boton-editar"
              @click="editBin(bindata.data)"
            />
            <!-- borrar -->
            <button
              class="dx-icon-trash boton-borrar"
              @click="deleteBin(bindata.data.bd_id)"
            />
            <!-- registrar -->
            <button
              class="dx-icon-check boton-registered"
              @click="changeState(bindata.data.bd_id, 'REGISTERED')"
            >
             <!-- <span>{{ $t('DxBinsTable_ButtonText_Register') }}</span>-->
            </button>
          </div>
          <!-- REGISTRADO -->
          <div
            v-else-if="bindata.data.statusTemplateClass == 'registered' && !isReclassifying"
            class="templateButtons"
          >
            <!-- reclasificar -->
            <button
              class="dx-icon-fieldchooser boton-reclasificar"
              @click="reclassifyBin(bindata.data)"
            />
            <!-- enviar -->
            <button
              class="boton-enviar"
              @click="sendActualBin(bindata.data)"
            >
              {{ $t('DxBinsTable_ButtonText_Send') }}
            </button>
            <!-- mover -->
            <button
              class="boton-mover"
              @click="moveBinsContent(bindata.data)"
            >
              {{ $t('DxBinsTable_ButtonText_Move') }}
            </button>
            <!-- cerrar -->
            <button
              class="boton-cerrar"
              @click="changeState(bindata.data.bd_id, 'CLOSED')"
            >
              {{ $t('DxBinsTable_ButtonText_Close') }}
            </button>
          </div>
          <div
            v-else-if="bindata.data.statusTemplateClass == 'registered' && isReclassifying"
            class="templateButtons"
          >
            <!-- reclasificar -->
            <button
              class="dx-icon-fieldchooser boton-reclasificar"
              @click="reclassifyBin(bindata.data)"
            />
          </div>
          <!-- PENDIENTE DE RECIBIR -->
          <div
            v-else-if="bindata.data.statusTemplateClass == 'to_receive'"
            class="templateButtons"
          >
            <!-- confirmar recepción -->
            <button
              class="boton-confirm-reception"
              @click="changeState(bindata.data.bd_id, 'RECEIVED')"
            >
              {{ $t('DxBinsTable_ButtonText_ConfirmReception') }}
            </button>
          </div>
          <!-- RECIBIDO -->
          <div
            v-else-if="bindata.data.statusTemplateClass == 'received'"
            class="templateButtons"
          >
            <!-- reclasificar -->
            <button
              class="dx-icon-fieldchooser boton-reclasificar"
              @click="reclassifyBin(bindata.data)"
            />
          </div>
          <!-- ENVIADO y CERRADO sin acciones-->
        </template>
      </DxDataGrid>
      <DxPopup
        :visible.sync="popupOpened"
        :title="$t('DxSelectLevel0Location_modal_title')"
        :show-close-button="false"
        width="auto"
        height="auto"
      >
        <template>
          <DxSelectLevel0Location
            :level-zero-locations="levelZeroFilteredLocations"
          />
          <f7-block-footer
            class="content-action-botton"
          >
            <f7-button
              class="dx-btn-cancel margin-right"
              raised
              type="default"
              text-color="grey"
              styling-mode="outlined"
              name="ButtonCancel"
              @click="closePopup"
            >
              {{ $t('DxBinsTable_button_cancel') }}
            </f7-button>
            <f7-button
              class="dx-btn-success"
              raised
              type="success"
              styling-mode="contained"
              name="ButtonSave"
              :disabled="isLocationToSendSelected"
              @click="confirmSendActualBin()"
            >
              {{ $t("DxBinsTable_button_send") }}
            </f7-button>
          </f7-block-footer>
        </template>
      </DxPopup>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxMasterDetail,
  DxStateStoring,
  DxHeaderFilter,
  DxSelection,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import DxContentBin from './DxContentBin.vue';
import DxSelectLevel0Location from './DxSelectLevel0Location.vue';

export default {
  name: 'DxBinsTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxMasterDetail,
    DxContentBin,
    DxPopup,
    DxSelectLevel0Location,
    DxStateStoring,
    DxHeaderFilter,
    DxSelection,
  },
  props: {
    isReclassifying: { type: Boolean, default: false },
  },
  data() {
    return {
      dataGrid: this.$refs.binsTable,
      bindata: [],
      loaded: false,
      statusTranslated: '',
      popupOpened: false,
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      levelZeroFilteredLocations: [],
      binToReceive: false,
      dataStatus: [{
        text: this.$t('DxBinsTable_State_Created'),
        value: 'CREATED',
      },
      {
        text: this.$t('DxBinsTable_State_Registered'),
        value: 'REGISTERED',
      }, {
        text: this.$t('DxBinsTable_State_Closed'),
        value: 'CLOSED',
      },
      {
        text: this.$t('DxBinsTable_State_Sent'),
        value: 'SENT',
      }],
      dataWordTaskStatus: [{
        text: this.$t('DxBinsTable_State_Received'),
        value: 'RECEIVED',
      }, {
        text: this.$t('DxBinsTable_State_ToReceive'),
        value: 'TO_RECEIVE',
      }],
    };
  },
  computed: {
    ...mapState('greenhouse', [
      'binsLocationName',
      'selectedFarmToSend',
    ]),
    ...mapGetters('greenhouse', [
      'locationNameByLocationId',
      'formattedTree',
      'levelZeroLocationsNamesToSendBin',
      'getVarietyNameByCode',
      'getCategoryNameByCode',
      'getClassificationNameByCode',
    ]),
    ...mapGetters('authentication', ['userFarmsIds']),
    isLocationToSendSelected() {
      return this.selectedFarmToSend === '';
    },
  },
  async beforeMount() {
    await this.getLevelZeroLocationsToSendBin();
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      await this.fetchLocationTree();
      let xhr = '';
      if (this.isReclassifying === true) {
        xhr = await Api.binsToReclassify();
      } else {
        xhr = await Api.getBins();
      }
      const bins = JSON.parse(xhr.response);
      for (let i = 0; i < bins.data.length; i += 1) {
        let nombreLote = '';
        if (bins.data[i].lote) {
          nombreLote = bins.data[i].lote.name;
        }
        const idOrigin = bins.data[i].shipments[bins.data[i].shipments.length - 1].origin;
        const nameOrigin = this.locationNameByLocationId(idOrigin);
        let locationDest = bins.data[i].shipments[bins.data[i].shipments.length - 1].destination;
        // Comprobamos si el id de destino es una de las farms del usuario.
        if (this.userFarmsIds.indexOf(locationDest) !== -1) {
          // Establezco la variable a true para forzar que el estado sea pendiente de recibir
          this.binToReceive = true;
        }
        let nameDestination = '';
        if (!locationDest) {
          locationDest = [];
        } else {
          nameDestination = this.locationNameByLocationId(locationDest);
        }
        // Creamos un objeto con los campos de la tabla de detalles
        const shipmentsSize = bins.data[i].shipments.length;
        const contentSize = bins.data[i].shipments[bins.data[i].shipments.length - 1].content.length;
        const details = bins.data[i].shipments[shipmentsSize - 1].content[contentSize - 1].elements;
        details.forEach((element) => {
          const varietyName = this.getVarietyNameByCode(element.variety);
          const categoryName = this.getCategoryNameByCode(element.category);
          const classificationName = this.getClassificationNameByCode(element.class_type);
          element.variety = varietyName !== '' ? varietyName : element.variety;
          element.category = varietyName !== '' ? categoryName : element.category;
          element.class_type = classificationName !== '' ? classificationName : element.class_type;

          const filteredArray = this.formattedTree.filter(
            (elemento) => elemento.bd_id === element.location,
          );
          element.locationName = filteredArray.length > 0 ? filteredArray[0].name : '';
        });
        this.bindata.push({
          code: bins.data[i].code,
          name: bins.data[i].name,
          date: bins.data[i].date,
          lotName: nombreLote,
          origin: nameOrigin,
          destination: nameDestination,
          statusTemplateClass: this.setClassByStatus(bins.data[i].shipments[bins.data[i].shipments.length - 1].status),
          state: this.statusTranslated,
          binsDetail: details,
          bd_id: bins.data[i].id,
        });
      }
    } catch (e) {
      this.$f7.dialog.alert(e);
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    sendActualBin(data) {
      this.levelZeroFilteredLocations = this.levelZeroLocationsNamesToSendBin.filter(
        (elemento) => elemento !== data.origin,
      );
      this.setIdBinToSend(data.bd_id);
      this.setSelectedFarmToSend('');
      this.popupOpened = true;
    },
    async confirmSendActualBin() {
      try {
        const response = await this.fetchSendBin();
        if (response.status === 200) {
          this.setActualMenuSelected('/bins/');
          this.$f7.views.main.router.navigate('/bins/', { reloadCurrent: true });
        } else {
          this.$f7.dialog.alert(response);
        }
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    closePopup() {
      this.popupOpened = false;
    },
    async deleteBin(bdId) {
      try {
        const response = await this.fetchDeleteBin(bdId);
        if (response.status === 204) {
          this.setActualMenuSelected('/bins/');
          this.$f7.views.main.router.navigate('/bins/', { reloadCurrent: true });
        } else {
          this.$f7.dialog.alert(response);
        }
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    async changeState(bdId, newState) {
      try {
        const payload = { id: bdId, status: newState };
        const response = await this.fetchChangeBinStatus(payload);
        if (response.status === 200) {
          this.setActualMenuSelected('/bins/');
          this.$f7.views.main.router.navigate('/bins/', { reloadCurrent: true });
        } else {
          this.$f7.dialog.alert(response);
        }
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    editBin(binData) {
      this.setBinToEdit(binData);
      this.navigateTo('/bins/edit/');
    },
    reclassifyBin(bindata) {
      this.setBinToEdit(bindata);
      this.navigateTo('/bins/reclassify/');
    },
    moveBinsContent(binData) {
      this.setBinToEdit(binData);
      this.navigateTo('/bins/move/');
    },
    navigateTo(url) {
      if (url !== '/bins/edit/' || url !== '/bins/reclassify/') {
        this.setActualMenuSelected(url);
      }
      this.setActualMenuSelected('/bins/');
      this.$f7.views.main.router.navigate(url, { reloadCurrent: true });
    },
    // SET CSS CLASSES TO CELLS
    setClassByStatus(data) {
      this.statusTranslated = '';
      let colorCell = '';
      let status = data;
      if (status === 'SENT' && this.binToReceive === true) {
        status = 'TO_RECEIVE';
      }
      this.binToReceive = false;
      switch (status) {
        // CREATED REGISTERED SENT TO_RECEIVE RECEIVED CLOSED
        case 'CREATED':
          colorCell = 'created';
          this.statusTranslated = this.$t('DxBinsTable_State_Created');
          break;
        case 'REGISTERED':
          colorCell = 'registered';
          this.statusTranslated = this.$t('DxBinsTable_State_Registered');
          break;
        case 'SENT':
          colorCell = 'sent';
          this.statusTranslated = this.$t('DxBinsTable_State_Sent');
          break;
        case 'TO_RECEIVE':
          colorCell = 'to_receive';
          this.statusTranslated = this.$t('DxBinsTable_State_ToReceive');
          break;
        case 'RECEIVED':
          colorCell = 'received';
          this.statusTranslated = this.$t('DxBinsTable_State_Received');
          break;
        case 'CLOSED':
          colorCell = 'closed';
          this.statusTranslated = this.$t('DxBinsTable_State_Closed');
          break;
        default:
          break;
      }
      return colorCell;
    },
    ...mapActions('greenhouse', [
      'fetchLocationTree',
      'fetchChangeBinStatus',
      'fetchDeleteBin',
      'setIdBinToSend',
      'fetchSendBin',
      'setSelectedFarmToSend',
      'setBinToEdit',
      'getLevelZeroLocationsToSendBin',
    ]),
    ...mapActions('authentication', ['setActualMenuSelected']),
  },
};
</script>
<style lang="scss">
.templateButtons {
  display: flex;
  justify-content: flex-start;
}
.main-binstable, .templateButtons{
  .content-status-cell {
    padding: 0;
  }
  .created, .registered, .closed, .sent, .received, .to_receive{
    border-radius: 2px;
    font-size: 12px;
    padding: 4px 2px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .created {
    color: var(--fr-open);
    background: #F4F5F7;
    border: 1px solid rgba(47, 72, 89, 0.35);
  }
  .registered {
    color: white;
    background: #556080;
  }
  .closed {
    background: var(--fr-deleted-tint);
    color: var(--fr-deleted);
    border: 1px solid var(--fr-deleted);
  }
  .received {
    background: var(--fr-finished);
    color: var(--fr-finished-tint);
  }
  .sent { 
    background: var(--fr-inprogress-tint);
    border-color: var(--ha-yellow);
    color: var(--ha-orange);  
  }
  .to_receive {
    color: white;
    background: #4A98F7;
  }
  .boton-enviar, .boton-mover, .boton-cerrar, .boton-confirm-reception {
    font-size: 13px;
    line-height: 1.2;
    padding: 4px 8px 3px!important;
    width: auto;
    min-width: 26px;
    margin: 0 2px;
    border: 0;
    border-radius: 2px;
    outline:none;
  }
  .boton-confirm-reception {
    width: 100%;
    border-radius: 3px;
    color: var(--fr-open);
    background: #F4F5F7;
    border: 1px solid rgba(47, 72, 89, 0.35);
    &:hover{
      background: #D4DCE1;
    }
  }
  .boton-enviar {
    background-color: #daeed2;
    border: 1px solid rgba(54,121,14,0.35);
    color: var(--ha-dark-green);
    &:hover{
      background: #acd99a;
    }
  }
  .boton-registered {
      background-color: #daeed2;
      border: 1px solid rgba(54,121,14,0.35);
      color: var(--ha-dark-green);
   &:hover {
      background: #acd99a;
    }
    span {
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      float: right;
      display: inline-block;
      margin-left: 5px;
    }
  }
  .boton-mover {
    background-color: #eee6d2;
    border: 1px solid rgba(89,90,92,0.35);
    color: #595a5c;
    &:hover{
      background:#e7dcbf;
    }
  }
  .boton-cerrar {
    background-color: #e6e6e6;
    border: 1px solid rgba(135,119,135,0.35);
    color: #877787;
    &:hover{
      background:#dddddd;
    }
  }
  .boton-editar, .boton-borrar, .boton-reclasificar, .boton-registered {
    width: auto;
    padding: 3px 6px;
    font-weight: 500;
    border: 1px solid var(--ha-gray);
    text-transform: uppercase;
    margin-right: 5px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    text-decoration: none;
    outline:none;
  }
  .boton-editar {
    background: var(--fr-inprogress-tint);
    border-color: var(--ha-yellow);
    color: var(--ha-orange);
    &:hover {
      background: var(--ha-orange);
      border-color: var(--ha-orange);
      color: var(--fr-inprogress-tint);
    }
  }
  .boton-borrar {
    background: var(--fr-deleted-tint);
    border-color: var(--fr-deleted);
    color: var(--fr-deleted);
    &:hover {
      background: var(--ha-red);
      color: white;
    }
  }
  .boton-reclasificar {
    background: #F4F5F7;
    border-color: rgba(47,72,89,0.35);
    color: rgb(47,72,89);
    &:hover {
      background: #ececec;
      border-color: var(--ha-orange);
      color: #263b48;
    }
  }
}
</style>
