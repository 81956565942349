<template>
  <div class="content-location">
    <DxTreeList
      id="locationsTree"
      ref="treeList"
      :data-source="locationsTreeFormated"
      :show-row-lines="false"
      :show-borders="false"
      :column-auto-width="true"
      :expanded-row-keys="expandedRowKeys"
      :selected-row-keys.sync="selectedRowKeys"
      key-expr="ID"
      parent-id-expr="HEAD_ID"
      @selection-changed="saveLocations"
      :disabled="false"
    >
      <DxSelection
        v-if="editable"
        :recursive="recursive"
        mode="multiple"
      />
      <DxColumn
        data-field="location"
      />
    </DxTreeList>

    <DxForm v-if="totalArea!==0">
      <DxGroupItem
        name=""
        :caption="$t('totalArea_title')"
      >
        <DxSimpleItem
          :data-field="$t('totalArea_field')"
          editor-type="dxNumberBox"
          :editor-options="{
            value: totalArea.toFixed(2),
            mode: 'number',
            disabled: true,
          }"
        />
        <DxSimpleItem
          v-for="(item, index) in level1DataArea"
          :key="index"
          :data-field="item.name"
          editor-type="dxNumberBox"
          :editor-options="{
            value: item.area.toFixed(2),
            mode: 'number',
            disabled: true,
          }"
        />
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>
import { DxTreeList, DxSelection, DxColumn } from 'devextreme-vue/tree-list';
import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
} from 'devextreme-vue/form';
import EventBus from '../../js/event-bus';

export default {
  components: {
    DxTreeList,
    DxSelection,
    DxColumn,
    DxForm,
    DxGroupItem,
    DxSimpleItem,
  },
  props: {
    planning: { type: Object, default: null },
    locationSelected: { type: Array, default: () => [] },
    locationsTree: { type: Array, default: () => [] },
    showToDefault: { type: Boolean, default: true },
    showExpanded: { type: Boolean, default: true },
    recursive: { type: Boolean, default: true },
    editable: { type: Boolean, default: false },
    showGrouped: { type: Boolean, default: true },
    locationsLevel: { type: Array, default: () => [] },
  },
  data() {
    return {
      locations: [],
      selectedRowKeys: [],
      expandedRowKeys: [],
      locationsSelected: [],
      locationsTreeFormated: [],
      locationsAreas: [],
      level1DataArea: [],
      currentLevel1Name: '',
      currentLevel1Area: 0,
      totalArea: 0,
    };
  },
  computed: {
    ...mapState('PlanningManager', ['actualLocationsSelected', 'actualLocationsTreeFormated', 'groupedSelected', ]),
  },
  mounted() {
    if (this.planning != null) {
      this.locations = this.planning.location;
    }
    this.setActualLocationsSelected([]);
    for (const item of this.locationsTree) {
      this.setLocationTreeFormat(item);
      this.recursiveAddChildrens(item.children);
    }
    this.setActualLocationsTreeFormated(this.locationsTreeFormated);
    if (this.locations.length !== 0) {
      this.locationsSelected = this.locations;
      this.setSelectedRowKeys();
    }
  },
  methods: {
    // Añado el método groupSelected de la clase PlanningForm para el campo group.
    groupedSelectedEvent(e) {
      this.setGroupedSelected(e.value);
      this.actualGrouped = e.value;
    },
    setExpandedRowKeys(location) {
      let parentLocation = location;
      while (parentLocation.HEAD_ID !== null) {
        if (this.expandedRowKeys.indexOf(parentLocation.ID) === -1) {
          this.expandedRowKeys.push(parentLocation.ID);
        }
        parentLocation = this.locationsTreeFormated[parentLocation.HEAD_ID - 1];
      }
      if (this.expandedRowKeys.indexOf(parentLocation.ID) === -1) {
        this.expandedRowKeys.push(parentLocation.ID);
      }
    },
    recursiveSelectedKeys(location) {
      if (this.locations.indexOf(location.bd_id) > -1) {
        if (this.selectedRowKeys.indexOf(location.ID) === -1) {
          this.selectedRowKeys.push(location.ID);
          if (this.showExpanded) {
            this.setExpandedRowKeys(location);
          }
        }
      }
      for (let i = 0; i < location.children.length; i += 1) {
        this.recursiveSelectedKeys(location.children[i]);
      }
    },
    setSelectedRowKeys() {
      this.selectedRowKeys = [];
      for (let i = 0; i < this.locationsTreeFormated.length; i += 1) {
        this.recursiveSelectedKeys(this.locationsTreeFormated[i]);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      if (item.level > 3) {
        this.locationsTreeFormated.push({
          location: `${item.name} -${item.properties.variety_code}- ${item.area.toFixed(2)} ha`,
          bd_id: item.bd_id,
          ID: item.ID,
          HEAD_ID: item.HEAD_ID,
          children: item.children,
          level: item.level,
          name: item.name,
          area: item.area,
        });
      } else {
        this.locationsTreeFormated.push({
          location: `${item.name} - ${item.area.toFixed(2)} ha`,
          bd_id: item.bd_id,
          ID: item.ID,
          HEAD_ID: item.HEAD_ID,
          children: item.children,
          level: item.level,
          name: item.name,
          area: item.area,
        });
      }
    },
    saveLocations(selection) {
      this.locationsSelected = [];
      this.locationsAreas = [];
      this.totalArea = 0;
      this.currentLevel1Name = '';
      this.currentLevel1Area = 0;
      this.level1DataArea = [];
      for (let i = 0; i < selection.selectedRowsData.length; i += 1) {
        this.recursiveLocationsSelected(selection.selectedRowsData[i]);
      }
      this.locationsAreas = [...this.locationsSelected];
      this.setTotalAreasLevel1();
      this.setActualLocationsSelected(this.locationsSelected);
      this.setTotalAreaSelected(this.totalArea.toFixed(2));
      EventBus.$emit('changeTotalAreaSelected');
    },
    setTotalAreasLevel1() {
      for (let i = 0; i < this.locationsTreeFormated.length; i += 1) {
        if (this.locationsAreas.length === 0) {
          // Si no hay ids en el array de seleccionados, no recorremos el bucle
          break;
        } else if (this.locationsTreeFormated[i].level === 1) {
          if (this.currentLevel1Name !== '' && this.currentLevel1Area !== 0) {
            const item = { name: this.currentLevel1Name, area: this.currentLevel1Area };
            this.level1DataArea.push(item);
          }
          // Solo comprobamos los elementos con nivel === 1, sus hijos los comprobamos dentro
          this.currentLevel1Name = this.locationsTreeFormated[i].name;
          this.currentLevel1Area = 0;
          this.recursiveGetAreasChildrens(this.locationsTreeFormated[i]);
        }
      }
      if (this.currentLevel1Name !== '' && this.currentLevel1Area !== 0) {
        const item = { name: this.currentLevel1Name, area: this.currentLevel1Area };
        this.level1DataArea.push(item);
      }
    },
    recursiveGetAreasChildrens(items) {
      if (items.children.length === 0) {
        // Si el nivel no tiene hijos, comprobamos si su id está en el array
        const check = this.locationsAreas.indexOf(items.bd_id);
        if (check !== -1) {
          // Está en el array, lo guardamos para pintar su area y sacamos el id del array.
          this.currentLevel1Area += items.area;
          this.locationsAreas.splice(check, 1); // Elimino el id del array
        }
      } else {
        // El nivel tiene hijos
        for (let j = 0; j < items.children.length; j += 1) {
          this.recursiveGetAreasChildrens(items.children[j]);
        }
      }
    },
    recursiveLocationsSelected(locationSelected) {
      if (locationSelected.children.length === 0) {
        this.totalArea += locationSelected.area;
        this.addLocationsSelected(locationSelected.bd_id);
      } else {
        for (let i = 0; i < locationSelected.children.length; i += 1) {
          this.recursiveLocationsSelected(locationSelected.children[i]);
        }
      }
    },
    addLocationsSelected(bdId) {
      if (this.locationsSelected.indexOf(bdId) === -1) {
        this.locationsSelected.push(bdId);
      }
    },
    ...mapActions('TaskManager', ['setTotalAreaSelected']),
    ...mapActions('PlanningManager', ['setActualLocationsSelected', 'setActualLocationsTreeFormated', 'setGroupedSelected']),
  },
};
</script>
<style lang="scss" scoped>
#employees {
    max-height: 440px;
}
.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}
</style>
