<template>
  <DxForm
    id="formDate"
    :col-count="1"
  >
    <DxSimpleItem
      data-field="date"
      name="date"
      editor-type="dxDateBox"
      :caption="$t('DxSelectDate_date_caption')"
      :editor-options="{
        value: date,
        displayFormat: 'dd/MM/yyyy',
        onValueChanged: dateSelectedEvent,
        min: minDate,
        disabledDates:disableSundays,
      }"
    />
  </DxForm>
</template>

<script>
import moment from 'moment';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';

export default {
  components: {
    DxForm,
    DxSimpleItem,
  },
  props: {
    actualDate: { type: String, default: '' },
  },
  data() {
    return {
      minDate: moment(new Date()).format('MM/DD/YYYY'),
      date: '',
    };
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    this.date = moment(this.actualDate, 'DD-MM-YYYY').format('MM/DD/YYYY');
    this.setDateWorkOrder(moment(this.actualDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
  },
  methods: {
    dateSelectedEvent(e) {
      const newValue = (e.value) ?? 0;
      this.setDateWorkOrder(moment(newValue, 'YYYY/MM/DD').format('YYYY-MM-DD'));
      this.date = newValue;
    },
    disableSundays(e) {
      if (e) {
        return [0].indexOf(e.date.getDay()) >= 0;
      }
      return undefined;
    },
    ...mapActions('PlanningManager', ['setDateWorkOrder']),
  },
};
</script>

<style>

</style>
