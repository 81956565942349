<template>
  <f7-sheet
    class="demo-sheet"
    data-swipe-to-close="true"
  >
    <f7-page-content>
      <f7-row>
        <f7-col width="25">
          <div class="item-media">
            <div
              v-if="event.type==='ERROR'"
              class="event-type-icon"
            />
            <div v-else>
              <img
                class="ic-user"
              >
            </div>
          </div>
        </f7-col>
        <f7-col
          width="45"
          style="margin-top:5%;color:black;"
        >
          <strong v-if="event.type==='ERROR' && event.status===404">
            {{ $t("Nfc_into_error_404") }} : {{ tag }}
          </strong>
          <strong v-else-if="event.type==='ERROR' && event.status===400">
            {{ $t("Nfc_into_error_400") }} : {{ tag }}
          </strong>
          <strong v-else-if="event.type==='ERROR' && event.status===406">
            {{ $t("Nfc_into_error_406") }}
          </strong>
          <strong v-else-if="event.type==='ERROR' && event.status===409">
            {{ $t("Nfc_into_error_409") }}
          </strong>
          <strong v-else-if="event.type==='ERROR' && event.error!==''">
            {{ event.error }}
          </strong>
          <strong v-else>
            {{ selectedWorker.name }} {{ selectedWorker.surname }}
          </strong>
          <div style="color:green" />
        </f7-col>
        <f7-col width="30">
          <div class="item-media">
            <div class="event-type-icon-error">
              <img
                v-if="event!=null && event.type==='ERROR'"
                class="ic-err"
              >
              <img
                v-else-if="event!=null && event.type==='IN'"
                class="ic-ok"
              >
              <img
                v-else
                class="ic-out"
              >
            </div>
          </div>
        </f7-col>
      </f7-row>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SheetNFC',
  props: { tag: { type: String, default: '' } },
  data() {
    return {
    };
  },

  computed: {
    ...mapState('TaskManager', ['workers']),
    ...mapState('worker', ['selectedWorker', 'event']),
  },
};
</script>

<style lang="scss" scoped>
.demo-sheet.sheet-modal.sheet-modal-bottom.modal-in {
  height: auto !important;
}
.page-content {
  color: #9b9b9b !important;

  .block-title-medium {
    color: #9b9b9b !important;
  }
}
.i {
  width: 100px !important;
  height: 100px !important;
}
.ic-ok {
  content: url("../static/img/ic_ok.svg");
  width: 100%;
  height: 100%;
}
.ic-out {
  content: url("../static/img/ic_out.svg");
  width: 100%;
  height: 100%;
}
.ic-err {
 content: url("../static/img/incidencia.svg");
  width: 100%;
  height: 100%
}

.ic-user {
  content: url("../static/img/ic_user.svg");
  width: "58";
  height: "58";
  padding: 25% !important;
}

</style>
