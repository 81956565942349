var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',[_c('f7-row',[_c('f7-col',[_c('DxForm',[_c('DxGroupItem',{attrs:{"css-class":"content-activity"}},[_c('DxSimpleItem',{attrs:{"css-class":"content-activity__area","data-field":_vm.$t('totalArea_field'),"editor-type":"dxNumberBox","editor-options":{
              value: _vm.totalAreaSelected,
              mode: 'number',
              disabled: true,
            }}})],1)],1),_vm._v(" "),_c('DxForm',{attrs:{"id":"form","caption":"Activity Task"}},[_c('DxGroupItem',[_c('DxGroupItem',{attrs:{"caption":_vm.$t('PlanningComponent_caption_text')}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_activityName_caption'),"editor-options":{
                items: _vm.activitiesName,
                searchEnabled: true,
                searchTimeout: _vm.searchTimeoutOption,
                minSearchLength: _vm.minSearchLengthOption,
                showDataBeforeSearch: _vm.showDataBeforeSearchOption,
                onItemClick: _vm.activitySelectedEvent,
                value: _vm.actualActivityName,
              },"editor-type":"dxSelectBox","name":"activityName"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxPlanningForm_activity_name')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_activitycode_caption'),"name":"activityCode","caption":_vm.$t('PlanningComponent_activitycode_caption'),"editor-options":{ disabled: true, value: _vm.actualActivityCode }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_taskName_caption'),"editor-options":{
                dataSource: _vm.tasksAvailableName,
                searchEnabled: true,
                searchTimeout: _vm.searchTimeoutOption,
                minSearchLength: _vm.minSearchLengthOption,
                showDataBeforeSearch: _vm.showDataBeforeSearchOption,
                onItemClick: _vm.taskSelectedEvent,
                onValueChanged: _vm.taskSelectedEvent,
                value: _vm.actualTaskName
              },"editor-type":"dxSelectBox","name":"taskName","caption":_vm.$t('PlanningComponent_taskName_caption')}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxPlanningForm_Task_name')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_taskCode_caption'),"name":"taskCode","caption":_vm.$t('PlanningComponent_taskCode_caption'),"editor-options":{ disabled: true, value: _vm.actualTaskCode }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_variety_caption'),"editor-options":{
                items: _vm.varietyAvailables,
                searchEnabled: true,
                searchTimeout: _vm.searchTimeoutOption,
                minSearchLength: _vm.minSearchLengthOption,
                showDataBeforeSearch: _vm.showDataBeforeSearchOption,
                onItemClick: _vm.varietySelectedEvent,
                displayExpr: 'name',
                valueExpr: 'name',
                value: _vm.actualVariety,
              },"editor-type":"dxSelectBox","name":"variety","visible":_vm.actualActivityCode === '08'}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxPlanningForm_variety_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_date_caption'),"name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('f7-input',{staticClass:"form-calendar",attrs:{"id":"datepicker-multiple","type":"datepicker","placeholder":_vm.$t('PlanningComponent_datepicker_multiple_placeholder'),"readonly":"","clear-button":"","calendar-params":{openIn: 'customModal', header: false, footer: true, dateFormat: 'dd/mm/yyyy', multiple: _vm.calendarMultiple, minDate: _vm.minDate, maxDate: _vm.maxDate, disabled:_vm.sundays},"value":_vm.actualDate},on:{"calendar:change":_vm.dateSelectedEvent}})]},proxy:true}],null,false,742704698)},[_vm._v(" "),_c('DxRequiredRule',{attrs:{"message":_vm.$t('CreatePlanning_ValidationDate')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('PlanningComponent_workingarea_caption')) + " (HA)"),"name":"workingArea","caption":_vm.$t('PlanningComponent_workingarea_caption'),"editor-options":{
                disabled:_vm.workingAreaDisabled,
                value: _vm.workingArea,
                showSpinButtons: false,
                mode: 'number',
                placeholder: '0',
                showClearButton: true,
                min: '0',
                max: _vm.totalAreaSelected,
                step: '1',
                onValueChanged: _vm.workingAreaChanged,
              }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_distributed_caption'),"name":"distributed","visible":_vm.distributedVisible,"caption":_vm.$t('PlanningComponent_distributed_caption'),"editor-type":"dxCheckBox","editor-options":{
                value: _vm.distributed,
                onValueChanged: _vm.distributedChanged,
              }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('PlanningComponent_performanceCount_caption')) + " (" + _vm.actualPerformanceUnit + ")"),"name":"PerformanceCount","editor-type":"dxNumberBox","editor-options":{
                value: _vm.actualPerformanceCount,
                showSpinButtons: false,
                mode: 'number',
                placeholder: '0',
                showClearButton: true,
                min: '0',
                step: '1',
                onValueChanged: _vm.performanceUnitSelectEvent,
              },"visible":_vm.visiblePerformance}})],1)],1),_vm._v(" "),(_vm.showTables)?_c('DxGroupItem',[_c('DxGroupItem',{attrs:{"caption":_vm.$t('PlanningComponent_numberWorkers_caption')}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('TEMPORARY'),"editor-type":"dxNumberBox","editor-options":{
                value: _vm.getWorkerTypeValue('TEMPORARY'),
                onValueChanged: _vm.temporarySelectEvent,
                showSpinButtons: false,
                mode: 'number',
                placeholder: '0',
                showClearButton: true,
                min: '0'
              }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PERMANENT'),"editor-type":"dxNumberBox","editor-options":{
                value: _vm.getWorkerTypeValue('PERMANENT'),
                onValueChanged: _vm.permanentSelectEvent,
                showSpinButtons: false,
                mode: 'number',
                placeholder: '0',
                showClearButton: true,
                min: '0'
              }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('CONTRATIST'),"editor-type":"dxNumberBox","editor-options":{
                value: _vm.getWorkerTypeValue('CONTRATIST'),
                onValueChanged: _vm.contratistSelectEvent,
                showSpinButtons: false,
                mode: 'number',
                placeholder: '0',
                showClearButton: true,
                min: '0'
              }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_shift_caption'),"editor-options":{
                dataSource: ['Shift 1', 'Shift 2', 'Shift 3' ],
                searchEnabled: true,
                searchTimeout: _vm.searchTimeoutOption,
                minSearchLength: _vm.minSearchLengthOption,
                showDataBeforeSearch: _vm.showDataBeforeSearchOption,
                onItemClick: _vm.shiftSelectedEvent,
                value: _vm.actualShift,
              },"editor-type":"dxSelectBox","name":"Shift","caption":_vm.$t('PlanningComponent_shift_caption')}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_groupName_caption'),"name":"groupName","caption":_vm.$t('PlanningComponent_groupName_caption'),"editor-options":{
                value: _vm.groupName,
                onValueChanged: _vm.groupNameEvent,
                maxLength: 10,
              }}})],1)],1):_vm._e()],1)],1)],1),_vm._v(" "),_c('f7-row',{staticClass:"block"},[(_vm.showTables)?_c('f7-col',{staticClass:"content-machinery"},[_c('f7-block-title',[_vm._v("\n        "+_vm._s(_vm.$t('DxMachinery_Title'))+"\n      ")]),_vm._v(" "),_c('DxMachineryTable',{attrs:{"machinary":_vm.machinaryList,"planning":_vm.planning}}),_vm._v(" "),_c('f7-block-title',[_vm._v("\n        "+_vm._s(_vm.$t('DxAttachment_Title'))+"\n      ")]),_vm._v(" "),_c('DxAttachmentTable',{attrs:{"attachment":_vm.attachmentList,"planning":_vm.planning}}),_vm._v(" "),_c('f7-block-title',[_vm._v("\n        "+_vm._s(_vm.$t('DxTools_Title'))+"\n      ")]),_vm._v(" "),_c('DxToolsTable',{attrs:{"select-tool":_vm.toolList,"planning":_vm.planning,"allow-updating":true,"allow-deleting":true,"allow-adding":true}})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }