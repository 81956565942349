<template>
  <f7-page
    name="nfcPage"
    @page:beforein="beforein"
    @page:beforeout="beforeout"
  >
    <navbar
      :elements="elements"
      :text="`${currentFarmCode} - ${$t('Nfc_navbarpresence_incidence')}`"
      :path-back="pathBack"
    />
    <f7-page-content>
      <f7-block class="text-align-center">
        <f7-icon icon="icon-scan" />
      </f7-block>
      <f7-block name="info">
        <div class="content-block text-align-center">
          <f7-block-title medium>
            {{ $t("Nfc_info_txtTitle") }}
          </f7-block-title>
          <p>{{ $t("Nfc_info_txtSubTitle") }}</p>
        </div>
      </f7-block>
      <!-- <f7-block>
        <f7-row>
          <f7-col>
            <f7-button
              name="btnChangePassword"
              class="botonPrimary"
              col
              button
              button-large
              button-raised
              fill
              @click="virtualNFC('04DFA3EA265E80')"
            >
              virtual nfc
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>-->
    </f7-page-content>
    <SheetNFC :tag="code" />
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../components/NavBar.vue';
// import { f7NavTitleLarge } from 'framework7-vue';
import EventBus from '../../js/event-bus';
import SheetNFC from '../../components/SheetNFC.vue';
import Api from '../../services/Api';

export default {
  name: 'NfcPage',

  components: {
    navbar,
    SheetNFC,
  },

  data() {
    return {
      code: '',
      manualAccessControl: false,
      eventAction: (newId) => this.manualAccessAction(newId),
      pathBack: '/tasks/workersSelection/',
      elements: [
        {
          iconEnable: true,
          textEnable: false,
          bagdeEnable: false,
          info: { urlImage: 'icon-new', urlDestiny: '/nfcManually/' },
        },
      ],
    };
  },
  computed: {
    scanDetailsOpened() {
      return this.persona !== null || this.personNotExists === true;
    },
    ...mapState('TaskManager', ['id']),
    ...mapState('worker', ['selectedWorker', 'event', 'upSheet']),
    ...mapState('AccessControl', ['currentFarmCode']),
  },
  methods: {
    beforein() {
      this.updateStateSheet();
      this.subscribeNFC();
    },
    beforeout() {
      EventBus.$off('nfc-scan', this.scanNFC);
      this.$f7.sheet.close('.demo-sheet');
    },
    subscribeNFC() {
      EventBus.$on('nfc-scan', this.scanNFC);
    },
    scanNFC(tag) {
      this.checkIn('NFC', tag);
    },
    modalInfoClosed() {
      this.closeModal();
    },
    async checkIn(type, code) {
      this.$f7.preloader.show();
      try {
        const xhr = await Api.checkIn(this.id, type, code);
        const response = JSON.parse(xhr.response);
        this.setWorkers(response.workers);
        this.setSelectedWorker(response);
        const { event } = response;
        event.push({ type: 'IN' });
        this.setEvent(event);
        this.$f7.sheet.open('.demo-sheet');
      } catch (error) {
        if (error === 400) {
          this.setType(type);
          this.setCode(code);
          this.$f7.views.main.router.navigate(`/tasks/checkOut${'/nfc/'}`, { reloadCurrent: true });
        } else {
          const event = { type: 'ERROR', status: error, error };
          this.code = code;
          this.setEvent(event);
          this.$f7.sheet.open('.demo-sheet');
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },

    virtualNFC(tag) {
      this.checkIn('NFC', tag);
    },
    updateStateSheet() {
      if (this.upSheet === true) {
        this.$f7.sheet.open('.demo-sheet');
        this.changeUpSheet();
      }
    },
    ...mapActions('TaskManager', ['setWorkers', 'changeUpSheet']),
    ...mapActions('worker', [
      'changeUpSheet',
      'setSelectedWorker',
      'setEvent',
      'setType',
      'setCode',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.i.icon.icon-menu.icon-new {
  width: 100px !important;
  height: 200px !important;
}
.page-content {
  color: #9b9b9b !important;

  .block-title-medium {
    color: #9b9b9b !important;
  }
}
div.demo-sheet.sheet-modal.sheet-modal-bottom.modal-in {
  height: 40%;
}

.i.icon.icon-menu.icon-new {
  height: 100px;
}
</style>
