<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-work-orders main-dx-taskorders"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="gridContainer"
      :data-source="detailpostWortJson"
      :remote-operations="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      @toolbar-preparing="onToolbarPreparing($event)"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxTaskOrders"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxSelection
        mode="single"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        :buttons="editButtons"
        type="buttons"
        width="90"
        data-field="Actions"
      />
      <DxColumn
        data-field="shortId"
        name="shortId"
        :caption="$t('DxWorkOrder_short_id')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Date"
        name="date"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Activity"
        name="Activity"
        :caption="$t('DxEffort_activity_caption')"
        :allow-header-filtering="true"
        css-class="bold"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :caption="$t('DxDailyPlanning_task_caption')"
        :allow-header-filtering="true"
        css-class="bold"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        :caption="$t('DxDailyPlanning_location_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Workers"
        name="workers"
        :caption="$t('DxDailyPlanning_workers_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="Supervisor"
        name="supervisor"
        :caption="$t('DxDailyPlanning_supervisors_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxMasterDetail
        :enabled="true"
        template="master-detail"
      />
      <template #master-detail="{ data: detailpostWortJson }">
        <DxDetailTabs :master-detail-data="detailpostWortJson" />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxMasterDetail,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import DxDetailTabs from './DxDetailTabs.vue';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxMasterDetail,
    DxDetailTabs,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      locationsFake: [],
      detailpostWortJson: [],
      locationsTree: [],
      finalLevelLocation: [],
      supervisorsAvailables: [],
      taskStatusTranslated: '',
      workOrderStatusTranslated: '',
      machineryPopup: [],
      productPopup: [],
      postWork: [],
      ocultarBotton: false,
      editButtons: [{
        icon: 'find',
        hint: this.$t('DxWorkOrder_Button_View_hint'),
        visible: this.isViewconVisible,
        onClick: this.viewtask,
      },
      ],
      keyName: 'postWork',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getVarietyNameByCode', 'getCategoryNameByCode', 'getClassificationNameByCode']),
  },
  async beforeMount() {
    this.locationsTree = await this.loadLocationsTree();
    this.supervisorsAvailables = await this.getSupervisors();
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      this.postWork = await Api.getPostWork(checkedInitEndDate.init, checkedInitEndDate.end);
      const postWortJson = JSON.parse(this.postWork.response);
      this.detailpostWortJson.length = 0;
      for (let i = 0; i < postWortJson.length; i += 1) {
        const items = this.getItems(postWortJson[i].items_drivers);
        this.locationsFake = [...postWortJson[i].location];
        // Establedemos los nombres de variedad/categoria/clasificacion a través del code
        postWortJson[i].nursery.forEach((element) => {
          const varietyName = this.getVarietyNameByCode(element.variety);
          const categoryName = this.getCategoryNameByCode(element.category);
          const classificationName = this.getClassificationNameByCode(element.class_type);
          element.variety = varietyName !== '' ? varietyName : element.variety;
          element.category = varietyName !== '' ? categoryName : element.category;
          element.class_type = classificationName !== '' ? classificationName : element.class_type;
        });
        if (postWortJson[i].planner != null) {
          this.detailpostWortJson.push({
            ID: postWortJson[i].id,
            Date: this.formatoDate(postWortJson[i].date),
            Task: postWortJson[i].task,
            taskCode: postWortJson[i].task_code,
            Activity: postWortJson[i].activity,
            activityCode: postWortJson[i].activity_code,
            TaskStatus: postWortJson[i].status,
            WorkOrderStatus: postWortJson[i].worder_status,
            Supervisor: this.getNameSupervisor(postWortJson[i].responsible),
            Workers: `${this.getTotalWorkers(postWortJson[i].workers)} / ${this.getTotalWorkersPlanner(postWortJson[i].planner.workers)}`,
            WorkersDetailTab: postWortJson[i].workers,
            ItemsDetailTab: items,
            Attachments: postWortJson[i].attachments_vehicles,
            Tools: postWortJson[i].planner.tools,
            ToolsDetailTab: postWortJson[i].tools,
            itemsTypes: postWortJson[i].planner.items,
            items,
            itemsDriversWorkOrder: postWortJson[i].items_drivers,
            Location: this.getFinalLevelLocation(postWortJson[i].location),
            locationLevel2: this.getLocationLevel2(),
            location: postWortJson[i].location,
            Planner: postWortJson[i].planner,
            responsible: postWortJson[i].responsible,
            additionalInfoWorkOrder: postWortJson[i].comment,
            machineryPopup: postWortJson[i].machinery_performance,
            products: postWortJson[i].products,
            productPopup: postWortJson[i].product_performance,
            area_effective: postWortJson[i].area_effective,
            variety: postWortJson[i].planner.variety === null || postWortJson[i].planner.variety === 'ALL' ? '' : postWortJson[i].planner.variety,
            shortId: postWortJson[i].short_id,
            binsDetail: postWortJson[i].nursery,
          });
        }
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    getItems(itemsDriver) {
      const items = [];
      for (const itemDriver of itemsDriver) {
        items.push(itemDriver.item);
      }
      return items;
    },
    isViewconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' && e.row.data.TaskStatus === 'COMPLETED') {
        return true;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS' || e.row.data.TaskStatus === 'OPEN' || e.row.data.WorkOrderStatus !== 'COMPLETED') {
        return false;
      }
      return true;
    },
    viewtask(rowSelected) {
      if (rowSelected.row.data.TaskStatus === 'COMPLETED') {
        // Comprobación para editar productos / prescripcion
        if (rowSelected.row.data.Planner.prescription) {
          this.setPrescription(rowSelected.row.data.Planner);
        }
        // Comprobación para editar tools
        if (rowSelected.row.data.Planner) {
          rowSelected.row.data.ToolsDetailTab = rowSelected.row.data.Planner.tools;
        }
        this.setActualWorkOrder(rowSelected.row.data);
        this.setProducts(rowSelected.row.data.products);
        this.$f7.views.main.router.navigate('/ViewPostWork/', { reloadCurrent: true });
      }
    },
    // POR CADA FILA QUE SEA TASKSTATUS = COMPLETED Y WORKWORDERSTATUS = COMPLETED DESHABILITO LOS BOTONES EDITAR Y BORRAR
    isEditIconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' && e.row.data.TaskStatus === 'COMPLETED') {
        return true;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS') {
        return true;
      }
      return true;
    },
    // Cambio el formato de Date en formato: 10/05/2020
    formatoDate(date) {
      const dateMoment = moment(date);
      return dateMoment.format('DD/MM/YYYY');
    },
    // GET AND SET LOCATIONS
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    getFinalLevelLocation(locations) {
      this.finalLevelLocation = [];
      for (const item of this.locationsTree) {
        this.viewChildren(item, locations);
      }
      return this.finalLevelLocation;
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (locations.find((element) => element === item.bd_id)) {
          this.finalLevelLocation.push(item.name);
        }
      }
    },
    getLocationLevel2() {
      this.currentLevel2Name = '';
      this.level2Data = [];
      this.areaLevel2 = 0;
      for (let i = 0; i < this.locationsTree.length; i += 1) {
        if (this.locationsFake.length === 0) {
          // Si no quedan ids en el array de parcelas de esta tarea
          break;
        }
        this.recursiveGetChildrens(this.locationsTree[i]);
      }
      return this.level2Data;
    },
    recursiveGetChildrens(items) {
      if (items.level === 2) {
        // Solo comprobamos los elementos con nivel === 2, sus hijos los comprobamos dentro
        this.currentLevel2Name = items.name;
      }
      if (items.children.length === 0) {
        // Si el nivel no tiene hijos, comprobamos si su id está en el array
        const check = this.locationsFake.indexOf(items.bd_id);
        if (check !== -1) {
          const alreadyInserted = this.level2Data.indexOf(this.currentLevel2Name);
          if (alreadyInserted === -1) {
            // Está en el array de localizaciones y aun no lo hemos guardado
            this.level2Data.push(this.currentLevel2Name);
          }
          this.areaLevel2 += items.area;
          this.locationsFake.splice(check, 1); // Elimino el id del array
        }
      } else if (this.locationsFake.length !== 0) {
        // El nivel tiene hijos
        for (let j = 0; j < items.children.length; j += 1) {
          this.recursiveGetChildrens(items.children[j]);
        }
      }
    },
    // GET WORKERS - SUPERVISORS
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    getNameSupervisor(idSupervisor) {
      for (const supervisor of this.supervisorsAvailables) {
        if (supervisor.id === idSupervisor) {
          return `${supervisor.name} ${supervisor.surname}`;
        }
      }
      return '';
    },
    getTotalWorkers(workers) {
      return workers.length;
    },
    getTotalWorkersPlanner(workersPlanner) {
      let element = 0;
      for (let i = 0; i < workersPlanner.length; i += 1) {
        element += workersPlanner[i].count;
      }
      return element;
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('WorkOrders');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WorkOrders.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxTaskOrders_text'),
          onClick: () => {
            this.$f7.views.main.router.navigate('/createPostWork/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('PlanningManager', ['setActualWorkOrder', 'setVisiblePopupWorkOrder']),
    ...mapActions('Prescription', ['setProducts', 'setPrescription', 'setNewPrescription']),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed{
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
</style>
