<template>
  <div v-if="loaded">
    <div
      class="content-devx content-dx-daily-planning"
    >
      <DxDataGrid
        id="batchesTable"
        ref="batchesTable"
        :data-source="batchlot"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :column-auto-width="true"
        :row-alternation-enabled="true"
      >
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="false"
          :allow-deleting="false"
          :allow-adding="false"
          mode="row"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          type="buttons"
          data-field=""
        >
          <!-- TODO El orden de los botones implica su color y estilo, no tocar -->
          <DxButton
            icon="edit"
            :hint="$t('DxDailyPlanning_edit_hint')"
            :on-click="editBatch"
          />
          <DxButton
            icon="trash"
            :hint="$t('DxDailyPlanning_delete_hint')"
            :on-click="deteleBatch"
          />
        </DxColumn>
        <DxColumn
          data-field="code"
          name="code"
          alignment="left"
          :caption="$t('DxBatches_dxColumn_Code')"
        />
        <DxColumn
          data-field="creation"
          name="creation"
          alignment="left"
          :caption="$t('DxBatches_dxColumn_CreationDate')"
        />
        <DxColumn
          data-field="name"
          name="name"
          alignment="left"
          :caption="$t('DxBatches_dxColumn_NameBatch')"
        />
        <DxColumn
          data-field="bins"
          name="bins"
          alignment="left"
          :caption="$t('DxBatches_dxColumn_BinsList')"
        />
        <DxMasterDetail
          :enabled="true"
          template="master-detail"
        />
        <template #master-detail="{ data: batchlot }">
          <DxDetailTabsBatches :master-detail-data="batchlot" />
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxMasterDetail,
  DxButton,
  DxExport,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import DxDetailTabsBatches from './DxDetailTabsBatches.vue';

export default {
  name: 'DxProducts',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxDetailTabsBatches,
    DxExport,
  },
  data() {
    return {
      batchlot: [],
      loaded: false,
    };
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getBatches();
      const batches = JSON.parse(xhr.response);
      for (let i = 0; i < batches.data.length; i += 1) {
        this.batchlot.push({
          code: batches.data[i].code,
          creation: batches.data[i].creation,
          name: batches.data[i].name,
          bins: this.binsFromBatch(batches.data[i].bins),
          binsDetail: batches.data[i].bins,
          id: batches.data[i].id,
        });
      }
    } catch (e) {
      this.$f7.dialog.alert(e);
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    binsFromBatch(bins) {
      let binslist = '';
      for (let i = 0; i < bins.length; i += 1) {
        binslist += bins[i].code;
        if (bins.length - 1 > i) {
          binslist += ' - ';
        }
      }
      return binslist;
    },
    async deteleBatch(rowSelected) {
      try {
        const response = await this.fetchDeleteBatch(rowSelected.row.data.id);
        if (response.status === 204) {
          this.setActualMenuSelected('/batches/');
          this.$f7.views.main.router.navigate('/batches/', { reloadCurrent: true });
        } else {
          this.$f7.dialog.alert(response);
        }
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    editBatch(rowSelected) {
      this.setIdBatch(rowSelected.row.data.id);
      this.setNameBatch(rowSelected.row.data.name);
      this.setDateBatch(rowSelected.row.data.creation);
      this.setLotBins(rowSelected.row.data.binsDetail);
      this.setSelectedBins(rowSelected.row.data.binsDetail);
      this.setActualMenuSelected('/batches/edit/');
      this.$f7.views.main.router.navigate('/batches/edit/', { reloadCurrent: true });
    },
    ...mapActions('greenhouse', [
      'setNameBatch',
      'setDateBatch',
      'setLotBins',
      'setSelectedBins',
      'setIdBatch',
      'fetchDeleteBatch',
    ]),
    ...mapActions('authentication', ['setActualMenuSelected']),
  },
};
</script>
