<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning no-shadow no-margin" 
    >
      <DxDataGrid
        id="postworkMachinaryDriverTable"
        ref="postworkMachinaryDriverTable"
        :data-source="driversAvailables"
        :column-min-width="120"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxViewWorkOrderMachineryDriverTable"
        />
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <!-- <DxEditing
          :allow-updating="updating"
          :allow-deleting="deleting"
          :allow-adding="adding"
          mode="row"
        /> -->
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="item_data.item.subfamily"
          :width="100"
          :caption="$t('DxAttachment_dxColumn_TypeAttachment')"
        />
        <DxColumn
          data-field="item_data.description"
          name="item"
          :caption="$t('DxAttachmentMachinary_dxColumn_Machinary')"
        />
        <DxColumn
          data-field="driver_data.nameComplete"
          name="driver"
          :caption="$t('DxWorkOrders_driver_caption')"
        />
        <DxColumn
          data-field="km_start"
          :customize-text="getKmStart"
          data-type="number"
          :caption="$t('DxWorkOrders_km_start_caption')"
        />
        <DxColumn
          data-field="km_finish"
          data-type="number"
          :customize-text="getKmEnd"
          :caption="$t('DxWorkOrders_km_finish_caption')"
        />
        <DxColumn
          data-field="horometer_start"
          data-type="number"
          :customize-text="getHorometerStart"
          :caption="$t('DxWorkOrders_horometer_start_caption')"
        />
        <DxColumn
          data-field="horometer_finish"
          :customize-text="getHorometerEnd"
          data-type="number"
          :caption="$t('DxWorkOrders_horometer_finish_caption')"
        />
        <DxColumn
          data-field="fuel_consumption"
          :customize-text="getFuelConsumption"
          data-type="number"
          :caption="$t('DxWorkOrders_fuel_consumption_caption')"
        />
        <DxColumn
          data-field="fuel_type"
          :customize-text="getFuelType"
          name="fuel_type"
          alignment="left"
          :caption="$t('MonthlyComponent_typeconsumption_caption')"
        />
        <DxColumn
          data-field="comment"
          :customize-text="getComment"
          :caption="$t('DxWorkOrders_aditional_info_caption')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxAttachmentMachinaryTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxStateStoring,
  },
  props: {
    driversAvailables: { type: Array, default: () => [] },
    itemsAvailables: { type: Array, default: () => [] },
    machinery: { type: Array, default: () => [] },
  },
  data() {
    return {

    };
  },
  beforeMount() {
    this.getNameSurnameDriver();
  },
  methods: {
    getNameSurnameDriver() {
      for (let i = 0; i < this.driversAvailables.length; i++) {
        this.driversAvailables[i].driver_data.nameComplete = `${this.driversAvailables[i].driver_data.name} ${this.driversAvailables[i].driver_data.surname}`;
      }
    },
    getKmStart() {
      for (let i = 0; i < this.machinery.length; i++) {
        const element = this.machinery[i];
        return element.km_start;
      }
    },
    getKmEnd() {
      for (let i = 0; i < this.machinery.length; i++) {
        const element = this.machinery[i];
        return element.km_finish;
      }
    },
    getHorometerStart() {
      for (let i = 0; i < this.machinery.length; i++) {
        const element = this.machinery[i];
        return element.horometer_start;
      }
    },
    getHorometerEnd() {
      for (let i = 0; i < this.machinery.length; i++) {
        const element = this.machinery[i];
        return element.horometer_finish;
      }
    },
    getFuelConsumption() {
      for (let i = 0; i < this.machinery.length; i++) {
        const element = this.machinery[i];
        return element.fuel_consumption;
      }
    },
    getFuelType() {
      for (let i = 0; i < this.machinery.length; i++) {
        const element = this.machinery[i];
        return element.fuel_type;
      }
    },
    getComment() {
      for (let i = 0; i < this.machinery.length; i++) {
        const element = this.machinery[i];
        return element.comment;
      }
    },
  },
};
</script>
