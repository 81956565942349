<template>
  <f7-page>
    <mostrarTabPanel />
  </f7-page>
</template>

<script>

import mostrarTabPanel from '../components/tabPanel.vue';

export default {
  components: {
    mostrarTabPanel,
  },
};
</script>
