<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning no-margin no-shadow"
    >
      <DxForm
        id="form"
        :col-count="1"
      >
        <DxSimpleItem
          data-field="Name"
          :editor-options="{
            readOnly: readOnly,
            disabled: false,
            value: supervisorsAvailables,
          }"
          :caption="$t('SelectSupervisor_supervisor_caption')"
        />
        <DxSimpleItem
          editor-type="dxTextArea"
          :editor-options="{
            placeholder: $t('DxInformationPrescription_notes_caption'),
            value: additionalInfoWorkOrder,
            readOnly: readOnly,
          }"
        />
      </DxForm>
    </div>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions } from 'vuex';
import DxTextArea from 'devextreme-vue/text-area';

export default {
  name: 'DxSelectSupervisor',
  components: {
    DxForm,
    DxSimpleItem,
    DxTextArea,
  },
  props: {
    supervisorsAvailables: { type: String, default: '' },
    readOnly: { type: Boolean, default: true },
    additionalInfoWorkOrder: { type: String, default: '' },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
    };
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
  },
  methods: {

  },
};
</script>
<style>
.status-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 8px;
}
.middle {
  vertical-align: middle;
}
</style>
