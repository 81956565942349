<template>
  <div
    v-if="loaded"
    class="content-devx main-monthly-planning"
  >
    <f7-block>
      <f7-row>
        <f7-col>
          <f7-block-title>
            {{ annualBudget.annualBudgetVersion }}
          </f7-block-title>
        </f7-col>
        <f7-col>
          <CalendarInitEndDate :key-name="keyName" />
        </f7-col>
      </f7-row>
    </f7-block>
    <DxDataGrid
      id="gridContainer"
      :data-source="detailPlanning"
      :remote-operations="true"
      :column-min-width="90"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      :column-auto-width="true"
      key-expr="ID"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxMonthlyPlanning"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        type="buttons"
        :width="60"
        data-field="Actions"
      >
        <DxButton
          v-if="monthlyPlanningEditable"
          icon="edit"
          :hint="$t('DxDailyPlanning_edit_hint')"
          :on-click="editTaskBadget"
        />
      </DxColumn>
      <DxColumn
        data-field="Date"
        name="date"
        alignment="left"
        :width="300"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="days"
        name="days"
        alignment="right"
        :caption="$t('DxDailyPlanning_days_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Activity"
        name="activity"
        :allow-header-filtering="true"
        alignment="left"
        :caption="$t('DxDailyPlanning_activity_caption')"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :allow-header-filtering="true"
        alignment="left"
        :caption="$t('DxDailyPlanning_task_caption')"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        alignment="left"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_location_caption')"
      />
      <DxColumn
        data-field="goalFormated"
        name="goal"
        :allow-header-filtering="false"
        alignment="right"
        :caption="$t('DxMonthlyPlanning_goal_caption')"
      />
      <DxColumn
        data-field="permanent"
        name="permanent"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_p_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="temporary"
        name="temporary"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_t_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="contratist"
        name="contratist"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_c')"
        alignment="right"
      />
      <DxColumn
        data-field="cost"
        name="cost"
        :allow-header-filtering="false"
        :caption="`${$t('DxDailyPlanning_coste')} €`"
        alignment="right"
      />

      <template #totalGroupCount="{ data }">
        <div class="total-group-count">
          <span>{{ $t("DxMonthlyPlanning_totalCost") }}: </span> {{ totalCount }} €
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxButton,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import moment from 'moment';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../services/Api';
import CalendarInitEndDate from './CalendarInitEndDate.vue';

export default {
  name: 'DxDailyPlanning',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxButton,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    CalendarInitEndDate,
  },
  data() {
    return {
      loaded: false,
      locationsTreeAux: [],
      detailPlanning: [],
      finalLevelLocation: [],
      locationsTree: [],
      widthSearchPanel: 500,
      currentLevel2Name: '',
      level2Data: [],
      areaLevel2: 0,
      permanent: 0,
      temporary: 0,
      contratist: 0,
      totalCount: 0,
      prueba: [],
      pageSizes: [5, 10, 20],
      keyName: 'monthlyPlanning',
    };
  },
  computed: {
    ...mapState('Prescription', ['prescription']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('TaskBadget', ['badgedId', 'versionId']),
    ...mapGetters('PlanningManager', ['monthlyPlanningEditable']),
    ...mapState('PlanningManager', ['annualBudget']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.locationsTree = await this.loadLocationsTree();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getMonthlyPlanning(this.badgedId, this.versionId, checkedInitEndDate.init, checkedInitEndDate.end);
      const planning = JSON.parse(xhr.response);
      this.detailPlanning.length = 0;
      // esto es la estructura que hay que montar para mostrar la tabla MonthlyPlanning
      for (let j = 0; j < planning.task.length; j += 1) {
        this.locationsTreeAux = [...planning.task[j].locations];
        this.detailPlanning.push({
          badgeId: this.badgedId,
          versionId: this.versionId,
          ID: planning.task[j].id,
          Date: this.formattingDates(planning.task[j].dates),
          days: planning.task[j].days,
          Task: planning.task[j].task_name,
          taskName: planning.task[j].task,
          taskCode: planning.task[j].task_code,
          Activity: planning.task[j].activity,
          activityCode: planning.task[j].activity_code,
          goal: planning.task[j].goal,
          goalUnit: planning.task[j].goal_unit,
          locationLevel2: this.getLocationLevel2(),
          locations: planning.task[j].locations,
          temporary: planning.task[j].temporary,
          permanent: planning.task[j].permanent,
          contratist: planning.task[j].contratist,
          cost: new Intl.NumberFormat('de-DE').format(parseFloat(planning.task[j].cost).toFixed(2)),
          costAux: planning.task[j].cost,
          goalFormated: `${planning.task[j].goal} ${planning.task[j].goal_unit}`,
          variety: planning.task[j].variety === null || planning.task[j].variety === 'ALL' ? '' : planning.task[j].variety,
        });
      }
      this.calculateSum();
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    calculateSum() {
      for (const iterator of this.detailPlanning) {
        this.totalCount += parseFloat(iterator.costAux);
      }
      this.totalCount = new Intl.NumberFormat('de-DE').format(this.totalCount.toFixed(2));
    },
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    async editTaskBadget(rowSelected) {
      this.setTaskId(rowSelected.row.data.ID);
      try {
        const xhr = await Api.getMonthlyTaskPlanningId(rowSelected.row.data.badgeId, rowSelected.row.data.versionId, rowSelected.row.data.ID);
        const data = JSON.parse(xhr.response);
        const loadActualTaskBadget = {};
        loadActualTaskBadget.activityName = data.activity;
        loadActualTaskBadget.activityCode = data.activity_code;
        loadActualTaskBadget.taskName = data.task_name;
        loadActualTaskBadget.taskCode = data.task_code;
        loadActualTaskBadget.goal = data.goal;
        loadActualTaskBadget.goalUnit = data.goal_unit;
        loadActualTaskBadget.workers = {
          CONTRATIST: data.workers.contratist,
          PERMANENT: data.workers.permanent,
          TEMPORARY: data.workers.temporary,
        };
        loadActualTaskBadget.performanceWorker = data.workers.performance;
        loadActualTaskBadget.dateProgrammed = data.dates;
        loadActualTaskBadget.machinery = data.machineries;
        loadActualTaskBadget.attachments = data.attachments;
        loadActualTaskBadget.tools = data.tools;
        loadActualTaskBadget.prescription = data.prescription;
        loadActualTaskBadget.products = data.products;
        loadActualTaskBadget.totalWorkingDays = data.dates.length;
        loadActualTaskBadget.locations = data.locations;
        loadActualTaskBadget.area_effective = data.area_effective;
        loadActualTaskBadget.variety = data.variety;
        this.setActualTaskBadget(loadActualTaskBadget);
        this.setPrescription(data.prescription);
        this.$f7.views.main.router.navigate('/editMonthly/', { reloadCurrent: true });
      } catch (e) {
        console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
      // this.$f7.views.main.router.navigate('/editMonthly/', { reloadCurrent: true });
    },
    getLocationLevel2() {
      this.currentLevel2Name = '';
      this.level2Data = [];
      this.areaLevel2 = 0;
      for (let i = 0; i < this.locationsTree.length; i += 1) {
        if (this.locationsTreeAux.length === 0) {
          // Si no quedan ids en el array de parcelas de esta tarea
          break;
        }
        this.recursiveGetChildrens(this.locationsTree[i]);
      }
      return this.level2Data;
    },
    recursiveGetChildrens(items) {
      if (items.level === 2) {
        // Solo comprobamos los elementos con nivel === 2, sus hijos los comprobamos dentro
        this.currentLevel2Name = items.name;
      }
      if (items.children.length === 0) {
        // Si el nivel no tiene hijos, comprobamos si su id está en el array
        const check = this.locationsTreeAux.indexOf(items.bd_id);
        if (check !== -1) {
          const alreadyInserted = this.level2Data.indexOf(this.currentLevel2Name);
          if (alreadyInserted === -1) {
            // Está en el array de localizaciones y aun no lo hemos guardado
            this.level2Data.push(this.currentLevel2Name);
          }
          this.areaLevel2 += items.area;
          this.locationsTreeAux.splice(check, 1); // Elimino el id del array
        }
      } else if (this.locationsTreeAux.length !== 0) {
        // El nivel tiene hijos
        for (let j = 0; j < items.children.length; j += 1) {
          this.recursiveGetChildrens(items.children[j]);
        }
      }
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (locations.find((element) => element === item.bd_id)) {
          this.finalLevelLocation.push(item.name);
        }
      }
    },
    formattingDates(dates) {
      const formatedDates = [];
      for (const date of dates) {
        formatedDates.push(moment(date).format('DD-MM-YYYY'));
      }
      return formatedDates;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('MonthlyPlaning');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'MonthlyPlaning.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      let cssClass = 'add-task';
      if (!this.monthlyPlanningEditable) {
        cssClass = 'hide';
      }
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: cssClass,
          },
          text: this.$t('DxMonthlyPlanning_text'),
          onClick: () => {
            this.$f7.views.main.router.navigate('/createMonthly/', { reloadCurrent: true });
          },
        },
      }, {
        location: 'after',
        template: 'totalGroupCount',
      });
    },
    ...mapActions('TaskBadget', ['setBadgedId', 'setVersionId', 'setTaskId', 'setActualTaskBadget']),
    ...mapActions('Prescription', ['setPrescription']),
  },
};
</script>
<style scoped>
.total-group-count{
    font-size: 16px;
    font-weight: 700;
    border: 1px solid rgba(133,103,103,0.2);
    margin-right: 10px;
    padding: 8px 12px;
    border-radius: 5px;
    background: #FBF7EF;
    color: #470000;
}
.total-group-count span {
  font-weight: 400;
  font-size: 13px;
}
</style>
