<template>
  <DxForm
    id="selectSupervisorPerformance"
  >
    <DxSimpleItem
      :data-field="$t('SelectSupervisor_supervisor_caption')"
      :editor-options="{
        items: supervisorsAvailables,
        searchEnabled: true,
        searchTimeout: searchTimeoutOption,
        minSearchLength: minSearchLengthOption,
        showDataBeforeSearch: showDataBeforeSearchOption,
        onItemClick: onSupervisorChange,
        value: actualSupervisor,
        displayExpr: 'fullname',
        valueExpr: 'id',
      }"
      editor-type="dxSelectBox"
    >
      <DxRequiredRule :message="$t('DxSelectSupervisorPerformance_SelectSupervisor_required')" />
    </DxSimpleItem>
  </DxForm>
</template>
<script>

import { mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxSelectSupervisorPerformance',
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
  },
  props: {
    supervisorsAvailables: { type: Array, default: () => [] },
    supervisor: { type: String, default: '' },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      actualSupervisor: '',
      setDisabled: false,
    };
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.actualSupervisor = this.supervisor;
  },
  methods: {
    onSupervisorChange(e) {
      this.actualSupervisor = e.itemData.id;
      this.setResponsible(e.itemData.id);
      this.setSaveButtonPopupWorkOrder(false);
    },
    ...mapActions('PlanningManager', ['setResponsible', 'setSaveButtonPopupWorkOrder']),
  },
};
</script>
<style>
