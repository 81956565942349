<template>
  <div />
</template>

<script>
import store from '../../store/index';

export default {
  store,
  mounted() {
    this.$f7.panel.close();
    store.dispatch('authentication/logout');
  },

};
</script>
