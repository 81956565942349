var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-devx no-shadow content-dx-daily-planning content-block-table"},[_c('DxDataGrid',{ref:"toolsTable",attrs:{"id":"toolsTable","data-source":_vm.tools.values,"column-min-width":70,"allow-column-reordering":true,"show-borders":true,"show-column-lines":true,"show-row-lines":false,"row-alternation-enabled":true,"column-auto-width":true},on:{"row-inserted":_vm.onInsertedRow,"row-updated":_vm.onChangeRow,"row-removed":_vm.onRemovedRow}},[_c('DxStateStoring',{attrs:{"enabled":true,"type":"localStorage","storage-key":"DxToolsTable"}}),_vm._v(" "),_c('DxExport',{attrs:{"enabled":false}}),_vm._v(" "),_c('DxPaging',{attrs:{"page-size":18}}),_vm._v(" "),_c('DxEditing',{attrs:{"allow-updating":_vm.allowUpdating,"allow-deleting":_vm.allowDeleting,"allow-adding":_vm.allowAdding,"mode":"form"}},[_c('DxForm',[_c('DxItem',{attrs:{"col-count":1,"col-span":2,"item-type":"group"}},[_c('DxItem',{attrs:{"data-field":"item","caption":_vm.$t('DxToolsMonthlyTable_type_caption')}}),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{ showSpinButtons: false,
                                 mode: 'number',
                                 placeholder: '0',
                                 showClearButton: true,
                                 min: 0,
                                 step: 1,
              },"editor-type":"dxNumberBox","data-field":"count"}}),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{ showSpinButtons: false,
                                 mode: 'number',
                                 placeholder: '0',
                                 showClearButton: true,
                                 min: '0',
                                 step: '1',
              },"editor-type":"dxNumberBox","data-field":"fuel_consumption","caption":_vm.$t('DxToolsMonthlyTable_consumption_caption')}}),_vm._v(" "),_c('DxItem',{attrs:{"data-field":"fuel_type","camption":_vm.$t('DxToolsMonthlyTable_consumption_type_caption')}})],1)],1)],1),_vm._v(" "),_c('DxSearchPanel',{attrs:{"visible":true,"width":200,"placeholder":_vm.$t('Search_placeholder')}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"item","name":"item","alignment":"left","caption":_vm.$t('DxTools_dxColumn_TypeTool')}},[_c('DxLookup',{attrs:{"data-source":_vm.selectTool,"display-expr":"name","value-expr":"id"}}),_vm._v(" "),_c('DxRequiredRule')],1),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"count","name":"count","data-type":"number","alignment":"right","caption":_vm.$t('DxTool_dxColumn_QuantityTool')}},[_c('DxRequiredRule')],1),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"fuel_consumption","alignment":"right","name":"fuel_consumption","data-type":"number","caption":_vm.$t('MonthlyComponent_consumption_caption')}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"fuel_type","name":"fuel_type","alignment":"left","caption":_vm.$t('MonthlyComponent_typeconsumption_caption')}},[_c('DxLookup',{attrs:{"data-source":_vm.typeCosumptionAvailable,"display-expr":_vm.dysplayConsumptionType}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }