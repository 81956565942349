<template>
  <f7-page name="nfcManually">
    <navbar
      :new-icon-enable="false"
      :path-back="pathBack"
      :text="`${currentFarmCode}`"
    />
    <ManualList
      v-if="showManualList"
      :full-name-filter="fullNameFilter"
    />
    <f7-block
      v-else
    >
      <f7-list
        inline-labels
        no-hairlines-md
      >
        <f7-list-input
          outline
          type="text"
          name="listInput"
          :placeholder="$t('NfcManually_listInput_placeholder')"
          clear-button
          @input="changeInput($event.target.value)"
        />
      </f7-list>
      <f7-block>
        <f7-button
          name="btnContinue"
          class="botonPrimary"
          col
          button
          button-large
          button-raised
          fill
          :disabled="btnDisable"
          @click="setFullNameFilter"
        >
          {{ $t('NfcManually_navbarpresence_btnContinue') }}
        </f7-button>
      </f7-block>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import navbar from '../../components/NavBar.vue';
import ManualList from '../../components/presence/ManualList.vue';

export default {
  name: 'NfcManually',
  components: {
    navbar,
    ManualList,
  },

  data() {
    return {
      items: [],
      btnDisable: true,
      vlData: {
        items: [],
      },
      fullNameFilter: '',
      employees: [],
      showManualList: false,
      pathBack: '/nfc/',
    };
  },
  computed: {
    ...mapState('AccessControl', ['currentFarmCode']),
  },
  methods: {
    changeInput(inputValue) {
      this.fullNameFilter = inputValue;
      this.btnDisable = (this.fullNameFilter === '');
    },
    setFullNameFilter() {
      this.showManualList = true;
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
