export default {
  namespaced: true,
  state: {
    actualMachinery: {},
    familyAvailables: [],
    itemSubfamilyAttachment: [],
    itemSubfamilyTool: [],
  },

  getters: {
    actualMachinery: (state) => state.actualMachinery,
    familyAvailables: (state) => state.familyAvailables,
    itemSubfamilyAttachment: (state) => state.itemSubfamilyAttachment,
    itemSubfamilyTool: (state) => state.itemSubfamilyTool,
  },
  actions: {
    setActualMachinery(context, value) {
      context.commit('setActualMachinery', value);
    },
    setCode(context, value) {
      context.commit('setCode', value);
    },
    setDescription(context, value) {
      context.commit('setDescription', value);
    },
    setBrand(context, value) {
      context.commit('setBrand', value);
    },
    setModel(context, value) {
      context.commit('setModel', value);
    },
    setSerialNumber(context, value) {
      context.commit('setSerialNumber', value);
    },
    setPlate(context, value) {
      context.commit('setPlate', value);
    },
    setInternalCode(context, value) {
      context.commit('setInternalCode', value);
    },
    setFamily(context, value) {
      context.commit('setFamily', value);
    },
    setSubfamily(context, value) {
      context.commit('setSubfamily', value);
    },
    setPurchase(context, value) {
      context.commit('setPurchase', value);
    },
    setAttachment(context, value) {
      context.commit('setAttachment', value);
    },
    setStatus(context, value) {
      context.commit('setStatus', value);
    },
    setFamilyAvailables(context) {
      context.commit('setFamilyAvailables');
    },
    itemSubfamilyAttachment(context) {
      context.commit('itemSubfamilyAttachment');
    },
    itemSubfamilyTool(context) {
      context.commit('itemSubfamilyTool');
    },
  },
  mutations: {
    setActualMachinery(state, value) {
      state.actualMachinery = value;
    },
    setCode(state, value) {
      state.actualMachinery.code = value;
    },
    setDescription(state, value) {
      state.actualMachinery.description = value;
    },
    setBrand(state, value) {
      state.actualMachinery.brand = value;
    },
    setModel(state, value) {
      state.actualMachinery.model = value;
    },
    setSerialNumber(state, value) {
      state.actualMachinery.serialNumber = value;
    },
    setPlate(state, value) {
      state.actualMachinery.plate = value;
    },
    setInternalCode(state, value) {
      state.actualMachinery.internalCode = value;
    },
    setFamily(state, value) {
      state.actualMachinery.family = value;
    },
    setSubfamily(state, value) {
      state.actualMachinery.subfamily = value;
    },
    setPurchase(state, value) {
      state.actualMachinery.purchase = value;
    },
    setAttachment(state, value) {
      state.actualMachinery.attachment = value;
    },
    setStatus(state, value) {
      state.actualMachinery.status = value;
    },
    setFamilyAvailables(state, value) {
      state.familyAvailables = value;
    },
    setItemSubfamilyAttachment(state, value) {
      state.itemSubfamilyAttachment = value;
    },
    setItemSubfamilyTool(state, value) {
      state.itemSubfamilyTool = value;
    },
  },
};
