<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning content-products"
      style="margin:5px 15px 25px 15px;"
    >
      <DxForm
        id="formPrecription"
      >
        <DxSimpleItem
          name="subfamily"
          :data-field="$t('DxPlanningProducts_typePrescription')"
          editor-type="dxSelectBox"
          :editor-options="{
            items: typePrescription,
            value: actualSubFamily,
            displayExpr: 'name',
            valueExpr:'id',
            onItemClick: onChangeSubFamily,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
          }"
        >
          <DxRequiredRule :message="$t('DxPlanningProducts_typePrescription_required')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="component"
          :data-field="$t('DxPlanningProducts_prescription')"
          editor-type="dxSelectBox"
          :editor-options="{
            items: listComponents,
            value: actualComponent,
            displayExpr: 'name',
            valueExpr:'id',
            onItemClick: onChangeComponent,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
          }"
        >
          <DxRequiredRule :message="$t('DxPlanningProducts_prescription_required')" />
        </DxSimpleItem>
      </DxForm>

      <DxDataGrid
        id="productsGrid"
        ref="productsGrid"
        :data-source="prescription.products"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :column-auto-width="true"
        :row-alternation-enabled="true"
        @row-updated="onChangeRow"
      >
        <DxEditing
          :allow-updating="true"
          :allow-deleting="false"
          :allow-adding="false"
          mode="row"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :highlight-case-sensitive="true"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="product.description"
          name="product.description"
          :caption="$t('DxProducts_dxColumn_productName')"
          :allow-editing="false"
          alignment="left"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="dosis"
          name="dosis"
          data-type="number"
          alignment="right"
          :caption="$t('DxProducts_dxColumn_dosis')"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="dosisUt"
          name="dosisUt"
          alignment="left"
          :caption="$t('DxProducts_dxColumn_dosisUt')"
          :allow-editing="false"
        />
        <!-- Quantity Planned -->
        <DxColumn
          data-field="dosis_total"
          name="dosis_total"
          alignment="right"
          :allow-editing="false"
          :caption="$t('DxProducts_dxColumn_dosisTotal')"
          :calculate-cell-value="calculateDosisTotal"
        />
        <DxColumn
          data-field="solution"
          name="solution"
          alignment="right"
          data-type="number"
          :caption="$t('DxProducts_dxColumn_solution')"
          :calculate-cell-value="calculateSolution"
        />
        <DxColumn
          data-field="solutionUt"
          name="solutionUt"
          alignment="right"
          :caption="$t('DxProducts_dxColumn_solutionUt')"
          :calculate-cell-value="calculateUnit"
          :allow-editing="false"
        />
        <DxColumn
          data-field="dilution_factor"
          name="dilution_factor"
          alignment="right"
          :caption="$t('DxProducts_dxColumn_dilutionFactor')"
          :allow-editing="false"
          :calculate-cell-value="calculateDilutionFactor"
        />
        <!-- Water Planned (L) -->
        <DxColumn
          data-field="solution_total"
          name="solution_total"
          :allow-editing="false"
          :caption="$t('DxProducts_dxColumn_solutionTotal')"
          alignment="right"
          :calculate-cell-value="calculateTotalSolution"
        />
        <DxColumn
          data-field="qty_collected"
          data-type="number"
          :editor-options="numberColumnOptions"
          :caption="$t('DxProductPerformance_products_qtyCollected')"
          alignment="right"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="qty_used"
          data-type="number"
          :editor-options="numberColumnOptions"
          :caption="$t('DxProductPerformance_products_qtyUsed')"
          alignment="right"
        >
          <DxRequiredRule />
        </DxColumn>
        <!-- Quantity Returned to Warehouse -->
        <DxColumn
          data-field="qty_task"
          :caption="$t('DxProductPerformance_products_qtyTask')"
          :customize-text="calculateTotal"
          :allow-editing="false"
          alignment="left"
        />
        <DxColumn
          data-field="total_real_prescription"
          :caption="$t('DxProductPerformance_products_totalRealPrescription')"
          :allow-editing="true"
          alignment="left"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxRequiredRule,
  DxSearchPanel,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxPostWorkProducts',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxRequiredRule,
    DxForm,
    DxSimpleItem,
    DxSearchPanel,
  },
  props: {
    editable: { type: Boolean, default: () => true },
  },
  data() {
    return {
      family: [],
      subfamily: [],
      product: [],
      listPrescriptions: [],
      actualFamily: '',
      actualSubFamily: '',
      actualComponent: '',
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      searchTimeoutOption: 200,
      numberColumnOptions: {
        showSpinButtons: true, min: 0, showClearButton: true,
      },
    };
  },
  computed: {
    ...mapState('TaskManager', ['totalAreaSelected']),
    ...mapState('Prescription', ['prescription', 'listFamily', 'listDosisUT', 'typePrescription', 'listComponents']),
    ...mapState('PlanningManager', ['workingArea']),
  },
  destroyed() {
    EventBus.$off('changeTotalAreaSelected');
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    const xhr = await Api.getDataSubfamilyByType('product');
    this.setData(JSON.parse(xhr.response));
    const xhr2 = await Api.getPrescriptions();
    this.listPrescriptions = JSON.parse(xhr2.response);
    EventBus.$on('changeTotalAreaSelected', this.calculateDoseSolution);
    if (this.prescription.name !== '') {
      // Modo edición

      // Seteo subfamilia, filtrando por la familia seleccionada
      this.actualSubFamily = this.prescription.type;
      this.setlistSubfamily(this.subfamily);

      // Seteo las prescripciones filtradas:
      this.actualComponent = this.prescription.id;
      const arrayComponent = this.listPrescriptions.filter(
        (elemento) => elemento.type === this.prescription.type && elemento.active === 'Yes',
      );
      this.setlistComponents(arrayComponent);
    }
  },
  beforeDestroy() {
    this.setNewPrescription();
  },
  methods: {
    calculateTotalSolution(rowData) {
      return (rowData.solution * this.workingArea).toFixed(2);
    },
    calculateDilutionFactor(rowData) {
      return (typeof (rowData.solution) === 'undefined' || this.isKilogrameOrliter(rowData)) ? '' : `${rowData.solution / 100}`;
    },
    calculateDosisTotal(rowData) {
      let calcDosisTotal = (parseFloat(rowData.dosis) * parseFloat(this.workingArea)).toFixed(2);
      const dilutionFactor = parseFloat(rowData.solution) / 100;
      const unit = this.calculateUnitDosisTotal(rowData);

      calcDosisTotal = (!this.isKilogrameOrliter(rowData)) ? ((calcDosisTotal * dilutionFactor) / 1000).toFixed(2) : calcDosisTotal;
      return `${calcDosisTotal} ${unit}`;
    },
    calculateUnitDosisTotal(rowData) {
      if (rowData.dosisUt.toLowerCase().includes('gr/')
        || rowData.dosisUt.toLowerCase().includes('kg/')) return 'Kg';
      return 'L';
    },
    cleanDataToUnit(rowData) {
      rowData.solution = 0;
      rowData.solutionUt = '';
      rowData.dilution_factor = 0;
    },
    isKilogrameOrliter(rowData) {
      return typeof (rowData.dosisUt) === 'undefined' || rowData.dosisUt.toLowerCase().includes('kg/') || rowData.dosisUt.toLowerCase().includes('l/');
    },
    calculateSolution(rowData) {
      const value = (typeof (rowData.solution) === 'undefined' || this.isKilogrameOrliter(rowData)) ? '' : `${rowData.solution}`;
      if (value === '') this.cleanDataToUnit(rowData);
      return value;
    },
    calculateUnit(rowData) {
      const value = (this.isKilogrameOrliter(rowData)) ? '' : 'L/Ha';
      if (value === '') this.cleanDataToUnit(rowData);
      return value;
    },
    calculateTotal(e) {
      if (e.valueText !== 'NaN') {
        return e.valueText;
      }
      return '';
    },
    setData(dataArray) {
      this.family.length = 0;
      this.subfamily.length = 0;
      this.product.length = 0;
      for (let i = 0; i < dataArray.length; i += 1) {
        this.family.push({
          id: dataArray[i].id,
          name: dataArray[i].id,
        }); // PHITOSANITARY / SUBSTRATE / FERTILIZERS
        const subfami = dataArray[i].items;
        for (let j = 0; j < subfami.length; j += 1) {
          this.subfamily.push({
            id: subfami[j].id,
            name: subfami[j].id,
            family_id: dataArray[i].id,
          }); // <-- subfamily

          const component = subfami[j].items;
          for (let k = 0; k < component.length; k += 1) {
            if (dataArray[i].id === 'PHITOSANITARY') {
              // En caso de fitosanitario, los productos estan un nivel mas adentro
              const prod = component[k].items;
              for (let n = 0; n < prod.length; n += 1) {
                this.product.push({
                  id: prod[n].id,
                  name: prod[n].description,
                  subfamily_id: subfami[j].id,
                });
              }
            } else {
              // En caso contrario, los productos son directamente el componente
              this.product.push({
                id: component[k].id,
                name: component[k].description,
                subfamily_id: subfami[j].id,
              });
            }
          }
        }
      }
      this.setlistFamily(this.family);
    },
    onChangeFamily(e) {
      this.actualFamily = e.itemData.id;
      const arrayFiltrado = this.subfamily.filter(
        (elemento) => elemento.family_id === e.itemData.id,
      );
      this.setlistSubfamily(arrayFiltrado);
      this.actualSubFamily = '';
      this.setlistComponents([]);
      this.actualComponent = '';
      this.setProducts([]);
    },
    onChangeSubFamily(e) {
      this.actualSubFamily = e.itemData.id;
      const arrayFiltrado = this.listPrescriptions.filter(
        (elemento) => elemento.type === e.itemData.id,
      );
      this.setlistComponents(arrayFiltrado);
      this.actualComponent = '';
      this.setProducts([]);
    },
    onChangeComponent(e) {
      this.actualComponent = e.itemData.id;
      const arrayFiltrado = this.listPrescriptions.filter(
        (elemento) => elemento.id === e.itemData.id,
      );
      this.setPrescription(arrayFiltrado[0]);
      this.calculateDoseSolution();
    },
    // SET DATA SOURCE TO PRODUCTS VUEX
    onChangeRow() {
      this.setProducts(this.$refs.productsGrid.instance.getDataSource().items());
      this.calculateDoseSolution();
    },
    calculateDoseSolution() {
      if (this.prescription.name === '') {
        this.setNewPrescription(); // Controlo que no tenga productos una prescripción vacía
      }
      if (this.prescription.products.length > 0) {
        for (let i = 0; i < this.prescription.products.length; i += 1) {
          const producto = this.prescription.products[i];
          let dosisTotal = 0;
          let solutionTotal = 0;
          if ((typeof (producto.solution) !== 'undefined') && (producto.dosisUt.includes('Hl'))) {
            dosisTotal = parseFloat(producto.dosis) * parseFloat(producto.solution);
          } else {
            dosisTotal = parseFloat(producto.dosis) * parseFloat(this.totalAreaSelected);
          }
          if ((typeof (producto.solution) !== 'undefined') && (producto.dosisUt.includes('Ha'))) {
            solutionTotal = parseFloat(producto.solution) * parseFloat(this.totalAreaSelected);
          } else {
            solutionTotal = (parseFloat(producto.dosis)
              * parseFloat(this.totalAreaSelected)
              * parseFloat(producto.solution)) / parseFloat(producto.dosis);
          }
          let totalValue = 0;
          if (typeof this.prescription.products[i].qty_collected !== 'undefined' && typeof this.prescription.products[i].qty_used !== 'undefined') {
            totalValue = this.prescription.products[i].qty_collected - this.prescription.products[i].qty_used;
          }
          this.prescription.products[i].dosis_total = dosisTotal.toFixed(2).toString();
          this.prescription.products[i].qty_planned = this.prescription.products[i].dosis_total;
          this.prescription.products[i].solution_total = solutionTotal.toFixed(2).toString();
          this.prescription.products[i].qty_task = totalValue;
          this.prescription.products[i].updated = true;
          this.prescription.products[i].item_id = producto.product.id;
          if (producto.dosisUt === this.listDosisUT[2] || producto.dosisUt === this.listDosisUT[3]) {
            this.prescription.products[i].total_real_prescription = 0;
            this.prescription.products[i].solution = 0;
            this.prescription.products[i].solution_total = 0;
          }
          if (typeof this.prescription.products[i].total_real_prescription === 'undefined') {
            this.prescription.products[i].total_real_prescription = 0;
          }
        }
        this.setProducts(this.prescription.products);
      }
    },
    ...mapActions('Prescription', ['setPrescription', 'setNewPrescription', 'setProducts', 'setlistFamily', 'setlistSubfamily', 'setlistComponents']),
  },
};
</script>
