var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx content-dx-cost-form"},[_c('DxForm',{attrs:{"id":"costForm"}},[_c('DxGroupItem',[_c('DxGroupItem',{attrs:{"css-class":"content-combo"}},[_c('DxSimpleItem',{attrs:{"name":"name","data-field":_vm.$t('DxCostsDatabase_column_name'),"editor-options":{
            value: _vm.actualName,
            onValueChanged: _vm.onChangeName,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxCostForm_name_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"code","data-field":_vm.$t('DxCostsDatabase_column_code'),"editor-options":{
            value: _vm.actualCode,
            onValueChanged: _vm.onChangeCode,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxCostForm_code_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"currency","data-field":_vm.$t('DxCostsDatabase_column_currency'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.listCurrency,
            value: _vm.actualCurrency,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            onItemClick: _vm.onChangeCurrency
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxCostForm_Currency_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"Currency Code","data-field":_vm.$t('DxCostsDatabase_column_currency_code'),"editor-options":{
            value: _vm.currencyCodeValue,
            readOnly: true,
          }}}),_vm._v(" "),(_vm.editForm)?_c('DxSimpleItem',{attrs:{"name":"Id","data-field":_vm.$t('DxCostsDatabase_column_id'),"editor-options":{
            value: _vm.actualId,
            onValueChanged: _vm.onChangeId,
            readOnly: true,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxCostForm_Id_required')}})],1):_vm._e(),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"Price","data-field":_vm.$t('DxCostsDatabase_column_price'),"editor-options":{
            value: _vm.actualPrice,
            onValueChanged: _vm.onChangePrice,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxCostForm_price_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"Unit","data-field":_vm.$t('DxCostsDatabase_column_unit'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.listUnit,
            value: _vm.actualUnit,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            onItemClick: _vm.onChangeUnit
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxMachineryForm_family_required')}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }