var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxForm',{attrs:{"id":"selectSupervisorPerformance"}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('SelectSupervisor_supervisor_caption'),"editor-options":{
      items: _vm.supervisorsAvailables,
      searchEnabled: true,
      searchTimeout: _vm.searchTimeoutOption,
      minSearchLength: _vm.minSearchLengthOption,
      showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      onItemClick: _vm.onSupervisorChange,
      value: _vm.actualSupervisor,
      displayExpr: 'fullname',
      valueExpr: 'id',
    },"editor-type":"dxSelectBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxSelectSupervisorPerformance_SelectSupervisor_required')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }