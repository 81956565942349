<template>
  <f7-page>
    <ItemsTable />
  </f7-page>
</template>

<script>
import ItemsTable from '../components/ItemsTable.vue';

export default {
  components: {
    ItemsTable,
  },
};
</script>
