<template>
  <DxTabPanel
    v-if="loaded"
    class="backgroundColor"
  >
    <DxItem
      name="DetailTabs_machinary_title"
      :title="$t('DetailTabs_machinary_title')"
      template="machinary-tab"
    />
    <DxDetailTabMachinary
      slot="machinary-tab"
      :data="dataMachine"
      type-tab="machinary"
    />
    <!-- Attachments -->
    <DxItem
      name="DetailTabs_attachment_title"
      :title="$t('DetailTabs_attachment_title')"
      template="Attachments-tab"
    />
    <DxDetailTabAttachment
      slot="Attachments-tab"
      :attachments-selected="masterDetailData.data.Attachments"
      type-tab="Attachment"
    />
    <!-- tools -->
    <DxItem
      name="DetailTabs_tools_title"
      :title="$t('DetailTabs_tools_title')"
      template="tools-tab"
    />
    <DxDetailTab
      slot="tools-tab"
      :tools-selected="masterDetailData.data.Tools"
      type-tab="tools"
    />
    <!-- Products -->
    <DxItem
      name="DetailTabs_products_title"
      :title="$t('DetailTabs_products_title')"
      template="products-tab"
    />
    <DxDetailTabProduct
      slot="products-tab"
      :products-selected="masterDetailData"
      type-tab="products"
    />
    <DxItem
      name="DetailTabs_workers_title"
      :title="$t('DetailTabs_workers_title')"
      template="workers-tab"
    />
    <DxDetailTabWorkers
      slot="workers-tab"
      :workers-selected="masterDetailData.data.WorkersDetailTab"
      type-tab="workers"
    />
    <DxItem
      name="DetailTabs_locations_title"
      :title="$t('DetailTabs_locations_title')"
      template="locations-tab"
    />
    <DxDetailTabLocations
      slot="locations-tab"
      :locations="masterDetailData.data.location"
      :locations-tree="locationsTree"
      type-tab="locations"
    />
    <DxItem
      name="DetailTabs_nursery_title"
      title="Nursery"
      template="nursery-tab"
    />
    <DxContentBin
      slot="nursery-tab"
      :master-detail-data="masterDetailData"
      type-tab="nursery"
    />
  </DxTabPanel>
</template>

<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import DxDetailTabMachinary from './DxDetailTabMachinary.vue';
import DxDetailTabLocations from './DxDetailTabLocations.vue';
import DxDetailTabWorkers from './DxDetailTabWorkers.vue';
import DxDetailTabAttachment from './DxDetailTabAttachment.vue';
import DxContentBin from '../greenhouse/DxContentBin.vue';
import DxDetailTabProduct from './DxDetailTabProduct.vue';
import DxDetailTab from './DxDetailTab.vue';
import Api from '../../services/Api';

export default {
  components: {
    DxTabPanel, DxItem, DxDetailTabMachinary, DxDetailTabLocations, DxDetailTabWorkers, DxDetailTab, DxDetailTabAttachment, DxDetailTabProduct, DxContentBin,
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      locationsTree: [],
      itemsAvailables: [],
      loaded: false,
      dataMachine: [],
    };
  },
  async beforeMount() {
    this.dataMachinary();
    this.$f7.preloader.show();
    try {
      this.locationsTree = await this.getLocationsTree();
      this.itemsAvailables = await this.getItems('MACHINERY', 'VEHICLE');
    } catch (error) {
      this.$f7.dialog.alert(error);
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async getLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    async getItems(type, family) {
      const xhr = await Api.getItems(type, family);
      return JSON.parse(xhr.response);
    },
    dataMachinary() {
      for (let i = 0; i < this.masterDetailData.data.Planner.items.length; i += 1) {
        this.dataMachine.push({
          count: this.masterDetailData.data.Planner.items[i].count,
          type: this.masterDetailData.data.Planner.items[i].type,
        });
      }
    },
  },
};
</script>
<style scoped>
.backgroundColor{
  background-color:white;
}
</style>
