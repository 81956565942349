<template>
  <DxForm v-if="data.length !== 0">
    <DxGroupItem>
      <DxSimpleItem
        v-for="(item, index) in data"
        :key="index"
        :data-field="item.type"
        :name="item.type"
        :editor-options="{ value: item.count, disabled:true}"
      />
    </DxGroupItem>
  </DxForm>
  <f7-block
    v-else
    name="sin_seleccion"
  >
    {{ $t('DetailTab_sin_seleccion') }}
  </f7-block>
</template>

<script>

import { DxForm, DxSimpleItem, DxGroupItem } from 'devextreme-vue/form';

export default {
  components: { DxForm, DxSimpleItem, DxGroupItem },
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
    };
  },
};
</script>
