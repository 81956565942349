<template>
  <f7-page>
    <navbar :text="$t('ReportingWorkOrders_navbar_title')" />
    <DxWorkOrders
      :back="back"
      :key-name="'reportingWorkOrders'"
      :reporting="true"
    />
  </f7-page>
</template>

<script>

import DxWorkOrders from '../../components/workOrder/DxWorkOrders.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxWorkOrders,
    navbar,
  },
  data() {
    return {
      back: 'reportingWorkOrders',
    };
  },
};
</script>

<style>

</style>
