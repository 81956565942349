<template>
  <f7-block class="no-padding">
    <DxForm
      id="form"
      :col-count="1"
    >
      <DxSimpleItem
        :editor-options="{
          items: areaList,
          displayExpr:'name',
          valueExpr:'id',
          searchEnabled: true,
          onValueChanged: changeLevel,
        }"
        editor-type="dxSelectBox"
      />
    </DxForm>
  </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxLocationSelector',
  components: {
    DxForm,
    DxSimpleItem,
  },
  props: {
    startLevel: { type: Number, default: 0 },
    endLevel: { type: Number, default: 1 },
    events: { type: Array, default: () => ['updateLocations'] },
  },
  data() {
    return {
      seletedFarm: { type: Object, default: null },
      children: [],
      locationsTree: [],
      filteredLocations: [],
      temporalAreaList: [],
      locations: [],
    };
  },
  computed: {
    ...mapState('Reporting', ['selectedFarmList', 'currentLevel', 'areaList']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    try {
      this.setCurrentLevel(this.startLevel);
      this.setCurrentLocation(null);
      this.setCurrentLocationName(null);
      this.setSelectedFarmList([]);
      this.setAreaList([]);
      await this.loadLocationsTree();
      this.emitEvents();
    } catch (error) {
      this.$f7.dialog.alert(error);
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    changeLevel(e) {
      let newLocations = [];
      this.temporalAreaList = [];
      this.setCurrentLocation(e.value);
      for (const farm of this.locations) {
        if (farm.bd_id === e.value) {
          this.seletedFarm = farm;
          this.setCurrentLocationName(farm.name);
          this.setCurrentLevel(farm.level);
          newLocations.push(farm.bd_id);
          this.locations = farm.children;
          this.temporalAreaList.push({ id: farm.bd_id, name: farm.name, area: farm.area });
          break;
        }
      }
      if (this.currentLevel === this.endLevel) {
        this.updateLocations(newLocations, this.temporalAreaList);
        return;
      }
      if (this.currentLevel > this.endLevel) {
        return;
      }
      newLocations = [];
      this.temporalAreaList = [];
      for (const child of this.seletedFarm.children) {
        newLocations.push(child.bd_id);
        this.temporalAreaList.push({ id: child.bd_id, name: child.name, area: child.area });
      }
      this.updateLocations(newLocations, this.temporalAreaList);
    },
    emitEvents() {
      for (const event of this.events) {
        EventBus.$emit(event);
      }
    },
    updateLocations(selectedFarms, newAreaList) {
      this.setSelectedFarmList(selectedFarms);
      this.setAreaList(newAreaList);
      this.emitEvents();
    },
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      const locations = JSON.parse(xhr.response);
      this.setLocationsTree(locations);
      this.locations = locations;
      for (const location of locations) {
        this.setCurrentLocation(location.bd_id);
        this.setCurrentLocationName(location.name);
        this.filterByLevel(this.currentLevel, location);
      }
      this.setSelectedFarmList(this.selectedFarmList.filter(this.onlyUnique));
      this.setAreaList(this.removeDuplicatesAreas());
    },
    filterByLevel(level, locationsList) {
      this.filteredLocations = [];
      this.temporalAreaList = [];
      if (level > 0) {
        for (const farm of locationsList.children) {
          this.viewChildren(farm, locationsList, level);
        }
      } else {
        this.filteredLocations.push(locationsList.bd_id);
        this.temporalAreaList.push({ id: locationsList.bd_id, name: locationsList.name, area: locationsList.area });
      }
      this.setSelectedFarmList(this.selectedFarmList.concat(this.filteredLocations));
      this.setAreaList(this.areaList.concat(this.temporalAreaList));
    },
    viewChildren(item, locations, level) {
      if (item.level === level) {
        this.filteredLocations.push(item.bd_id);
        this.temporalAreaList.push({ id: item.bd_id, name: item.name, area: item.area });
        return;
      }

      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    onlyUniqueArea(value, index, self) {
      return self[index].id !== value.id;
    },
    removeDuplicatesAreas() {
      return this.areaList.filter((obj, pos, arr) => arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos);
    },
    ...mapActions('Reporting',
      ['setSelectedFarmList', 'setAreaList',
        'setCurrentLevel', 'setCurrentLocation',
        'setLocationsTree', 'setCurrentLocationName']),
  },
};
</script>

<style lang="scss">
</style>
