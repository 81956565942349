<template>
  <DxDataGrid
    :data-source="DataSource"
    :remote-operations="false"
    :allow-column-reordering="true"
    :row-alternation-enabled="true"
    :show-borders="true"
    @content-ready="onContentReady"
  >
    <DxStateStoring
      :enabled="true"
      type="localStorage"
      storage-key="ItemsTable"
    />
    <DxHeaderFilter :visible="true" />
    <DxFilterRow :visible="true" />
    <DxFilterPanel :visible="true" />
    <DxFilterBuilderPopup :position="filterBuilderPopupPosition" />
    <DxColumn
      :group-index="0"
      data-field="Product"
    />
    <DxColumn
      data-field="Amount"
      caption="Sale Amount"
      data-type="number"
      format="currency"
      alignment="right"
    />
    <DxColumn
      :allow-grouping="false"
      data-field="Discount"
      caption="Discount %"
      data-type="number"
      format="percent"
      alignment="right"
      cell-template="discountCellTemplate"
      css-class="bullet"
    />
    <DxColumn
      data-field="SaleDate"
      data-type="date"
    />
    <DxColumn
      data-field="Region"
      data-type="string"
    />
    <DxColumn
      data-field="Sector"
      data-type="string"
    />
    <DxColumn
      data-field="Channel"
      data-type="string"
    />
    <DxColumn
      :width="150"
      data-field="Customer"
      data-type="string"
    />

    <DxGroupPanel
      :visible="true"
    />
    <DxSearchPanel
      :visible="true"
      :highlight-case-sensitive="true"
      :placeholder="$t('Search_placeholder')"
    />
    <DxGrouping
      :auto-expand-all="false"
    />
    <DxPager
      :allowed-page-sizes="pageSizes"
      :show-page-size-selector="true"
    />
    <DxPaging
      :page-size="10"
    />
    <template #discountCellTemplate="{ data: cellData }">
      <DiscountCell
        :cell-data="cellData"
      />
    </template>
  </DxDataGrid>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxFilterRow,
  DxFilterPanel,
  DxFilterBuilderPopup,
  DxHeaderFilter,
  DxStateStoring,
} from 'devextreme-vue/data-grid';

import DataSource from 'devextreme/data/data_source';
import 'devextreme/data/odata/store';

import DiscountCell from './DiscountCell.vue';

let collapsed = false;

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DiscountCell,
    DxFilterRow,
    DxFilterPanel,
    DxFilterBuilderPopup,
    DxHeaderFilter,
    DxStateStoring,
  },
  data() {
    return {
      dataSource: new DataSource({
        store: {
          type: 'odata',
          url: 'https://js.devexpress.com/Demos/SalesViewer/odata/DaySaleDtoes',
          beforeSend(request) {
            request.params.startDate = '2018-05-10';
            request.params.endDate = '2018-05-15';
          },
        },
      }),
      pageSizes: [10, 25, 50, 100],
      onContentReady(e) {
        if (!collapsed) {
          e.component.expandRow(['EnviroCare']);
          collapsed = true;
        }
      },
      DataSource,
      filterBuilderPopupPosition: {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
      },
      filterBuilder: {
        customOperations: [{
          name: 'weekends',
          caption: 'Weekends',
          dataTypes: ['date'],
          icon: 'check',
          hasValue: false,
          calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
      },
      filterValue: [['Employee', '=', 'Clark Morgan'], 'and', ['OrderDate', 'weekends']],
      saleAmountHeaderFilters: [{
        text: 'Less than $3000',
        value: ['SaleAmount', '<', 3000],
      }, {
        text: '$3000 - $5000',
        value: [['SaleAmount', '>=', 3000], ['SaleAmount', '<', 5000]],
      }, {
        text: '$5000 - $10000',
        value: [['SaleAmount', '>=', 5000], ['SaleAmount', '<', 10000]],
      }, {
        text: '$10000 - $20000',
        value: [['SaleAmount', '>=', 10000], ['SaleAmount', '<', 20000]],
      }, {
        text: 'Greater than $20000',
        value: ['SaleAmount', '>=', 20000],
      }],
    };
  },
};
</script>
