<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning"
      style="margin:5px 15px 25px 15px;"
    >
      <DxDataGrid
        id="toolsTable"
        ref="toolsTable"
        :data-source="actualTaskBadget.tools"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :column-auto-width="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        @row-inserted="onInsertedRow"
        @row-updated="onChangeRow"
        @row-removed="onRemovedRow"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxToolsMonthlyTable"
        />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          mode="form"
        >
          <DxForm>
            <DxItem
              :col-count="1"
              :col-span="2"
              item-type="group"
            >
              <DxItem
                data-field="type"
                :caption="$t('DxToolsMonthlyTable_type_caption')"
              />
              <DxItem
                :editor-options="{ showSpinButtons: false,
                                   mode: 'number',
                                   placeholder: '0',
                                   showClearButton: true,
                                   min: 0,
                                   step: 1,
                }"
                editor-type="dxNumberBox"
                data-field="count"
              />
              <DxItem
                :editor-options="{ showSpinButtons: false,
                                   mode: 'number',
                                   placeholder: '0',
                                   showClearButton: true,
                                   min: '0',
                                   step: '1',
                }"
                editor-type="dxNumberBox"
                data-field="consumption"
                :caption="$t('DxToolsMonthlyTable_consumption_caption')"
              />
              <DxItem
                data-field="consumption_type"
                :camption="$t('DxToolsMonthlyTable_consumption_type_caption')"
              />
              <DxItem
                data-field="consumption_unit"
                :camption="$t('DxToolsMonthlyTable_consumption_unit_caption')"
              />
            </DxItem>
          </DxForm>
        </DxEditing>
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="type"
          name="type"
          alignment="left"
          :caption="$t('DxMachinery_dxColumn_TypeMachinery')"
        >
          <DxLookup
            :data-source="selectTool"
            display-expr="name"
            value-expr="name"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="count"
          alignment="right"
          name="count"
          data-type="number"
          :caption="$t('MonthlyComponent_quantity_caption')"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="consumption"
          alignment="right"
          name="consumption"
          data-type="number"
          :caption="$t('MonthlyComponent_consumption_caption')"
        />
        <DxColumn
          data-field="consumption_unit"
          alignment="left"
          name="consumptionUnit"
          :caption="$t('MonthlyComponent_consumptionunit_caption')"
        >
          <DxLookup
            :data-source="consumptionUnit"
          />
        </DxColumn>
        <DxColumn
          data-field="consumption_type"
          name="consumptionType"
          alignment="left"
          :caption="$t('MonthlyComponent_typeconsumption_caption')"
        >
          <DxLookup
            :data-source="typeCosumptionAvailable"
            :display-expr="dysplayConsumptionType"
          />
        </DxColumn>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
  DxForm,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxProducts',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxForm,
    DxItem,
    DxRequiredRule,
    DxLookup,
    DxStateStoring,
  },
  props: {
    selectTool: { type: Array, default: () => [] },
    allowUpdating: { type: Boolean, default: true },
    allowDeleting: { type: Boolean, default: true },
    allowAdding: { type: Boolean, default: true },
  },
  data() {
    return {
      typeCosumptionAvailable: ['DIESEL', 'GASOLINE'],
      consumptionUnit: [`L/${this.$t('DxWorker_workingDay_caption')}`],
    };
  },
  computed: {
    ...mapState('TaskBadget', ['actualTaskBadget']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
  },
  methods: {
    dysplayConsumptionType(e) {
      if (e === 'DIESEL') {
        return this.$t('DIESEL');
      }
      return this.$t('GASOLINE');
    },
    // SET DATA SOURCE TO PRODUCTS VUEX
    onChangeRow() {
      const arrayItems = this.actualTaskBadget.tools;
      this.setTools(arrayItems);
      EventBus.$emit('updateCosts');
    },
    onInsertedRow() {
      const arrayItems = this.actualTaskBadget.tools;
      this.setTools(arrayItems);
      EventBus.$emit('updateCosts');
    },
    onRemovedRow() {
      const arrayItems = this.actualTaskBadget.tools;
      this.setTools(arrayItems);
      EventBus.$emit('updateCosts');
    },
    ...mapActions('TaskBadget', ['setTools']),
  },
};
</script>
