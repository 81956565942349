export default {
  namespaced: true,
  state: {
    actualCost: {},
    costFormated: [],
    listCurrency: ['EURO'],
    listUnit: ['L', 'KG', 'DAY'],
  },
  getters: {
    actualCost: (state) => state.actualCost,
    costFormated: (state) => state.costFormated,
    listCurrency: (state) => state.lisCurrency,
    listUnit: (state) => state.listUnit,
  },
  actions: {
    setActualCost(context, value) {
      context.commit('setActualCost', value);
    },
    setCostFormated(context, value) {
      context.commit('setCostFormated', value);
    },
    setCode(context, value) {
      context.commit('setCode', value);
    },
    setCurrency(context, value) {
      context.commit('setCurrency', value);
    },
    setCurrencyCode(context, value) {
      context.commit('setCurrencyCode', value);
    },
    setId(context, value) {
      context.commit('setId', value);
    },
    setName(context, value) {
      context.commit('setName', value);
    },
    setPrice(context, value) {
      context.commit('setPrice', value);
    },
    setUnit(context, value) {
      context.commit('setUnit', value);
    },
  },
  mutations: {
    setCostFormated(state, value) {
      state.costFormated = value;
    },
    setActualCost(state, value) {
      state.actualCost = value;
    },
    setCode(state, value) {
      state.actualCost.code = value;
    },
    setName(state, value) {
      state.actualCost.name = value;
    },
    setCurrency(state, value) {
      state.actualCost.currency = value;
    },
    setCurrencyCode(state, value) {
      state.actualCost.currencyCode = value;
    },
    setId(state, value) {
      state.actualCost.id = value;
    },
    setPrice(state, value) {
      state.actualCost.price = value;
    },
    setUnit(state, value) {
      state.actualCost.unit = value;
    },
  },
};
