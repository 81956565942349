export default {
  namespaced: true,
  state: {
    id: 0,
    newTask: 0,
    task_name: null,
    task_code: null,
    workers: [],
    task_unit: null,
    unit_price: null,
    locations: null,
    locationId: 0,
    locationName: null,
    event: null,
    upSheet: false,
    activity: null,
    activityId: 0,
    farm: null,
    totalAreaSelected: 0,
  },

  getters: {
    newTask: (state) => state.newTask,
    locations: (state) => state.locations,
    locationId: (state) => state.locationId,
    locationName: (state) => state.locationName,
    event: (state) => state.event,
    id: (state) => state.id,
    task_name: (state) => state.task_name,
    task_code: (state) => state.task_code,
    workers: (state) => state.workers,
    task_unit: (state) => state.task_unit,
    task_price: (state) => state.task_price,
    upSheet: (state) => state.upSheet,
    activity: (state) => state.activity,
    activityId: (state) => state.activityId,
    farm: (state) => state.farm,
    totalAreaSelected: (state) => state.totalAreaSelected,
  },

  actions: {
    setLocations(context, value) {
      context.commit('setLocations', value);
    },
    setLocationId(context, value) {
      context.commit('setLocationId', value);
    },
    setLocationName(context, value) {
      context.commit('setLocationName', value);
    },
    setNewTask(context, value) {
      context.commit('setNewTask', value);
    },
    setId(context, value) {
      context.commit('setId', value);
    },
    changeUpSheet(context, value) {
      context.commit('changeUpSheet', value);
    },
    setTaskName(context, value) {
      context.commit('setTaskName', value);
    },
    setTaskCode(context, value) {
      context.commit('setTaskCode', value);
    },
    setWorkers(context, value) {
      context.commit('setWorkers', value);
    },
    setTaskUnit(context, value) {
      context.commit('setTaskUnit', value);
    },
    setTaskPrice(context, value) {
      context.commit('setTaskPrice', value);
    },
    setEvent(context, value) {
      context.commit('setEvent', value);
    },
    setActivity(context, value) {
      context.commit('setActivity', value);
    },
    setActivityId(context, value) {
      context.commit('setActivityId', value);
    },
    setFarm(context, value) {
      context.commit('setFarm', value);
    },
    setTotalAreaSelected(context, value) {
      context.commit('setTotalAreaSelected', value);
    },
  },

  mutations: {
    setLocations(state, user) {
      state.locations = user;
    },
    setLocationId(state, user) {
      state.locationId = user;
    },
    setLocationName(state, user) {
      state.locationName = user;
    },
    setNewTask(state, user) {
      state.newTask = user;
    },
    changeUpSheet(state) {
      state.upSheet = !state.upSheet;
    },
    setId(state, user) {
      state.id = user;
    },
    setTaskName(state, user) {
      state.task_name = user;
    },
    setTaskCode(state, user) {
      state.task_code = user;
    },
    setWorkers(state, user) {
      state.workers = user;
    },
    setTaskUnit(state, user) {
      state.task_unit = user;
    },
    setTaskPrice(state, user) {
      state.task_price = user;
    },
    setEvent(state, event) {
      state.event = event;
    },
    setActivity(state, user) {
      state.activity = user;
    },
    setActivityId(state, user) {
      state.activityId = user;
    },
    setFarm(state, user) {
      state.farm = user;
    },
    setTotalAreaSelected(state, user) {
      state.totalAreaSelected = user;
    },
  },
};
