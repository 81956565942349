<template>
  <f7-sheet 
    class="demo-sheet-swipe-to-close"
    swipe-to-close
    backdrop
  >
    <f7-page-content class="SheetExport">
      <f7-row class="rowStyles">
        <f7-col width="40">
          <div class="item-media">
            <div class="event-type-icon">
              <img
                v-if="propssheetexport.status==='ok'"
                width="60"
                height="60"
                class="ic-sheet-export"
              >
              <img 
                v-else
                width="60"
                height="60"
                class="ic-sheet-export-error"
              >
            </div>
          </div>
        </f7-col>
        <f7-col
          v-if="propssheetexport.status==='ok'"
          width="60"
          top="25%"
          style="color:#4A98F7;"
          name="sheet_export_ok"
        >
          <div class="messageStyles">
            {{ $t("SheetExport_sheet_export_ok") }}
            {{ propssheetexport.filename }}
          </div>
        </f7-col>
        <f7-col
          v-else
          width="60"
          top="25%"
          style="color:#FF654F;"
          name="sheet_export_error"
        >
          {{ $t("SheetExport_sheet_export_error") }}
        </f7-col>
      </f7-row>
    </f7-page-content>
  </f7-sheet>
</template>

<script>

export default {
  name: 'SheetExport',
  props: { propssheetexport: { type: Object, default: null } },
  data() {
    return {
    };
  },
};
</script>

<style>
.demo-sheet-swipe-to-close{
  height:auto; 
  --f7-sheet-bg-color: #fff;
}
.img.ic-sheet-export{
  padding: 20%;
}
.img.ic-sheet-export-error{
  padding: 20%;
}
.SheetExport{
  height: auto;
  
}
.rowStyles{
  align-items: center;
}
.ic-sheet-export{
  content:url('../static/img/ic_sheet_export.svg');
}
.ic-sheet-export-error{
  content:url('../static/img/ic_sheet_export_error.svg');
}
.messageStyles{
  padding-right: 5%;
}
</style>
