<template>
  <f7-page>
    <navbar :text="$t('CloneAnnualBudget_Title')" />
    <DxAnnualBudgetForm
      class="content-planning"
    />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToAnnualBudgets"
      >
        {{ $t("Button_cancel_text") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        @click="cloneAnnualBudget"
      >
        {{ $t('Button_save_text') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import DxAnnualBudgetForm from '../../components/Planning/DxAnnualBudgetForm.vue';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';

export default {
  name: 'CloneAnnualBudget',
  components: {
    DxAnnualBudgetForm,
    navbar,
  },
  computed: {
    ...mapState('PlanningManager', ['annualBudget']),
  },
  methods: {
    goToAnnualBudgets() {
      this.$f7.views.main.router.navigate('/annualBudgets/', { reloadCurrent: true });
    },
    async cloneAnnualBudget() {
      if (this.annualBudget.annualBudgetVersion === '') {
        this.$f7.dialog.alert(this.$t('AnnualBudget_version_validation'));
      } else if (this.annualBudget.annualBudgetYear === '') {
        this.$f7.dialog.alert(this.$t('AnnualBudget_year_validation'));
      } else {
        this.$f7.preloader.show();
        try {
          const newAnnualBudgetVersion = {
            name: this.annualBudget.annualBudgetVersion,
            year: this.annualBudget.annualBudgetYear,
            badget: this.annualBudget.annualBudgetId,
            version: this.annualBudget.annualBudgetVersionId,
          };
          await Api.cloneAnnualBudgetVersion(newAnnualBudgetVersion);
          this.goToAnnualBudgets();
        } catch (reason) {
          switch (reason.code) {
            case 'NAME_EXIST':
              this.$f7.dialog.alert(this.$t('AnnualBudget_catch_NAME_EXIST_case'));
              break;
            case 'NOT_CLOSED':
              this.$f7.dialog.alert(this.$t('AnnualBudget_catch_NOT_CLOSED_case'));
              break;
            case 'VERSION_NOT_FOUND':
              this.$f7.dialog.alert(this.$t('AnnualBudget_catch_VERSION_NOT_FOUND_case'));
              break;
            case 'BADGET_NOT_FOUND':
              this.$f7.dialog.alert(this.$t('AnnualBudget_catch_BADGET_NOT_FOUND_case'));
              break;
            case 'BADGET_MANY_VERSIONS ':
              this.$f7.dialog.alert(this.$t('AnnualBudget_catch_ERROR_BADGET_MANY_VERSIONS_case'));
              break;
            default:
              this.$f7.dialog.alert(reason); // TODO: Hay que dar una respuesta más detallada del error
              break;
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
  },
};
</script>
