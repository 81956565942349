<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-work-orders main-dx-work-orders"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="gridContainer"
      :data-source="detailDailyTasks"
      :remote-operations="true"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        :storage-key="`${keyName}`"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxSelection
        mode="single"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        :buttons="editButtons"
        type="buttons"
        width="80"
        data-field="Actions"
      />
      <DxColumn
        data-field="short_id"
        name="short_id"
        :caption="$t('DxWorkOrder_short_id')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Date"
        name="date"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Activity"
        name="Activity"
        :caption="$t('DxDailyPlanning_activity_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :width="200"
        css-class="text-expand"
        :caption="$t('DxDailyPlanning_task_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        :caption="$t('DxDailyPlanning_location_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        v-if="keyName === 'harvesting'"
        data-field="variety"
        name="variety"
        :caption="$t('DxWorkOrders_variety_caption')"
        :allow-header-filtering="true"
        alignment="center"
      />
      <DxColumn
        data-field="area_effective"
        name="area_effective"
        data-type="number"
        :caption="$t('DxDailyPlanning_workingArea_caption')"
        :allow-header-filtering="false"
        :format="areaEffectiveColumnFormat"
        alignment="center"
        width="140"
      />
      <DxColumn
        data-field="goal_performance"
        name="goal_performance"
        :caption="$t('DxMonthlyPlanning_goal_caption')"
        :allow-header-filtering="false"
        alignment="center"
        width="140"
      />
      <DxColumn
        v-if="keyName === 'harvesting'"
        data-field="driver"
        name="driver"
        :caption="$t('DxWorkOrders_driver_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        v-else
        data-field="Workers"
        name="workers"
        :caption="$t('DxDailyPlanning_workers_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="Supervisor"
        name="supervisor"
        :caption="$t('DxDailyPlanning_supervisors_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        v-if="keyName === 'harvesting'"
        data-field="additionalInfoWorkOrder"
        name="notes"
        :caption="$t('DxWorkOrders_notes_caption')"
        :allow-header-filtering="false"
        alignment="left"
        width="400"
        css-class="text-expand"
      />
      <DxColumn
        v-if="keyName === 'harvesting'"
        data-field="manualPerformance.total"
        name="manualPerformance"
        :caption="$t('DxWorkOrders_manualPerformance_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="TaskStatus"
        name="task_status"
        :caption="$t('DxDailyPlanning_task_status_caption')"
        alignment="center"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataTaskStatus }"
        cell-template="taskStatusTemplate"
      />
      <DxColumn
        data-field="WorkOrderStatus"
        name="work_order_status"
        width="160"
        alignment="center"
        :caption="$t('DxDailyPlanning_work_order_status_caption')"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataWordTaskStatus }"
        cell-template="workOrderStatusTemplate"
      />
      <!--<DxButton
          :text="$t('DxDailyPlanning_delete_text')"
          :hint="$t('DxDailyPlanning_delete_hint')"
          :on-click="deleteTask"
          :disabled="deshabilitarEditDelete(detailDailyTasks.TaskStatus)"
        />
        <DxButton
          :text="$t('DxWorkOrders_edit_text')"
          :hint="$t('DxWorkOrders_edit_hint')"
          :on-click="editWorkOrder"
          :disabled="deshabilitarEditDelete(detailDailyTasks.TaskStatus)"
        />-->
      <template #taskStatusTemplate="{ data: detailDailyTasks }">
        <div :class="detailDailyTasks.data.taskStatusTemplateClass">
          {{ detailDailyTasks.data.taskStatusTranslated }}
        </div>
      </template>
      <template #workOrderStatusTemplate="{ data: detailDailyTasks }">
        <div :class="detailDailyTasks.data.workOrderStatusTemplateClass">
          {{ detailDailyTasks.data.workOrderStatusTranslated }}
        </div>
      </template>
      <DxMasterDetail
        :enabled="true"
        template="master-detail"
      />
      <template #master-detail="{ data: detailDailyTasks }">
        <DxDetailTabs :master-detail-data="detailDailyTasks" />
      </template>
    </DxDataGrid>
    <DxPopupWorkOrder
      v-if="visiblePopupWorkOrder"
      :machinery-popup="machineryPopup"
      :product-popup="productPopup"
      :supervisor="supervisorPopup"
      :supervisors-availables="supervisorsAvailables"
      :back="back"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxMasterDetail,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import DxDetailTabs from './DxDetailTabs.vue';
import DxPopupWorkOrder from './DxPopupWorkOrder.vue';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxMasterDetail,
    DxDetailTabs,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxPopupWorkOrder,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
  },
  props: {
    includeActivities: { type: Array, default: () => [] },
    excludeActivities: { type: Array, default: () => [] },
    back: { type: String, default: 'workOrders' },
    keyName: { type: String, default: '' },
    reporting: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
      detailDailyTasks: [],
      locationsTree: [],
      locationsFake: [],
      finalLevelLocation: [],
      supervisorsAvailables: [],
      taskStatusTranslated: '',
      workOrderStatusTranslated: '',
      machineryPopup: [],
      productPopup: [],
      ocultarBotton: false,
      editButtons: [{
        icon: 'edit',
        hint: this.$t('DxWorkOrders_edit_hint'),
        visible: this.isEditIconVisible,
        onClick: this.editWorkOrder,
      },
      {
        icon: 'trash',
        hint: this.$t('DxDailyPlanning_delete_hint'),
        visible: this.isDeleteIconVisible,
        onClick: this.deleteTask,
      },
      {
        icon: 'find',
        hint: this.$t('DxWorkOrder_Button_View_hint'),
        visible: this.isViewconVisible,
        onClick: this.viewtask,
      }],
      areaEffectiveColumnFormat: { type: 'fixedPoint', precision: 2 },
      dataTaskStatus: [{
        text: this.$t('Status_Open'),
        value: 'OPEN',
      }, {
        text: this.$t('Status_Completed'),
        value: 'COMPLETED',
      },
      {
        text: this.$t('Status_InProgress'),
        value: 'In_Progress',
      }],
      dataWordTaskStatus: [{
        text: this.$t('Status_reallyNot_Closed'),
        value: 'OPEN',
      }, {
        text: this.$t('Status_Closed'),
        value: 'COMPLETED',
      }],
      supervisorPopup: '',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['visiblePopupWorkOrder']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getVarietyNameByCode', 'getCategoryNameByCode', 'getClassificationNameByCode']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      this.locationsTree = await this.loadLocationsTree();
      this.supervisorsAvailables = await this.getSupervisors();
      await this.fetchBinsContentFamilies();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getDailyTasks(true, this.includeActivities, this.excludeActivities, checkedInitEndDate.init, checkedInitEndDate.end, this.reporting);
      const dailyTasks = JSON.parse(xhr.response);
      this.detailDailyTasks.length = 0;
      for (let i = 0; i < dailyTasks.detail.length; i += 1) {
        for (let j = 0; j < dailyTasks.detail[i].tasks.length; j += 1) {
          const items = this.getItems(dailyTasks.detail[i].tasks[j].items_drivers);
          // Establedemos los nombres de variedad/categoria/clasificacion a través del code
          if (dailyTasks.detail[i].tasks[j].nursery) {
            dailyTasks.detail[i].tasks[j].nursery.forEach((element) => {
              const varietyName = this.getVarietyNameByCode(element.variety);
              const categoryName = this.getCategoryNameByCode(element.category);
              const classificationName = this.getClassificationNameByCode(element.class_type);
              element.variety = varietyName !== '' ? varietyName : element.variety;
              element.category = varietyName !== '' ? categoryName : element.category;
              element.class_type = classificationName !== '' ? classificationName : element.class_type;
            });
          }
          if (dailyTasks.detail[i].tasks[j].planner != null) {
            this.locationsFake = [...dailyTasks.detail[i].tasks[j].location];
            if (this.reporting && (dailyTasks.detail[i].tasks[j].status !== 'COMPLETED' || dailyTasks.detail[i].tasks[j].worder_status !== 'COMPLETED')) {
              continue;
            }
            this.detailDailyTasks.push({
              ID: dailyTasks.detail[i].tasks[j].id,
              Date: moment(dailyTasks.detail[i].tasks[j].date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
              Task: dailyTasks.detail[i].tasks[j].task,
              taskCode: dailyTasks.detail[i].tasks[j].task_code,
              Activity: dailyTasks.detail[i].tasks[j].activity,
              activityCode: dailyTasks.detail[i].tasks[j].activity_code,
              TaskStatus: dailyTasks.detail[i].tasks[j].status,
              taskStatusTemplateClass: this.setClassByTaskStatus(dailyTasks.detail[i].tasks[j].status),
              taskStatusTranslated: this.taskStatusTranslated,
              WorkOrderStatus: dailyTasks.detail[i].tasks[j].worder_status,
              workOrderStatusTemplateClass: this.setClassByWorkOrderStatus(dailyTasks.detail[i].tasks[j].worder_status),
              workOrderStatusTranslated: this.workOrderStatusTranslated,
              Supervisor: this.getNameSupervisor(dailyTasks.detail[i].tasks[j].responsible),
              Workers: `${this.getTotalWorkers(dailyTasks.detail[i].tasks[j].workers)} / ${this.getTotalWorkersPlanner(dailyTasks.detail[i].tasks[j].planner.workers)}`,
              WorkersDetailTab: dailyTasks.detail[i].tasks[j].workers,
              ItemsDetailTab: items,
              Attachments: dailyTasks.detail[i].tasks[j].attachments_vehicles,
              AttachmentsDetailTab: dailyTasks.detail[i].tasks[j].attachments,
              Tools: dailyTasks.detail[i].tasks[j].planner.tools,
              ToolsDetailTab: dailyTasks.detail[i].tasks[j].tools,
              itemsTypes: dailyTasks.detail[i].tasks[j].planner.items,
              items,
              itemsDriversWorkOrder: dailyTasks.detail[i].tasks[j].items_drivers,
              driver: this.getDriverName(dailyTasks.detail[i].tasks[j].workers),
              Location: this.getFinalLevelLocation(dailyTasks.detail[i].tasks[j].location),
              locationLevel2: this.getLocationLevel2(),
              area_effective: dailyTasks.detail[i].tasks[j].area_effective,
              goal_performance: this.getGoalPerformance(dailyTasks.detail[i].tasks[j].planner),
              location: dailyTasks.detail[i].tasks[j].location,
              Planner: dailyTasks.detail[i].tasks[j].planner,
              responsible: dailyTasks.detail[i].tasks[j].responsible,
              additionalInfoWorkOrder: dailyTasks.detail[i].tasks[j].comment,
              machineryPopup: dailyTasks.detail[i].tasks[j].machinery_performance,
              products: dailyTasks.detail[i].tasks[j].products,
              prescription: dailyTasks.detail[i].tasks[j].prescription,
              productPopup: dailyTasks.detail[i].tasks[j].product_performance,
              variety: dailyTasks.detail[i].tasks[j].variety,
              binsDetail: dailyTasks.detail[i].tasks[j].nursery,
              short_id: dailyTasks.detail[i].tasks[j].short_id,
              manualPerformance: dailyTasks.detail[i].tasks[j].performance,
            });
          }
        }
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    getItems(itemsDriver) {
      const items = [];
      for (const itemDriver of itemsDriver) {
        items.push(itemDriver.item);
      }
      return items;
    },
    // POR CADA FILA QUE SEA TASKSTATUS = COMPLETED Y WORKWORDERSTATUS = COMPLETED DESHABILITO LOS BOTONES EDITAR Y BORRAR
    isDeleteIconVisible(e) {
      if (e.row.data.TaskStatus === 'COMPLETED' || e.row.data.TaskStatus === 'IN_PROGRESS') {
        return false;
      }
      return true;
    },
    isEditIconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' && e.row.data.TaskStatus === 'COMPLETED') {
        return false;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS') {
        return false;
      }
      return true;
    },
    isViewconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' && e.row.data.TaskStatus === 'COMPLETED') {
        return true;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS' || e.row.data.TaskStatus === 'OPEN' || e.row.data.WorkOrderStatus !== 'COMPLETED') {
        return false;
      }
      return true;
    },
    // GET AND SET LOCATIONS
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    getLocationLevel2() {
      this.currentLevel2Name = '';
      this.level2Data = [];
      this.areaLevel2 = 0;
      for (let i = 0; i < this.locationsTree.length; i += 1) {
        if (this.locationsFake.length === 0) {
          // Si no quedan ids en el array de parcelas de esta tarea
          break;
        }
        this.recursiveGetChildrens(this.locationsTree[i]);
      }
      return this.level2Data;
    },
    recursiveGetChildrens(items) {
      if (items.level === 2) {
        // Solo comprobamos los elementos con nivel === 2, sus hijos los comprobamos dentro
        this.currentLevel2Name = items.name;
      }
      if (items.children.length === 0) {
        // Si el nivel no tiene hijos, comprobamos si su id está en el array
        const check = this.locationsFake.indexOf(items.bd_id);
        if (check !== -1) {
          const alreadyInserted = this.level2Data.indexOf(this.currentLevel2Name);
          if (alreadyInserted === -1) {
            // Está en el array de localizaciones y aun no lo hemos guardado
            this.level2Data.push(this.currentLevel2Name);
          }
          this.areaLevel2 += items.area;
          this.locationsFake.splice(check, 1); // Elimino el id del array
        }
      } else if (this.locationsFake.length !== 0) {
        // El nivel tiene hijos
        for (let j = 0; j < items.children.length; j += 1) {
          this.recursiveGetChildrens(items.children[j]);
        }
      }
    },
    getFinalLevelLocation(locations) {
      this.finalLevelLocation = [];
      for (const item of this.locationsTree) {
        this.viewChildren(item, locations);
      }
      const tam = this.finalLevelLocation.length - 1;
      return this.finalLevelLocation[tam];
    },
    getGoalPerformance(planner) {
      if (planner.goal_performance.unit === '') {
        return '';
      }
      return `${planner.goal_performance.count} ${planner.goal_performance.unit}`;
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (locations.find((element) => element === item.bd_id)) {
          this.finalLevelLocation.push(item.name);
        }
      }
    },
    // GET WORKERS - SUPERVISORS
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    getNameSupervisor(idSupervisor) {
      for (const supervisor of this.supervisorsAvailables) {
        if (supervisor.id === idSupervisor) {
          return `${supervisor.name} ${supervisor.surname}`;
        }
      }
      return '';
    },
    getTotalWorkers(workers) {
      return workers.length;
    },
    getTotalWorkersPlanner(workersPlanner) {
      let element = 0;
      for (let i = 0; i < workersPlanner.length; i += 1) {
        element += workersPlanner[i].count;
      }
      return element;
    },
    // EDIT, VIEW AND DELETE TASK
    editWorkOrder(rowSelected) {
      if (rowSelected.row.data.TaskStatus === 'OPEN') {
        // Comprobación para editar productos / prescripcion
        if (rowSelected.row.data.Planner.prescription) {
          this.setPrescription(rowSelected.row.data.Planner.prescription);
        } else {
          this.setNewPrescription();
        }
        // Comprobación para editar tools
        if (rowSelected.row.data.Planner) {
          rowSelected.row.data.ToolsDetailTab = rowSelected.row.data.Planner.tools;
        }
        const binsContentPlannedToEdit = rowSelected.row.data.binsDetail.map((content) => ({
          bin: content.bin.bin_id,
          category: content.category,
          classification: content.class_type,
          content: content.code,
          variety: content.variety,
          plants: content.count,
        }));
        this.setBinsContentPlannedToEdit(binsContentPlannedToEdit);
        this.setTaskSelectedByTaskCode(rowSelected.row.data.taskCode);
        this.setActualWorkOrder(rowSelected.row.data);
        this.setProducts(rowSelected.row.data.products);
        this.$f7.views.main.router.navigate(`/editOrder/${this.back}/`, { reloadCurrent: true });
      } else if (rowSelected.row.data.TaskStatus === 'COMPLETED' && rowSelected.row.data.WorkOrderStatus !== 'COMPLETED') {
        this.machineryPopup = rowSelected.row.data.machineryPopup;
        this.productPopup = rowSelected.row.data.productPopup;
        this.supervisorPopup = rowSelected.row.data.responsible;
        if (rowSelected.row.data.responsible === null) {
          this.supervisorPopup = '';
          rowSelected.row.data.responsible = '';
        }
        this.setActualWorkOrder(rowSelected.row.data);
        this.setVisiblePopupWorkOrder(true);
      } else {
        this.$f7.dialog.alert(this.$t('DxDailyWorkOrders_dialogUpdate_noOpen'));
      }
    },
    async deleteTask(rowSelected) {
      if (rowSelected.row.data.TaskStatus !== 'OPEN') {
        this.$f7.dialog.alert(this.$t('DxDailyWorkOrders_dialogDelete_noOpen'));
        return false;
      }
      const taskId = rowSelected.row.data.ID;
      const f7 = this.$f7;
      this.$f7.dialog.create({
        title: this.$t('DxDailyWorkOrders_dialogDelete_title'),
        text: this.$t('DxDailyWorkOrders_dialogDelete_text'),
        buttons: [
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_cancel'),
          },
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_confirm'),
            async onClick() {
              try {
                f7.preloader.show();
                await Api.deleteTask(taskId);
                f7.views.main.router.navigate('/workOrders/', { reloadCurrent: true });
              } catch (e) {
                f7.dialog.alert(e);
              } finally {
                f7.preloader.hide();
              }
            },
          },
        ],
      }).open();
      return false;
    },
    viewtask(rowSelected) {
      if (rowSelected.row.data.TaskStatus === 'COMPLETED') {
        // Comprobación para editar productos / prescripcion
        if (rowSelected.row.data.Planner.prescription) {
          this.setPrescription(rowSelected.row.data.Planner);
        }
        // Comprobación para editar tools
        if (rowSelected.row.data.Planner) {
          rowSelected.row.data.ToolsDetailTab = rowSelected.row.data.Planner.tools;
        }
        this.setActualWorkOrder(rowSelected.row.data);
        this.setProducts(rowSelected.row.data.products);
        this.$f7.views.main.router.navigate('/ViewPostWork/', { reloadCurrent: true });
      }
    },
    // SET CSS CLASSES TO CELLS
    setClassByTaskStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'OPEN':
          colorCell = 'open';
          this.taskStatusTranslated = this.$t('Status_Open');
          break;
        case 'IN_PROGRESS':
          colorCell = 'in-progress';
          this.taskStatusTranslated = this.$t('Status_InProgress');
          break;
        case 'COMPLETED':
          colorCell = 'completed';
          this.taskStatusTranslated = this.$t('Status_Completed');
          break;
        case 'PENDING':
          colorCell = 'pending';
          this.taskStatusTranslated = this.$t('Status_Pending');
          break;
        case 'DELETED':
          colorCell = 'deleted';
          this.taskStatusTranslated = this.$t('Status_Deleted');
          break;
        default:
          break;
      }
      return colorCell;
    },
    setClassByWorkOrderStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'COMPLETED':
          colorCell = 'work-order-closed';
          this.workOrderStatusTranslated = this.$t('Closed');
          break;
        case null:
        default:
          colorCell = 'work-order-not-closed';
          this.workOrderStatusTranslated = this.$t('Not Closed');
          break;
      }
      return colorCell;
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('WorkOrders');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WorkOrders.xlsx');
          });
      });
      e.cancel = true;
    },
    getDriverName(workers) {
      if (workers.length === 0) {
        return '';
      }
      return `${workers[0].name} ${workers[0].surname}`;
    },
    ...mapActions('PlanningManager', ['setActualWorkOrder', 'setVisiblePopupWorkOrder']),
    ...mapActions('Prescription', ['setProducts', 'setPrescription', 'setNewPrescription']),
    ...mapActions('greenhouse', [
      'fetchBinsContentFamilies',
      'setBinsContentPlannedToEdit',
      'setTaskSelectedByTaskCode',
    ]),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed{
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
</style>
