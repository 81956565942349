var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxForm',{attrs:{"id":"selectManualPerformance"}},[_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('DxManualPerformance_total_performance')) + " (" + _vm.goalPerformanceUnit + ")"),"name":"ManualPerformance","editor-type":"dxNumberBox","editor-options":{
      value: _vm.manualPerformance,
      showSpinButtons: false,
      mode: 'number',
      placeholder: 0,
      showClearButton: false,
      min: 0,
      step: 1,
      onValueChanged: _vm.manualPerformanceEvent,
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }