<template>
  <DxForm
    id="selectManualPerformance"
  >
    <DxSimpleItem
      :data-field="`${$t('DxManualPerformance_total_performance')} (${goalPerformanceUnit})`"
      name="ManualPerformance"
      editor-type="dxNumberBox"
      :editor-options="{
        value: manualPerformance,
        showSpinButtons: false,
        mode: 'number',
        placeholder: 0,
        showClearButton: false,
        min: 0,
        step: 1,
        onValueChanged: manualPerformanceEvent,
      }"
    />
  </DxForm>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxSelectSupervisorPerformance',
  components: {
    DxForm,
    DxSimpleItem,
  },
  computed: {
    ...mapGetters('PlanningManager', ['manualPerformance', 'goalPerformanceUnit']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.setUnitManualPerformance(this.goalPerformanceUnit);
  },
  methods: {
    manualPerformanceEvent(e) {
      const newValue = (e.value) ?? 0;
      this.setManualPerformance(newValue);
      this.setSaveButtonPopupWorkOrder(false);
    },
    ...mapActions('PlanningManager', ['setManualPerformance', 'setUnitManualPerformance', 'setSaveButtonPopupWorkOrder']),
  },
};
</script>
<style>
