<template>
  <f7-page
    name="changePassword"
    login-screen
  >
    <changePassword />
  </f7-page>
</template>

<script>
import changePassword from '../../components/accounts/ChangePassword.vue';

export default {
  components: {
    changePassword,
  },
  data() {
    return {
      changePassword,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
