<template>
  <div class="container">
    <f7-sheet 
      class="locationPicker"
      :opened="opened"
      :close-by-backdrop-click="false"
      :close-by-outside-click="false"
      :close-on-escape="false"
      :close-on-select="true"
    >
      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found" />
      </f7-list>-->

      <f7-list
        class="search-list searchbar-found"
      >
        <ul>
          <f7-list-button
            v-for="(location, index) in JSON.parse(loggedUser).farms"
            :key="index"
            :title="location.code"
            @click="locationPressed(location)"
          />
        </ul>
      </f7-list>
    </f7-sheet>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LocationPickerEventRow',
  props: {
    opened: { type: Boolean, default: false },
    availableLocations: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState('authentication', ['loggedUser']),
  },
  methods: {
    locationPressed(location) {
      this.$emit('locationSelected', location);
    },
  },
};
</script>

<style lang="scss">
  .locationPicker.sheet-modal .sheet-modal-inner {
    overflow: auto;
  }
  .locationPicker.sheet-modal{
    height: inherit;
  }
</style>
