<template>
  <div
    v-if="loaded"
    class="content-devx main-order-create-no-grouped"
  >
    <f7-block
      class="content-planning-resume"
      name="order"
    >
      <label>{{ $t("WorkOrderDetail_order") }}</label>
      <span>{{ actualDailyPlanning.taskCode }}</span>
      <span>{{ actualDailyPlanning.Date.toString() }}</span>
      <span>({{ actualDailyPlanning.taskCode }}){{ actualDailyPlanning.Task }}</span>
      <span>({{ actualDailyPlanning.activityCode }}){{ actualDailyPlanning.Activity }}</span>
    </f7-block>
    <f7-row>
      <f7-col width="30">
        <f7-block-title name="select_supervisor">
          {{ $t("WorkOrderDetail_select_supervisor") }}:
        </f7-block-title>
        <DxSelectSupervisor
          :locations="actualDailyPlanning.locations"
          :locations-tree="locationsTree"
          :supervisors-availables="supervisorsAvailables"
        />
        <f7-block-title
          class="no-padding-top"
          name="additional_info"
        >
          {{ $t("WorkOrderDetail_additional_info") }}:
        </f7-block-title>
        <DxAdditionalInfo />
      </f7-col>
      <f7-col width="70">
        <f7-row class="block content-devx">
          <f7-col>
            <f7-block-title
              v-if="actualDailyPlanning.ItemsDetailTab.length !== 0"
              class="no-padding-top"
            >
              {{ $t('WorkOrderDetail_select_driver') }}:
            </f7-block-title>
            <DxSelectItemsDriver
              v-if="actualDailyPlanning.ItemsDetailTab.length !== 0"
              :items-planning="actualDailyPlanning.ItemsDetailTab"
              :items-availables="itemsAvailables"
              :items-work-order="actualWorkOrder.itemsWorkOrder"
              :drivers-availables="driversAvailables"
            />
            <f7-block-title v-if="actualDailyPlanning.AttachmentsDetailTab.length !== 0">
              {{ $t('DxAttachment_Title') }}:
            </f7-block-title>
            <DxAttachmentMachinaryTable
              v-if="actualDailyPlanning.AttachmentsDetailTab.length !== 0"
              :attachment-list="attachmentList"
              :items-planning="actualDailyPlanning.AttachmentsDetailTab"
              :machinary-availables="itemsAvailables"
            />
          </f7-col>
        </f7-row>
        <f7-row
          v-if="actualDailyPlanning.ToolsDetailTab.length !== 0 || actualDailyPlanning.products.length !== 0"
          class="block content-devx"
        >
          <f7-col>
            <f7-block-title v-if="actualDailyPlanning.ToolsDetailTab.length !== 0">
              {{ $t('DxTools_Title') }}:
            </f7-block-title>
            <DxToolsTable
              v-if="actualDailyPlanning.ToolsDetailTab.length !== 0"
              :select-tool="toolList"
              :planning="actualDailyPlanning"
              :allow-updating="true"
              :allow-deleting="false"
              :allow-adding="false"
            />
            <f7-block-title v-if="actualDailyPlanning.products.length !== 0">
              {{ $t('DxProducts_Title') }}:
            </f7-block-title>
            <DxPlanningProducts
              v-if="actualDailyPlanning.products.length !== 0"
            />
            <f7-block-title v-if="actualDailyPlanning.binsDetail.length !== 0">
              {{ $t('DxNursery_Title') }}
            </f7-block-title>
            <DxBinsContentGridPlanning
              v-if="actualDailyPlanning.binsDetail.length !== 0"
            />
          </f7-col>
        </f7-row>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import DxSelectItemsDriver from './DxSelectItemsDriver.vue';
import DxAdditionalInfo from './DxAdditionalInfo.vue';
import DxSelectSupervisor from './DxSelectSupervisor.vue';
import DxPlanningProducts from '../Planning/DxPlanningProducts.vue';
import DxAttachmentMachinaryTable from './DxAttachmentMachinaryTable.vue';
import DxBinsContentGridPlanning from '../greenhouse/DxBinsContentGridPlanning.vue';
import DxToolsTable from '../Planning/DxToolsTable.vue';
import Api from '../../services/Api';

export default {
  name: 'DxWorkOrderDetail',
  components: {
    DxSelectItemsDriver,
    DxSelectSupervisor,
    DxAdditionalInfo,
    DxPlanningProducts,
    DxAttachmentMachinaryTable,
    DxToolsTable,
    DxBinsContentGridPlanning,
  },
  props: {
    actualWorkOrder: { type: Array, default: () => [] },
    actualDailyPlanning: { type: Object, default: () => {} },
  },
  data() {
    return {
      loaded: false,
      supervisorsAvailables: [],
      locationsTree: [],
      itemsAvailables: [],
      driversAvailables: [],
      attachmentList: [],
      toolList: [],
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.locationsTree = await this.loadLocationsTree();
      this.supervisorsAvailables = await this.getSupervisors();
      this.driversAvailables = await this.getEmployeesDriver();
      // Cargamos attachment
      let xhr = await Api.getDataSubfamilyByType('machinery');
      const machineryAttachment = JSON.parse(xhr.response);
      this.setDataAttachment(machineryAttachment);
      // Cargamos tools
      xhr = await Api.getDataSubfamilyByType('tool');
      const dataTools = JSON.parse(xhr.response);
      this.setDataTool(dataTools);
    } catch (error) {
      this.$f7.dialog.alert(error);
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },

  methods: {
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    async getItems(type, family) {
      const xhr = await Api.getItems(type, family);
      return JSON.parse(xhr.response);
    },
    async getEmployeesDriver() {
      const xhr = await Api.getEmployees();
      return JSON.parse(xhr.response).data.filter((employee) => employee.driver === true);
    },
    isDriver(empleado) {
      for (let i = 0; i < empleado.properties.length; i += 1) {
        if (empleado.properties[i].key === 'drive') {
          return empleado.properties[i].value;
        }
      }
      return 'False';
    },
    setDataAttachment(dataMachinaryAttachments) {
      this.itemsAvailables.length = 0;
      this.attachmentList.length = 0;
      for (let i = 0; i < dataMachinaryAttachments.length; i += 1) {
        if (dataMachinaryAttachments[i].id === 'VEHICLE') {
          for (let j = 0; j < dataMachinaryAttachments[i].items.length; j += 1) {
            this.itemsAvailables.push(dataMachinaryAttachments[i].items[j]);
          }
        } else if (dataMachinaryAttachments[i].id === 'ATTACHMENT') {
          for (let j = 0; j < dataMachinaryAttachments[i].items.length; j += 1) {
            this.attachmentList.push(dataMachinaryAttachments[i].items[j]);
          }
        }
      }
    },
    setDataTool(tools) {
      this.toolList.length = 0;
      for (let i = 0; i < tools.length; i += 1) {
        if (tools[i].id === 'TOOL') {
          for (let j = 0; j < tools[i].items.length; j += 1) {
            this.toolList.push({
              name: tools[i].items[j].id,
              id: tools[i].items[j].items[0].id,
            });
          }
          break;
        }
      }
    },
  },
};
</script>

<style>

</style>
