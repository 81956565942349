<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning no-margin no-shadow"
    >
      <DxDataGrid
        id="postworkattachmentMachinaryTable"
        ref="postworkattachmentMachinaryTable"
        :data-source="types"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxViewWorkOrderAttachmentMachineryTable"
        />
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="attachment_data.brand"
          :width="100"
          :caption="$t('DxAttachment_dxColumn_TypeAttachment')"
        />
        <DxColumn
          data-field="vehicle_data.description"
          name="vehicle"
          :caption="$t('DxAttachmentMachinary_dxColumn_Machinary')"
        />
        <DxColumn
          data-field="attachment_data.description"
          name="attachment"
          :caption="$t('DxAttachmentMachinary_dxColumn_Attachment')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxAttachmentMachinaryTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxStateStoring,
  },
  props: {
    types: { type: Array, default: () => [] },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState('PlanningManager', ['attachments']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
  },
  methods: {

  },
};
</script>
