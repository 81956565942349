<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-presciption-manager main-dx-prescription-manager"
  >
    <DxDataGrid
      id="prescriptionGrid"
      :data-source="prescriptionManagerFormated"
      :remote-operations="true"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxPrescriptionManager"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        v-if="$helpers.havePermission(getPermissions.company_update_master_data)"
        type="buttons"
        :width="80"
        data-field="Actions"
      >
        <DxButton
          icon="edit"
          :hint="$t('DxDailyPlanning_edit_hint')"
          :on-click="editPrescription"
        />
      </DxColumn>
      <DxColumn
        data-field="active"
        name="active"
        alignment="left"
        :caption="$t('DxCreatePrescription_column_active')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="name"
        name="name"
        alignment="left"
        :caption="$t('DxCreatePrescription_column_name')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="type"
        name="type"
        alignment="left"
        :caption="$t('DxCreatePrescription_column_type')"
        :allow-header-filtering="true"
      >
        <DxLookup
          :data-source="typePrescription"
          value-expr="id"
          display-expr="name"
        />
      </DxColumn>
      <DxColumn
        data-field="description"
        alignment="left"
        name="description"
        :caption="$t('DxPrescriptionManager_description')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="method"
        alignment="left"
        name="method"
        :caption="$t('DxCreatePrescription_column_method')"
        :allow-header-filtering="true"
      >
        <DxLookup
          :data-source="listMethodsFormated"
          value-expr="id"
          display-expr="name"
        />
      </DxColumn>
      <DxColumn
        data-field="dateActivation"
        alignment="left"
        name="dateActivation"
        :caption="$t('DxCreatePrescription_column_activeSince')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="notes"
        name="notes"
        alignment="left"
        :caption="$t('DxCreatePrescription_column_notes')"
        :allow-header-filtering="false"
      />
      <DxMasterDetail
        :enabled="true"
        template="products-tab"
      />
      <template #products-tab="{ data: activeIngredients }">
        <DxDetailTabs :detail-products="activeIngredients" />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxMasterDetail,
  DxSearchPanel,
  DxButton,
  DxExport,
  DxLookup,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import DxDetailTabs from './DxDetailTabs.vue';
import Api from '../../services/Api';

export default {
  name: 'DxPrescriptionManager',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxMasterDetail,
    DxDetailTabs,
    DxSearchPanel,
    DxButton,
    DxExport,
    DxLookup,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      prescriptionManagerFormated: [],
      listMethodsFormated: [],
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('authentication', ['getPermissions']),
    ...mapState('Prescription', ['prescription', 'listTypes', 'listMethods', 'typePrescription']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.translateMethods();
      const xhr = await Api.getDataSubfamilyByType('product');
      const dataArray = JSON.parse(xhr.response);
      this.setActiveIngredientsAvailables(dataArray);
      const xhr2 = await Api.getPrescriptions();
      const prescriptionManager = JSON.parse(xhr2.response);
      this.prescriptionManagerFormated.length = 0;
      for (let i = 0; i < prescriptionManager.length; i += 1) {
        let description = '';
        for (let j = 0; j < prescriptionManager[i].products.length; j += 1) {
          description = prescriptionManager[i].products[j].product.description;
        }
        this.prescriptionManagerFormated.push({
          active: this.formattingActiveColumn(prescriptionManager[i].active),
          name: prescriptionManager[i].name,
          type: prescriptionManager[i].type,
          method: prescriptionManager[i].method,
          disease: prescriptionManager[i].disease,
          dateActivation: prescriptionManager[i].dateActivation,
          notes: prescriptionManager[i].notes,
          products: prescriptionManager[i].products,
          id_edit: prescriptionManager[i].id,
          description,
        });
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async deleteTask(rowSelected) {
      const taskId = rowSelected.row.data.id_edit;
      const f7 = this.$f7;
      this.$f7.dialog.create({
        title: this.$t('DxDailyWorkOrders_dialogDelete_title'),
        text: this.$t('DxDailyWorkOrders_dialogDelete_text'),
        buttons: [
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_cancel'),
          },
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_confirm'),
            async onClick() {
              try {
                f7.preloader.show();
                await Api.daletePrescriptions(taskId);
                f7.views.main.router.navigate('/presciptionManager/', { reloadCurrent: true });
              } catch (e) {
                f7.dialog.alert(e);
              } finally {
                f7.preloader.hide();
              }
            },
          },
        ],
      }).open();
      return false;
    },
    createPrescription() {
      this.setNewPrescription();
      this.$f7.views.main.router.navigate('/createPrescription/', { reloadCurrent: true });
    },
    editPrescription(rowSelected) {
      this.setPrescription(rowSelected.row.data);
      this.$f7.views.main.router.navigate('/editPrescription/', { reloadCurrent: true });
    },
    async setActiveIngredientsAvailables(dataArray) {
      const typePrescription = [];
      const activeIngredients = [];
      const products = [];
      for (let i = 0; i < dataArray.length; i += 1) {
        const itemsSubfamily = dataArray[i].items;
        for (let j = 0; j < itemsSubfamily.length; j += 1) {
          typePrescription.push({
            id: itemsSubfamily[j].id,
            subfamily: dataArray[i].id,
          }); // <-- typePrescription

          if (dataArray[i].id === 'PHITOSANITARY') {
            const componentes = itemsSubfamily[j].items;
            for (let k = 0; k < componentes.length; k += 1) {
              activeIngredients.push({
                id: componentes[k].id,
                typePrescription_id: itemsSubfamily[j].id,
              }); // <-- activeIngredients

              const descripciones = componentes[k].items;
              for (let n = 0; n < descripciones.length; n += 1) {
                products.push({
                  id: descripciones[n].id,
                  name: descripciones[n].description,
                  activeIngredients_id: componentes[k].id,
                }); // <-- products
              }
            }
          } else {
            const descripciones = itemsSubfamily[j].items;
            for (let n = 0; n < descripciones.length; n += 1) {
              let family = '';
              for (let m = 0; m < descripciones[n].properties.length; m += 1) {
                if (descripciones[n].properties[m].key === 'family') family = descripciones[n].properties[m].value;
              }
              products.push({
                id: descripciones[n].id,
                name: descripciones[n].description,
                activeIngredients_id: itemsSubfamily[j].id,
                family,
              }); // <-- products
            }
          }
        }
      }
      this.setListTypes(typePrescription);
      this.setlistActiveIngredients(activeIngredients);
      this.setlistProducts(products);
    },
    // UTILITIES
    formattingActiveColumn(value) {
      if (value === 'Yes') {
        return this.$t('Yes');
      }
      return this.$t('No');
    },
    // CREACION BOTON AÑADIR PRESCRIPCION
    onToolbarPreparing(e) {
      if (!this.$helpers.havePermission(this.getPermissions.company_update_master_data)) return;
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxCreatePrescription_button_text'),
          onClick: () => {
            this.createPrescription();
          },
        },
      });
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('PrescriptionManager');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PrescriptionManager.xlsx');
          });
      });
      e.cancel = true;
    },
    translateMethods() {
      for (const method of this.listMethods) {
        this.listMethodsFormated.push({
          id: method.id,
          name: this.$t(method.name),
        });
      }
    },
    ...mapActions('Prescription', ['setPrescription', 'setNewPrescription', 'setListTypes', 'setlistActiveIngredients', 'setlistProducts']),
  },
};
</script>
