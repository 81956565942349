<template>
  <f7-page>
    <DxTreePlanning />
  </f7-page>
</template>
<script>

import DxTreePlanning from '../components/DxTreePlanning.vue';

export default {
  name: 'DxTreePlanning',
  components: {
    DxTreePlanning,
  },
};
</script>
