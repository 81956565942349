<template>
  <div v-if="loaded">
    <div
      class="content-devx content-dx-daily-planning"
    >
      <DxDataGrid
        id="binsTable"
        ref="binsTable"
        :data-source="getMergedBins"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        :selected-row-keys="selectedBins"
        :column-auto-width="true"
        @selection-changed="changeBatchsSelected"
      >
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="false"
          :allow-deleting="false"
          :allow-adding="false"
          mode="row"
        />
        <DxSelection
          mode="multiple"
          :allow-select-all="true"
          show-check-boxes-mode="always"
        />
        <DxSearchPanel
          :visible="true"
          :width="400"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="code"
          name="code"
          alignment="left"
          :caption="$t('DxBins_dxColumn_Code')"
        />
        <DxColumn
          data-field="date"
          name="date"
          alignment="left"
          :caption="$t('DxBins_dxColumn_CreationDate')"
        />
        <DxColumn
          data-field="name"
          name="name"
          alignment="left"
          :caption="$t('DxBins_dxColumn_NameBins')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxSelection,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxBatchesBinsTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxSelection,
  },
  props: {
  },
  data() {
    return {
      selectedKeys: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('greenhouse', ['selectedBins']),
    ...mapGetters('greenhouse', ['getMergedBins']),
  },
  beforeDestroy() {
    this.setLotBins([]);
    this.setSelectedBins([]);
  },
  async created() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      await this.fetchBinsCreateLote();
    } catch (error) {
      this.$f7.dialog.alert(error);
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    changeBatchsSelected(e) {
      this.setSelectedBins(e.selectedRowKeys);
    },
    ...mapActions('greenhouse',
      [
        'setSelectedBins',
        'fetchBinsCreateLote',
        'setLotBins',
      ]),
  },
};
</script>