<template>
  <f7-block class="container">
    <f7-searchbar
      search-container=".search-list"
      search-item="li"
      search-in=".item-title"
      :placeholder="$t('Search_placeholder')"
    />
    <f7-block>
      <f7-list
        class="search-list searchbar-found"
        virtual-list
        :virtual-list-params="vlParams"
      >
        <f7-list
          v-if="vlData.items.length == 0"
          class="nothingfound"
        >
          <f7-list-item
            :title="$t('ManualList_listItem_title')"
          />
        </f7-list>
        <ul>
          <f7-list-item
            v-for="employee in vlData.items"
            :key="employee.code"
            radio
            :title="employee.fullname"
            radio-icon="start"
            name="demo-radio-start"
            @click="changeStatebtnDisable(employee.object)"
          />
        </ul>
      </f7-list>
    </f7-block>
    <f7-block>
      <f7-button
        name="btnContinue"
        class="botonPrimary"
        col
        button
        button-large
        button-raised
        fill
        :disabled="btnDisable"
        @click="saveWorker()"
      >
        {{ $t("Nfc_navbarpresence_btnContinue") }}
      </f7-button>
    </f7-block>
  </f7-block>
</template>

<script>
import { mapActions } from 'vuex';
import Api from '../../services/Api';

export default {
  props: {
    employees: { type: Array, default: () => [] },
    callbackSelected: { type: Function, default: () => {} },
    fullNameFilter: { type: String, default: '' },
    limitFilter: { type: Number, default: 9999 },
    pageFilter: { type: Number, default: 0 },
  },
  data() {
    return {
      vlData: {
        items: [],
      },
      vlParams: {
        items: this.employees,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
      },
      worker: null,
      btnDisable: true,
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getEmployees(
        this.limitFilter,
        this.pageFilter,
        this.fullNameFilter,
      );
      const xhrFormat = JSON.parse(xhr.response).data;
      for (let i = 0; i < xhrFormat.length; i += 1) {
        this.employees.push({
          fullname: `${xhrFormat[i].name} ${xhrFormat[i].surname}`,
          code: xhrFormat[i].code,
          object: xhrFormat[i],
        });
      }
      this.vlData.items = this.employees;
    } catch (error) {
      this.$f7.dialog.alert(error);
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (item.fullname.toLowerCase().includes(lowerQuery)) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    saveWorker() {
      this.setSelectedWorker(this.worker);
      this.access('id', this.worker.id);
      this.changeUpSheet();
      this.$f7router.back();
    },
    changeStatebtnDisable(item) {
      this.btnDisable = false;
      this.worker = item;
    },
    async access(type, code) {
      this.$f7.preloader.show();
      const xhr = await Api.access(type, code);
      const json = JSON.parse(xhr.response);
      this.$f7.preloader.hide();
      this.setEvent(json.event);
    },
    ...mapActions('worker', ['setSelectedWorker', 'setEvent', 'changeUpSheet']),
  },
};
</script>
<style scoped>
.list .item-subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #999;
  mix-blend-mode: normal;
  opacity: 0.8;
}
.searchbar-found {
  height: 338px !important;
  overflow: scroll;
  background: white;
}
.container {
  background: #f4f5f7;
  padding-top: 10px;
}
.searchbar-input-wrap {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
}
.searchbar {
  height: 40px;
}
.searchbar-not-found {
  text-align: left;
}
div.item-content{
  text-align: center !important;
}
</style>
