var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx content-dx-daily-planning main-dx-information-prescription"},[_c('DxForm',{attrs:{"id":"formNewPrecription"}},[_c('DxGroupItem',{attrs:{"col-count":6}},[_c('DxGroupItem',{attrs:{"css-class":"content-combo","col-span":3}},[_c('DxSimpleItem',{attrs:{"name":"name","data-field":_vm.$t('DxInformationPrescription_name_caption'),"editor-options":{
            value: _vm.actualName,
            onValueChanged: _vm.onChangeName,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxInformationPrescription_name_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"active","data-field":_vm.$t('DxInformationPrescription_active_caption'),"editor-type":"dxCheckBox","editor-options":{
            value: _vm.actualActive,
            onValueChanged: _vm.onChangeActive,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"dateofactivation","data-field":_vm.$t('DxInformationPrescription_dateofactivation_caption'),"editor-type":"dxDateBox","editor-options":{
            value: _vm.actualDate,
            onValueChanged: _vm.onChangeDate,
            displayFormat: 'dd/MM/yyyy',
            disabledDates:_vm.disableSundays,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxInformationPrescription_date_required')}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-span":3,"css-class":"content-combo"}},[_c('DxSimpleItem',{attrs:{"name":"typeofPrescription","data-field":_vm.$t('DxInformationPrescription_typeofprescription_caption'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.typePrescription,
            value: _vm.actualTypePrescription,
            displayExpr: 'name',
            valueExpr:'id',
            onItemClick: _vm.onChangeTypePrescription,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"methodofapplication","data-field":_vm.$t('DxInformationPrescription_methodofapplication_caption'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.listMethodsFormated,
            value: _vm.actualMethodOfApplication,
            displayExpr: 'name',
            valueExpr:'id',
            onItemClick: _vm.onChangeMethodOfApplication,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxInformationPrescription_methodofapplication_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"notes","data-field":_vm.$t('DxInformationPrescription_notes_caption'),"editor-type":"dxTextArea","editor-options":{
            value: _vm.actualNotes,
            onValueChanged: _vm.onChangeNotes,
            maxLength: 110,
          }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }