export default {
  namespaced: true,
  state: {
    locationsTree: [],
    selectedFarmList: [],
    areaList: [],
    currentLevel: 0,
    currentLocation: null,
    currentLocationName: null,
    firstDateSelected: '',
    secondDateSelected: '',
    rangeOfDays: 14,
  },

  getters: {
    locationsTree: (state) => state.locationsTree,
    selectedFarmList: (state) => state.selectedFarmList,
    areaList: (state) => state.areaList,
    currentLevel: (state) => state.currentLevel,
    currentLocation: (state) => state.currentLocation,
    currentLocationName: (state) => state.currentLocationName,
    firstDateSelected: (state) => state.firstDateSelected,
    secondDateSelected: (state) => state.secondDateSelected,
  },
  actions: {
    setLocationsTree(context, value) {
      context.commit('setLocationsTree', value);
    },
    setSelectedFarmList(context, value) {
      context.commit('setSelectedFarmList', value);
    },
    setAreaList(context, value) {
      context.commit('setAreaList', value);
    },
    setCurrentLevel(context, value) {
      context.commit('setCurrentLevel', value);
    },
    setCurrentLocation(context, value) {
      context.commit('setCurrentLocation', value);
    },
    setCurrentLocationName(context, value) {
      context.commit('setCurrentLocationName', value);
    },
    setFirstDateSelected(context, value) {
      context.commit('setFirstDateSelected', value);
    },
    setSecondDateSelected(context, value) {
      context.commit('setSecondDateSelected', value);
    },
    setRangeOfDays({ commit }, value) {
      commit('setRangeOfDays', value);
    },
  },
  mutations: {
    setLocationsTree(state, value) {
      state.locationsTree = value;
    },
    setSelectedFarmList(state, value) {
      state.selectedFarmList = value;
    },
    setAreaList(state, value) {
      state.areaList = value;
    },
    setCurrentLevel(state, value) {
      state.currentLevel = value;
    },
    setCurrentLocation(state, value) {
      state.currentLocation = value;
    },
    setCurrentLocationName(state, value) {
      state.currentLocationName = value;
    },
    setFirstDateSelected(state, value) {
      state.firstDateSelected = value;
    },
    setSecondDateSelected(state, value) {
      state.secondDateSelected = value;
    },
    setRangeOfDays(state, value) {
      state.rangeOfDays = value;
    },
  },
};
