<template>
  <f7-page>
    <navbar :text="$t('ReportingEmployeeEffectiveness_navbar_title')" />
    <DxEmployeeEfectiveness />
  </f7-page>
</template>

<script>
import DxEmployeeEfectiveness from '../../components/reporting/DxEmployeeEfectiveness.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ReportingEmployeeEfectiveness',
  components: {
    DxEmployeeEfectiveness,
    navbar,
  },
};
</script>
