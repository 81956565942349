export default {
  namespaced: true,
  state: {
    actualTools: {},
  },

  getters: {
    actualTools: (state) => state.actualTools,
  },
  actions: {
    setActualTools(context, value) {
      context.commit('setActualTools', value);
    },
    setCode(context, value) {
      context.commit('setCode', value);
    },
    setDescription(context, value) {
      context.commit('setDescription', value);
    },
    setId(context, value) {
      context.commit('setId', value);
    },
    setItemId(context, value) {
      context.commit('setItemId', value);
    },
    setFamily(context, value) {
      context.commit('setFamily', value);
    },
    setSubfamily(context, value) {
      context.commit('setSubfamily', value);
    },
    setStatus(context, value) {
      context.commit('setStatus', value);
    },
    setFuel(context, value) {
      context.commit('setFuel', value);
    },
  },
  mutations: {
    setActualTools(state, value) {
      state.actualTools = value;
    },
    setCode(state, value) {
      state.actualTools.code = value;
    },
    setDescription(state, value) {
      state.actualTools.description = value;
    },
    setId(state, value) {
      state.actualTools.id = value;
    },
    setItemId(state, value) {
      state.actualTools.itemId = value;
    },
    setFamily(state, value) {
      state.actualTools.family = value;
    },
    setSubfamily(state, value) {
      state.actualTools.subfamily = value;
    },
    setStatus(state, value) {
      state.actualTools.status = value;
    },
    setFuel(state, value) {
      state.fuel = value;
    },
  },
};
