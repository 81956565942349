<template>
  <f7-page>
    <navbar :text="$t('ReportingMachinery_navbar_title')" />
    <DxMachinery />
  </f7-page>
</template>

<script>
import DxMachinery from '../../components/reporting/DxMachinery.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'Machinery',
  components: {
    DxMachinery,
    navbar,
  },
};
</script>
