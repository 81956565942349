<template>
  <f7-block v-if="loaded">
    <div class="loadingFalse">
      <div class="preloader" />
    </div>
  </f7-block>
  <f7-block v-else>
    <DxDataGrid
      id="gridContainer"
      :data-source="effortFormated"
      :show-borders="true"
      :remote-operations="true"
      :allow-column-reordering="true"
      :column-auto-width="true"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        :storage-key="`${keyName}`"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxGroupPanel :visible="false" />
      <DxGrouping
        :auto-expand-all="false"
      />
      <DxExport
        :enabled="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="locationLevel2"
        :group-index="0"
        name="location"
        :caption="$t('DxDailyPlanning_location_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :caption="$t('DxDailyPlanning_task_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="taskCode"
        name="taskCode"
        :caption="$t('PlanningComponent_taskCode_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="PlannWorkers"
        name="workers"
        :caption="$t('DxPlannReal_plannWorkers_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="Workers"
        name="workers"
        :caption="$t('DxPlannReal_workers_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="TotalWorkers"
        name="workers"
        :caption="$t('DxPlannReal_Totalworkers_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="PlannMachinary"
        name="PlannMachinary"
        :caption="$t('DxWorkOrders_PlannMachinary_caption')"
        :allow-header-filtering="true"
        alignment="center"
      />
      <DxColumn
        data-field="machinary"
        name="machinary"
        :caption="$t('DxWorkOrders_WorkedMachinary_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="totalMachinary"
        name="totalMachinary"
        :caption="$t('DxWorkOrders_totalMachinary_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
    </DxDataGrid>
  </f7-block>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxGroupPanel,
  DxExport,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxGroupPanel,
    DxSearchPanel,
    DxExport,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxGrouping,
  },
  props: {
    keyName: { type: String, default: '' },
  },
  data() {
    return {
      locationsFake: ['5f2d2597a3b29a5c2b858204', '5f2d2597a3b29a5c2b858205', '5f2d2597a3b29a5c2b858206', '5f2d2597a3b29a5c2b858207'],
      loaded: true,
      effort: [],
      effortFormated: [],
      locationsAvailables: [],
      event: 'updateEffort',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapState('Reporting', ['selectedFarmList', 'locationsTree']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  updated() {
    this.loaded = false;
  },
  mounted() {
    EventBus.$on(this.event, this.updateLocations);
  },
  beforeDestroy() {
    EventBus.$off(this.event, this.updateLocations);
  },
  methods: {
    // ---- OBTENCIÓN Y FORMATEO DE LOCATIONS ----
    async getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
    // ---- OBTENCIóN Y FORMATEO DE ACTIVITIES Y TASKS ----
    async getEffort() {
      const xhr = await Api.getEffortByLocations(this.selectedFarmList, moment.utc().startOf('year').format('YYYY-MM-DD'), moment.utc().add(1, 'd').format('YYYY-MM-DD'));
      return JSON.parse(xhr.response);
    },
    async formattingEffort() {
      this.effortFormated.length = 0;
      this.$f7.preloader.show();
      try {
        const day = moment(new Date()).format('YYYY-MM-DD');
        const xhr = await Api.getDailyTasks(true, [], [], day, day, true);
        const planning = JSON.parse(xhr.response);
        for (let i = 0; i < planning.detail.length; i += 1) {
          for (let j = 0; j < planning.detail[i].tasks.length; j += 1) {
            const items = this.getItems(planning.detail[i].tasks[j].items_drivers);
            if (planning.detail[i].status !== 'OPEN' && planning.detail[i].tasks[j].planner != null) {
              this.locationsFake = [...planning.detail[i].tasks[j].location];
              this.effortFormated.push({
                ID: planning.detail[i].tasks[j].id,
                Date: moment(planning.detail[i].tasks[j].date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                Task: planning.detail[i].tasks[j].task,
                taskCode: planning.detail[i].tasks[j].task_code,
                TaskStatus: planning.detail[i].tasks[j].status,
                PlannWorkers: `${this.getTotalWorkersPlanner(planning.detail[i].tasks[j].planner.workers)}`,
                Workers: `${this.getWorkersIn(planning.detail[i].tasks[j].workers)}`,
                TotalWorkers: `${this.getTotalWorkers(planning.detail[i].tasks[j].workers)}`,
                PlannMachinary: `${this.getTotalMachinaryPlanner(planning.detail[i].tasks[j].planner.items)}`,
                machinary: `${this.getMachinary(planning.detail[i].tasks[j])}`,
                totalMachinary: items.length,
                locationLevel2: this.getLocationLevel2(),
              });
            }
          }
        }
      } catch (e) {
        console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    getItems(itemsDriver) {
      const items = [];
      for (const itemDriver of itemsDriver) {
        items.push(itemDriver.item);
      }
      return items;
    },
    getWorkersIn(workers) {
      let x = 0;
      for (let i = 0; i < workers.length; i += 1) {
        if (typeof workers[i].end_date !== 'undefined') {
          x += 1;
        }
      }
      return x;
    },
    getMachinary(task) {
      let x = 0;
      for (let i = 0; i < task.workers.length; i += 1) {
        if (typeof task.workers[i].end_date !== 'undefined') {
          x = task.items_drivers.length;
          break;
        }
      }
      return x;
    },
    getTotalWorkers(workers) {
      return workers.length;
    },
    getTotalWorkersPlanner(workersPlanner) {
      let element = 0;
      for (let i = 0; i < workersPlanner.length; i += 1) {
        element += workersPlanner[i].count;
      }
      return element;
    },
    getTotalMachinaryPlanner(workersPlanner) {
      let element = 0;
      for (let i = 0; i < workersPlanner.length; i += 1) {
        element += workersPlanner[i].count;
      }
      return element;
    },
    getTotalHoursLocations() {
      let totalHours = 0;
      for (const item of this.effort) {
        totalHours += item.parameters.hours;
      }
      return totalHours;
    },
    getLocationNameById(id) {
      for (const location of this.locationsAvailables) {
        if (location.bd_id === id) {
          return location.name;
        }
      }
      return '';
    },
    getAreaLocationById(id) {
      for (const location of this.locationsAvailables) {
        if (location.bd_id === id) {
          return location.area;
        }
      }
      return 0;
    },
    // GET AND SET LOCATIONS
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    getLocationLevel2() {
      this.currentLevel2Name = '';
      this.level2Data = [];
      this.areaLevel2 = 0;
      for (let i = 0; i < this.locationsTree.length; i += 1) {
        if (this.locationsFake.length === 0) {
          // Si no quedan ids en el array de parcelas de esta tarea
          break;
        }
        this.recursiveGetChildrens(this.locationsTree[i]);
      }
      return this.level2Data;
    },
    recursiveGetChildrens(items) {
      if (items.level === 2) {
        // Solo comprobamos los elementos con nivel === 2, sus hijos los comprobamos dentro
        this.currentLevel2Name = items.name;
      }
      if (items.children.length === 0) {
        // Si el nivel no tiene hijos, comprobamos si su id está en el array
        const check = this.locationsFake.indexOf(items.bd_id);
        if (check !== -1) {
          const alreadyInserted = this.level2Data.indexOf(this.currentLevel2Name);
          if (alreadyInserted === -1) {
            // Está en el array de localizaciones y aun no lo hemos guardado
            this.level2Data.push(this.currentLevel2Name);
          }
          this.areaLevel2 += items.area;
          this.locationsFake.splice(check, 1); // Elimino el id del array
        }
      } else if (this.locationsFake.length !== 0) {
        // El nivel tiene hijos
        for (let j = 0; j < items.children.length; j += 1) {
          this.recursiveGetChildrens(items.children[j]);
        }
      }
    },
    formattingDates(dates) {
      const formatedDates = [];
      for (const date of dates) {
        formatedDates.push(moment(date).format('DD-MM-YYYY'));
      }
      return formatedDates;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('CurrentEffort');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'CurrentEffort.xlsx',
          );
        });
      });
      e.cancel = true;
    },
    async updateLocations() {
      this.$f7.preloader.show();
      this.loaded = true;
      try {
        await this.getLocationsAvailables();
        this.effort = await this.getEffort();
        this.formattingEffort();
      } catch (error) {
        this.$f7.dialog.alert(error);
      } finally {
        this.$f7.preloader.hide();
        this.loaded = false;
      }
    },
  },
};
</script>
<style scoped>
</style>
