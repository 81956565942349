<template>
  <div class="container">
    <f7-searchbar
      search-container="#searcherVirtual-list"
      search-item="li"
      search-in=".item-title"
      :disable-button="!$theme.aurora"
    />
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found" />
    </f7-list>
    <f7-block
      v-if="items === 0"
      name="info"
      class="text-align-center"
    >
      <f7-icon icon="icon-scan" />
      <div class="content-block text-align-center">
        <f7-block-title medium>
          {{ $t("Home_info_txtTitle") }}
        </f7-block-title>
      </div>
    </f7-block>
    <f7-list
      v-else
      id="searcherVirtual-list"
      class="searchbar-found"
      media-list
      virtual-list
      :virtual-list-params="{
        items,
        fullName,
        searchAll,
        renderExternal,
        height: $theme.ios ? 63 : ($theme.md ? 73 : 46)
      }"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          :key="index"
          media-item
          link="#"
          :style="`top: ${vlData.topPosition}px`"
          no-chevron
        >
          <a
            class="item-link item-content"
            @click="buttonPressed"
          >
            <div class="item-media">
              <div class="event-type-icon">
                <img
                  class="float-left icon-status"
                  width="auto"
                  height="20"
                  :src="showImage(item.event)"
                >
                <img
                  class="avatar"
                  width="58"
                  height="58"
                  :src="photoSrc(item.event)"
                  @error="setImgError"
                >
              </div>
            </div>
            <div class="item-inner infoUser">
              <div class="item-title-row">
                <div class="row">
                  <div class="col-70">
                    <output class="item-title-bold">
                      {{ item.fullname }} {{ item.type }}
                    </output>
                  </div>
                  <div class="col-30">
                    <img
                      v-if="item.event.type==='IN'"
                      class="ic-ok"
                    >
                    <img
                      v-else
                      class="ic-out"
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="item.driver == 'true'"
                class="item-subtitle"
                name="conductor"
              >
                {{ $t("Home_conductor") }}
              </div>
            </div>
          </a>
        </f7-list-item>
      </ul>
    </f7-list>
  </div>
</template>

<script>
import moment from 'moment';
import ImageEnter from '../../static/img/ic_enter_arrow.svg';
import ImageExit from '../../static/img/ic_exit_arrow.svg';
import ImageUnknown from '../../static/img/Unknown.svg';
import imgEnterUser from '../../static/img/ic_user_enter.svg';
import imgExitUser from '../../static/img/ic_user_exit.svg';
import UnknownArrow from '../../static/img/ic_unknown_arrow.png';
import ImageEdit from '../../static/img/ic_edit-btn-round.svg';
import ImageUser from '../../static/img/ic_user.svg';
import IconScan from '../../static/img/icon-scan.svg';
import IconQr from '../../static/img/ic_qr.svg';
import EventBus from '../../js/event-bus';

export default {
  name: 'Searcher',
  data() {
    const items = [];
    return {
      images: {
        edit: ImageEdit,
        user: ImageUser,
        IconScan,
        IconQr,
      },
      vlData: {
        items: [],
      },
      items,
      imgError: false,
      fullName: '',
    };
  },
  beforeMount() {
    EventBus.$on('load-workers', this.updateItems);
  },
  methods: {

    formattedDate() {
      return `${this.dateDay} - ${this.startDate}`;
    },
    formattedHour(item) {
      const end = (item.end_date) ? item.end_date : new Date();
      const ms = moment(end).diff(moment(item.init_date));
      const d = moment.duration(ms);
      return `${d.hours()}:${d.minutes()}`;
    },
    dateDay(item) {
      const aux = String(item.date).substring(0, 16);
      return moment(aux).format('DD/MM');
    },

    showImage(item) {
      switch (item.type) {
        case 'IN': {
          return ImageEnter;
        }
        case 'OUT': {
          return ImageExit;
        }
        default: {
          return UnknownArrow;
        }
      }
    },

    photoSrc(item) {
      if (item.type === 'IN') {
        return imgEnterUser;
      }
      if (item.type === 'OUT') {
        return imgExitUser;
      }
      return ImageUnknown;
    },
    setImgError() {
      this.imgError = true;
    },
    searchAll(query, items) {
      const found = [];
      for (let i = 0; i < items.length; i += 1) {
        if (
          items[i].fullname.toLowerCase().indexOf(query.toLowerCase()) >= 0
          || query.trim() === ''
        ) found.push(i);
      }
      return found; // return array with mathced indexes
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    buttonPressed() {
      this.$emit('click');
    },
    updateItems(items) {
      this.items = items;
      this.vlData.items = items;
      this.$f7.virtualList.get().replaceAllItems(items);
    },
  },
};
</script>

<style scoped>
.searchbar-found{
  height: 100%;
}
.container {
  background: #F4F5F7;
  padding-top: 10px;
}
.content-location {
  display: block;
  border: 1px solid #e5eef7;
}

.change-location-btn {
  padding: 10px 5px 6px;
}

.item-title-block {
  padding: 15px 20px;
}

.content-location {
  display: block;
  border: 1px solid #e5eef7;
}

.header-space {
  position: relative;
  padding: 20px 35px;
}
.number-icon {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: #556080;
  padding: 0 10px 0 10px;
}
.list .item-title {
  padding: 10px 0 5px;
  margin-right: 10px;
  font-size: 18px;
  letter-spacing: -0.04em;
}
.item-title-row {
  align-items: center;
}
.list .item-subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #9b9b9b;
  padding: 2px 0px 0px;
}
.hour {
  font-weight: 500;
  font-size: 26px;
  letter-spacing: -0.04em;
  line-height: 1.2;
  color: #556080;
  margin: 2px;
}

.ic-ok {
  content: url("../../static/img/ic_in_v2.svg");
  width: 100%;
  height: 100%;
}
.ic-out {
  content: url("../../static/img/ic_out_v2.svg");
  width: 100%;
  height: 100%;
}

.date {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #c4c4c4;
}
.icon-status {
  padding: 22px 5px 0;
}
.item-link.item-content {
  padding-left: 0;
}
.smart-border {
  left: 1.67%;
  right: 1.67%;
  top: 14.56%;
  bottom: 81.22%;

  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  margin-left: 9px;
  width: 97%;
}
div.item-inner.infoUser::after{
  display: none;
}
.backgroundPage{
  background-color: white;
}
.virtual-list ul{
  height: auto !important;
}
.block-title-medium {
      color: #9B9B9B !important;
}
.searchbar-input-wrap{
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
}
.searchbar{
  height: 40px;
}
.searchbar-not-found{
  text-align: center;
}
</style>
