var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxForm',{attrs:{"id":"form","col-count":1}},_vm._l((_vm.locationsFormated),function(item,index){return _c('DxSimpleItem',{key:index,attrs:{"data-field":item.name[0],"editor-options":{
      items: _vm.nameSupervisors,
      searchEnabled: true,
      searchTimeout: _vm.searchTimeoutOption,
      minSearchLength: _vm.minSearchLengthOption,
      showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      onItemClick: _vm.onSupervisorChange,
      value: _vm.supervisorSelected(item), // TODO: Esto habrá que cambiarlo cuando los supervisores empiecen a venir con localizaciones posibles
      readOnly: _vm.readOnly,
    },"editor-type":"dxSelectBox","caption":_vm.$t('SelectSupervisor_supervisor_caption')}},[_c('DxRequiredRule',{attrs:{"message":"At least one supervisor is needed"}})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }