<template>
  <f7-page>
    <navbar :text="$t('WorkOrder_title')" />
    <DxWorkOrders
      :exclude-activities="excludeActivities"
      :key-name="'workOrders'"
    />
  </f7-page>
</template>

<script>
import DxWorkOrders from '../components/workOrder/DxWorkOrders.vue';
import navbar from '../components/NavBar.vue';

export default {
  components: {
    DxWorkOrders,
    navbar,
  },
  data() {
    return {
      excludeActivities: ['08'],
    };
  },
};
</script>

<style>

</style>
