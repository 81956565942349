import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Modules
import authentication from './modules/authentication';
import worker from './modules/worker';
import TaskManager from './modules/TaskManager';
import PlanningManager from './modules/PlanningManager';
import EmployeeManager from './modules/EmployeeManager';
import TaskBadget from './modules/TaskBadget';
import AccessControl from './modules/AccessControl';
import Reporting from './modules/reporting';
import Product from './modules/Product';
import Prescription from './modules/Prescription';
import Machinery from './modules/Machinery';
import weather from './modules/weather';
import costManager from './modules/CostManager';
import CalendarInitEndDate from './modules/CalendarInitEndDate';
import Tool from './modules/Tool';
import greenhouse from './modules/greenhouse';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    authentication,
    worker,
    AccessControl,
    EmployeeManager,
    TaskManager,
    PlanningManager,
    TaskBadget,
    Reporting,
    Product,
    Prescription,
    Machinery,
    weather,
    costManager,
    CalendarInitEndDate,
    Tool,
    greenhouse,
  },
  plugins: [new VuexPersistence({
    storage: window.localStorage,
  }).plugin],
});

store.dispatch('authentication/initialize');

export default store;
