<template>
  <DxForm
    id="form"
    :col-count="1"
  >
    <DxSimpleItem
      :editor-options="{
        items: nameSupervisors,
        searchEnabled: true,
        searchTimeout: searchTimeoutOption,
        minSearchLength: minSearchLengthOption,
        showDataBeforeSearch: showDataBeforeSearchOption,
        onItemClick: onSupervisorChange,
        value: nameSupervisors[0], // TODO: Esto habrá que cambiarlo cuando los supervisores empiecen a venir con localizaciones posibles
      }"
      editor-type="dxSelectBox"
      :caption="$t('SelectSupervisor_supervisor_caption')"
    />
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions } from 'vuex';

export default {
  components: {
    DxForm,
    DxSimpleItem,
  },
  props: {
    locations: { type: Array, default: () => [] },
    locationsTree: { type: Array, default: () => [] },
    supervisorsAvailables: { type: Array, default: () => [] },
    supervisorsLocation: { type: Array, default: () => [] },
  },
  data() {
    return {
      locationsFormated: [],
      finalLevelLocation: [],
      supervisorsLocationAux: [],
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      nameSupervisors: [],
      create: false,
      currentRoot: [],
      nameRoot: '',

    };
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    for (let i = 0; i < this.locations.length; i += 1) {
      this.locationsFormated.push({ bd_id: this.locations[i], name: this.getFinalLevelLocation(this.locations[i]) });
    }
    for (let i = 0; i < this.supervisorsAvailables.length; i += 1) {
      const farms = this.supervisorsAvailables[i].farm;
      for (const item of farms) {
        for (let j = 0; j < this.currentRoot.length; j += 1) {
          if (item === this.currentRoot[j]) {
            if (this.nameSupervisors.indexOf(`${this.supervisorsAvailables[i].name} ${this.supervisorsAvailables[i].surname}`) < 0) {
              this.nameSupervisors.push(`${this.supervisorsAvailables[i].name} ${this.supervisorsAvailables[i].surname}`);
            }
          }
        }
      }
    }
    this.setSupervisorsLocation(this.getCodeSupervisor(this.supervisorsAvailables, this.nameSupervisors[0]));
  },
  methods: {
    getFinalLevelLocation(locations) {
      this.finalLevelLocation = [];
      for (const item of this.locationsTree) {
        this.nameRoot = item.name;
        this.viewChildren(item, locations);
      }
      return this.finalLevelLocation;
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (typeof locations === 'string') {
          if (locations === item.bd_id) {
            if (this.currentRoot.indexOf(this.nameRoot) < 0) {
              this.currentRoot.push(this.nameRoot);
            }
            this.finalLevelLocation.push(item.name);
          }
        } else {
          for (const location of locations) {
            if (location === item.bd_id) {
              if (this.currentRoot.indexOf(this.nameRoot) < 0) {
                this.currentRoot.push(this.nameRoot);
              }
              this.finalLevelLocation.push(item.name);
            }
          }
        }
      }
    },
    onSupervisorChange(e) {
      const idSupervisorUpdated = this.getCodeSupervisor(this.supervisorsAvailables, e.itemData);
      this.setSupervisorsLocation(idSupervisorUpdated);
    },
    getCodeSupervisor(items, fullname) {
      for (const item of items) {
        if (`${item.name} ${item.surname}` === fullname) {
          return item.id;
        }
      }
      return '';
    },
    ...mapActions('PlanningManager', ['setSupervisorsLocation']),
  },
};
</script>
<style>
.status-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 8px;
}
.middle {
  vertical-align: middle;
}
</style>
