<template>
  <f7-page>
    <navbar :text="$t('ActiveIngredients_navbar_title')" />
    <DxActiveIngredientsVademecum />
  </f7-page>
</template>

<script>

import DxActiveIngredientsVademecum from '../../components/prescriptions/DxActiveIngredientsVademecum.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ActiveIngredients',
  components: {
    DxActiveIngredientsVademecum,
    navbar,
  },
};
</script>

<style>

</style>
