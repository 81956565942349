<template>
  <f7-page
    name="register"
    login-screen
  >
    <div class="text-align-center">
      <img
        class="img_logo"
        :src="APP_LOGO"
        :alt="APPLICATION_NAME"
      >
    </div>
    <div
      name="registerText"
      class="label-text"
    >
      {{ $t("Register_registerText") }}
    </div>

    <f7-row>
      <f7-col>
        <f7-list form>
          <f7-list-input
            :name="$t('Register_inpName_name')"
            outline
            item-label
            type="text"
            :placeholder="$t('Register_inpName_placeholder')"
            clear-button
            max="255"
            required
            @input="name = $event.target.value"
          />
          <f7-list-input
            :name="$t('Register_inpSurnames_name')"
            outline
            item-label
            type="text"
            :placeholder="$t('Register_inpSurnames_placeholder')"
            clear-button
            max="255"
            required
            @input="surnames = $event.target.value"
          />
          <f7-list-input
            :name="$t('Register_inpEmail_name')"
            outline
            item-label
            type="email"
            :placeholder="$t('Register_inpEmail_placeholder')"
            clear-button
            max="255"
            required
            @input="email = $event.target.value"
          />
          <f7-list-input
            :name="$t('Register_inpPassword_name')"
            outline
            item-label
            type="password"
            :placeholder="$t('Register_inpPassword_placeholder')"
            clear-button
            max="255"
            required
            @input="password = $event.target.value"
          />
          <f7-list-input
            :name="$t('Register_inpPasswordVerification_name')"
            outline
            item-label
            type="password"
            :placeholder="$t('Register_inpPasswordVerification_placeholder')"
            clear-button
            max="255"
            required
            @input="passwordVerification = $event.target.value"
          />
          <f7-list-input
            :name="$t('Register_inpUsername_name')"
            outline
            item-label
            type="text"
            :placeholder="$t('Register_inpUsername_placeholder')"
            clear-button
            max="255"
            required
            @input="username = $event.target.value"
          />
        </f7-list>
      </f7-col>
      <f7-col>
        <f7-list form>
          <f7-list-input
            :name="$t('Register_inpName_name')"
            outline
            item-label
            type="text"
            :placeholder="$t('Register_inpName_placeholder')"
            clear-button
            max="255"
            required
            @input="name = $event.target.value"
          />
          <f7-list-input
            :name="$t('Register_inpSurnames_name')"
            outline
            item-label
            type="text"
            :placeholder="$t('Register_inpSurnames_placeholder')"
            clear-button
            max="255"
            required
            @input="surnames = $event.target.value"
          />
          <f7-list-input
            :name="$t('Register_inpEmail_name')"
            outline
            item-label
            type="email"
            :placeholder="$t('Register_inpEmail_placeholder')"
            clear-button
            max="255"
            required
            @input="email = $event.target.value"
          />
          <f7-list-input
            :name="$t('Register_inpPassword_name')"
            outline
            item-label
            type="password"
            :placeholder="$t('Register_inpPassword_placeholder')"
            clear-button
            max="255"
            required
            @input="password = $event.target.value"
          />
          <f7-list-input
            :name="$t('Register_inpPasswordVerification_name')"
            outline
            item-label
            type="password"
            :placeholder="$t('Register_inpPasswordVerification_placeholder')"
            clear-button
            max="255"
            required
            @input="passwordVerification = $event.target.value"
          />
          <f7-list-input
            :name="$t('Register_inpUsername_name')"
            outline
            item-label
            type="text"
            :placeholder="$t('Register_inpUsername_placeholder')"
            clear-button
            max="255"
            required
            @input="username = $event.target.value"
          />
        </f7-list>
      </f7-col>
    </f7-row>

    <f7-list>
      <f7-button
        name="btnEnter"
        col
        button
        button-large
        button-raised
        fill
        :disabled="btnDisable"
        class="botonPrimary"
        @click="signIn"
      >
        {{ $t("Register_btnEnter") }}
      </f7-button>
    </f7-list>
  </f7-page>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { APP_LOGO, APPLICATION_NAME } from '../../js/constants';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      APP_LOGO,
      APPLICATION_NAME,
      // btnForgotPassword: { text: "texto fake", urlDestiny: "#" }
      btnForgotPassword: {
        text: this.$t('Login_forgotPass_text'),
        urlDestiny: '#',
      },
      // wantToRemember
    };
  },
  computed: {
    btnDisable() {
      return this.username && !this.wantToRemember && !this.password;
    },
    appName() {
      return this.APPLICATION_NAME;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapState('authentication', ['isLogged', 'wantToRemember']),
  },

  methods: {
    signIn() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;
      app.dialog.alert(
        `Username: ${self.username}<br>Password: ${self.password}`,
        () => {
          router.back();
        },
      );
    },
    signUp() {
      const self = this;
      const router = self.$f7router;
      router.navigate('/register/', { reloadCurrent: true });
    },
    quit() {
      const self = this;
      const router = self.$f7router;
      router.back();
    },
    ...mapActions('authentication', ['login']),
    ...mapMutations('authentication', ['setWantToRemember']),
  },
};
</script>

<style lang="scss" scoped>
.img_logo {
  width: 450px;
  height: auto;
}
div[name="registerText"] {
  text-align: center;
  font-weight: 300;
  font-size: 28px;
  line-height: 33px;
  color: #003D3E;
  font-family: Roboto;
}
div[name="btnSignUp"] {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #4a4a4a;
  mix-blend-mode: normal;
  opacity: 0.7;
  cursor: pointer;
}

input {
  background: #f7f8fa;
  border: 1px solid #d4dce1;
  border-radius: 8px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
}

div[name="btnSignIn"] {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #4a4a4a;
  mix-blend-mode: normal;
  opacity: 0.7;
}
</style>
