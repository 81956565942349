export default {
  namespaced: true,
  state: {
    prescription: {
      active: false,
      name: '',
      type: '',
      method: '',
      disease: '',
      dateActivation: '',
      notes: '',
      products: [],
    },
    listTypes: [],
    listMethods: [{
      id: '1',
      name: 'ORCHARD',
    },
    {
      id: '2',
      name: 'NURSERY',
    }],
    listActiveIngredients: [],
    listDosisUT: ['Cc/Hl', 'Gr/Hl', 'Kg/Ha', 'L/Ha'], // IMPORTANTE: No cambiar el orden!!!
    listFamily: [],
    listSubfamily: [],
    listProducts: [],
    listComponents: [],
    listSolutionUT: ['L', 'Hl', 'L/Ha', 'Hl/Ha'],
    headersVademecum: ['activeSustance', 'active_substance_id', 'approval_date', 'approval_expiration', 'authorisation_in_progress', 'authorised', 'category', 'organic_approved', 'remark', 'status', 'substitution_candidate', 'countries'],
    typePrescription: [{
      id: '1',
      name: 'INSECTICIDE',
    },
    {
      id: '2',
      name: 'FUNGICIDE',
    },
    {
      id: '3',
      name: 'HERBICIDE',
    },
    {
      id: '4',
      name: 'NEMATICIDE',
    },
    {
      id: '5',
      name: 'FERTI-IRRGATION',
    },
    {
      id: '6',
      name: 'FOLIAR FERTILIZER',
    },
    {
      id: '7',
      name: 'GRANULAR FERTILIZER',
    },
    {
      id: '8',
      name: 'OTHER',
    },
    {
      id: '9',
      name: 'SOLUBLE FERTILIZER',
    },
    {
      id: '10',
      name: 'AMENDMENT',
    },
    {
      id: '11',
      name: 'BIO STIMULANT',
    },
    {
      id: '12',
      name: 'ADJUVANT',
    },
    {
      id: '13',
      name: 'BIO CONTROL',
    }],
  },

  getters: {
    prescription: (state) => state.prescription,
    listDosisUT: (state) => state.listDosisUT,
    listSolutionUT: (state) => state.listSolutionUT,
    listFamily: (state) => state.listFamily,
    listSubfamily: (state) => state.listSubfamily,
    listProducts: (state) => state.listProducts,
    listComponents: (state) => state.listComponents,
    activePrescription: (state) => state.prescription.active,
    headersVademecum: (state) => state.headersVademecum,
    typePrescription: (state) => state.typePrescription,
    listTypes: (state) => state.listTypes,
  },
  actions: {
    setPrescription(context, value) {
      context.commit('setPrescription', value);
    },
    setNewPrescription(context) {
      context.commit('setNewPrescription');
    },
    setActivePrescription(context, value) {
      context.commit('setActivePrescription', value);
    },
    setNamePrescription(context, value) {
      context.commit('setNamePrescription', value);
    },
    setDateActivationPrescription(context, value) {
      context.commit('setDateActivationPrescription', value);
    },
    setTypePrescription(context, value) {
      context.commit('setTypePrescription', value);
    },
    setMethodPrescription(context, value) {
      context.commit('setMethodPrescription', value);
    },
    setNotesPrescription(context, value) {
      context.commit('setNotesPrescription', value);
    },
    setProducts(context, value) {
      context.commit('setProducts', value);
    },
    pushProducts(context, value) {
      context.commit('pushProducts', value);
    },
    setListTypes(context, value) {
      context.commit('setListTypes', value);
    },
    setListMethods(context, value) {
      context.commit('setListMethods', value);
    },
    setlistActiveIngredients(context, value) {
      context.commit('setlistActiveIngredients', value);
    },
    setlistDosisUT(context, value) {
      context.commit('setlistDosisUT', value);
    },
    setlistSolutionUT(context, value) {
      context.commit('setlistSolutionUT', value);
    },
    setlistFamily(context, value) {
      context.commit('setlistFamily', value);
    },
    setlistSubfamily(context, value) {
      context.commit('setlistSubfamily', value);
    },
    setlistProducts(context, value) {
      context.commit('setlistProducts', value);
    },
    setlistComponents(context, value) {
      context.commit('setlistComponents', value);
    },
    setHeadersVademecum(context, value) {
      context.commit('setHeadersVademecum', value);
    },
  },
  mutations: {
    setPrescription(state, value) {
      state.prescription = value;
    },
    setNewPrescription(state) {
      state.prescription = {
        active: 'Yes',
        name: '',
        type: '',
        method: '',
        disease: '',
        dateActivation: '',
        notes: '',
        products: [],
      };
    },
    setActivePrescription(state, value) {
      state.prescription.active = value;
    },
    setNamePrescription(state, value) {
      state.prescription.name = value;
    },
    setDateActivationPrescription(state, value) {
      state.prescription.dateActivation = value;
    },
    setTypePrescription(state, value) {
      state.prescription.type = value;
    },
    setMethodPrescription(state, value) {
      state.prescription.method = value;
    },
    setNotesPrescription(state, value) {
      state.prescription.notes = value;
    },
    setProducts(state, value) {
      state.prescription.products = value;
    },
    pushProducts(state, value) {
      state.prescription.products.push(value);
    },
    setListTypes(state, value) {
      state.listTypes = value;
    },
    setListMethods(state, value) {
      state.listMethods = value;
    },
    setlistActiveIngredients(state, value) {
      state.listActiveIngredients = value;
    },
    setlistDosisUT(state, value) {
      state.listDosisUT = value;
    },
    setlistSolutionUT(state, value) {
      state.listSolutionUT = value;
    },
    setlistFamily(state, value) {
      state.listFamily = value;
    },
    setlistSubfamily(state, value) {
      state.listSubfamily = value;
    },
    setlistProducts(state, value) {
      state.listProducts = value;
    },
    setlistComponents(state, value) {
      state.listComponents = value;
    },
    setHeadersVademecum(state, value) {
      state.headersVademecum = value;
    },
  },
};
