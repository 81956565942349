<template>
  <div>
    <DxDataGrid
      id="binsContentGrid"
      :data-source="binsContent"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      @row-inserted="onInsertedRow"
      @row-updated="onRowUpdated"
      @row-removed="onRowUpdated"
      @init-new-row="onInitNewRow"
      @editing-start="onEditingStart"
    >
      <DxEditing
        :allow-updating="canUpdate"
        :allow-deleting="canDelete"
        :allow-adding="canAdd"
        :select-text-on-edit-start="selectTextOnEditStart"
        :start-edit-action="startEditAction"
        mode="form"
      />
      <DxPaging :page-size="18" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        v-if="isCodeVisible"
        data-field="code"
        alignment="left"
        :caption="$t('DxBinsContentGrid_code')"
        :allow-editing="false"
      />
      <template v-if="reclassifying">
        <DxColumn
          v-if="isParentCodeVisible"
          data-field="parent_code"
          alignment="left"
          :caption="$t('DxBinsContentGrid_parent_code')"
          :allow-editing="true"
          :set-cell-value="currentParentSelected"
        >
          <DxRequiredRule />
          <DxLookup
            :data-source="availableParentCodes"
          />
        </DxColumn>
        <DxColumn
          data-field="location_level_0"
          alignment="left"
          :caption="$t('DxBinsContentGrid_location_level_0_caption')"
          :allow-editing="false"
          :calculate-cell-value="getParentLevelZero"
        />
        <DxColumn
          data-field="location_level_last"
          alignment="left"
          :caption="$t('DxBinsContentGrid_location_level_last_caption')"
          :allow-editing="false"
          :calculate-cell-value="getParentLevelLast"
        />
        <DxColumn
          data-field="variety"
          alignment="left"
          :caption="$t('DxBinsContentGrid_variety_caption')"
          :allow-editing="isVarietyEnabled"
          :calculate-cell-value="getParentVariety"
        >
          <DxLookup
            :data-source="availableVarietyNames"
          />
        </DxColumn>
      </template>
      <template v-else>
        <DxColumn
          data-field="location_level_0"
          alignment="left"
          :caption="$t('DxBinsContentGrid_location_level_0_caption')"
          :allow-editing="false"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="location_level_last"
          alignment="left"
          :caption="$t('DxBinsContentGrid_location_level_last_caption')"
          :allow-editing="false"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="variety"
          alignment="left"
          :caption="$t('DxBinsContentGrid_variety_caption')"
          :allow-editing="isVarietyEnabled"
        >
          <DxRequiredRule />
          <DxLookup
            :data-source="availableVarietyNames"
          />
        </DxColumn>
      </template>
      <DxColumn
        data-field="category"
        alignment="left"
        :allow-editing="isCategoryEnabled"
        :caption="$t('DxBinsContentGrid_category_caption')"
      >
        <DxRequiredRule />
        <DxLookup
          :data-source="availableCategoryNames"
        />
      </DxColumn>
      <DxColumn
        data-field="classification"
        alignment="right"
        :allow-editing="isClassificationEnabled"
        :caption="$t('DxBinsContentGrid_classification_caption')"
      >
        <DxRequiredRule />
        <DxLookup
          :data-source="availableClassificationNames"
        />
      </DxColumn>
      <DxColumn
        data-field="plantsCount"
        data-type="number"
        alignment="right"
        :editor-options="numberColumnOptions"
        :caption="$t('DxBinsContentGrid_plants_count_caption')"
      >
        <DxRequiredRule />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxEditing,
  DxLookup,
  DxRequiredRule,
} from 'devextreme-vue/data-grid';

import EventBus from '../../js/event-bus';

export default {
  name: 'BinsContentGrid',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxEditing,
    DxLookup,
    DxRequiredRule,
  },
  props: {
    binsContent: {
      type: Array,
      default: () => [],
    },
    editing: {
      type: Boolean,
      default: false,
    },
    reclassifying: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      numberColumnOptions: {
        showSpinButtons: false, min: 0, showClearButton: true,
      },
      setSaveButtonPopupWorkOrder: true,
      startEditAction: 'click',
      canUpdate: true,
      selectTextOnEditStart: true,
      isParentCodeVisible: false,
      isCategoryEnabled: true,
      isClassificationEnabled: true,
      currentParentSelected(rowData, value) {
        this.defaultSetCellValue(rowData, value);
      },
    };
  },

  computed: {
    ...mapState('PlanningManager',
      [
        'actualLocationsSelected',
        'actualLocationsTreeFormated',
      ]),
    ...mapState('greenhouse',
      [
        'binsLocationName',
        'binsCurrentContent',
      ]),
    ...mapGetters('greenhouse',
      [
        'locationTreeByName',
        'availableCategoryNames',
        'availableVarietyNames',
        'availableClassificationNames',
      ]),
    canAdd() {
      let canAdd = true;
      if (this.creating || this.editing) {
        canAdd = this.isLocationSelected;
      }
      return canAdd;
    },
    isLocationSelected() {
      return this.actualLocationsSelected.length > 0;
    },
    canDelete() {
      return !this.reclassifying;
    },
    isCodeVisible() {
      return this.editing || this.reclassifying;
    },
    isVarietyEnabled() {
      return this.editing || this.creating;
    },
    creating() {
      return !this.editing && !this.reclassifying;
    },
    availableParentCodes() {
      return this.binsCurrentContent.map((content) => content.code !== '' && content.code);
    },
  },

  async created() {
    this.$f7.preloader.show();
    try {
      await this.fetchBinsContentFamilies();
      await this.fetchLocationTree();
    } catch (error) {
      this.$f7.dialog.alert(error);
    } finally {
      this.$f7.preloader.hide();
    }
    this.updateBinsCurrentContent(this.binsContent);
    if (this.reclassifying) {
      this.isCategoryEnabled = false;
      this.isClassificationEnabled = false;
    }
  },
  beforeDestroy() {
    this.resetStates();
  },

  methods: {
    ...mapActions('PlanningManager',
      [
        'setActualLocationsSelected',
      ]),
    ...mapActions('greenhouse',
      [
        'fetchBinsContentFamilies',
        'fetchLocationTree',
        'disableBinsLocationSelect',
        'updateBinsCurrentContent',
        'resetStates',
      ]),
    onInsertedRow() {
      this.updateBinsContentInStore();
      EventBus.$emit('clear-tree-planning-selected-location');
      this.disableBinsLocationSelect();
    },
    onInitNewRow(e) {
      if (this.creating || this.editing) {
        const lastLevelLocation = this.actualLocationsTreeFormated.filter(
          (location) => location.bd_id === this.actualLocationsSelected[0],
        );
        const lastLevelLocationName = lastLevelLocation[0].name;
        e.data.location_level_0 = this.binsLocationName;
        e.data.location_level_last = lastLevelLocationName;
      }
      this.isParentCodeVisible = this.reclassifying;

      if (this.reclassifying) {
        this.isCategoryEnabled = true;
        this.isClassificationEnabled = true;
      }
    },
    onEditingStart(e) {
      if (this.reclassifying) {
        this.isCategoryEnabled = false;
        this.isClassificationEnabled = false;
      }
      if (this.reclassifying && e.data.parent_code && !e.data.code) {
        this.isCategoryEnabled = true;
        this.isClassificationEnabled = true;
      }
      if (e.data.parent_code === null) {
        this.isParentCodeVisible = false;
      }
    },
    onRowUpdated() {
      this.binsContent.forEach((content) => {
        if (content.parent_code !== null) this.isParentCodeVisible = true;
      });
      this.updateBinsContentInStore();
    },
    getParentLevelZero(rowData) {
      if (rowData.code && rowData.location_level_0) {
        return rowData.location_level_0;
      }
      if (rowData.parent_code) {
        const parentContent = this.binsCurrentContent
          .filter((content) => content.code === rowData.parent_code);
        if (parentContent.length > 0) {
          const parentLevelZero = parentContent[0].location_level_0;
          return parentLevelZero;
        }
        return '';
      }
      return '';
    },
    getParentLevelLast(rowData) {
      if (rowData.code && rowData.location_level_last) {
        return rowData.location_level_last;
      }

      if (rowData.parent_code) {
        const parentContent = this.binsCurrentContent
          .filter((content) => content.code === rowData.parent_code);
        if (parentContent.length > 0) {
          const parentLevelLast = parentContent[0].location_level_last;
          return parentLevelLast;
        }
        return '';
      }
      return '';
    },
    getParentVariety(rowData) {
      if (rowData.code && rowData.variety) {
        return rowData.variety;
      }

      if (rowData.parent_code) {
        const parentContent = this.binsCurrentContent
          .filter((content) => content.code === rowData.parent_code);
        if (parentContent.length > 0) {
          const parentVariety = parentContent[0].variety;
          return parentVariety;
        }
        return '';
      }
      return '';
    },
    updateBinsContentInStore() {
      this.updateBinsCurrentContent(this.binsContent);
    },
  },

};
</script>

<style lang="scss" scoped>
.ha-card {
    padding: 20px;
    &--first {
        margin-top: 30px;
    }
}

</style>
