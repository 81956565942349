<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-bpas"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="worker-grid"
      :data-source="BPASFormated"
      :remote-operations="true"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxBpas"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="date"
        :caption="$t('DxDxBpas_Date_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="activityCode"
        :caption="$t('DxDxBpas_activityCode_caption')"
        :allow-header-filtering="false"
        alignment="right"
        width="100"
      />
      <DxColumn
        data-field="activity"
        :caption="$t('DxDxBpas_activity_caption')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="taskCode"
        :caption="$t('DxDxBpas_CodeTask_caption')"
        :allow-header-filtering="false"
        alignment="right"
        width="80"
      />
      <DxColumn
        data-field="task"
        :caption="$t('DxDxBpas_Task_caption')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="locations"
        :caption="$t('DxDxBpas_Location_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('DxDxBpas_Variety_caption')"
        :allow-header-filtering="true"
        alignment="center"
      />
      <DxColumn
        data-field="productName"
        :caption="$t('DxDxBpas_Name_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="activeIngredients"
        :caption="$t('DxDxBpas_ActiveIngredients_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="prescription"
        :caption="$t('DxDxBpas_Formulas_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="dosisTotal"
        :caption="$t('DxDxBpas_dosisUt_caption')"
        :allow-header-filtering="false"
        alignment="right"
        width="100"
      />
      <DxColumn
        data-field="solution_total"
        :caption="$t('DxDxBpas_SolutionTotal_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="qty_used"
        :caption="$t('DxDxBpas_QtyUsed_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="qtyUsedUnit"
        :caption="$t('DxDxBpas_QtyUsedUnit_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="totalRealPrescription"
        :caption="$t('DxDxBpas_totalREalPrescription_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="machineryFamily"
        :caption="$t('DxDxBpas_machineryfamily_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="machinery"
        :caption="$t('DxDxBpas_Machinary_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="internalCode"
        :caption="$t('DxBpas_Machinary_InternalCodecaption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="attachmentFamily"
        :caption="$t('DxDxBpas_attachmentFamily_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="attachment"
        :caption="$t('DxDxBpas_attachment_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="worker"
        :caption="$t('DxDxBpas_Worker_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxBpas',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      BPASFormated: [],
      locationsTree: [],
      locationsTreeFormated: [],
      machineryFormated: [],
      keyName: 'reportingBpas',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      let xhr = await Api.getDataSubfamilyByType('MACHINERY');
      const machineryAvailables = JSON.parse(xhr.response);
      for (let i = 0; i < machineryAvailables.length; i += 1) {
        for (let j = 0; j < machineryAvailables[i].items.length; j += 1) {
          for (let x = 0; x < machineryAvailables[i].items[j].items.length; x += 1) {
            const attachmentFamilySelected = (machineryAvailables[i].id === 'ATTACHMENT')
              ? this.$helpers.getProperty(machineryAvailables[i].items[j].items[x].properties, 'family') : '';

            this.machineryFormated.push({
              description: ((machineryAvailables[i].id === 'ATTACHMENT')) ? '' : machineryAvailables[i].items[j].items[x].description,
              descriptionAttachment: ((machineryAvailables[i].id !== 'ATTACHMENT')) ? ''
                : `${machineryAvailables[i].items[j].items[x].brand}
                ${machineryAvailables[i].items[j].items[x].model}
                ${this.$helpers.getProperty(machineryAvailables[i].items[j].items[x].properties, 'internal_code')} `,
              internalCode: this.$helpers.getProperty(machineryAvailables[i].items[j].items[x].properties, 'internal_code'),
              machineryFamily: this.$helpers.getProperty(machineryAvailables[i].items[j].items[x].properties, 'subfamily'),
              attachmentFamily: attachmentFamilySelected,
            });
          }
        }
      }
      xhr = await Api.getLocationsTree();
      this.locationsTree = JSON.parse(xhr.response);
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      xhr = await Api.getBPASSummary(checkedInitEndDate.init, checkedInitEndDate.end);
      const reportingBPAS = JSON.parse(xhr.response).data;
      for (let i = 0; i < reportingBPAS.length; i += 1) {
        const { date } = reportingBPAS[i];
        const { task } = reportingBPAS[i];
        const taskCode = reportingBPAS[i].task_code;
        const { activity } = reportingBPAS[i];
        const activityCode = reportingBPAS[i].task_code.substr(0, 2);
        const taskType = reportingBPAS[i].task_type;
        const locations = this.getNamesByBdIds(reportingBPAS[i].locations);
        const { comment } = reportingBPAS[i];
        const { prescription } = reportingBPAS[i];
        let internalCode = '';
        let machinery = '';
        let attachment = '';
        for (const product of reportingBPAS[i].products) {
          /* Recorro machineryFormated para comprobar que coincidan los nombres para obtener
          el internal Code. */
          internalCode = '';
          machinery = '';
          attachment = '';
          for (let j = 0; j < this.machineryFormated.length; j += 1) {
            /*
            * Si la maquinaría del producto, coincide con la maquinaría de la BBDD
            * devuelveme su internal code.
            */
            if (product.machinery === this.machineryFormated[i].description) {
              internalCode = this.machineryFormated[i].internalCode;
              machinery = this.machineryFormated[i].description;
            }
            if (product.attachment === this.machineryFormated[i].attachmentFamily) {
              attachment = this.machineryFormated[i].descriptionAttachment;
            }
          }
          this.BPASFormated.push({
            activity,
            activityCode,
            productName: product.name,
            activeIngredients: product.activeIngredients,
            variety: product.variety,
            prescription,
            dosisTotal: `${parseFloat(product.dosis_total).toFixed(0)} ${product.dosisUt}`,
            solution_total: `${parseFloat(product.solution_total).toFixed(0)} ${product.solutionUt}`,
            qty_used: `${parseFloat(product.qty_used).toFixed(0)}`,
            qtyUsedUnit: product.dosisUt,
            machinery,
            attachment,
            worker: product.worker,
            totalRealPrescription: product.total_real_prescription,
            date,
            task,
            taskCode,
            taskType,
            locations,
            comment,
            internalCode,
            machineryFamily: product.machinery,
            attachmentFamily: product.attachment,
          });
        }
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    getTotalWorkingHours(init, end) {
      if (end === null) {
        const ms = moment().diff(moment(init));
        const d = moment.duration(ms);
        return `${d.hours()}`;
      }
      const ms = moment(end).diff(moment(init));
      const d = moment.duration(ms);
      return `${d.hours()}`;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('reportingBPAS');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReportingBPAS.xlsx');
          });
      });
      e.cancel = true;
    },
    setLocationTreeFormat(item) {
      this.locationsTreeFormated.push({
        bd_id: item.bd_id,
        children: item.children,
        name: item.name,
      });
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    getNamesByBdIds(ids) {
      const names = [];
      for (const id of ids) {
        names.push(this.getNameById(id));
      }
      return names;
    },
    getNameById(id) {
      for (const locationTreeFormated of this.locationsTreeFormated) {
        if (locationTreeFormated.bd_id === id) {
          return locationTreeFormated.name;
        }
      }
      return '';
    },
  },
};
</script>
