<template>
  <div
    class="content-devx content-dx-annualBudget-form"
  >
    <DxForm
      id="formAnnualBudget"
    >
      <DxGroupItem :col-count="2">
        <DxGroupItem
          css-class="content-combo"
          :col-span="1"
        >
          <DxSimpleItem
            name="version"
            :data-field="$t('DxAnnualBudgetForm_version_caption')"
            :editor-options="{
              value: actualAnnualBudgetVersion,
              onValueChanged: onChangeVersion,
            }"
          >
            <DxRequiredRule :message="$t('AnnualBudget_version_validation')" />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem
          :col-span="1"
          css-class="content-combo"
        >
          <DxSimpleItem
            name="year"
            :data-field="$t('DxAnnualBudgetForm_year_caption')"
            editor-type="dxDateBox"
            :editor-options="{
              value: actualAnnualBudgetYear,
              onValueChanged: onChangeYear,
              maxZoomLevel:'decade',
              minZoomLevel:'decade',
              zoomLevel: 'decade',
              displayFormat: 'yyyy',
            }"
          >
            <DxRequiredRule :message="$t('AnnualBudget_year_validation')" />
          </DxSimpleItem>
        </DxGroupItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import moment from 'moment';

export default {
  name: 'DxAnnualBudgetForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
      actualAnnualBudgetVersion: '',
      actualAnnualBudgetYear: moment().format('yyyy'),
    };
  },
  computed: {
    ...mapGetters('PlanningManager', ['annualBudgetVersion', 'annualBudgetYear']),
  },

  beforeMount() {
    overlay.baseZIndex(9999);
    this.setAnnualBudgetVersion(this.actualAnnualBudgetVersion);
    this.setAnnualBudgetYear(this.actualAnnualBudgetYear);
  },
  methods: {
    // EVENTS HANDLER ITEMS FORM
    onChangeVersion(e) {
      this.actualAnnualBudgetVersion = e.value;
      this.setAnnualBudgetVersion(this.actualAnnualBudgetVersion);
    },
    onChangeYear(e) {
      this.actualAnnualBudgetYear = moment(e.value).format('yyyy');
      this.setAnnualBudgetYear(this.actualAnnualBudgetYear);
    },
    ...mapActions('PlanningManager', ['setAnnualBudgetVersion', 'setAnnualBudgetYear']),
  },
};
</script>

<style lang="scss">

  .content-combo{
    .dx-item{
      .dx-item-content{
        label{
          min-width: 160px;
        }
      }
    }
  }
</style>
