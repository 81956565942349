export const TOPIC = 'SECURITY_ACCESS';
export const EVENT_TYPE_EMPLOYEE = 'EMPLOYEE';

export const OUTCOME_UNKNOWN = 'UNKNOWN';
export const OUTCOME_ISSUE = 'ISSUE';

export default {
  username: '',
  token: '',
  locationId: -1,
  identificationType: '',
  identification: '',
};
