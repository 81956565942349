<template>
  <f7-page>
    <navbar :text="$t('EffortAgriFarmCockpit_navbar_title')" />
    <div class="content-devx">
      <f7-row>
        <f7-col
          :width="35"
        >
          <f7-block>
            <DxLocationSelector
              :start-level="0"
              :events="['updateGIS', 'updateEffort']"
            />
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col
          width="100"
        >
          <GisEffort />
        </f7-col>
      </f7-row>
      <f7-row class="datatable">
        <f7-col>
          <DxEffort />
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col>
          <DxEffortActivity />
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import navbar from '../../components/NavBar.vue';
import DxEffort from '../../components/reporting/DxEffort.vue';
import DxEffortActivity from '../../components/reporting/DxEffortActivity.vue';
import GisEffort from '../../components/reporting/GisEffort.vue';
import DxLocationSelector from '../../components/reporting/DxLocationSelector.vue';

export default {
  components: {
    navbar,
    DxEffort,
    DxEffortActivity,
    GisEffort,
    DxLocationSelector,
  },
};
</script>

<style lang="scss" scoped>
.datatable {
  margin-bottom: 1.2rem;
}
</style>
