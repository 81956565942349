<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning"
    >
      <DxDataGrid
        id="attachmentTable"
        ref="attachmentTable"
        :data-source="actualTaskBadget.attachments"
        :column-min-width="70"
        :allow-column-reordering="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        @row-inserted="onInsertedRow"
        @row-updated="onChangeRow"
        @row-removed="onRemovedRow"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxAttachmentMonthlyTable"
        />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          mode="row"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="type"
          name="type"
          alignment="left"
          :caption="$t('DxAttachment_dxColumn_TypeAttachment')"
        >
          <DxLookup
            :data-source="attachment"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="count"
          alignment="right"
          name="count"
          data-type="number"
          :caption="$t('DxAttachment_dxColumn_QuantityAttachment')"
        >
          <DxRequiredRule />
        </DxColumn>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxProducts',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxStateStoring,
  },
  props: {
    attachment: { type: Array, default: () => [] },
    planning: { type: Object, default: null },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('TaskBadget', ['actualTaskBadget']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
  },
  methods: {
    // SET DATA SOURCE TO PRODUCTS VUEX
    onChangeRow() {
      const arrayItems = this.actualTaskBadget.attachments;
      this.setAttachments(arrayItems);
    },
    onInsertedRow() {
      const arrayItems = this.actualTaskBadget.attachments;
      this.setAttachments(arrayItems);
    },
    onRemovedRow() {
      const arrayItems = this.actualTaskBadget.attachments;
      this.setAttachments(arrayItems);
    },
    ...mapActions('TaskBadget', ['setAttachments']),
  },
};
</script>
