<template>
  <div
    class="content-devx no-shadow content-dx-daily-planning content-block-table"
    style="margin: 0px 0px 15px 0;"
  >
    <DxDataGrid
      id="binsContentGrid"
      :data-source="binsContentPlanned"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
    >
      <DxEditing
        :allow-updating="allowEditting"
        :allow-deleting="allowEditting"
        :allow-adding="allowEditting"
        :select-text-on-edit-start="true"
        start-edit-action="click"
        mode="form"
      />
      <DxSearchPanel
        :visible="true"
        :width="400"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="bin"
        alignment="left"
        :caption="$t('DxBinsContentGridPlanning_bin')"
        :allow-editing="true"
        :set-cell-value="setBinValue"
      >
        <DxRequiredRule />
        <DxLookup
          :data-source="binsAvailableForPlanning"
          display-expr="nameCode"
          value-expr="bin_id"
        />
      </DxColumn>
      <DxColumn
        data-field="variety"
        alignment="left"
        :caption="$t('DxBinsContentGridPlanning_variety')"
        :allow-editing="true"
        :set-cell-value="setVarietyValue"
      >
        <DxRequiredRule />
        <DxLookup
          :data-source="getFilteredVarieties"
          display-expr="name"
          value-expr="name"
        />
      </DxColumn>
      <DxColumn
        data-field="category"
        alignment="left"
        :caption="$t('DxBinsContentGridPlanning_category')"
        :allow-editing="true"
        :set-cell-value="setCategoryValue"
      >
        <DxRequiredRule />
        <DxLookup
          :data-source="getFilteredCategories"
          display-expr="name"
          value-expr="name"
        />
      </DxColumn>
      <DxColumn
        data-field="classification"
        alignment="left"
        :caption="$t('DxBinsContentGridPlanning_classification')"
        :allow-editing="true"
        :set-cell-value="setClassificationValue"
      >
        <DxRequiredRule />
        <DxLookup
          :data-source="getFilteredClassifications"
          display-expr="name"
          value-expr="name"
        />
      </DxColumn>
      <DxColumn
        data-field="content"
        alignment="left"
        :caption="$t('DxBinsContentGridPlanning_content')"
        :allow-editing="true"
        :set-cell-value="setContentValue"
      >
        <DxRequiredRule />
        <DxLookup
          :data-source="getFilteredContents"
          display-expr="name"
          value-expr="name"
        />
      </DxColumn>
      <DxColumn
        data-field="plants"
        alignment="left"
        :caption="$t('DxBinsContentGridPlanning_plants_count')"
        :allow-editing="false"
        :calculate-cell-value="getContentsNumberOfPlants"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxEditing,
  DxLookup,
  DxRequiredRule,
} from 'devextreme-vue/data-grid';
import EventBus from '../../js/event-bus';

export default {
  name: 'BinsContentGridPlanning',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxEditing,
    DxLookup,
    DxRequiredRule,
  },
  props: {
    allowEditting: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      canAdd: true,
      canDelete: true,
      canUpdate: true,
      setBinValue(rowData, value) {
        rowData.variety = null;
        rowData.category = null;
        rowData.classification = null;
        rowData.content = null;
        this.defaultSetCellValue(rowData, value);
      },
      setVarietyValue(rowData, value) {
        rowData.category = null;
        rowData.classification = null;
        rowData.content = null;
        this.defaultSetCellValue(rowData, value);
      },
      setCategoryValue(rowData, value) {
        rowData.classification = null;
        rowData.content = null;
        this.defaultSetCellValue(rowData, value);
      },
      setClassificationValue(rowData, value) {
        rowData.content = null;
        this.defaultSetCellValue(rowData, value);
      },
      setContentValue(rowData, value) {
        rowData.plants = null;
        this.defaultSetCellValue(rowData, value);
      },
    };
  },
  computed: {
    ...mapState('greenhouse',
      [
        'binsContentPlanned',
        'binsContentPlannedToEdit',
      ]),
    ...mapGetters('greenhouse',
      [
        'binsAvailableForPlanning',
        'varietiesAvailableForPlanning',
        'categoriesAvailableForPlanning',
        'classificationsAvailableForPlanning',
        'contentsAvailableForPlanning',
        'filteredContentsForPlanning',
      ]),
    currentURL() {
      return this.$f7route.url;
    },
  },
  beforeMount() {
    EventBus.$on('DxTreePlanningMounted', this.updateTable);
  },
  beforeDestroy() {
    EventBus.$off('DxTreePlanningMounted');
  },
  methods: {
    ...mapActions('greenhouse', [
      'updateBinsContentPlanned',
      'setBinsContentPlannedToEdit',
    ]),
    updateTable() {
      if (this.currentURL === '/editPlanning/' || this.currentURL === '/createOrder/' || this.currentURL === '/editOrder/workOrders/') {
        this.updateBinsContentPlanned(this.binsContentPlannedToEdit);
        this.setBinsContentPlannedToEdit([]);
      }
    },
    getContentsNumberOfPlants(rowData) {
      if (rowData.content) {
        const filteredContent = this.filteredContentsForPlanning(
          rowData.bin,
          rowData.variety,
          rowData.category,
          rowData.classification,
        );
        if (filteredContent.length > 0) {
          const plantsCount = filteredContent[0].plants_count;
          return plantsCount;
        }
        return '';
      }
      return '';
    },
    getFilteredVarieties(options) {
      return {
        store: this.varietiesAvailableForPlanning,
        filter: options.data
          ? ['bin_id', '=', options.data.bin]
          : null,
      };
    },
    getFilteredCategories(options) {
      return {
        store: this.categoriesAvailableForPlanning,
        filter: options.data
          ? [
            ['bin_id', '=', options.data.bin],
            'and',
            ['variety', '=', options.data.variety],
          ]
          : null,
      };
    },
    getFilteredClassifications(options) {
      return {
        store: this.classificationsAvailableForPlanning,
        filter: options.data
          ? [
            ['bin_id', '=', options.data.bin],
            'and',
            ['variety', '=', options.data.variety],
            'and',
            ['category', '=', options.data.category],
          ]
          : null,
      };
    },
    getFilteredContents(options) {
      return {
        store: this.contentsAvailableForPlanning,
        filter: options.data
          ? [
            ['bin_id', '=', options.data.bin],
            'and',
            ['variety', '=', options.data.variety],
            'and',
            ['category', '=', options.data.category],
            'and',
            ['classification', '=', options.data.classification],
          ]
          : null,
      };
    },
  },

};
</script>

<style lang="scss">
</style>
