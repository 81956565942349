<template>
  <div
    v-if="loaded"
    class="content-devx main-dxwarehouse"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="warehouseGrid"
      ref="warehouseGrid"
      :data-source="detailDailyTasks"
      :remote-operations="true"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @row-updated="onChangeRow"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxWarehouse"
      />
      <DxEditing
        :allow-updating="true"
        mode="row"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="shortId"
        name="shortId"
        :caption="$t('DxWorkOrder_short_id')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="Date"
        name="date"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
        :allow-editing="false"
      />
      <DxColumn
        data-field="Activity"
        name="activity"
        :caption="$t('DxDailyPlanning_activity_caption')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="activityCode"
        name="activityCode"
        data-type="number"
        :caption="$t('DxWorker_ActivityCode_caption')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :caption="$t('DxDailyPlanning_task_caption')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="taskCode"
        name="taskCode"
        data-type="number"
        :caption="$t('PlanningComponent_taskCode_caption')"
        :allow-header-filtering="true"
        :allow-editing="false"
        alignment="center"
      />
      <DxColumn
        data-field="item_name"
        :caption="$t('DxWorkOrders_machinery_caption')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="internal_code"
        :allow-header-filtering="true"
        :caption="$t('DxMachineryForm_internal_code_caption')"
        :allow-editing="false"
      />
      <DxColumn
        data-field="km_start"
        data-type="number"
        :caption="$t('DxWorkOrders_km_start_caption')"
        :allow-header-filtering="false"
        alignment="center"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        data-field="km_finish"
        data-type="number"
        :caption="$t('DxWorkOrders_km_finish_caption')"
        :allow-header-filtering="false"
        alignment="center"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        data-field="horometer_start"
        data-type="number"
        :caption="$t('DxWorkOrders_horometer_start_caption')"
        :allow-header-filtering="false"
        alignment="center"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        data-field="horometer_finish"
        data-type="number"
        :caption="$t('DxWorkOrders_horometer_finish_caption')"
        :allow-header-filtering="false"
        alignment="center"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        data-field="fuel_consumption"
        data-type="number"
        :caption="$t('DxWorkOrders_fuel_consumption_caption')"
        :allow-header-filtering="false"
        alignment="center"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        data-field="fuel_type"
        alignment="center"
        :caption="$t('DxWorkOrders_fuel_type_caption')"
      >
        <DxLookup
          :data-source="typeCosumptionAvailable"
          :display-expr="dysplayConsumptionType"
        />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import {
  DxRangeRule,
} from 'devextreme-vue/validator';
import Api from '../services/Api';
import CalendarInitEndDate from './CalendarInitEndDate.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxRangeRule,
  },
  data() {
    return {
      loaded: false,
      detailDailyTasks: [],
      machineryPopup: [],
      productPopup: [],
      ocultarBotton: false,
      itemsAvailables: [],
      selectTextOnEditStart: true,
      startEditAction: 'click',
      typeCosumptionAvailable: ['DIESEL', 'GASOLINE'],
      keyName: 'warehouse',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      this.itemsAvailables = await this.getItemsMachinary('MACHINERY', 'VEHICLE');
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getDailyTasks(true, [], [], checkedInitEndDate.init, checkedInitEndDate.end);
      const dailyTasks = JSON.parse(xhr.response);
      this.detailDailyTasks.length = 0;
      for (let i = 0; i < dailyTasks.detail.length; i += 1) {
        for (let j = 0; j < dailyTasks.detail[i].tasks.length; j += 1) {
          if (dailyTasks.detail[i].status === 'COMPLETED' && typeof dailyTasks.detail[i].tasks[j].machinery_performance !== 'undefined') {
            for (let k = 0; k < dailyTasks.detail[i].tasks[j].machinery_performance.length; k += 1) {
              this.detailDailyTasks.push({
                ID: dailyTasks.detail[i].tasks[j].id,
                Date: moment(dailyTasks.detail[i].tasks[j].date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                Task: dailyTasks.detail[i].tasks[j].task,
                taskCode: dailyTasks.detail[i].tasks[j].task_code,
                Activity: dailyTasks.detail[i].tasks[j].activity,
                activityCode: dailyTasks.detail[i].tasks[j].activity_code,
                item_id: dailyTasks.detail[i].tasks[j].machinery_performance[k].item_id,
                item_name: this.machineryNameFormatted(dailyTasks.detail[i].tasks[j].machinery_performance[k].item_id),
                internal_code: this.machineryCodeFormatted(dailyTasks.detail[i].tasks[j].machinery_performance[k].item_id),
                km_start: dailyTasks.detail[i].tasks[j].machinery_performance[k].km_start,
                km_finish: dailyTasks.detail[i].tasks[j].machinery_performance[k].km_finish,
                horometer_start: dailyTasks.detail[i].tasks[j].machinery_performance[k].horometer_start,
                horometer_finish: dailyTasks.detail[i].tasks[j].machinery_performance[k].horometer_finish,
                working_area: dailyTasks.detail[i].tasks[j].machinery_performance[k].working_area,
                fuel_consumption: dailyTasks.detail[i].tasks[j].machinery_performance[k].fuel_consumption,
                fuel_type: dailyTasks.detail[i].tasks[j].machinery_performance[k].fuel_type,
                comment: dailyTasks.detail[i].tasks[j].machinery_performance[k].comment,
                shortId: dailyTasks.detail[i].tasks[j].short_id,
              });
            }
          }
        }
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    machineryCodeFormatted(e) {
      for (let i = 0; i < this.itemsAvailables.length; i += 1) {
        if (this.itemsAvailables[i].id === e) {
          return this.itemsAvailables[i].item.code;
        }
      }
      return '';
    },
    machineryNameFormatted(e) {
      for (let i = 0; i < this.itemsAvailables.length; i += 1) {
        if (this.itemsAvailables[i].id === e) {
          return this.itemsAvailables[i].item.description;
        }
      }
      return '';
    },
    dysplayConsumptionType(e) {
      if (e === 'DIESEL') {
        return this.$t('DIESEL');
      }
      return this.$t('GASOLINE');
    },
    async getItemsMachinary(type, family) {
      const xhr = await Api.getItems(type, family);
      return JSON.parse(xhr.response);
    },
    getItems(itemsDriver) {
      const items = [];
      for (const itemDriver of itemsDriver) {
        items.push(itemDriver.item);
      }
      return items;
    },
    /*
    Quito la columan working area, compruebo con un console
    que sigue viniendo el dato en el currentRow, si fallase,
    volver a colocar la columna y en vez de eliminarla,
    añadir por ejemplo un visible: false.
    */
    async onChangeRow(e) {
      this.$f7.preloader.show();
      const currentRow = e.data;
      this.machineryPopup.push({
        item_id: currentRow.item_id,
        km_start: currentRow.km_start,
        km_finish: currentRow.km_finish,
        horometer_start: currentRow.horometer_start,
        horometer_finish: currentRow.horometer_finish,
        working_area: currentRow.working_area,
        fuel_consumption: currentRow.fuel_consumption,
        fuel_type: currentRow.fuel_type,
        comment: currentRow.comment,
      });
      try {
        const updateOrder = { // TODO: Falta obtener el delta para solo enviar los cambios
          machinery_performance: this.machineryPopup,
          // product_performance: this.productPopup,
        };
        await Api.updateOrder(currentRow.ID, updateOrder);
      } catch (reason) {
        this.$f7.dialog.alert(reason); // TODO: Hay que dar una respuesta más detallada del error
      } finally {
        this.$f7.preloader.hide();
      }
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('WorkOrders');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WorkOrders.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('PlanningManager', ['setActualWorkOrder', 'setVisiblePopupWorkOrder']),
    ...mapActions('Prescription', ['setProducts', 'setPrescription', 'setNewPrescription']),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed{
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
</style>
