<template>
  <div
    class="content-devx content-dx-daily-planning"
  >
    <DxDataGrid
      id="binsContentToMoveTable"
      ref="binsContentToMoveTable"
      :data-source="binsContent"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      :selected-row-keys="binsCurrentContentToMove"
      :column-auto-width="true"
      @selection-changed="changeContentToMoveSelected"
    >
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="18" />
      <DxEditing
        :allow-updating="false"
        :allow-deleting="false"
        :allow-adding="false"
        mode="row"
      />
      <DxSelection
        mode="multiple"
        :allow-select-all="true"
        show-check-boxes-mode="always"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="code"
        name="code"
        alignment="left"
        :caption="$t('DxBinsContentGrid_code')"
      />
      <DxColumn
        data-field="location_level_0"
        alignment="left"
        :caption="$t('DxBinsContentGrid_location_level_0_caption')"
        :allow-editing="false"
      />
      <DxColumn
        data-field="location_level_last"
        alignment="left"
        :caption="$t('DxBinsContentGrid_location_level_last_caption')"
        :allow-editing="false"
      />
      <DxColumn
        data-field="variety"
        alignment="left"
        :caption="$t('DxBinsContentGrid_variety_caption')"
        :allow-editing="false"
      />
      <DxColumn
        data-field="category"
        alignment="left"
        :allow-editing="false"
        :caption="$t('DxBinsContentGrid_category_caption')"
      />
      <DxColumn
        data-field="classification"
        alignment="right"
        :allow-editing="false"
        :caption="$t('DxBinsContentGrid_classification_caption')"
      />
      <DxColumn
        data-field="plantsCount"
        data-type="number"
        alignment="right"
        :allow-editing="false"
        :caption="$t('DxBinsContentGrid_plants_count_caption')"
      />
    </DxDataGrid>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxSelection,
} from 'devextreme-vue/data-grid';

export default {
  name: 'DxBatchesBinsTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxSelection,
  },
  props: {
    binsContent: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('greenhouse', ['binsCurrentContent', 'binsCurrentContentToMove']),
  },
  beforeDestroy() {
    this.updateBinsCurrentContentToMove([]);
  },
  methods: {
    changeContentToMoveSelected(e) {
      this.updateBinsCurrentContentToMove(e.selectedRowKeys);
    },
    ...mapActions('greenhouse',
      [
        'updateBinsCurrentContentToMove',
        'setLotBins',
      ]),
  },
};
</script>
