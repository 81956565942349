const dictionary = {
  es: {
    translation: {
      Login_txtWelcome: 'Acceso',
      Login_inpUsername_placeholder: 'Usuario',
      Login_inpPassword_label: 'Contraseña',
      Login_inpPassword_placeholder: 'Contraseña',
      Login_chkRememberMe: 'Recordar contraseña',
      Login_txtRestorePass: 'Cambiar contraseña',
      Login_txtNeedHelp: '¿Necesitas ayuda?',
      Login_btnSignIn: 'ENTRAR',
      Login_btnSignUp_question: '¿Todavía no te has registrado?',
      Login_btnSignUp_link: 'Registrate aquí',
      Login_msgEmail_title: 'Información',
      Login_msgEmail_content: 'En breve recibirá un email para cambiar su contraseña',
      Login_msgEmail_btnClose: 'Cerrar',
      loggOut_Message: '¿Estás seguro que quieres salír de la aplicación?',
      RecoveryPassword_txtDataAccess: 'Introduzca su email',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Reestrablecer contraseña',
      RecoveryPassword_btnGoBack: 'Volver',
      ChangePassword_txtDataAccess: 'Introduzca su nueva contraseña',
      ChangePassword_inpEmail_placeholder: 'Contraseña',
      ChangePassword_inpRepeatEmail_placeholder: 'Repita contraseña',
      ChangePassword_btnChangePassword: 'Cambiar contraseña',
      Status_Closed: 'Cerrada',
      Status_notReally_Closed: 'No Cerrada',
      Status_reallyNot_Closed: 'No Cerrada',
      Register_btnEnter: 'ENTRAR',
      Register_inpName_name: 'Nombre',
      Register_inpName_placeholder: 'Nombre',
      Register_inpSurnames_name: 'Apellidos',
      Register_inpSurnames_placeholder: 'Apellidos',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Contrasena',
      Register_inpPassword_placeholder: 'Contraseña',
      Register_inpPasswordVerification_name: 'RepetirContrasena',
      Register_inpPasswordVerification_placeholder: 'Repetir contraseña',
      Register_inpUsername_name: 'NombreUsuario',
      Register_inpUsername_placeholder: 'Nombre de usuario',
      Nfc_into_error_404: 'El empleado no está registrado',
      Nfc_into_error_400: 'El formato de nfc no es válido',
      Nfc_into_error_406: 'La tarea actual ya está creada en esta localización',
      Nfc_into_error_409: 'El trabajador ya está trabajando en otra tarea',
      Nfc_info_txtTitle: 'Lectura de tarjeta',
      Nfc_info_txtSubTitle: 'Aproxime la tarjeta para su lectura',
      Nfc_navbarpresence_incidence: 'Escaneo de NFC',
      Nfc_navbarTask_salida: 'Productividad',
      Nfc_navbarpresence_manual: 'Añadir NFC manual',
      Nfc_navbarpresence_btnContinue: 'Continuar',
      NfcManually_navbarpresence_btnContinue: 'Continuar',
      NfcManually_listInput_placeholder: 'Nombre o apellido',
      DxSelectSupervisor: 'Se necesita al menos un supervisor',
      Profile_info_profile: 'Información de perfil',
      Profile_name_placeholder: 'Nombre',
      Profile_surname_label: 'Apellidos',
      Profile_surname_placeholder: 'Apellidos',
      Profile_email_label: 'E-mail',
      Profile_email_placeholder: 'E-mail',
      Profile_mobile_label: 'Teléfono',
      Profile_mobile_placeholder: 'Teléfono',
      Profile_password_profile: 'Contraseña',
      Profile_oldPass_label: 'Contraseña anterior',
      Profile_oldPass_placeholder: 'Contraseña anterior',
      Profile_newPass_label: 'Nueva contraseña',
      Profile_newPass_placeholder: 'Nueva contraseña',
      Profile_checkNewPass_label: 'Confirmar nueva contraseña',
      Profile_change_password: 'Guardar contraseña',
      Profile_photo_profile: 'Foto perfil',
      Profile_update_photo: 'Actualizar foto perfil',
      Profile_setup_profile: 'Configuración',
      Profile_language_label: 'Idioma',
      Profile_theme_label: 'Tema',
      Profile_save_changes: 'Guardar cambios',
      Home_info_txtTitle: 'No hay empleados',
      ExportWorkerList_btnExport: 'Exportar a Excel',
      ExportExcel_thNombre: 'Nombre',
      ExportExcel_thTipo: 'Tipo',
      ExportExcel_thFecha: 'Fecha',
      PlanningComponent_numberWorkers_caption: 'Número de trabajadores',
      ExportExcel_captionListaEmpleados: 'Lista de empleados',
      SheetExport_sheet_export_ok: 'Fichero descargado: ',
      SheetExport_sheet_export_error: 'Error en la descarga',
      TasksList_navbar_text: 'Ordenes de trabajo',
      TasksList_lstNewTasks_title: 'Iniciar tarea',
      TasksList_lstInProgressTasks_title: 'Tareas en progreso',
      TasksList_lstCompletedTasks_title: 'Tareas completadas',
      AccordionList_workers: 'trabajadores',
      AccordionList_worker: 'trabajador',
      Home_conductor: 'Conductor',
      Start_order: 'Selecciona trabajador',
      ManualList_listItem_title: 'Ningun empleado encontrado',
      checkOut_Productivity_placeholder: 'Campo Numérico',
      checkOut_Productivity: 'Productividad',
      checkOut_Task: 'Tarea',
      list_content_farm_img_alt: 'Cambiar de granja',
      farmSelection_navbar_text: 'Seleccionar granja',
      activitySelection_navbar_text: 'Seleccionar actividad',
      locationSelect_navbar_text: 'Seleccionar Localización',
      ActivitySelection_navbarTask_text: 'Seleccionar actividad',
      ActivitySelection_txtSearchCodeTask: 'Comenzar tarea por código: ',
      ActivitySelection_txtSearchActivity: 'Elige una actividad: ',
      ActivitySelection_taskError: 'No existe esa tarea',
      TasksList_filterTask_title: 'Seleccionar tarea',
      DxDailyPlanning_text: 'Crear',
      DxAddEmployee_text: 'Añadir nuevo empleado',
      DxMonthlyPlanning_text: 'Create new monthly planning',
      DetailTabs_machinary_title: 'Maquinaria',
      DetailTabs_attachment_title: 'Implementos',
      DxEmployeeForm_name_require: 'Nomre es requerido',
      DxEmployeeForm_surname_require: 'Apellido es requerido',
      DxEmployeeForm_ID_require: 'Numero de identificacion es requerido',
      DxEmployeeForm_status_require: 'Estatus es requerido',
      DxEmployeeForm_role_require: 'Rol es requerido',
      DxEmployeeForm_type_require: 'Tipo es requerido',
      DxEmployeeForm_email_require: 'Email es requerido',
      DxEmployeeForm_password_require: 'Contraseña es requerida',
      DetailTabs_tools_title: 'Herramientas',
      DetailTabs_products_title: 'Productos',
      DetailTabs_workers_title: 'Trabajadores',
      DetailTabs_locations_title: 'Localizaciones',
      DxDailyPlanning_date_caption: 'Fecha',
      DxListEmployee_name_caption: 'Nombre',
      DxListEmployee_surname_caption: 'Apellidos',
      DxListEmployee_type_caption: 'Tipo',
      DxListEmployee_nfc_caption: 'identificación de la placa',
      DxListEmployee_code_caption: 'RUT',
      DxListEmployee_status_caption: 'Estado',
      DxListEmployee_driver_caption: 'Conductor',
      DxListEmployee_info_caption: 'Info',
      DxDailyPlanning_task_caption: 'Tarea',
      DxDailyPlanning_activity_caption: 'Actividad',
      DxDailyPlanning_ha_caption: 'Ha',
      DxDailyPlanning_workers_caption: 'Trabajadores',
      DxDailyPlanning_location_caption: 'Localización',
      DxDailyPlanning_workingarea_caption: 'Area Trabajo (HA)',
      DxDailyPlanning_workingArea_caption: 'Area de trabajo (HA)',
      DxMonthlyPlanning_goal_caption: 'Objetivo',
      DxDailyPlanning_dialogDelete_title: 'Borrar la planificación',
      DxDailyPlanning_dialogDelete_text: '¿Está seguro de borrar la planificación?',
      DxDailyPlanning_dialogDelete_confirm: 'Confirmar',
      DxDailyPlanning_dialogDelete_cancel: 'Cancelar',
      DxDailyWorkOrders_dialogDelete_title: 'Borrar orden de trabajo',
      DxDailyWorkOrders_dialogDelete_text: '¿Está seguro de borrar la orden de trabajo?',
      DxDailyWorkOrders_dialogDelete_confirm: 'Confirmar',
      DxDailyWorkOrders_dialogDelete_cancel: 'Cancelar',
      DxDailyWorkOrders_dialogDelete_noOpen: 'No se puede eliminar una orden de trabajo ya empezada.',
      DxDailyWorkOrders_dialogUpdate_noOpen: 'No se puede editar una orden de trabajo ya empezada.',
      MonthlyComponent_type_caption: 'Tipo',
      MonthlyComponent_performance_caption: 'Objetivo',
      MonthlyComponent_performanceunit_caption: 'Obejtivo Unitario',
      MonthlyComponent_consumption_caption: 'Consumo',
      MonthlyComponent_consumptionunit_caption: 'Unidad Consumo',
      MonthlyComponent_quantity_caption: 'Cantidad',
      MonthlyComponent_typeconsumption_caption: 'Tipo Consumo',
      MonthlyComponent_notes_caption: 'Notas',
      PlanningComponent_caption_text: 'Actividad',
      PlanningComponent_activityName_caption: 'Nombre Actividad',
      PlanningComponent_activitycode_caption: 'Codigo Actividad',
      PlanningComponent_workingarea_caption: 'Area de Trabajo',
      EmployeeComponent_name_caption: 'Nombre',
      EmployeeComponent_surname_caption: 'Apellidos',
      EmployeeComponent_identificationNumber_caption: 'RUT',
      EmployeeComponent_status_caption: 'Estado',
      EmployeeComponent_role_caption: 'Rol',
      EmployeeComponent_type_caption: 'tipo',
      EmployeeComponent_drive_caption: 'Conductor',
      EmployeeComponent_group_caption: 'Grupo',
      EmployeeComponent_shift_caption: 'Turno',
      EmployeeComponent_email_caption: 'Email',
      EmployeeComponent_password_caption: 'Nueva Contraseña',
      EmployeeComponent_identification_title: 'Identificacion del empleado',
      EmployeeComponent_typeIdentification_caption: 'Tipo',
      EmployeeComponent_code_caption: 'Codigo',
      PlanningComponent_taskName_caption: 'Nombre Tarea',
      PlanningComponent_taskCode_caption: 'Codigo Tarea',
      PlanningComponent_date_caption: 'Fecha',
      PlanningComponent_numberWokers_caption: 'Numero de Trabajadores',
      PlanningComponent_family_caption: 'Familia',
      PlanningComponent_addMachineButton_caption: 'Añadir Maquinaria',
      PlanningComponent_shift_caption: 'Turno',
      PlanningComponent_groupName_caption: 'Nombre Grupo',
      PlanningComponent_dailyPlanning: 'PLAN DIARIO',
      PlanningComponent_harvesting: 'COSECHA',
      PlanningComponent_edit_dailyPlanning: 'Editar planificación',
      CreatePlanning_ButtonSave_caption: 'Guardar',
      CreatePlanning_Title: 'CREAR NUEVA PLANIFICACIÓN',
      CreateMonthlyPlanning_Title: 'CREAR NUEVA PLANIFICACIÓN MENSUAL',
      EditMonthlyPlanning_Title: 'EDITAR PLANIFICACIÓN MENSUAL',
      CreateEmployee_Title: 'CREAR EMPLEADO',
      EditEmployee_Title: 'EDITAR EMPLEADO',
      DxTaskOrders_text: 'Registrar nueva orden de trabajo',
      StoreProduct_Buttoncancel_caption: 'Cancelar',
      StoreProduct_Buttonsave_caption: 'Guardar',
      CreatePlanning_ButtonCancel_caption: 'Cancelar',
      CreatePlanning_ValidationDate: 'La fecha es necesaria.',
      CreatePlanning_ValidationTaskName: 'El nombre de la tarea es necesaria.',
      CreatePlanning_ValidationActivityName: 'El nombre de la actividad es necesaria.',
      CreatePlanning_ValidationNumberOfWorker: 'El numero trabajadores debe de ser mayor que cero.',
      CreatePlanning_ValidationNumberOfDriver: 'El numero de conductores debe de ser superior a cero.',
      CreatePlanning_ValidationActualLocationsSelected: 'Es necesario seleccionar una localización.',
      CreateEmployee_ValidationIdentificationNumber: 'Numero de indentificacion es requerido',
      CreateEmployee_ValidationName: 'Nombre es requerido.',
      CreateEmployee_ValidationSurname: 'Apellido es requerido.',
      CreateEmployee_ValidationEmail: 'Email es requerido.',
      CreateEmployee_ValidationPassword: 'Contraseña es requerido.',
      EmployeeComponent_caption_text: 'Datos del Empleado',
      CreateOrder_ValidationSupervisorsLocation: 'Hay localizaciones sin un supervisor asignado.',
      CreateOrder_ValidationItemsWorkOrder: 'Hay items sin asignar.',
      CreateOrder_ValidationLocationExists: 'Ya hay una tarea con esta localización para la misma fecha.',
      DxDailyPlanning_edit_text: 'Editar',
      DxDailyPlanning_edit_hint: 'Editar',
      DxDailyPlanning_new_order_text: 'Nueva orden',
      DxDailyPlanning_new_order_hint: 'Nueva orden',
      DxDailyPlanning_task_status_caption: 'Estado Tarea',
      DxDailyPlanning_work_order_status_caption: 'Estado Orden Trabajo',
      DxDailyPlanning_supervisors_caption: 'Supervisores',
      DetailTabs_supervisors_title: 'Supervisores',
      SelectSupervisor_supervisor_caption: 'Supervisor',
      SelectSupervisor_location_caption: 'Localización',
      WorkOrder_title: 'ESTADO ORDEN DE TRABAJO',
      FuelRegistration_title: 'REGISTRO COMBUSTIBLE',
      Employee_title: 'LISTA EMPLEADOS',
      WorkOrderDetail_select_supervisor: 'Seleccionar supervisor',
      WorkOrderDetail_select_maquinaria: 'Seleccionar maquinaria',
      WorkOrderDetail_order: 'ORDEN',
      DxWorkOrders_edit_text: 'Editar',
      DxWorkOrders_edit_hint: 'Editar',
      DxWorkOrders_modal_title: 'Editar ordenes de trabajo',
      DxWorkOrders_machinery_caption: 'Maquinaria',
      DxWorkOrders_driver_caption: 'Conductor',
      DxWorkOrders_km_start_caption: 'Km comienzo',
      DxWorkOrders_km_finish_caption: 'Km fin',
      DxWorkOrders_horometer_start_caption: 'Horometro comienzo',
      DxWorkOrders_horometer_finish_caption: 'Horometro fin',
      DxWorkOrders_fuel_consumption_caption: 'Combustibles consumido (L)',
      DxWorkOrders_fuel_type_caption: 'Tipo Combustible',
      DxWorkOrders_working_area_caption: 'Area trabajo',
      DxWorkOrders_aditional_info_caption: 'Información adicional',
      DxProductPerformance_unit: 'Unidad',
      DxProductPerformance_products_name: 'Producto',
      DxProductPerformance_products_qtyCollected: 'Cantidad recolectada',
      DxProductPerformance_products_qtyPlanned: 'Cantidad planificada',
      DxProductPerformance_machinery_qtyTask: 'Cantidad devuelva a almacén',
      DxProductPerformance_products_qtyUsed: 'Cantidad usada',
      DxProductPerformance_products_qtyReturnWarehouse: 'Cantidad devuelta',
      DxProductPerformance_products_totalRealPrescription: 'Total real',
      DxProductPerformance_products_realSolution: 'Real solución',
      DxProductPerformance_products_realDilutionFactor: 'Factor de dilución real',
      DxProductPerformance_products_appliedDose: 'Dosis aplicada',
      DxProductPerformance_products_theoricalDose: 'Dosis teórica',
      EditOrder_ButtonSave_caption: 'Guardar',
      EditOrder_ButtonCancel_caption: 'Cancelar',
      CreateOrder_ButtonSave_caption: 'Guardar',
      CreateOrder_ButtonCancel_caption: 'Cancelar',
      CreateOrder_ValidationOutDate: 'No puede crear una orden de trabajo con una planificación fuera de fecha',
      DxSelectedItems_ya_esta_seleccionado_alert: 'Ya está seleccionado',
      DxDailyPlanning_delete_text: 'Eliminar',
      DxDailyPlanning_delete_hint: 'Eliminar',
      DxWorkOrder_Button_View_text: 'Vista',
      DxWorkOrder_Button_View_hint: 'Vista',
      DetailTabWorkers_sin_trabajadores: 'No existen trabajadores asignados a la tarea',
      WorkOrderDetail_select_date: 'Seleccionar fecha',
      DxSelectDate_date_caption: 'Fecha',
      PlanningComponent_typeUnit_caption: 'Tipo unidad',
      DxDailyPlanning_performanceha_caption: 'Objetivo',
      DxDailyPlanning_totalArea: 'Area total (ha)',
      PlanningComponent_performanceUnit_caption: 'Productividad Unidad',
      PlanningComponent_performanceCount_caption: 'Productividad Objetivo',
      PlanningComponent_distributed_caption: 'Distribuido',
      Status_Open: 'Abierta',
      Status_InProgress: 'En progreso',
      Status_Completed: 'Completa',
      Status_Pending: 'Pendiente',
      Status_Deleted: 'Eliminada',
      DetailTabWorkers_sin_items: 'No existe maquinaria asignada a la tarea',
      DetailTab_sin_seleccion: 'No existe selección',
      WorkOrderDetail_additional_info: 'Información adicional',
      WorkOrderDetail_select_driver: 'Seleccionar conductor',
      CreateOrder_ValidationItemsDriversWorkOrder: 'Hay conductores sin asignar',
      Login_inpUsername_validate: 'Completa este campo',
      Login_inpPassword_validate: 'Completa este campo',
      EffortAgriFarmCockpit_navbar_title: 'REPORTE DE ESFUERZO AGRI FARM COCKPIT',
      AgriFarmCockpit_navbar_title: 'REPORTE AGRI FARM COCKPIT', // TODO: Revisar nombre
      WeatherFarmCockpit_navbar_title: 'GRAFICOS DEL CLIMA',
      EffortTask_navbar_title: 'REPORTE EFFORT TASK',
      PrecisionFarming_navbar_title: 'PRECISION FARMING',
      LeftPanel_title_reporting: 'REPORTES',
      LeftPanel_title_plan: 'PLAN',
      LeftPanel_title_execution: 'EJECUCIÓN',
      LeftPanel_title_prescriptions: 'PRESCRIPCIONES',
      LeftPanel_title_weather: 'CLIMA',
      LeftPanel_title_warehouse: 'BODEGUERO',
      LeftPanel_subtitle_fuelRegistration: 'Registro combustible',
      LeftPanel_subtitle_precisionFarming: 'Precision Farming',
      LeftPanel_title_fertilizers: 'FERTILIZANTES',
      LeftPanel_title_information: 'INFORMACION',
      LeftPanel_subtitle_dailyPlanning: 'Planificación Diaria',
      LeftPanel_subtitle_workOrders: 'Estado orden de trabajo',
      LeftPanel_subtitle_unplannedWorkOrders: 'Registro manual OT',
      LeftPanel_subtitle_postWork: 'Órdenes tras completar', // TODO: Revisar nombre
      LeftPanel_subtitle_logOut: 'Salir',
      LeftPanel_subtitle_home: 'Inicio',
      LeftPanel_subtitle_activeIngredients: 'Principios Activos autorizados EU',
      LeftPanel_subtitle_prescriptionManager: 'Gestión de prescripciones',
      LeftPanel_subtitle_fertilizersPrescriptions: 'Gestión de Prescripciones',
      LeftPanel_subtitle_AgriFarmCockpit: 'Agri Farm Cockpit',
      LeftPanel_subtitle_effortAgriFarm: 'Esfuerzo',
      LeftPanel_subtitle_weatherFarmCockpit: 'Gráficos del Clima',
      LeftPanel_subtitle_phytosanitariesLocalDatabase: 'Base de Datos local de fitosanitarios',
      LeftPanel_subtitle_employee: 'Empleados',
      LeftPanel_title_dataConfiguration: 'CONFIGURACION DATOS',
      LeftPanel_subtitle_productsLocalDatabase: 'Base de Datos local de productos',
      LeftPanel_subtitle_monthlyPlanning: 'Budget Anual',
      LeftPanel_subtitle_harvesting: 'Cosecha',
      DxEffort_level_caption: 'Nivel',
      DxMonthlyMachinary_text: 'Añadir nueva maquinaria',
      DxDailyPlanning_days_caption: 'Dias',
      DxDailyPlanning_p_caption: 'Permanente',
      DxDailyPlanning_t_caption: 'Temporal',
      DxDailyPlanning_c: 'Contratista',
      DxDailyPlanning_coste: 'Coste',
      DxEffort_activity_caption: 'Actividad',
      DxEffort_task_caption: 'Tarea',
      DxEffort_hrHours_caption: 'Horas RH',
      DxEffort_totalHours_caption: 'Horas totales',
      DxEffort_totalHoursHa_caption: 'Horas totales/Ha',
      DxEffort_totalHoursTotalHa_caption: '% de horas totales vs total de Ha',
      DxEffort_progressCompleted_caption: 'Progreso (% Completado)',
      Search_placeholder: 'Buscar',
      WeatherForecast_title: 'Predicción Meteorológica',
      WeatherStatus_title: 'Estado Meteorológico',
      GisMap_title: 'Mapa',
      PlanningComponent_machinary_caption: 'Maquinaria',
      DxProducts_dxColumn_Unit: 'Unidad',
      DxAttachment_dxColumn_TypeAttachment: 'Tipo',
      DxAttachment_dxColumn_QuantityAttachment: 'Cantidad',
      DxMachinery_dxColumn_TypeMachinery: 'Tipo',
      DxMachinery_dxColumn_QuantityMachinery: 'Cantidad',
      DxProducts_dxColumn_activeIngredients: 'Active ingredients',
      DxProducts_dxColumn_productName: 'Nombre producto',
      DxProducts_dxColumn_dosis: 'Dosis',
      DxProducts_dxColumn_dosisUt: 'Dosis Unid',
      DxProducts_dxColumn_dosisTotal: 'Cantidad Planificada',
      DxProducts_dxColumn_solucion: 'Solución',
      DxProducts_dxColumn_solucionUt: 'Solución Unid',
      DxProducts_dxColumn_dilutionFactor: 'Factor de mojamiento',
      DxProducts_dxColumn_unit: 'Unidad',
      DxProducts_dxColumn_dilutionFactorUt: 'Unidad',
      DxProducts_dxColumn_solutionTotal: 'Disolucion planificada (L)',
      DxProducts_dxColumn_caution: 'Precaución',
      DxProducts_dxColumn_restrictedEntryInterval: 'Intervalo de entrada',
      DxProducts_dxColumn_preharvestInterval: 'Intervalo de precosecha',
      ActiveIngredients_navbar_title: 'Principios Activos autorizados EU',
      PrescriptionManager_navbar_title: 'Gestión de Prescripciones',
      DxCreatePrescription_button_text: 'Crear nueva prescripción',
      DxCreatePrescription_column_active: 'Active',
      DxCreatePrescription_column_name: 'Nombre prescripción',
      DxCreatePrescription_column_type: 'Tipo',
      DxCreatePrescription_column_method: 'Huerto / Vivero',
      ORCHARD: 'Huerto',
      NURSERY: 'Vivero',
      DxCreatePrescription_column_disease: 'Disolución',
      DxCreatePrescription_column_activeSince: 'Activo desde',
      DxCreatePrescription_column_notes: 'Notas',
      CreatePrescription_Title: 'CREAR PRESCRIPCION',
      EditPrescription_Title: 'EDITAR PRESCRIPCION',
      EditProduct_navbar_create: 'EDITAR PRODUCTO',
      EditProduct_navbar_edit: 'CREAR NUEVO PRODUCTO',
      DxFormProduct_code_caption: 'SAP ID',
      DxFormProduct_code_required: 'El código es requerido',
      DxFormProduct_status_caption: 'ESTADO',
      DxFormProduct_comment_caption: 'COMENTARIO',
      DxFormProduct_description_caption: 'DESCRIPCION ARTICULO SAP',
      DxFormProduct_component_caption: 'INGREDIENTE ACTIVO',
      DxFormProduct_family_caption: 'NOMBRE GRUPO SAP',
      DxFormProduct_subfamily_caption: 'SUBGRUPO',
      DxFormProduct_sapunit_caption: 'U.M SAP',
      DxFormProduct_toxicity_caption: 'TOXICIDA',
      DxFormProduct_description_required: 'La descripcion es requerida.',
      DxInformationPrescription_name_caption: 'NOMBRE',
      DxInformationPrescription_active_caption: 'ACTIVO',
      DxInformationPrescription_dateofactivation_caption: 'FECHA DE ACTIVACION',
      DxInformationPrescription_typeofprescription_caption: 'TIPO DE PRESCRIPCION',
      DxInformationPrescription_methodofapplication_caption: 'HUERTO / VIVERO',
      DxInformationPrescription_notes_caption: 'NOTAS',
      DxInformationPrescription_name_required: 'Nombre requerido',
      DxInformationPrescription_date_required: 'Fecha requerida',
      DxInformationPrescription_typeofprescription_required: 'Tipo de prescripción requerido',
      DxInformationPrescription_methodofapplication_required: 'El campo Huerto/Vivero es requerido',
      totalArea_field: 'Área total (ha)',
      totalArea_title: 'Resumen de parcelas seleccionadas',
      DxAttachment_Title: 'Aperos',
      DxMachineryPerformance_Title: 'Rendimiento de Maquinaria',
      DxProductsPerformance_Title: 'Rendimiento de Productos',
      DxMachinery_Title: 'Maquinaria',
      DxTools_Title: 'Herramientas',
      DxProducts_Title: 'Productos',
      DxTools_dxColumn_TypeTool: 'Tipo',
      DxTool_dxColumn_QuantityTool: 'Cantidad',
      CreatePrescription_ValidationName: 'El nombre es necesario',
      CreatePrescription_ValidationNameDuplicated: 'El nombre indicado ya está en uso',
      CreatePrescription_ValidationProducts: 'Es necesario incluir al menos un producto',
      CreatePrescription_ValidationMethod: 'Es necesario seleccionar un método de aplicación',
      CreatePrescription_ValidationType: 'Es necesario seleccionar un tipo de prescripción',
      CreatePrescription_ValidationDate: 'Es necesario seleccionar una fecha',
      CreatePrescription_ValidationActive: 'Una nueva prescripción debe ser activa',
      DxPlanningProducts_prescription: 'Nombre Prescripción',
      DxPlanningProducts_prescription_required: 'Prescripción requerida',
      DxPlanningProducts_typePrescription: 'Tipo Prescripción',
      DxPlanningProducts_typePrescription_required: 'Tipo Prescripción requerido',
      DxPlanningProducts_typeOfPrescription: 'Tipo de presc.',
      DxPlanningProducts_typeOfPrescription_required: 'Tipo de presc. requerido',
      // DxActiveIngredientsVademecum i18next desde vuex.
      active_substance_id: 'Sustancia activa',
      approval_date: 'Fecha de aprobación',
      approval_expiration: 'Fecha de expiración',
      authorisation_in_progress: 'Autorización en curso',
      authorised: 'Autorizada',
      category: 'Categoría',
      organic_approved: 'Orgánica aprobada',
      remark: 'Observación',
      status: 'Estado',
      substitution_candidate: 'Candidata a sustitución',
      countries: 'Países',
      FertilizersPrescriptions_title: 'PRESCRIPCIONES DE FERTILIZANTES',
      ProductsDatabase_title: 'BASE DE DATOS DE PRODUCTOS',
      DxAttachmentMachinary_dxColumn_Machinary: 'Maquinaria',
      DxAttachmentMachinary_dxColumn_Attachment: 'Apero',
      DxWeatherElementLinesGraph_average: 'Media',
      DxWeatherElementLinesGraph_averageForecasted: 'Media Predictiva',
      DxWeatherElementLinesGraph_totalForecasted: 'Total Predictivo',
      DxWeatherElementLinesGraph_total: 'Total',
      DxProduts_rule_range: 'El rango debe ser superior a 0',
      DxProduts_rule_solution_required: 'La solución es requerida',
      DxProduts_rule_dosis_required: 'Dosis es requerida',
      DxProdutsDataBase_button_text: 'Crear nuevo producto',
      DxProdutsDataBase_edit_text: 'Editar',
      DxProdutsDataBase_edit_hint: 'Editar',
      DxEmployeeTable_Title: 'Empleados',
      DxPostWorkEmployeeTable_rule_range: 'El rango debe ser superior a 0',
      DxPostWorkEmployeeTable_Name: 'Nombre',
      DxPostWorkEmployeeTable_Surname: 'Apellido',
      DxPostWorkEmployeeTable_Checkin: 'Checkin',
      DxPostWorkEmployeeTable_Checkout: 'Checkout',
      DxPostWorkEmployeeTable_Productivity: 'Productivity',
      DxPostWorkEmployeeTable_Unit: 'Unidad',
      DxPostWorkEmployeeTable_Notes: 'Notas',
      DxTaskOrders_navbar_Name: 'REGISTRO DE ORDEN DE TRABAJO',
      PlanningComponent_datepicker_multiple_placeholder: 'Seleccionar fechas',
      CreatePostWork_Navbar_Title: 'REGISTRAR NUEVA ORDEN DE TRABAJO',
      ViewPostWork_Navbar_Title: 'VISTA DE LA ORDEN DE TRABAJO',
      DxPostWorkEmployeeTable_dialogCreate_wrongHour: 'La hora de inicio debe ser anterior a la hora de fin.',
      CreatePostWork_Validation_PrescriptionNeeded: 'Debe añadir una receta en esta tarea.',
      DxCreatePrescription_column_family: 'Familia',
      DxProducts_dxColumn_productGroup: 'tipo',
      DxProducts_dxColumn_productFamily: 'Grupo',
      DxPlanningForm_activity_name: 'Nombre de la actividad obligatorio',
      DxPlanningForm_Task_name: 'Nombre de la tarea obligatorio',
      DxFertilizersDataBase_active_caption: 'Activo',
      DxFertilizersDataBase_description_caption: 'Descripción',
      DxFertilizersDataBase_family_caption: 'Familia',
      DxFertilizersDataBase_subfamily_caption: 'Subfamilia',
      DxPhytosanitariesDataBase_active_caption: 'Activo',
      DxPhytosanitariesDataBase_description_caption: 'Descripción',
      DxPhytosanitariesDataBase_family_caption: 'Familia',
      DxPhytosanitariesDataBase_subfamily_caption: 'Subfamilia',
      DxProductsDataBase_status_caption: 'Estado',
      DxProductsDataBase_code_caption: 'Código SAP',
      DxProductsDataBase_description_caption: 'Descripción artículo SAP',
      DxProductsDataBase_family_caption: 'Nombre grupo SAP',
      DxProductsDataBase_subfamily_caption: 'Subgrupo',
      DxProductsDataBase_component_caption: 'Ingrediente Activo',
      DxPostWorkEmployeeTable_dialogCreate_wrongCheckIn: 'La fecha de inicio tiene que ser la misma que la fecha de la tarea.',
      LeftPanel_title_machinery: 'MAQUINARIA',
      LeftPanel_subtitle_machineryDataBase: 'Base de datos de Maquinaria',
      Machinery_title: 'MAQUINARIA',
      CreateMachinery_Title: 'CREAR MAQUINARIA',
      EditMachinery_Title: 'EDITAR MAQUINARIA',
      DxMachineryDatabase_column_status: 'Estado',
      DxMachineryDatabase_column_family: 'Familia',
      DxMachineryDatabase_column_subfamily: 'Subfamilia',
      DxMachineryDatabase_column_description: 'Descripción artículo SAP',
      DxMachineryDatabase_column_brand: 'Marca',
      DxMachineryDatabase_column_code: 'Código Interno',
      DxMachineryDatabase_column_attachment: 'Implementos',
      DxMachineryDatabase_button_text: 'Crear nueva maquinaria',
      DxMachineryForm_code_caption: 'Código SAP',
      DxMachineryForm_code_required: 'Código es requerido',
      DxMachineryForm_description_caption: 'Descripción',
      DxMachineryForm_description_required: 'Descripción es requerida',
      DxMachineryForm_brand_caption: 'Marca',
      DxMachineryForm_brand_required: 'Marca es requerida',
      DxMachineryForm_model_caption: 'Modelo',
      DxMachineryForm_model_required: 'Modelo es requerido',
      DxMachineryForm_serial_number_caption: 'Número de serie',
      DxMachineryForm_serial_number_required: 'Número de serie requerido',
      DxMachineryForm_plate_caption: 'Placa',
      DxMachineryForm_plate_required: 'Placa es requerida',
      DxMachineryForm_internal_code_caption: 'Código interno',
      DxMachineryForm_internal_code_required: 'Código interno es requerido',
      DxMachineryForm_family_caption: 'Familia',
      DxMachineryForm_family_required: 'Familia es requerida',
      DxMachineryForm_subfamily_caption: 'Subfamilia',
      DxMachineryForm_subfamily_required: 'Subfamilia es requerida',
      DxMachineryForm_attachment_caption: 'Implementos',
      DxMachineryForm_purchase_caption: 'Fecha adquisición',
      DxMachineryForm_purchase_required: 'Fecha adquisición es requerida',
      DxMachineryForm_status_caption: 'Estado',
      Machinery_ValidationCode: 'El campo código es obligatorio',
      Machinery_ValidationBrand: 'El campo marca es obligatorio',
      Machinery_ValidationModel: 'El campo modelo es obligatorio',
      Machinery_ValidationSerialNumber: 'El campo nuúmero de serie es obligatorio',
      Machinery_ValidationPlate: 'El campo placa es obligatorio',
      Machinery_ValidationDescription: 'El campo descripción es obligatorio',
      Machinery_ValidationFamily: 'El campo familia es obligatorio',
      Machinery_ValidationSubfamily: 'El campo subfamilia es obligatorio',
      Machinery_ValidationPurchase: 'El campo adquisición es obligatorio',
      // Generics
      Button_cancel_text: 'Cancelar',
      Button_save_text: 'Salvar',
      LeftPanel_subtitle_productsDataBase: 'Base de datos de productos',
      LeftPanel_subtitle_reportingWorker: 'Trabajadores',
      LeftPanel_subtitle_timeWorked: 'Entrada / Salida',
      ReportingWorker_navbar_title: 'REPORTE TRABAJADORES',
      ReportingTimeWorked_navbar_title: 'ENTRADA / SALIDA',
      DxWorker_date_caption: 'Fecha',
      DxWorker_task_caption: 'Tarea',
      DxWorker_typeTask_caption: 'Tipo',
      DxWorker_codeTask_caption: 'Código',
      DxWorker_workerName_caption: 'Trabajador',
      DxWorker_name_caption: 'Nombre',
      DxWorker_workerSurname_caption: 'Apellido',
      DxWorker_idNumber_caption: 'Número de ID',
      DxWorker_typeWorker_caption: 'Tipo',
      DxWorker_territorialUnit_caption: 'Unidades Territoriales',
      DxWorker_totalHours_caption: 'Nº de Horas',
      DxWorker_workingDay_caption: 'Jornada',
      DxWorker_performanceUnit_caption: 'Unidad de medida',
      DxWorker_performanceCount_caption: 'Productividad',
      DxWorker_note_caption: 'Nota',
      CreateMonthlyPlanning_ValidationDate: 'El número de días de trabajo debe corresponder con la cantidad de fechas seleccionadas',
      CreateMonthlyPlanning_ValidationTotalWorkingDays: 'Debe de planificar al menos un día de trabajo',
      DxMonthlyForm_activityTask: 'Tarea de actividad',
      DxMonthlyForm_goalUnit: 'Obejtivo Unitario',
      DxMonthlyForm_DateProgrammed: 'Fecha Programada',
      DxMonthlyForm_numberWorkers_caption: 'Número de trabajadores',
      DxMonthlyForm_performanceWorker_caption: 'RENDIMIENTO/TRABAJADOR',
      DxMonthlyForm_workersDaysRequired: 'DIAS DE TRABAJO REQUERIDOS',
      DxMonthlyForm_machineryDaysRequired: 'DIAS DE MAQUINARIA REQUERIDOS',
      DxMonthlyForm_toolsConsumptionTotal: 'CONSUMO TOTAL HERRAMIENTAS (L)',
      DxMonthlyForm_estimatedCostActivity: 'COSTE ESTIMADO ACTIVIDAD',
      DxMonthlyForm_taskDuration: 'DURACIÓN DE LA TAREA',
      DxToolsMonthlyTable_type_caption: 'Tipo',
      DxToolsMonthlyTable_consumption_caption: 'consumo',
      DxToolsMonthlyTable_consumption_type_caption: 'tipo de consumo',
      DxToolsMonthlyTable_consumption_unit_caption: 'Obejtivo Unitario',
      DxMachineryMonthlyTableHADAY: 'HA/DIA',
      DxMachineryMonthlyTableUNDIA: 'UN/DIA',
      Home_navbar_title: 'INICIO',
      // CRUD Costes.
      Costs_title: 'Costes',
      DxCostsDatabase_column_name: 'Nombre',
      DxCostsDatabase_edit_text: 'Editar',
      DxCostsDatabase_edit_hint: 'Editar',
      LeftPanel_subtitle_costsDataBase: 'Base de datos de costes',
      DxCostsDatabase_column_code: 'Codigo',
      DxCostsDatabase_column_id: 'Id',
      DxCostsDatabase_column_price: 'Precio',
      DxCostsDatabase_column_currency: 'Moneda',
      DxCostsDatabase_column_currency_code: 'Codigo Moneda',
      DxCostsDatabase_column_unit: 'Unidad',
      DxCost_button_text: 'Crear nuevos Costes',
      DxCost_title_creation: 'Crear Costes',
      DxCostForm_code_required: 'El codigo es necesario',
      DxCostForm_name_required: 'El Nombre es necesario',
      DxCostForm_price_required: 'El Precio es necesario',
      DxCostForm_Unit_required: 'La unidad es necesaria',
      DxCostForm_Currency_required: 'La Monead es necesaria',
      DxCostForm_Id_required: 'Id es necesario',
      CreacteCost_ValidationCode: 'El campo código es obligatorio',
      CreacteCost_ValidationName: 'El campo name es obligatorio',
      CreacteCost_ValidationPrecio: 'El campo Precio es obligatorio',
      CreacteCost_ValidationUnit: 'El campo unidad de serie es obligatorio',
      CreacteCost_ValidationCurrency: 'El campo moneda es obligatorio',
      CreacteCost_ValidationCurrencyCode: 'El campo codigo moneda es obligatorio',
      CreacteCost_ValidationId: 'El campo ID es obligatorio',
      CreateCost_dialogDelete_title: 'Eliminar Coste',
      DeleteCost_dialogDelete_text: '¿Estas seguro de eliminar el coste seleccinado?',
      // Summary BPAS
      DxDxBpas_Date_caption: 'Fecha',
      DxDxBpas_Location_caption: 'Unidades Territoriales',
      DxDxBpas_ActiveIngredients_caption: 'Principio Activos',
      DxDxBpas_dosisUt_caption: 'Dosis',
      DxDxBpas_attachment_caption: 'Apero',
      DxDxBpas_machineryfamily_caption: 'Familia maquinaria',
      DxDxBpas_attachmentFamily_caption: 'Familia apero',
      DxDxBpas_Machinary_caption: 'Maquinaria',
      DxDxBpas_Name_caption: 'Nombre Comercial',
      DxDxBpas_QtyUsed_caption: 'Gasto total',
      DxDxBpas_SolutionTotal_caption: 'Mojamiento',
      DxDxBpas_Variety_caption: 'Variedad',
      DxDxBpas_Worker_caption: 'Aplicador',
      DxDxBpas_Task_caption: 'Tarea',
      DxDxBpas_CodeTask_caption: 'Código Tarea',
      DxDxBpas_TypeTask_caption: 'Tipo',
      DxDxBpas_Formulas_caption: 'Nombre Prescripción',
      DxListEmployee_role_caption: 'Rol',
      DxPrescriptionManager_dosis: 'Dosis',
      DxPrescriptionManager_solution: 'Mojamiento',
      DxPrescriptionManager_description: 'Descripción artículo SAP',
      DxMachineryDatabase_company: 'Localizacion',
      // Summary Machinery
      DxMachinery_Date_caption: 'Fecha',
      DxMachinery_Location_caption: 'Unidad Territorial',
      DxMachinery_Attachment_caption: 'Implementos',
      DxMachinery_unit_caption: 'Unidad de medida',
      DxMachinery_init_caption: 'km inicial',
      DxMachinery_end_caption: 'km final',
      DxMachinery_total_caption: 'km realizados',
      DxMachinery_Name_caption: 'Máquina',
      DxMachinery_workingArea_caption: 'Área de trabajo',
      DxMachinery_SolutionTotal_caption: 'Mojamiento',
      DxMachinery_Variety_caption: 'Variedad',
      DxMachinery_Worker_caption: 'Conductor',
      DxMachinery_Task_caption: 'Tarea',
      DxMachinery_typeFuel_caption: 'Tipo de Combustible',
      DxMachinery_quantityFuel_caption: 'Cantidad de Combustible',
      DxMachinery_performance_caption: 'Cantidad',
      DxMachinery_CodeTask_caption: 'Código Tarea',
      DxMachinery_InternalCode_caption: 'Códgio Interno',
      DxMachinery_TypeTask_caption: 'Tipo',
      DxMachinery_comment_caption: 'Notas',
      DxWorker_ActivityCode_caption: 'Código de Actividad',
      DxWorker_group_caption: 'Grupo',
      DxWorker_shift_caption: 'Turno',
      DxWorker_Activity_caption: 'Actividad',
      DxWorker_location_caption: 'Localización',
      DxWorker_initDate_caption: 'Hora Inicio',
      DxWorker_endDate_caption: 'Hora Fin',
      DxWorker_checkIn_caption: 'Check in',
      DxWorker_checkOut_caption: 'Check out',
      DxWorker_jornada_caption: 'Jornada',
      ReportingMachinery_navbar_title: 'Reporte Maquinaría',
      ReportingBPAS_navbar_title: 'Informe Uso fitosanitario',
      DxDxBpas_activity_caption: 'Actividad',
      DxDxBpas_activityCode_caption: 'Código Actividad',
      DxMachinery_Activity_caption: 'Actividad',
      DxMachinery_ActivityCode_caption: 'Código Actividad',
      DxMonthlyPlanning_totalCost: 'Coste total',
      DxPostWorkEmployeeTable_WorkingArea: 'Área trabajada',
      CreatePlanning_ValidationTotalWorkingDays: 'El número de días de trabajo de la maquinaria supera el número de días de trabajo totales. Ajuste el rendimiento de la maquinaria.',
      CreateOrder_ValidationAttachments: 'Hay maquinaria sin asignar en los aperos',
      UploadHarvestingPlan_preloader: 'Subiendo el fichero. Esto puede tardar un poco de tiempo según el tamaño del fichero',
      UploadHarvestingPlan_success: 'Fichero subido correctamente. Estamos procesando  las works orders. Puede tardar un tiempo en verlas',
      UploadHarvestingPlan_error: 'Se ha producido el siguiente error:',
      UploadHarvestingPlan_button: 'Cargar fichero HDS',
      UploadHarvestingPlan_empty: 'Por favor, seleccione un fichero HDS',
      LeftPanel_subtitle_logs: 'Cosecha Logs',
      ReportingLogs_navbar_title: 'LOGS',
      DxErrorLogs_Date_caption: 'Fecha',
      DxErrorLogs_Time_caption: 'Hora',
      DxErrorLogs_HDSID_caption: 'HDS Id',
      DxErrorLogs_Description_caption: 'Descripción',
      DxSelectSupervisorPerformance_SelectSupervisor_required: 'El supervisor es requerido',
      LeftPanel_subtitle_reportingBpas: 'Informe Uso fitosanitario',
      CalendarInitEndDate_btnClear: 'Limpiar',
      CalendarInitEndDate_btnSearch: 'Buscar',
      PlanningComponent_seasional_caption: 'Cantidad',
      DxProductPerformance_machinery_total_real_consumption: 'Total area prescripcion',
      DxDxBpas_totalREalPrescription_caption: 'Disolución utilizada',
      LeftPanel_subtitle_reportingMachinery: 'Maquinaría',
      DxWorkOrders_notes_caption: 'Notas',
      DxWorkOrders_variety_caption: 'Variedad',
      ReportingEmployeeEffectiveness_navbar_title: 'Efectividad de Empleado',
      ReportingEmployeeEffectiveness_date: 'Fecha',
      ReportingEmployeeEffectiveness_task: 'Tarea',
      ReportingEmployeeEffectiveness_taskCode: 'Código Tarea',
      ReportingEmployeeEffectiveness_Activity: 'Actividad',
      ReportingEmployeeEffectiveness_activityCode: 'Código Actividad',
      ReportingEmployeeEffectiveness_performance: 'Rendimiento',
      ReportingEmployeeEffectiveness_timeSpent: 'Tiempo Empleado',
      ReportingEmployeeEffectiveness_fullname: 'Nombre',
      ReportingEmployeeEffectiveness_type: 'Tipo',
      ReportingEmployeeEffectiveness_IdTask: 'Id Tarea',
      ReportingEmployeeEffectiveness_SumaParcial: 'Parcial Suma',
      HOURS: 'Horas',
      ReportingEmployeeEffectiveness_ratioTimeSpent: '% Tiempo Empleado',
      ReportingEmployeeEffectiveness_ratioTotalPerformance: '% Rendimiento Total',
      ReportingEmployeeEffectiveness_ratioPerformanceGoal: '% Rendimiento Objetivo',
      DxDailyPlanning_realGoal_caption: 'Objetivo real',
      DxDailyPlanning_goalVsReal_caption: '% objetivo completado',
      DxDailyPlanning_realCost: 'Coste real',
      DxWorkOrders_PlannMachinary_caption: 'Maquinaria Planeada',
      DxWorkOrders_WorkedMachinary_caption: 'Maquinaria trabajando',
      DxWorkOrders_totalMachinary_caption: 'Maquinaria total',
      DxPlannReal_plannWorkers_caption: 'Empleado planificado',
      DxPlannReal_workers_caption: 'Empleado trabajando',
      DxPlannReal_Totalworkers_caption: 'Total empleados',
      ReportingWorkOrders_navbar_title: 'Informe Órdenes de Trabajo',
      LeftPanel_subtitle_reportingWorkOrders: 'Órdenes de Trabajo',
      DxListTaskGroupedActivivty_task: 'Tarea',
      DxListTaskGroupedActivivty_taskCode: 'Código Tarea',
      DxListTaskGroupedActivivty_performanceUnit: 'Objetivo Unitario',
      DxListTaskGroupedActivivty_Activity: 'Actividad',
      DxListTaskGroupedActivivty_NavBarName: 'Actividad y Tareas',
      LeftPanel_subtitle_ListTaskGroupedActivivty: 'Actividad y Tareas',
      PlanningComponent_variety_caption: 'Variedad',
      DxPlanningForm_variety_required: 'Variedad requerida',
      DxDailyPlanning_CostVsReal: '% Coste Completado',
      planned_title: 'PLANEADO VS REAL',
      ReportingplannedVsReal_navbar_title: 'Planeado vs Real',
      Variety_title: 'Variedad',
      LeftPanel_subtitle_varietyDatabase: 'Base de datos de Variedad',
      DxVarietyDatabase_column_code: 'Código',
      DxVarietyDatabase_column_name: 'Nombre',
      DxMachineryDatabase_sapid: 'SAP ID',
      DxProductsDataBase_UMSAP_caption: 'U.M SAP',
      DxProductTable_dialogCreate_solutionNotEmpty: 'El campo solución no puede estar vacio o ser menor de 0',
      DxProducts_dxColumn_solution: 'Mojamiento',
      DxProducts_dxColumn_solutionUt: 'Unidad Mojamiento',
      DxDetailTabs_products_quantityPlanned: 'Cantidad Planificada',
      DxDetailTabs_products_waterPlanned: 'Disolución planificada',
      CreatePlanning_ValidationPrescription: 'Es necesario seleccionar una prescripción',
      DxBpas_Machinary_InternalCodecaption: 'Código Interno',
      DxDxBpas_QtyUsedUnit_caption: 'Unidad de cantidad',
      DxWorkOrders_kmTotal_caption: 'Km realizados',
      DxWorkOrders_HorometerTotal_caption: 'Horas realizadas',
      DxProductPerformance_products_doseUsed: 'Dosis usada',
      PlanningComponent_grouped_caption: 'Agrupado',
      Workers_StackedBarPlanned: 'Trabajadores',
      machinary_StackedBarPlanned: 'Maquinarías',
      ChartPlanned_StackedBarPlanned: 'Planificado vs Trabajando',
      plannWorkers_StackedBarPlanned: 'Trabajadores Planificados',
      plannMachinery_StackedBarPlanned: 'Maquinarías Planificadas',
      DxPostWorkMachinaryDriverTable_machinery_validating: 'Esta maquinaria ya está seleccionada',
      dxSideBySideBar_totalWorkerByActivity: 'Trabajadores Totales Por Actividad',
      dxSideBySideBar_Worker: 'Trabajdores',
      dxSideBySideBarDayWorked_totalWorkerByActivity: 'Jornada trabajada por Actividad',
      dxSideBySideBarDayWorked_totalWorkerByActivity_Worked: 'Jornada',
      DxToolsDatabase_column_status: 'Estado',
      DxToolsDatabase_column_code: 'Código',
      DxToolsDatabase_column_family: 'Familia',
      DxToolsDatabase_column_subfamily: 'Subfamilia',
      DxToolsDatabase_column_description: 'Descripción',
      DxToolsDatabase_column_brand: 'Matricula',
      DxToolsDatabase_column_id: 'id',
      DxToolsDatabase_column_fuel: 'Combustible',
      DxToolsDatabase_column_itemId: 'Id articulo',
      LeftPanel_subtitle_tools: 'Herramientas',
      editTool_Title: 'EDITAR HERRAMIENTA',
      createTool_Title: 'CREAR HERRAMIENTA',
      Tools_ValidationCode: 'El campo código es obligatorio',
      Tools_ValidationBrand: 'El campo marca es obligatorio',
      Tools_ValidationID: 'El campo ID es obligatorio',
      Tools_ValidationItemId: 'El campo elemento ID es obligatorio',
      Tools_ValidationDescription: 'El campo descripción es obligatorio',
      Tools_ValidationFamily: 'El campo familia es obligatorio',
      Tools_ValidationSubfamily: 'El campo subfamilia es obligatorio',
      Tools_ValidationFuel: 'El campo Combustible es obligatorio',
      DxWorkOrder_short_id: 'ID',
      DxManualPerformance_total_performance: 'Rendimiento total',
      DxPopupWorkOrder_Title_supervisor: 'Supervisor',
      DxPopupWorkOrder_Title_totalPerformance: 'Rendimiento total',
      DxPopupWorkOrder_Title_machinery: 'Maquinaria',
      DxPopupWorkOrder_Title_product: 'Productos',
      DxWorkOrders_manualPerformance_caption: 'Rendimiento total',
      PlanningComponent_manualPerformance_caption: 'Rendimiento total',
      ProductCollection_navbar_title: 'Retirar Productos',
      LeftPanel_subtitle_productCollection: 'Retirar Productos',
      ReturnedProduct_navbar_title: 'Devolver Productos',
      LeftPanel_subtitle_returnedProduct: 'Devolver Productos',
      DxProductWarehouse_column_date: 'Fecha',
      DxProductWarehouse_shortId: 'ID',
      DxProductWarehouse_column_activity: 'Actividad',
      DxProductWarehouse_column_activityCode: 'Código Actividad',
      DxProductWarehouse_column_task: 'Tarea',
      DxProductWarehouse_column_taskCode: 'Código Tarea',
      DxProductWarehouse_column_productName: 'Producto',
      DxProductWarehouse_column_supervisor: 'Supervisor',
      DxProductWarehouse_column_productWarehouse: 'Cantidad',
      DxProductWarehouse_column_statusCollection: 'Estado',
      DxProductWarehouse_column_statusConfirmed: 'Enviado',
      DxProductWarehouse_column_statusNotConfirmed: 'No enviado',
      DxProductWarehouse_column_cost_center: 'Centro de costes',
      DxProductWarehouse_column_cost_center_required: 'Centro de costes requerido',
      DxProductWarehouse_column_warehouse: 'Almacén',
      DxProductWarehouse_column_warehouse_required: 'Almacén requerido',
      // Greenhouse
      DxBins_dxColumn_Code: 'Código',
      DxBins_dxColumn_CreationDate: 'Fecha de creación',
      DxBins_dxColumn_NameBins: 'Nombre',
      BatchesForm_create_batches_info: 'Información del lote',
      BatchesForm_edit_batches_info: 'Información del lote',
      CreateBatch_Title: 'Crear Lote',
      EditBatch_Title: 'Editar Lote',
      CreateBatch_ButtonSave_caption: 'CREAR',
      DxBatches_dxColumn_BinsList: 'Bins Contenidos',
      DxBatches_dxColumn_NameBatch: 'Nombre Lote',
      DxBatches_dxColumn_CreationDate: 'Fecha Creación',
      DxBatches_dxColumn_Code: 'Código',
      LeftPanel_title_batches: 'LOTES',
      LeftPanel_subtitle_create_batches: 'Crear Lotes',
      LeftPanel_subtitle_batches: 'Consultar Lotes',
      LeftPanel_title_bins: 'BINS',
      LeftPanel_subtitle_create_bins: 'Crear bins',
      LeftPanel_subtitle_bins: 'Consultar bins',
      LeftPanel_title_stock_management: 'GESTIÓN DE STOCK',
      LeftPanel_subtitle_received_bins: 'Listado de bins recibidos',
      LeftPanel_subtitle_reclassification: 'Reclasificación',
      Batches_Title: 'LOTES',
      Bins_create_navbar_title: 'CREAR BIN',
      Bins_reclassify_navbar_title: 'RECLASIFICAR BIN',
      Bins_edit_navbar_title: 'EDITAR BIN',
      BinsForm_bins_info: 'Datos del Bin',
      DxBinsInfoForm_date_caption: 'Fecha',
      DxBinsInfoForm_name_caption: 'Nombre',
      DxBinsInfoForm_bins_location_caption: 'Farm',
      Bins_content_title: 'Contenido del Bin',
      DxBinsContentGrid_code: 'Codigo',
      DxBinsContentGrid_parent_code: 'Codigo del Padre',
      DxBinsContentGrid_location_level_0_caption: 'Nivel 0',
      DxBinsContentGrid_location_level_last_caption: 'Último Nivel',
      DxBinsContentGrid_variety_caption: 'Variedad',
      DxBinsContentGrid_category_caption: 'Categoría',
      DxBinsContentGrid_classification_caption: 'Clase',
      DxBinsContentGrid_plants_count_caption: 'Nº de Plantas',
      Bins_Title: 'LISTADO DE BINS',
      Reclassification_Title: 'RECLASIFICACIÓN',
      DxBinsTable_State_Created: 'Creado',
      DxBinsTable_State_Registered: 'Registrado',
      DxBinsTable_State_Closed: 'Cerrado',
      DxBinsTable_State_Sent: 'Enviado',
      DxBinsTable_State_Received: 'Recibido',
      DxBinsTable_State_ToReceive: 'Pendiente de recibir',
      DxBinsTable_dxColumn_Code: 'Código',
      DxBinsTable_dxColumn_Name: 'Nº Bins',
      DxBinsTable_dxColumn_LotName: 'Nº Lote',
      DxBinsTable_dxColumn_Origin: 'Farm Origen',
      DxBinsTable_dxColumn_Destination: 'Farm Destino',
      DxBinsTable_dxColumn_State: 'Estado Envío',
      DxDetailTabBins_dxColumn_Variety: 'Variedad',
      DxDetailTabBins_dxColumn_NumPlants: 'Nº Plantas',
      DxDetailTabBins_dxColumn_Category: 'Categoría',
      DxDetailTabBins_dxColumn_Classification: 'Clasificación',
      DxDetailTabsBins_BinsData: 'Datos del bin',
      DxDetailTabsBins_Origin: 'Origen',
      DxDetailTabsBins_Destination: 'Destino',
      DxDetailTabBins_dxColumn_Code: 'Código',
      DxDetailTabBins_dxColumn_LastLevel: 'Último nivel',
      DxBinsTable_ButtonText_Register: 'Registrar',
      DxBinsTable_ButtonText_Send: 'Enviar',
      DxBinsTable_ButtonText_Move: 'Mover',
      DxBinsTable_ButtonText_Close: 'Cerrar',
      DxBinsTable_ButtonText_ConfirmReception: 'Confirmar Recepción',
      DxSelectLevel0Location_modal_title: 'Seleccione destino:',
      DxSelectLevel0Location_requiredRule_farm: 'Este campo es requerido',
      DxSelectLevel0Location_farm: 'Destino',
      DxBinsTable_button_send: 'Enviar',
      DxBinsTable_button_cancel: 'Cancelar',
      CreateBin_ButtonSave_caption: 'CREAR',
      ReclassifyBin_ButtonSave_caption: 'RECLASIFICAR',
      MoveBin_ButtonSave_caption: 'MOVER',
      EditBin_ButtonSave_caption: 'GUARDAR',
      EditBatch_ButtonSave_caption: 'GUARDAR',
      ButtonCancel_caption: 'CANCELAR',
      DxBinsContentGridPlanning_bin: 'Bin',
      DxBinsContentGridPlanning_variety: 'Variedad',
      DxBinsContentGridPlanning_category: 'Categoría',
      DxBinsContentGridPlanning_classification: 'Clase',
      DxBinsContentGridPlanning_content: 'Contenido',
      DxBinsContentGridPlanning_plants_count: 'Nº Plantas',
      DxNursery_Title: 'Viveros',
      CreatePlanning_Duplicated_Nursery_Rows: 'Usted tiene una o más filas duplicadas en la tabla Vivero',
      Nursery_OnlyOneLastLevelMessage: '* IMPORTANTE: Seleccionar sólo un último nivel',
      DetailTabs_nursery_title: 'Viveros',
      BatchesInfoForm_name: 'Name',
      DxProductWarehouse_edit_validation: 'El producto ya está enviado',
      Bins_move_navbar_title: 'Mover Contenido del Bin',
      AnnualBudgets_Title: 'BUDGET ANUAL',
      DxAnnualBudgets_year_caption: 'Año',
      DxAnnualBudgets_version_caption: 'Versión',
      DxAnnualBudgets_status_caption: 'Estado',
      DxAnnualBudgets_createAnnualBudget_text: 'Crear badget anual',
      CreateAnnualBudget_Title: 'Crear badget anual',
      AnnualBudget_version_validation: 'El campo Versión es requerido',
      AnnualBudget_year_validation: 'El campo Año es requerido',
      DxAnnualBudgetForm_version_caption: 'Versión',
      DxAnnualBudgetForm_year_caption: 'Año',
      PlanningComponent_monthlyPlanning: 'Budget anual',
      CloneAnnualBudget_Title: 'Clonar versión badget anual',
      AnnualBudget_catch_NAME_EXIST_case: 'Ya existe una versión con el mismo nombre',
      AnnualBudget_catch_NOT_CLOSED_case: 'Todas las veriones tienen que estar cerradas',
      AnnualBudget_catch_VERSION_NOT_FOUND_case: 'No se encontró la versión',
      AnnualBudget_catch_BADGET_NOT_FOUND_case: 'No se encontró el badget',
      AnnualBudget_catch_ERROR_BADGET_MANY_VERSIONS_case: 'No puede haber más de 3 versiones en un badget',
    },
  },
};
export default dictionary;
