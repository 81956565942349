<template>
  <f7-page>
    <navbar :text="$t('DxCost_title_creation')" />
    <DxCostsForm
      class="content-planning"
    />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToCostsDatabase"
      >
        {{ $t("CreatePlanning_ButtonCancel_caption") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        @click="createCost"
      >
        {{ $t('CreatePlanning_ButtonSave_caption') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import DxCostsForm from '../../components/costs/DxCostsForm.vue';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';

export default {
  name: 'CreateCost',
  components: {
    DxCostsForm,
    navbar,
  },
  computed: {
    ...mapState('costManager', ['actualCost']),
  },
  methods: {
    goToCostsDatabase() {
      this.$f7.views.main.router.navigate('/costsDatabase/', { reloadCurrent: true });
    },
    async createCost() {
      if (this.actualCost.code === '') {
        this.$f7.dialog.alert(this.$t('CreacteCost_ValidationCode'));
      } else if (this.actualCost.name === '') {
        this.$f7.dialog.alert(this.$t('CreacteCost_ValidationName'));
      } else if (this.actualCost.currency === '') {
        this.$f7.dialog.alert(this.$t('CreacteCost_ValidationCurrency'));
      } else if (this.actualCost.price === '') {
        this.$f7.dialog.alert(this.$t('CreacteCost_ValidationPrecio'));
      } else if (this.actualCost.unit === '') {
        this.$f7.dialog.alert(this.$t('Cost_ValidationPlate'));
      } else if (this.actualCost.currencyCode === '') {
        this.$f7.dialog.alert(this.$t('CreacteCost_ValidationCurrencyCode'));
      } else {
        this.$f7.preloader.show();
        try {
          const newCost = {
            code: this.actualCost.code,
            currency: this.actualCost.currency,
            name: this.actualCost.name,
            price: parseInt(this.actualCost.price).toFixed(2),
            currency_code: this.actualCost.currencyCode,
            unit: this.actualCost.unit,
          };
          await Api.createCost(newCost);
          this.goToCostsDatabase(); // TODO: Habría que mostrar alguna notificación de que se creó correctamente
        } catch (reason) {
          this.$f7.dialog.alert(reason); // TODO: Hay que dar una respuesta más detallada del error
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
  },
};
</script>
