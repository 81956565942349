<template>
  <div
    v-if="loaded"
    class="content-devx annual-budgets"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="gridDxAnnualBudgets"
      :data-source="annualBudgetsFormatted"
      :remote-operations="true"
      :column-min-width="90"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      :column-auto-width="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxAnnualBudgets"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        :buttons="editButtons"
        type="buttons"
        width="80"
        data-field="Actions"
      />
      <DxColumn
        data-field="year"
        name="year"
        :caption="$t('DxAnnualBudgets_year_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="version"
        name="version"
        :caption="$t('DxAnnualBudgets_version_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="status"
        name="status"
        :allow-header-filtering="true"
        :caption="$t('DxAnnualBudgets_status_caption')"
        alignment="center"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxDailyPlanning',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    CalendarInitEndDate,
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      keyName: 'annualBudgets',
      editButtons: [{
        icon: 'find',
        onClick: this.showAnnualBudgetVersion,
      },
      {
        icon: 'close',
        visible: this.setButtonCloseVisible,
        onClick: this.closeAnnualBudgetVersion,
      },
      {
        icon: 'copy',
        onClick: this.cloneAnnualBudgetVersion,
      }],
      annualBudgetsFormatted: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getMonthlyTasks(checkedInitEndDate.init, checkedInitEndDate.end);
      const annualBudgets = JSON.parse(xhr.response).data;
      for (let i = 0; i < annualBudgets.length; i += 1) {
        for (let j = 0; j < annualBudgets[i].version.length; j += 1) {
          this.annualBudgetsFormatted.push({
            idAnnualBudget: annualBudgets[i].id,
            year: annualBudgets[i].year,
            idAnnualBudgetVersion: annualBudgets[i].version[j].id,
            version: annualBudgets[i].version[j].name,
            status: annualBudgets[i].version[j].closed ? this.$t('Status_Closed') : this.$t('Status_reallyNot_Closed'),
          });
        }
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    // VER VERSIÓN DE UN BUDGET ANUAL
    showAnnualBudgetVersion(e) {
      this.setBadgedId(e.row.data.idAnnualBudget);
      this.setVersionId(e.row.data.idAnnualBudgetVersion);
      this.setAnnualBudgetVersion(e.row.data.version);
      if (e.row.data.status === this.$t('Status_Closed')) {
        this.setMonthlyPlanningEditable(false);
      } else {
        this.setMonthlyPlanningEditable(true);
      }
      this.$f7.views.main.router.navigate('/monthlyPlanning/', { reloadCurrent: true });
    },
    // CERRAR VERSIÓN DE UN BUDGET ANUAL
    async closeAnnualBudgetVersion(e) {
      this.$f7.preloader.show();
      try {
        await Api.closeBadget(e.row.data.idAnnualBudget);
      } catch (error) {
        console.log(error); // TODO: Visualizar correctamente este error. No incluir los 404
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/annualBudgets/', { reloadCurrent: true });
      }
    },
    // CLONAR VERSIÓN DE UN BUDGET ANUAL
    cloneAnnualBudgetVersion(e) {
      this.setAnnualBudgetId(e.row.data.idAnnualBudget);
      this.setAnnualBudgetVersionId(e.row.data.idAnnualBudgetVersion);
      this.$f7.views.main.router.navigate('/cloneAnnualBudget/', { reloadCurrent: true });
    },
    // CREACIÓN DEL BOTÓN -> CREAR NUEVO ANNUAL BUDGET
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxAnnualBudgets_createAnnualBudget_text'),
          onClick: () => {
            this.$f7.views.main.router.navigate('/createAnnualBudget/', { reloadCurrent: true });
          },
        },
      });
    },
    setButtonCloseVisible(e) {
      if (e.row.data.status === this.$t('Status_Closed')) {
        return false;
      }
      return true;
    },
    // EXPORTAR EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('AnnualBudgets');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'AnnualBudgets.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('TaskBadget', ['setBadgedId', 'setVersionId', 'setTaskId', 'setActualTaskBadget']),
    ...mapActions('PlanningManager', ['setAnnualBudgetId', 'setAnnualBudgetVersionId', 'setMonthlyPlanningEditable', 'setAnnualBudgetVersion']),
  },
};
</script>
<style scoped>
.total-group-count{
    font-size: 16px;
    font-weight: 700;
    border: 1px solid rgba(133,103,103,0.2);
    margin-right: 10px;
    padding: 8px 12px;
    border-radius: 5px;
    background: #FBF7EF;
    color: #470000;
}
.total-group-count span {
  font-weight: 400;
  font-size: 13px;
}
</style>
