<template>
  <f7-page>
    <navbar :text="$t('PlanningComponent_monthlyPlanning')" />
    <DxMonthlyPlanning />
  </f7-page>
</template>

<script>

import DxMonthlyPlanning from '../../components/DxMonthlyPlanning.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxMonthlyPlanning,
    navbar,
  },
};
</script>

<style>

</style>
