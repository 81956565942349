<template>
  <f7-page
    name="accessPage"
    class="backgroundPage"
    @page:beforein="beforein"
  >
    <navbar
      :elements="elements"
      :list="items"
      :back="true"
    />
    <div class="content-location">
      <div class="row no-gap">
        <!-- Each "cell" has col-[width in percents] class -->
        <div class="col-80">
          <div class="item-title-row title-block">
            <div class="item-title item-title-block text-color-black">
              {{ currentLocationCode }}
            </div>
          </div>
        </div>
        <div class="col-20 text-align-center">
          <div
            class="change-location-btn"
            @click="openLocationSelectionSheet"
          >
            <img
              alt="Cambiar de ubicación"
              :src="images.edit"
            >
          </div>
        </div>
      </div>
    </div>
    <searcher />
    <LocationPicker
      :opened="!currentLocationCode || wantsToChangeLocation"
      @locationSelected="setCurrentLocation"
    />
    <SheetExport
      :propssheetexport="propsSheetExport"
    />
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import LocationPicker from '../../components/LocationPicker.vue';
import Searcher from '../../components/presence/Searcher.vue';

import navbar from '../../components/NavBar.vue';
import ImageEdit from '../../static/img/ic_edit-btn-round.svg';
import ImageUser from '../../static/img/ic_user.svg';
import IconScan from '../../static/img/icon-scan.svg';
import IconQr from '../../static/img/ic_qr.svg';
import Api from '../../services/Api';
import SheetExport from '../../components/SheetExport.vue';
import EventBus from '../../js/event-bus';

export default {
  components: {
    navbar,
    LocationPicker,
    Searcher,
    SheetExport,
  },
  props: {
    type: { type: String, default: '' },
    fullName: { type: String, default: '' },
    timeStamp: { type: Number, default: Number },
    startDate: { type: [Number, String], default: Number },
    date: { type: Number, default: Number },
    avatar: { type: String, default: '' },
  },
  data() {
    const items = [];
    const total = 0;
    return {
      navbar,
      elements: [
        {
          iconEnable: true,
          textEnable: false,
          bagdeEnable: false,
          info: { urlImage: 'icon-nfc', urlDestiny: '/nfcAccessPage/' },
        },
      ],
      sheetOpened: false,
      wantsToChangeLocation: false,
      iconPresence: '../../static/icons/presence.svg',
      imgError: false,
      items,
      total,
      vlData: {
        items: [],
      },
      images: {
        edit: ImageEdit,
        user: ImageUser,
        IconScan,
        IconQr,
      },
      location: [],
      locations: [],
      availableLocations: [],
      locationSelectedCode: null,
      propsSheetExport: {},
    };
  },
  computed: {
    ...mapState('AccessControl', ['currentLocationCode']),
    ...mapState('authentication', ['loggedUser']),
    locationSelectedTitle() {
      if (!this.locationSelectedCode) {
        return 'Buscar';
      }
      return this.locations.filter(
        (item) => item.code === this.locationSelectedCode,
      )[0].name;
    },
  },
  methods: {
    beforein() {
      const { farms } = JSON.parse(this.loggedUser);
      if (farms !== undefined && farms.length === 1) {
        this.setCurrentLocation(farms[0]);
      }
      this.updateList();
      EventBus.$on('exportCSV', this.openSheetExport);
    },
    async updateList() {
      this.$f7.preloader.show();
      const xhr = await Api.getAccess();
      const xhrFormat = JSON.parse(xhr.response).data;
      this.items.length = 0;
      for (let i = 0; i < xhrFormat.length; i += 1) {
        this.items.push({
          fullname: `${xhrFormat[i].name} ${xhrFormat[i].surname}`,
          code: xhrFormat[i].code,
          event: xhrFormat[i].event,
          driver: this.getProperty(xhrFormat[i].properties, 'drive'),
        });
      }
      this.$f7.preloader.hide();
      this.vlData.items = [];
      this.vlData.items = this.items;
      EventBus.$emit('load-workers', this.items);
    },
    getProperty(properties, property) {
      for (let i = 0; i < properties.length; i += 1) {
        if (properties[i].key === property) {
          return properties[i].value;
        }
      }
      return '';
    },
    openSheetExport(propsSheetExport) {
      this.propsSheetExport = propsSheetExport;
      this.$f7.sheet.open('.demo-sheet-swipe-to-close');
    },
    goToPresence() {
      const self = this;
      const router = self.$f7router;
      router.navigate('/nfcAccessPage/', { reloadCurrent: true });
    },
    setImgError() {
      this.imgError = true;
    },
    searchAll(query, items) {
      const found = [];
      for (let i = 0; i < items.length; i += 1) {
        if (
          items[i].fullname.toLowerCase().indexOf(query.toLowerCase()) >= 0
          || query.trim() === ''
        ) found.push(i);
      }
      return found; // return array with mathced indexes
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    async refreshAvailableLocations() {
      this.$f7.preloader.show();
      // const allowedLocations = await db.allowedLocations.toArray();
      // const allowedIds = allowedLocations.map((location) => location.id);
      // const locations = this.location;
      // this.availableLocations = locations.filter((location) => location.type === 'Garita');
      this.$f7.preloader.hide();
      this.wantsToChangeLocation = false;
    },
    setCurrentLocation(location) {
      this.selectLocation(location.code);
      this.wantsToChangeLocation = false;
      this.location = location;
      // this.refreshCurrentLocationInfo();
      // this.refreshEventsForCurrentLocation();
      this.currentPage = 0;
    },

    refreshCurrentLocationInfo() {
      if (!this.currentLocation) return Promise.resolve();
      this.currentLocation = this.location;
      return this.currentLocation;
    },
    openLocationSelectionSheet() {
      this.refreshAvailableLocations().then(() => {
        this.wantsToChangeLocation = true;
      });
    },
    ...mapActions('AccessControl', ['selectLocation', 'scan']),
  },
};
</script>

<style scoped>

.contenedor {
  background: #f4f5f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
}
.content-location {
  display: block;
  border: 1px solid #e5eef7;
}

.change-location-btn {
  padding: 10px 5px 6px;
}

.item-title-block {
  padding: 15px 20px;
}

.content-location {
  display: block;
  border: 1px solid #e5eef7;
}

.header-space {
  position: relative;
  padding: 20px 35px;
}
.number-icon {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: #556080;
  padding: 0 10px 0 10px;
}
.list .item-title {
  padding: 10px 0 5px;
  margin-right: 10px;
  font-size: 18px;
  letter-spacing: -0.04em;
}
.item-title-row {
  align-items: center;
}
.list .item-subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #9b9b9b;
  padding: 2px 0px 0px;
}
.hour {
  font-weight: 500;
  font-size: 26px;
  letter-spacing: -0.04em;
  line-height: 1.2;
  color: #556080;
  margin: 2px;
}

.date {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #c4c4c4;
}
.icon-status {
  padding: 22px 5px 0;
}
.item-link.item-content {
  padding-left: 0;
}
.smart-border {
  left: 1.67%;
  right: 1.67%;
  top: 14.56%;
  bottom: 81.22%;

  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  margin-left: 9px;
  width: 97%;
}

a .i.icon.material-icon{
  color: #ffffff;
}
div.item-inner.infoUser::after{
  display: none;
}
.backgroundPage{
  background-color: white;
}
.virtual-list ul{
  height: auto !important;
}
.block-title-medium {
      color: #9B9B9B !important;
}
</style>
