var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx content-dx-annualBudget-form"},[_c('DxForm',{attrs:{"id":"formAnnualBudget"}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxGroupItem',{attrs:{"css-class":"content-combo","col-span":1}},[_c('DxSimpleItem',{attrs:{"name":"version","data-field":_vm.$t('DxAnnualBudgetForm_version_caption'),"editor-options":{
            value: _vm.actualAnnualBudgetVersion,
            onValueChanged: _vm.onChangeVersion,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('AnnualBudget_version_validation')}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-span":1,"css-class":"content-combo"}},[_c('DxSimpleItem',{attrs:{"name":"year","data-field":_vm.$t('DxAnnualBudgetForm_year_caption'),"editor-type":"dxDateBox","editor-options":{
            value: _vm.actualAnnualBudgetYear,
            onValueChanged: _vm.onChangeYear,
            maxZoomLevel:'decade',
            minZoomLevel:'decade',
            zoomLevel: 'decade',
            displayFormat: 'yyyy',
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('AnnualBudget_year_validation')}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }