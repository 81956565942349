<template>
  <div class="content-devx">
    <f7-block class="content__linesgraph">
      <DxChart
        id="chart"
        :data-source="dataSource"
        @legend-click="onLegendClick"
      >
        <DxCommonSeriesSettings
          argument-field="date"
        />
        <DxSeries
          pane="top"
          :color="measureColor"
          :value-field="`measured${weatherElement}`"
          :name="`${$t('DxWeatherElementLinesGraph_average')} ${weatherElement}, ${weatherElementMeasure}`"
        >
          <DxValueErrorBar
            :line-width="lineWidth"
            :opacity="0"
            low-value-field="minTemp"
            high-value-field="maxTemp"
          />
        </DxSeries>
        <DxSeries
          pane="top"
          :value-field="`forecasted${weatherElement}`"
          type="line"
          :color="forecastColor"
          :name="`${$t('DxWeatherElementLinesGraph_averageForecasted')} ${weatherElement}, ${weatherElementMeasure}`"
        >
          <DxValueErrorBar
            :value="3"
            :line-width="lineWidth"
            type="fixed"
          />
        </DxSeries>

        <DxPane name="top" />
        <DxArgumentAxis
          discrete-axis-division-mode="crossLabels"
          argument-type="datetime"
        >
          <DxLabel
            display-mode="stagger"
            format="d MMM - hh:mm"
          />
        </DxArgumentAxis>
        <DxValueAxis pane="top">
          <DxGrid :visible="true" />
          <DxTitle :text="`${weatherElement}, ${weatherElementMeasure}`" />
        </DxValueAxis>
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
          item-text-position="top"
        />
        <DxExport :enabled="true" />
        <DxTooltip
          :enabled="true"
          :z-index="999999"
          :customize-tooltip="customizeTooltip"
        />
        <DxTitle :text="`${weatherElement}`" />
        <DxScrollBar :visible="true" />
        <DxZoomAndPan argument-axis="both" />
      </DxChart>
    </f7-block>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import DxChart, {
  DxCommonSeriesSettings,
  DxSeries,
  DxPane,
  DxScrollBar,
  DxArgumentAxis,
  DxValueAxis,
  DxTitle,
  DxValueErrorBar,
  DxLegend,
  DxLabel,
  DxTooltip,
  DxZoomAndPan,
  DxExport,
  DxGrid,
} from 'devextreme-vue/chart';

export default {
  components: {
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxPane,
    DxValueErrorBar,
    DxGrid,
    DxArgumentAxis,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxLabel,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxExport,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    weatherElement: {
      type: String,
      default: 'Temperature',
    },
  },
  data() {
    return {
      currentDate: moment(new Date()).format('YYYY-MM-DD'),
      temperatureMeasureColor: '#ce181e',
      temperatureForecastColor: '#ff4c4c',
      humidityMeasureColor: '#7b38e0',
      humidityForecastColor: '#b280ff',
    };
  },
  computed: {
    ...mapState('weather', [
      'selectedFarmID',
      'startDate',
      'endDate',
      'selectedMeasuringFrequency',
      'selectedRangeOfDays',
    ]),
    ...mapState('Reporting', ['selectedFarmList']),
    lineWidth() {
      return this.selectedMeasuringFrequency === 'DAILY' ? 1 : 0;
    },
    weatherElementMeasure() {
      return this.weatherElement === 'Temperature' ? 'ºC' : '%';
    },
    axisLabelFormat() {
      return this.selectedRangeOfDays === 1 ? 'shortDateShortTime' : 'monthAndDay';
    },
    measureColor() {
      return this.weatherElement === 'Temperature'
        ? this.temperatureMeasureColor
        : this.humidityMeasureColor;
    },
    forecastColor() {
      return this.weatherElement === 'Temperature'
        ? this.temperatureForecastColor
        : this.humidityForecastColor;
    },
  },
  methods: {
    ...mapActions('weather', [
      'fetchMeasuredWeatherData',
      'fetchWeatherForecastData',
      'setSelectedFarmID',
    ]),
    customizeTooltip(pointInfo) {
      const seriesName = pointInfo.seriesName.split(',')[0];
      const seriesMeasureUnit = pointInfo.seriesName.split(',')[1].trim();
      const date = moment(pointInfo.argument).format('ddd D MMM');

      if (this.selectedMeasuringFrequency === 'DAILY'
          && pointInfo.lowErrorValue
          && pointInfo.highErrorValue
      ) {
        return {
          text: `${seriesName} on ${date}: 
          ${pointInfo.value.toFixed(2)}${seriesMeasureUnit} 
          (range: ${pointInfo.lowErrorValue.toFixed(2)}${seriesMeasureUnit} - ${pointInfo.highErrorValue.toFixed(2)}${seriesMeasureUnit})`,
        };
      }
      return {
        text: `${seriesName} on ${date}: 
          ${pointInfo.value.toFixed(2)}${seriesMeasureUnit}`,
      };
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
#chart-demo {
    height: 700px;
}
#chart {
    width: 100%;
    min-width: 600px;
    height: 500px;
    margin: 0 0 15px;
}
</style>
