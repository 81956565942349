<template>
  <DxDataGrid
    id="machinary-tab"
    ref="binsTable"
    :data-source="masterDetailData.data.binsDetail"
    :column-min-width="70"
    :allow-column-reordering="true"
    :show-borders="true"
    :show-column-lines="true"
    :show-row-lines="false"
    :row-alternation-enabled="false"
  >
    <DxColumn
      data-field="code"
      name="code"
      :caption="$t('DxBins_dxColumn_Code')"
    />
    <DxColumn
      data-field="date"
      name="date"
      :caption="$t('DxBins_dxColumn_CreationDate')"
    />
    <DxColumn
      data-field="name"
      name="name"
      :caption="$t('DxBins_dxColumn_NameBins')"
    />
  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      locationsTree: [],
      nameTools: [],
      nameProducts: [],
    };
  },
  methods: {
  },

};
</script>
<style scoped>
.backgroundColor{
  background-color:white;
}
</style>
