<template>
  <div
    class="content-devx main-dx-create-employee"
  >
    <div id="form-container">
      <f7-row>
        <f7-col>
          <DxEmployeeForm
            :data-form="{activityName: activitiesAvailables, taskName: tasksAvailables, workerTypes, itemTypes, performanceTypes }"
            :working-area-disabled="workingAreaDisabled"
          />
        </f7-col>
      </f7-row>
    </div>
  </div>
</template>
<script>
import DxEmployeeForm from './DxEmployeeForm.vue';

export default {
  name: 'DxEmployeeComponent',
  components: {
    DxEmployeeForm,
  },
  props: {
    workingAreaDisabled: { type: Boolean, default: false },
  },
  data() {
    return {
      locationsTree: [],
      activitiesAvailables: [],
      tasksAvailables: [],
      itemTypes: [],
      workerTypes: [],
      performanceTypes: [],
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.long-title h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
