<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning"
    >
      <DxDataGrid
        id="attachmentMachinaryTable"
        ref="attachmentMachinaryTable"
        :data-source="attachments.values"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :column-auto-width="true"
        :row-alternation-enabled="true"
        @row-inserted="onInsertedRow"
        @row-updated="onChangeRow"
        @row-removed="onRemovedRow"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxAttachmentMachinery"
        />
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="updating"
          :allow-deleting="deleting"
          :allow-adding="adding"
          mode="row"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="type"
          alignment="left"
          :caption="$t('DxAttachment_dxColumn_TypeAttachment')"
          :allow-editing="false"
          :allow-header-filtering="true"
        />
        <DxColumn
          data-field="vehicle"
          name="vehicle"
          alignment="left"
          :caption="$t('DxAttachmentMachinary_dxColumn_Machinary')"
          :set-cell-value="actualActiveTractor"
          :allow-header-filtering="false"
        >
          <DxLookup
            :data-source="machineryFormatted"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="attachment"
          name="attachment"
          alignment="left"
          :caption="$t('DxAttachmentMachinary_dxColumn_Attachment')"
          :set-cell-value="actualActiveAttachment"
          :allow-header-filtering="false"
        >
          <DxLookup
            :data-source="filteredAttachment"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
  DxHeaderFilter,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxAttachmentMachinaryTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxHeaderFilter,
    DxStateStoring,
  },
  props: {
    attachmentList: { type: Array, default: () => [] },
    itemsPlanning: { type: Array, default: () => [] },
    machinaryAvailables: { type: Array, default: () => [] },
    itemsWorkOrderSelected: { type: Array, default: () => [] },
    updating: { type: Boolean, default: () => true },
    deleting: { type: Boolean, default: () => false },
    adding: { type: Boolean, default: () => false },
  },
  data() {
    return {
      allAttachments: [],
      allTractors: [],
      actualActiveAttachment(rowData, value) {
        this.defaultSetCellValue(rowData, value);
      },
      actualActiveTractor(rowData, value) {
        rowData.attachment = null;
        this.defaultSetCellValue(rowData, value);
      },
    };
  },
  computed: {
    ...mapState('PlanningManager', ['attachments', 'machinarySelected', 'actualWorkOrder']),
    machineryFormatted() {
      const machineryFormatted = [];
      for (const machinery of this.actualWorkOrder.itemsDriversWorkOrder) {
        if (machinery.item !== '') {
          machineryFormatted.push({ id: machinery.item, name: this.getMachineryNameById(machinery.item) });
        }
      }
      return machineryFormatted;
    },
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    const arrAttachments = [];
    for (let i = 0; i < this.itemsPlanning.length; i += 1) {
      if (this.itemsPlanning[i].count !== undefined) {
        for (let j = 0; j < this.itemsPlanning[i].count; j += 1) {
          arrAttachments.push({ type: this.itemsPlanning[i].type });
        }
      } else {
        let vehiculo = '';
        if (this.itemsPlanning[i].vehicle !== null) {
          vehiculo = this.itemsPlanning[i].vehicle;
        }
        // Modo editar workorder
        arrAttachments.push({
          type: `${this.itemsPlanning[i].attachment_data.item.subfamily}`,
          vehicle: `${vehiculo}`,
          attachment: `${this.itemsPlanning[i].attachment}`,
        });
      }
    }
    this.setAttachments(arrAttachments);
    // Seteamos un listado con todos los aperos, desde el que filtraremos
    for (let i = 0; i < this.attachmentList.length; i += 1) {
      const idSubfamily = this.attachmentList[i].id;
      for (let j = 0; j < this.attachmentList[i].items.length; j += 1) {
        const item = this.attachmentList[i].items[j];
        const idItem = item.id;
        let nameItem = item.description;
        for (let k = 0; k < item.properties.length; k += 1) {
          if (item.properties[k].key === 'internal_code') nameItem = `${nameItem} ${item.properties[k].value}`;
        }
        this.allAttachments.push({ id: idItem, name: nameItem, idParent: idSubfamily });
      }
    }
  },
  methods: {
    // SET DATA SOURCE TO PRODUCTS VUEX
    onChangeRow() {
      const arrayItems = this.$refs.attachmentMachinaryTable.instance.getDataSource().items();
      const newItems = this.attachments;
      newItems.values = [];
      for (let i = 0; i < arrayItems.length; i += 1) {
        newItems.values.push({ type: arrayItems[i].type, attachment: arrayItems[i].attachment, vehicle: arrayItems[i].vehicle });
      }
      this.setAttachments(newItems.values);
    },
    onInsertedRow() {
      const newItems = this.attachments;
      this.setAttachments(newItems.values);
    },
    filteredAttachment(e) {
      try {
        if (e.values !== undefined) {
          const listaFiltrado = this.allAttachments.filter(
            (elemento) => elemento.idParent === e.values[0],
          );
          return listaFiltrado;
        }
        return this.allAttachments;
      } catch (error) {
        return [{ id: '', name: '' }];
      }
    },
    onRemovedRow() {
      const newItems = this.attachments;
      this.setAttachments(newItems.values);
    },
    getMachineryNameById(machineryId) {
      for (const typeMachinery of this.machinaryAvailables) {
        for (const machineryAvailable of typeMachinery.items) {
          if (machineryAvailable.id === machineryId) {
            return this.setFullNameMachinery(machineryAvailable);
          }
        }
      }
      return '';
    },
    setFullNameMachinery(machinery) {
      let fullName = machinery.description;
      for (const property of machinery.properties) {
        if (property.key === 'internal_code') {
          fullName += ` ${property.value}`;
        }
      }
      return fullName;
    },
    ...mapActions('PlanningManager', ['setAttachments']),
  },
};
</script>
