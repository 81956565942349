<template>
  <f7-page>
    <navbar :text="$t('CreateMonthlyPlanning_Title')" />
    <DxMonthlyComponent
      class="content-planning"
    />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToMonthlyPlanning"
      >
        {{ $t("CreatePlanning_ButtonCancel_caption") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        @click="createMonthlyPlanning"
      >
        {{ $t('CreatePlanning_ButtonSave_caption') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DxMonthlyComponent from '../../components/Planning/DxMonthlyComponent.vue';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';

export default {
  name: 'CreateMonthlyPlanning',
  components: {
    DxMonthlyComponent,
    navbar,
  },
  computed: {
    ...mapState('TaskBadget', ['badgedId', 'versionId', 'actualTaskBadget']),
    ...mapState('PlanningManager', ['actualLocationsSelected', 'workingArea']),
    ...mapState('Prescription', ['prescription']),
  },
  beforeDestroy() {
    this.setNewPrescription();
  },
  beforeMount() {
    this.setNewPrescription();
  },
  methods: {
    goToMonthlyPlanning() {
      this.$f7.views.main.router.navigate('/monthlyPlanning/', { reloadCurrent: true });
    },
    async createMonthlyPlanning() {
      if (this.actualTaskBadget.totalWorkingDays === 0) {
        this.$f7.dialog.alert(this.$t('CreateMonthlyPlanning_ValidationTotalWorkingDays'));
      } else if (this.actualTaskBadget.dateProgrammed.length !== this.actualTaskBadget.totalWorkingDays) {
        this.$f7.dialog.alert(this.$t('CreateMonthlyPlanning_ValidationDate'));
      } else if (this.actualLocationsSelected.length === 0) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationActualLocationsSelected'));
      } else if (this.actualTaskBadget.numberMachineryDays > this.actualTaskBadget.totalWorkingDays) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationTotalWorkingDays'));
      } else {
        this.$f7.preloader.show();
        try {
          const newPlanning = {
            task_code: this.actualTaskBadget.taskCode,
            locations: this.actualLocationsSelected,
            goal: this.actualTaskBadget.goal,
            goal_unit: this.actualTaskBadget.goalUnit,
            dates: this.actualTaskBadget.dateProgrammed,
            workers: {
              temporary: this.actualTaskBadget.workers.TEMPORARY,
              permanent: this.actualTaskBadget.workers.PERMANENT,
              contratist: this.actualTaskBadget.workers.CONTRATIST,
              performance: this.actualTaskBadget.performanceWorker,
              performance_unit: this.actualTaskBadget.goalUnit,
            },
            machineries: this.actualTaskBadget.machinery,
            attachments: this.actualTaskBadget.attachments,
            tools: this.actualTaskBadget.tools,
            area_effective: this.workingArea,
            variety: this.actualTaskBadget.variety,
          };
          if (this.actualTaskBadget.prescription !== null) {
            newPlanning.prescription = this.actualTaskBadget.prescription.id;
            newPlanning.products = this.actualTaskBadget.products;
            this.checkProducts(newPlanning.products);
          }
          this.checkTools(newPlanning.tools);
          await Api.addTaskMonthlyPlanning(newPlanning, this.badgedId, this.versionId);
          this.goToMonthlyPlanning(); // TODO: Habría que mostrar alguna notificación de que se creó correctamente
        } catch (reason) {
          this.$f7.dialog.alert(reason); // TODO: Hay que dar una respuesta más detallada del error
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    checkTools(tools) {
      for (const tool of tools) {
        if (tool.consumption === null) {
          tool.consumption = 0;
        }
      }
    },
    checkProducts(products) {
      for (const product of products) {
        if (typeof product.dosisUt === 'undefined' || product.dosisUt.toLowerCase().includes('kg/') || product.dosisUt.toLowerCase().includes('l/')) {
          product.solution = 0;
          product.solutionUt = '';
        }
      }
    },
    ...mapActions('Prescription', ['setNewPrescription']),
  },
};
</script>
