<template>
  <f7-page>
    <navbar :text="$t('DxListTaskGroupedActivivty_NavBarName')" />
    <DxListTaskAgrupedActivity />
  </f7-page>
</template>

<script>

import navbar from '../../components/NavBar.vue';
import DxListTaskAgrupedActivity from '../../components/reporting/DxListTaskAgrupedActivity.vue';

export default {
  components: {
    navbar,
    DxListTaskAgrupedActivity,
  },
};
</script>

<style>

</style>
