<template>
  <f7-page class="page-effort">
    <navbar :text="$t('PrecisionFarming_navbar_title')" />
    <iframe
      id="inlineFrameExample"
      title="GIS MAP"
      width="100%"
      height="100%"
      :src="`${url}static/ferrero-gis/${farm}/main/index.html`"
    />
  </f7-page>
</template>

<script>

import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';

export default {
  components: {
    navbar,
  },
  data() {
    return {
      url: '/',
      farm: Api.getAgriFarm(),
    };
  },
  mounted() {
    this.url = this.$f7.views.main.router.params.url;
  },
};
</script>

<style>

</style>
