<template>
  <DxForm
    id="form"
    :col-count="1"
  >
    <DxSimpleItem
      v-for="(item, index) in locationsFormated"
      :key="index"
      :data-field="item.name[0]"
      :editor-options="{
        items: nameSupervisors,
        searchEnabled: true,
        searchTimeout: searchTimeoutOption,
        minSearchLength: minSearchLengthOption,
        showDataBeforeSearch: showDataBeforeSearchOption,
        onItemClick: onSupervisorChange,
        value: supervisorSelected(item), // TODO: Esto habrá que cambiarlo cuando los supervisores empiecen a venir con localizaciones posibles
        readOnly: readOnly,
      }"
      editor-type="dxSelectBox"
      :caption="$t('SelectSupervisor_supervisor_caption')"
    >
    <!--:message="$t('DxSelectSupervisor')"message="At least one supervisor is needed" -->
    <DxRequiredRule message="At least one supervisor is needed" />
    </DxSimpleItem>
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions } from 'vuex';

export default {
  name: 'DxSelectSupervisor',
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
  },
  props: {
    locations: { type: Array, default: () => [] },
    locationsTree: { type: Array, default: () => [] },
    supervisorsAvailables: { type: Array, default: () => [] },
    supervisorsLocation: { type: Array, default: () => [] },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      locationsFormated: [],
      finalLevelLocation: [],
      supervisorsLocationAux: [],
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      nameSupervisors: [],
      create: false,
      actualSupervisor: '',

    };
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    if (this.supervisorsLocation.length === 0) {
      this.actualSupervisor = this.supervisorsAvailables[0].id;
    } else {
      this.actualSupervisor = this.supervisorsLocation[0].supervisor;
    }
    for (let i = 0; i < this.locations.length; i += 1) {
      this.locationsFormated.push({ bd_id: this.locations[i], name: this.getFinalLevelLocation(this.locations[i]) });
      this.supervisorsLocationAux.push({ supervisor: this.actualSupervisor, location: this.locations[i] });
    }
    for (let i = 0; i < this.supervisorsAvailables.length; i += 1) {
      this.nameSupervisors.push(`${this.supervisorsAvailables[i].name} ${this.supervisorsAvailables[i].surname}`);
    }
    this.setSupervisorsLocation(this.supervisorsLocationAux);
  },
  methods: {
    getFinalLevelLocation(locations) {
      this.finalLevelLocation = [];
      for (const item of this.locationsTree) {
        this.viewChildren(item, locations);
      }
      return this.finalLevelLocation;
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (typeof locations === 'string') {
          if (locations === item.bd_id) {
            this.finalLevelLocation.push(item.name);
          }
        } else {
          for (const location of locations) {
            if (location === item.bd_id) {
              this.finalLevelLocation.push(item.name);
            }
          }
        }
      }
    },
    onSupervisorChange(e) {
      const idLocationUpdated = this.getCodeLocation(this.locationsFormated, e.element.getElementsByTagName('input')[0].getAttribute('name'));
      const idSupervisorUpdated = this.getCodeSupervisor(this.supervisorsAvailables, e.itemData);
      this.supervisorsLocationAux.forEach((element, index) => {
        if (element.location === idLocationUpdated) {
          this.supervisorsLocationAux[index].supervisor = idSupervisorUpdated;
        }
      });
      if (this.supervisorsLocationAux.length > 0) this.setSupervisorsLocation(this.supervisorsLocationAux);
    },
    getCodeLocation(items, name) {
      for (const item of items) {
        if (item.name[0] === name) {
          return item.bd_id;
        }
      }
      return '';
    },
    getCodeSupervisor(items, fullname) {
      for (const item of items) {
        if (`${item.name} ${item.surname}` === fullname) {
          return item.id;
        }
      }
      return '';
    },
    supervisorSelected(item) {
      for (const supervisorLocation of this.supervisorsLocation) {
        if (supervisorLocation.location === item.bd_id) {
          for (let i = 0; i < this.supervisorsAvailables.length; i += 1) {
            if (this.supervisorsAvailables[i].id === supervisorLocation.supervisor) {
              return `${this.supervisorsAvailables[i].name} ${this.supervisorsAvailables[i].surname}`;
            }
          }
        }
      }
      return `${this.supervisorsAvailables[0].name} ${this.supervisorsAvailables[0].surname}`;
    },
    ...mapActions('PlanningManager', ['setSupervisorsLocation']),
  },
};
</script>
<style>
.status-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 8px;
}
.middle {
  vertical-align: middle;
}
</style>
