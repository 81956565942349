import moment from 'moment';
import Api from '../../services/Api';

export default {
  namespaced: true,
  state: {
    binsLocationName: '',
    binsName: '',
    locationTree: [],
    binsCurrentContent: [],
    isLocationDisabled: false,
    binsCurrentContentToMove: [],
    selectedLocationToMove: '',
    nameBatch: '',
    dateBatch: '',
    idBatch: '',
    selectedBins: [],
    allBins: [],
    lotBins: [],
    binData: [],
    binToEdit: {},
    selectedFarmToSend: '',
    idBinToSend: '',
    levelZeroLocationsToSendBin: [],
    binsContentVarieties: [],
    binsContentCategories: [],
    binsContentClassifications: [],
    selectedTask: {},
    availableTasks: [],
    binsContentsForPlanning: [],
    binsContentPlanned: [],
    binsContentPlannedToEdit: [],
  },
  getters: {
    levelZeroLocationsNamesToSendBin(state) {
      if (state.levelZeroLocationsToSendBin.length > 0) {
        const levelZeroLocationsNamesToSendBin = state.levelZeroLocationsToSendBin.map(
          (location) => location.name,
        );
        return levelZeroLocationsNamesToSendBin;
      }
      return [];
    },
    usersLevelZeroNames(_state, getters, _rootState, rootGetters) {
      const userFarmsIds = rootGetters['authentication/userFarmsIds'];
      return userFarmsIds.map((farmId) => getters.locationNameByLocationId(farmId));
    },
    locationTreeByName(state) {
      if (state.binsLocationName !== '') {
        const locationTreeByName = state.locationTree.filter(
          (location) => location.name === state.binsLocationName,
        );
        return locationTreeByName;
      }
      return state.locationTree;
    },
    locationIdBySelectedLevelZeroLocation(state) {
      const locationArray = state.locationTree.filter(
        (location) => location.name === state.binsLocationName,
      );
      return locationArray[0].bd_id;
    },
    locationIdByLocationName: (_state, getters) => (name) => {
      const locationArray = getters.formattedTree.filter(
        (location) => location.name === name,
      );
      if (locationArray.length > 0) {
        return locationArray[0].bd_id;
      }
      return '';
    },
    newBinToCreate(state, getters) {
      const content = state.binsCurrentContent.map((formContent) => ({
        location: getters.locationIdByLocationName(formContent.location_level_last),
        variety: getters.getVarietyCodeByName(formContent.variety),
        category: getters.getCategoryCodeByName(formContent.category),
        class_type: getters.getClassificationCodeByName(formContent.classification),
        count: formContent.plantsCount,
      }));

      const newBin = {
        name: state.binsName,
        farm: getters.locationIdBySelectedLevelZeroLocation,
        date: moment().format('YYYY-MM-DD'),
        content,
      };

      return newBin;
    },
    locationNameByLocationId: (state) => (id) => {
      const locationArray = state.locationTree.filter(
        (location) => location.bd_id === id,
      );
      if (locationArray[0]) {
        return locationArray[0].name;
      }
      return '';
    },
    availableCategoryNames(state) {
      if (state.binsContentCategories.length > 0) {
        return state.binsContentCategories.map((category) => category.name);
      }
      return [];
    },
    getCategoryCodeByName: (state) => (categoryName) => {
      const categoryCodes = state.binsContentCategories
        .filter(
          (category) => category.name === categoryName,
        )
        .map(
          (category) => category.code,
        );
      if (categoryCodes.length > 0) {
        return categoryCodes[0];
      }
      return '';
    },
    getCategoryNameByCode: (state) => (categoryCode) => {
      const categoryNames = state.binsContentCategories
        .filter(
          (category) => category.code === categoryCode,
        )
        .map(
          (category) => category.name,
        );
      if (categoryNames.length > 0) {
        return categoryNames[0];
      }
      return '';
    },
    availableVarietyNames(state) {
      if (state.binsContentVarieties.length > 0) {
        return state.binsContentVarieties.map((variety) => variety.name);
      }
      return [];
    },
    getVarietyCodeByName: (state) => (varietyName) => {
      const varietyCodes = state.binsContentVarieties
        .filter(
          (variety) => variety.name === varietyName,
        )
        .map(
          (variety) => variety.code,
        );
      if (varietyCodes.length > 0) {
        return varietyCodes[0];
      }
      return '';
    },
    getVarietyNameByCode: (state) => (varietyCode) => {
      const varietyNames = state.binsContentVarieties
        .filter(
          (variety) => variety.code === varietyCode,
        )
        .map(
          (variety) => variety.name,
        );
      if (varietyNames.length > 0) {
        return varietyNames[0];
      }
      return '';
    },
    availableClassificationNames(state) {
      if (state.binsContentClassifications.length > 0) {
        return state.binsContentClassifications.map((variety) => variety.name);
      }
      return [];
    },
    getClassificationCodeByName: (state) => (classificationName) => {
      const classificationCodes = state.binsContentClassifications
        .filter(
          (classification) => classification.name === classificationName,
        )
        .map(
          (classification) => classification.code,
        );
      if (classificationCodes.length > 0) {
        return classificationCodes[0];
      }
      return '';
    },
    getClassificationNameByCode: (state) => (classificationCode) => {
      const classificationNames = state.binsContentClassifications
        .filter(
          (classification) => classification.code === classificationCode,
        )
        .map(
          (classification) => classification.name,
        );
      if (classificationNames.length > 0) {
        return classificationNames[0];
      }
      return '';
    },
    getMergedBins(state) {
      const mergedBins = [...state.lotBins, ...state.allBins];
      return mergedBins;
    },
    newBatchToCreate(state, getters) {
      const fechaCreacion = state.dateBatch;
      const lote = {
        name: state.nameBatch,
        creation: moment(fechaCreacion).format('YYYY-MM-DD'),
        bins: getters.getIdBins,
      };
      return lote;
    },
    getIdBins(state) {
      const idsBins = [];
      for (let i = 0; i < state.selectedBins.length; i += 1) {
        idsBins.push(state.selectedBins[i].id);
      }
      return idsBins;
    },
    formattedTree(state) {
      const locationsTreeFormatted = [];
      const setLocationTreeFormat = (item) => {
        if (item.level > 3) {
          locationsTreeFormatted.push({
            location: `${item.name} -${item.properties.variety_code}- ${item.area.toFixed(2)} ha`,
            bd_id: item.bd_id,
            ID: item.ID,
            HEAD_ID: item.HEAD_ID,
            children: item.children,
            level: item.level,
            name: item.name,
            area: item.area,
          });
        } else {
          locationsTreeFormatted.push({
            location: `${item.name} - ${item.area.toFixed(2)} ha`,
            bd_id: item.bd_id,
            ID: item.ID,
            HEAD_ID: item.HEAD_ID,
            children: item.children,
            level: item.level,
            name: item.name,
            area: item.area,
          });
        }
      };
      const recursiveAddChildren = (children) => {
        for (let i = 0; i < children.length; i += 1) {
          setLocationTreeFormat(children[i]);
          recursiveAddChildren(children[i].children);
        }
      };

      for (const item of state.locationTree) {
        setLocationTreeFormat(item);
        recursiveAddChildren(item.children);
      }
      return locationsTreeFormatted;
    },
    binsInfo(state) {
      if (state.binToEdit) {
        return {
          name: state.binToEdit.name,
          farm: state.binToEdit.origin,
          date: state.binToEdit.date,
        };
      }
      return {};
    },
    binsContent(state) {
      const binsContent = [];
      if (state.binToEdit.binsDetail) {
        state.binToEdit.binsDetail.map((content) => binsContent.push({
          category: content.category,
          classification: content.class_type,
          location_level_0: state.binToEdit.origin,
          location_level_last: content.locationName,
          plantsCount: content.count,
          variety: content.variety,
          code: content.code,
          parent_code: content.parent,
        }));
      }
      return binsContent;
    },
    binsId(state) {
      return state.binToEdit.bd_id;
    },
    binsContentCodesToMove(state) {
      return state.binsCurrentContentToMove.map((element) => element.code);
    },
    binsContentMovesToSave(state, getters) {
      return {
        elements: getters.binsContentCodesToMove,
        location: state.selectedLocationToMove[0],
      };
    },
    editedBinToSave(state, getters) {
      const content = state.binsCurrentContent.map((element) => {
        if (element.location_level_last !== '') {
          return {
            location: getters.locationIdByLocationName(element.location_level_last),
            variety: getters.getVarietyCodeByName(element.variety),
            category: getters.getCategoryCodeByName(element.category),
            class_type: getters.getClassificationCodeByName(element.classification),
            count: element.plantsCount,

          };
        }
        return {
          location: '',
          variety: getters.getVarietyCodeByName(element.variety),
          category: getters.getCategoryCodeByName(element.category),
          class_type: getters.getClassificationCodeByName(element.classification),
          count: element.plantsCount,

        };
      });

      return {
        name: state.binsName,
        farm: getters.locationIdByLocationName(state.binsLocationName),
        content,
      };
    },
    reclassifiedBinToSave(state, getters) {
      const content = state.binsCurrentContent.map((element) => {
        if (!element.code) {
          const parentContent = state.binsCurrentContent.filter(
            (parentElement) => parentElement.code === element.parent_code,
          );
          const elementFulfilled = {
            variety: getters.getVarietyCodeByName(parentContent[0].variety),
            category: getters.getCategoryCodeByName(element.category),
            class_type: getters.getClassificationCodeByName(element.classification),
            count: element.plantsCount,
            location: '',
            parent: element.parent_code,
          };

          if (parentContent[0].location_level_last && parentContent[0].location_level_last !== '') {
            elementFulfilled.location = getters
              .locationIdByLocationName(parentContent[0].location_level_last);
          }

          return elementFulfilled;
        }

        if (element.location_level_last !== '') {
          return {
            location: getters.locationIdByLocationName(element.location_level_last),
            variety: getters.getVarietyCodeByName(element.variety),
            category: getters.getCategoryCodeByName(element.category),
            class_type: getters.getClassificationCodeByName(element.classification),
            count: element.plantsCount,
            code: element.code,
            parent: element.parent_code,
          };
        }
        return {
          location: '',
          variety: getters.getVarietyCodeByName(element.variety),
          category: getters.getCategoryCodeByName(element.category),
          class_type: getters.getClassificationCodeByName(element.classification),
          count: element.plantsCount,
          code: element.code,
          parent: element.parent_code,
        };
      });
      return {
        farm: getters.locationIdByLocationName(state.binsLocationName),
        content,
      };
    },
    showBinsPlanningForm(state) {
      if (state.selectedTask && state.selectedTask.properties) {
        return (state.selectedTask.properties.find((property) => property.key === 'nursery').value === 'True');
      }
      return false;
    },
    binsAvailableForPlanning(state) {
      if (state.binsContentsForPlanning.length > 0) {
        const binsAvailableForPlanning = state.binsContentsForPlanning.map((element) => {
          element.bin.nameCode = `${element.bin.name} - ${element.bin.code}`;
          return element.bin;
        });
        return binsAvailableForPlanning;
      }
      return [];
    },
    varietiesAvailableForPlanning(state, getters) {
      if (state.binsContentsForPlanning.length > 0) {
        const varietiesAvailableForPlanning = state.binsContentsForPlanning
          .map((element) => element.varieties
            .map((variety) => {
              variety.binId = element.bin.bin_id;
              return {
                bin_id: variety.binId,
                name: getters.getVarietyNameByCode(variety.code),
              };
            }));
        return varietiesAvailableForPlanning.flat(2);
      }
      return [];
    },
    categoriesAvailableForPlanning(state, getters) {
      if (state.binsContentsForPlanning.length > 0) {
        const categoriesAvailableForPlanning = state.binsContentsForPlanning
          .map((element) => element.varieties
            .map((variety) => variety.categories
              .map((category) => {
                category.binId = element.bin.bin_id;
                return {
                  bin_id: category.binId,
                  variety: getters.getVarietyNameByCode(variety.code),
                  name: getters.getCategoryNameByCode(category.code),
                };
              })));
        return categoriesAvailableForPlanning.flat(3);
      }
      return [];
    },
    classificationsAvailableForPlanning(state, getters) {
      if (state.binsContentsForPlanning.length > 0) {
        const classificationsAvailableForPlanning = state.binsContentsForPlanning
          .map((element) => element.varieties
            .map((variety) => variety.categories
              .map((category) => category.class_types
                .map((classification) => {
                  classification.binId = element.bin.bin_id;
                  return {
                    bin_id: classification.binId,
                    variety: getters.getVarietyNameByCode(variety.code),
                    category: getters.getCategoryNameByCode(category.code),
                    name: getters.getClassificationNameByCode(classification.code),
                  };
                }))));
        return classificationsAvailableForPlanning.flat(4);
      }
      return [];
    },
    contentsAvailableForPlanning(state, getters) {
      if (state.binsContentsForPlanning.length > 0) {
        const contentsAvailableForPlanning = state.binsContentsForPlanning
          .map((element) => element.varieties
            .map((variety) => variety.categories
              .map((category) => category.class_types
                .map((classification) => classification.data
                  .map((content) => ({
                    bin_id: content.bin.bin_id,
                    variety: getters.getVarietyNameByCode(content.variety),
                    category: getters.getCategoryNameByCode(content.category),
                    classification: getters.getClassificationNameByCode(content.class_type),
                    name: content.code,
                    plants_count: content.count,
                  }))))));
        return contentsAvailableForPlanning.flat(5);
      }
      return [];
    },
    filteredContentsForPlanning: (_state, getters) => (binId, varietyName, categoryName, classificationName) => {
      const filteredContentsForPlanning = getters.contentsAvailableForPlanning
        .filter(
          (content) => content.bin_id === binId
            && content.variety === varietyName
            && content.category === categoryName
            && content.classification === classificationName,
        );
      return filteredContentsForPlanning;
    },
    nameBatch: (state) => state.nameBatch,
    dateBatch: (state) => state.dateBatch,
    selectedBins: (state) => state.selectedBins,
    lotBins: (state) => state.lotBins,
    allBins: (state) => state.allBins,
    idBatch: (state) => state.idBatch,
    binData: (state) => state.binData,
    binToEdit: (state) => state.binToEdit,
    binsName: (state) => state.binsName,
    binsCurrentContent: (state) => state.binsCurrentContent,
    selectedLocationToMove: (state) => state.selectedLocationToMove,
    binsCurrentContentToMove: (state) => state.binsCurrentContentToMove,
  },
  mutations: {
    SET_BINS_LOCATION_NAME(state, payload) {
      state.binsLocationName = payload;
    },
    SET_LOCATIONS_TREE(state, payload) {
      state.locationTree = payload;
    },
    DISABLE_BINS_LOCATION_SELECT(state) {
      state.isLocationDisabled = true;
    },
    ENABLE_BINS_LOCATION_SELECT(state) {
      state.isLocationDisabled = false;
    },
    SET_BINS_NAME(state, payload) {
      state.binsName = payload;
    },
    SET_BINS_CURRENT_CONTENT(state, payload) {
      state.binsCurrentContent = payload;
    },
    SET_BINS_CURRENT_CONTENT_TO_MOVE(state, payload) {
      state.binsCurrentContentToMove = payload;
    },
    SET_SELECTED_LOCATION_TO_MOVE(state, payload) {
      state.selectedLocationToMove = payload;
    },
    SET_LEVEL_ZERO_LOCATIONS_TO_SEND_BIN(state, payload) {
      state.levelZeroLocationsToSendBin = payload;
    },
    SET_BINS_CONTENT_VARIETIES(state, payload) {
      state.binsContentVarieties = payload;
    },
    SET_BINS_CONTENT_CLASSIFICATIONS(state, payload) {
      state.binsContentClassifications = payload;
    },
    SET_BINS_CONTENT_CATEGORIES(state, payload) {
      state.binsContentCategories = payload;
    },
    SET_SELECTED_TASK(state, payload) {
      state.selectedTask = payload;
    },
    SET_BINS_CONTENT_PLANNED(state, payload) {
      state.binsContentPlanned = payload;
    },
    setNameBatch(state, value) {
      state.nameBatch = value;
    },
    setDateBatch(state, value) {
      state.dateBatch = value;
    },
    setSelectedBins(state, value) {
      state.selectedBins = value;
    },
    setLotBins(state, value) {
      state.lotBins = value;
    },
    setAllBins(state, value) {
      state.allBins = value;
    },
    setIdBatch(state, value) {
      state.idBatch = value;
    },
    setBinData(state, value) {
      state.binData = value;
    },
    setBinToEdit(state, value) {
      state.binToEdit = value;
    },
    setIdBinToSend(state, value) {
      state.idBinToSend = value;
    },
    setSelectedFarmToSend(state, value) {
      state.selectedFarmToSend = value;
    },
    SET_BINS_CONTENTS_FOR_PLANNING(state, value) {
      state.binsContentsForPlanning = value;
    },
    SET_BINS_CONTENT_PLANNED_TO_EDIT(state, value) {
      state.binsContentPlannedToEdit = value;
    },
    SET_AVAILABLE_TASKS(state, value) {
      state.availableTasks = value;
    },
  },
  actions: {
    updateBinsLocationName({ commit }, payload) {
      commit('SET_BINS_LOCATION_NAME', payload);
    },
    updateBinsName({ commit }, payload) {
      commit('SET_BINS_NAME', payload);
    },
    updateBinsCurrentContent({ commit }, payload) {
      commit('SET_BINS_CURRENT_CONTENT', payload);
    },
    updateBinsCurrentContentToMove({ commit }, payload) {
      commit('SET_BINS_CURRENT_CONTENT_TO_MOVE', payload);
    },
    updateSelectedLocationToMove({ commit }, payload) {
      commit('SET_SELECTED_LOCATION_TO_MOVE', payload);
    },
    disableBinsLocationSelect({ commit }) {
      commit('DISABLE_BINS_LOCATION_SELECT');
    },
    resetStates({ commit }) {
      commit('SET_BINS_LOCATION_NAME', '');
      commit('SET_BINS_NAME', '');
      commit('ENABLE_BINS_LOCATION_SELECT');
      commit('SET_BINS_CURRENT_CONTENT', []);
      commit('setNameBatch', '');
      commit('setDateBatch', '');
      commit('setSelectedBins', []);
      commit('setLotBins', []);
      commit('setAllBins', []);
      commit('setIdBatch', '');
      commit('setBinToEdit', {});
      commit('SET_SELECTED_LOCATION_TO_MOVE', '');
      commit('SET_BINS_CURRENT_CONTENT_TO_MOVE', []);
      commit('SET_BINS_CONTENTS_FOR_PLANNING', []);
      commit('SET_SELECTED_TASK', {});
      commit('SET_BINS_CONTENT_PLANNED', []);
    },
    updateSelectedTask({ commit }, payload) {
      commit('SET_SELECTED_TASK', payload);
    },
    updateBinsContentPlanned({ commit }, payload) {
      commit('SET_BINS_CONTENT_PLANNED', payload);
    },
    setBinsContentPlannedToEdit({ commit }, payload) {
      commit('SET_BINS_CONTENT_PLANNED_TO_EDIT', payload);
    },
    updateAvailableTasks({ commit }, payload) {
      commit('SET_AVAILABLE_TASKS', payload);
    },
    setTaskSelectedByTaskCode({ commit, state }, payload) {
      commit('SET_SELECTED_TASK', state.availableTasks.find((task) => task.code === payload));
    },
    async fetchLocationTree({ commit }) {
      try {
        const locationTree = await Api.getLocationsTree();
        commit('SET_LOCATIONS_TREE', JSON.parse(locationTree.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentFamilies({ dispatch }) {
      try {
        await dispatch('fetchBinsContentVarieties');
        await dispatch('fetchBinsContentClassifications');
        await dispatch('fetchBinsContentCategories');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentVarieties({ commit }) {
      try {
        const binsContentVarieties = await Api.getBinsContentVarieties();
        commit('SET_BINS_CONTENT_VARIETIES', JSON.parse(binsContentVarieties.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentClassifications({ commit }) {
      try {
        const binsContentClassifications = await Api.getBinsContentClassifications();
        commit('SET_BINS_CONTENT_CLASSIFICATIONS', JSON.parse(binsContentClassifications.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentCategories({ commit }) {
      try {
        const binsContentCategories = await Api.getBinsContentCategories();
        commit('SET_BINS_CONTENT_CATEGORIES', JSON.parse(binsContentCategories.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsCreateLote({ commit }) {
      try {
        const binsCreateLote = await Api.getBinsCreateLote();
        const xhs = JSON.parse(binsCreateLote.response);
        commit('setAllBins', xhs.data);
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async createNewBin({ dispatch, getters }) {
      try {
        await Api.createBin(getters.newBinToCreate);
        dispatch('resetStates');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async saveEditedBin({ dispatch, getters }) {
      try {
        await Api.editBin(getters.binsId, getters.editedBinToSave);
        dispatch('resetStates');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async saveReclassifiedBin({ dispatch, getters }) {
      try {
        await Api.reclassifyBin(getters.binsId, getters.reclassifiedBinToSave);
        dispatch('resetStates');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async saveBinsContentMoves({ dispatch, getters }) {
      try {
        await Api.moveBinsContent(getters.binsId, getters.binsContentMovesToSave);
        dispatch('resetStates');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async createBatch({ state, dispatch, getters }) {
      try {
        if (state.idBatch === '') {
          await Api.createBatch(getters.newBatchToCreate);
        } else {
          await Api.updateBatch(state.idBatch, getters.newBatchToCreate);
        }
        dispatch('resetStates');
        Promise.resolve();
      } catch (e) {
        Promise.reject(e);
      }
    },
    async fetchChangeBinStatus({ commit }, payload) {
      let response = '';
      try {
        response = await Api.changeBinState(payload.id, payload);
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async fetchDeleteBin({ commit }, payload) {
      let response = '';
      try {
        response = await Api.deleteBin(payload);
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async fetchDeleteBatch({ commit }, payload) {
      let response = '';
      try {
        response = await Api.deleteBatch(payload);
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async fetchSendBin({ state, getters }) {
      let response = '';
      try {
        const idFarm = getters.locationIdByLocationName(state.selectedFarmToSend);
        const idBin = state.idBinToSend;
        const payload = { farm: idFarm };
        response = await Api.sendBin(idBin, payload);
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async getLevelZeroLocationsToSendBin({ commit }) {
      try {
        const levelZeroLocationsToSendBin = await Api.getFarmsToSend();
        commit('SET_LEVEL_ZERO_LOCATIONS_TO_SEND_BIN', JSON.parse(levelZeroLocationsToSendBin.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async getBinsContentsFilteredByLocation({ state, commit }) {
      try {
        const binsContentsForPlanning = await Api.getBinsContentsForPlanning(state.selectedLocationToMove);
        commit('SET_BINS_CONTENTS_FOR_PLANNING', JSON.parse(binsContentsForPlanning.response));
      } catch (error) {
        Promise.reject(error);
      }
    },
    setNameBatch(context, value) {
      context.commit('setNameBatch', value);
    },
    setDateBatch(context, value) {
      context.commit('setDateBatch', value);
    },
    setSelectedBins(context, value) {
      context.commit('setSelectedBins', value);
    },
    setLotBins(context, value) {
      context.commit('setLotBins', value);
    },
    setAllBins(context, value) {
      context.commit('setAllBins', value);
    },
    setIdBatch(context, value) {
      context.commit('setIdBatch', value);
    },
    setBinData(context, value) {
      context.commit('setBinData', value);
    },
    setBinToEdit(context, value) {
      context.commit('setBinToEdit', value);
    },
    setIdBinToSend(context, value) {
      context.commit('setIdBinToSend', value);
    },
    setSelectedFarmToSend(context, value) {
      context.commit('setSelectedFarmToSend', value);
    },
  },
};
