export default {
  havePermission(permission) {
    if (typeof permission !== 'undefined') {
      return permission.value;
    }
    return false;
  },
  getProperty(properties, key) {
    for (const property of properties) {
      if (property.key === key) {
        return property.value;
      }
    }
    return '';
  },
};
