<template>
  <f7-page
    class="main-formpage"
    @page:beforein="fetchFormData"
    @page:beforeout="resetBinStates"
  >
    <Navbar
      :text="currentTitle"
    />
    <f7-row>
      <f7-col
        width="100"
      >
        <DxBinsInfoForm
          :bins-info="binsInfo"
          :editing="isEditing"
          :reclassifying="isReclassifying"
          :moving="isMoving"
        />
      </f7-col>
    </f7-row>
    <div class="content-devx main-dx-binsinfoform no-shadow">
      <f7-row>
        <f7-col>
          <f7-block-title
            class="no-padding-top"
          >
            {{ $t('Bins_content_title') }}
          </f7-block-title>
        </f7-col>
      </f7-row>
      <template v-if="isReclassifying">
        <f7-row v-if="loaded">
          <f7-col>
            <DxBinsContentGrid
              :bins-content="binsContent"
              :reclassifying="isReclassifying"
            />
          </f7-col>
        </f7-row>
      </template>
      <template v-else>
        <f7-row v-if="loaded">
          <f7-col
            width="25"
            medium="30"
          >
            <DxTreePlanning
              :show-grouped="false"
              :show-summary="false"
              :max-height="300"
              :locations-tree="locationTreeByName"
            />
          </f7-col>
          <template v-if="isMoving">
            <f7-col
              width="75"
              medium="70"
            >
              <DxBinsContentGridMove
                :bins-content="binsContent"
              />
            </f7-col>
          </template>
          <template v-else>
            <f7-col
              width="75"
              medium="70"
            >
              <DxBinsContentGrid
                :bins-content="binsContent"
                :editing="isEditing"
              />
            </f7-col>
          </template>
        </f7-row>
      </template>
    </div>
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right button button-raised text-color-grey"
        type="cancel"
        styling-mode="contained"
        name="ButtonCancel_caption"
        @click="navigateTo"
      >
        {{ $t('ButtonCancel_caption') }}
      </f7-button>

      <f7-button
        class="dx-btn-success button button-raised"
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        :disabled="disableButton"
        @click="saveAction"
      >
        {{ textButtonSave }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import DxBinsInfoForm from '../../components/greenhouse/DxBinsInfoForm.vue';
import DxBinsContentGrid from '../../components/greenhouse/DxBinsContentGrid.vue';
import DxBinsContentGridMove from '../../components/greenhouse/DxBinsContentGridMove.vue';
import Navbar from '../../components/NavBar.vue';
import DxTreePlanning from '../../components/DxTreePlanning.vue';

export default {
  name: 'BinsFormPage',
  components: {
    DxBinsInfoForm,
    DxTreePlanning,
    DxBinsContentGrid,
    DxBinsContentGridMove,
    Navbar,
  },
  
  data() {
    return {
      defaultLocationsTree: [],
      locationsTreeFiltered: [],
      loaded: false,
      urlOfPreviousRoute: '',
    };
  },
  
  computed: {
    ...mapState('greenhouse',
      [
        'locationTree',
      ]),
    ...mapGetters('greenhouse',
      [
        'locationTreeByName',
        'locationIdBySelectedLevelZeroLocation',
        'binsInfo',
        'binsContent',
        'binsName',
        'binsCurrentContent',
        'binsCurrentContentToMove',
        'selectedLocationToMove',
      ]),
    currentURL() {
      return this.$f7route.url;
    },
    currentTitle() {
      let currentTitle = '';
      switch (this.currentURL) {
        case '/bins/create/':
          currentTitle = this.$t('Bins_create_navbar_title');
          break;
        case '/bins/edit/':
          currentTitle = this.$t('Bins_edit_navbar_title');
          break;
        case '/bins/reclassify/':
          currentTitle = this.$t('Bins_reclassify_navbar_title');
          break;
        case '/bins/move/':
          currentTitle = this.$t('Bins_move_navbar_title');
          break;
        default:
          currentTitle = this.$t('Bins_create_navbar_title');
          break;
      }
      return currentTitle;
    },
    isEditing() {
      return this.currentURL === '/bins/edit/';
    },
    isReclassifying() {
      return this.currentURL === '/bins/reclassify/';
    },
    isMoving() {
      return this.currentURL === '/bins/move/';
    },
    saveAction() {
      if (this.isEditing) {
        return this.editBin;
      }
      if (this.isReclassifying) {
        return this.reclassifyBin;
      }
      if (this.isMoving) {
        return this.moveBinsContent;
      }
      return this.createBin;
    },
    disableButton() {
      if (this.isEditing || this.currentURL === '/bins/create/') {
        if (this.binsCurrentContent.length === 0 || this.binsName === '') {
          return true;
        }
        return false;
      }
      if (this.isMoving) {
        if (this.selectedLocationToMove.length === 0 || this.binsCurrentContentToMove.length === 0) {
          return true;
        }
        return false;
      }
      return false;
    },
    textButtonSave() {
      let currentTitle = '';
      switch (this.currentURL) {
        case '/bins/create/':
          currentTitle = this.$t('CreateBin_ButtonSave_caption');
          break;
        case '/bins/edit/':
          currentTitle = this.$t('EditBin_ButtonSave_caption');
          break;
        case '/bins/reclassify/':
          currentTitle = this.$t('ReclassifyBin_ButtonSave_caption');
          break;
        case '/bins/move/':
          currentTitle = this.$t('MoveBin_ButtonSave_caption');
          break;
        default:
          currentTitle = this.$t('CreateBin_ButtonSave_caption');
          break;
      }
      return currentTitle;
    },
  },
  methods: {
    ...mapActions('greenhouse',
      [
        'fetchBinsContentFamilies',
        'fetchLocationTree',
        'resetStates',
        'createNewBin',
        'saveEditedBin',
        'saveReclassifiedBin',
        'saveBinsContentMoves',
      ]),
    ...mapActions('authentication', ['setActualMenuSelected']),
    async fetchFormData() {
      this.urlOfPreviousRoute = this.$f7router.previousRoute.url;
      this.$f7.preloader.show();
      try {
        await this.fetchBinsContentFamilies();
        await this.fetchLocationTree();
        this.defaultLocationsTree = this.locationTree;
      } catch (error) {
        this.$f7.dialog.alert(error);
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    async createBin() {
      this.$f7.preloader.show();
      try {
        await this.createNewBin();
        this.resetStates();
        this.setActualMenuSelected('/bins/');
        this.$f7router.navigate('/bins/', { reloadCurrent: true });
      } catch (error) {
        this.$f7.dialog.alert(error);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    resetBinStates() {
      this.resetStates();
    },
    async editBin() {
      this.$f7.preloader.show();
      try {
        await this.saveEditedBin();
        this.resetStates();
        this.setActualMenuSelected('/bins/');
        this.$f7router.navigate('/bins/', { reloadCurrent: true });
      } catch (error) {
        this.$f7.dialog.alert(error);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async reclassifyBin() {
      this.$f7.preloader.show();
      try {
        await this.saveReclassifiedBin();
        this.resetStates();
        if (this.urlOfPreviousRoute === '/stock-management/reclassification/') {
          this.setActualMenuSelected('/stock-management/reclassification/');
          this.$f7router.navigate('/stock-management/reclassification/', { reloadCurrent: true });
        } else {
          this.setActualMenuSelected('/bins/');
          this.$f7router.navigate('/bins/', { reloadCurrent: true });
        }
      } catch (error) {
        this.$f7.dialog.alert(error);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async moveBinsContent() {
      this.$f7.preloader.show();
      try {
        await this.saveBinsContentMoves();
        this.resetStates();
        this.setActualMenuSelected('/bins/');
        this.$f7router.navigate('/bins/', { reloadCurrent: true });
      } catch (error) {
        this.$f7.dialog.alert(error);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    navigateTo() {
      if (this.urlOfPreviousRoute === '/stock-management/reclassification/') {
        this.setActualMenuSelected('/stock-management/reclassification/');
        this.$f7router.navigate('/stock-management/reclassification/', { reloadCurrent: true });
      } else {
        this.setActualMenuSelected('/bins/');
        this.$f7router.navigate('/bins/', { reloadCurrent: true });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ha-card {
    padding: 20px;
    margin-top: 30px;
}
</style>
