<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-products-collection"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      :id="`${keyName}Grid`"
      :data-source="productWarehouseFormated"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @row-updated="updatedProduct"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        :storage-key="`${keyName}`"
      />
      <DxExport
        :enabled="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxEditing
        :allow-updating="true"
        mode="row"
      />
      <DxColumn
        data-field="date"
        name="date"
        :caption="$t('DxProductWarehouse_column_date')"
        :allow-header-filtering="false"
        :allow-editing="false"
      />
      <DxColumn
        data-field="shortId"
        name="shortId"
        :caption="$t('DxProductWarehouse_shortId')"
        :allow-header-filtering="false"
        :allow-editing="false"
      />
      <DxColumn
        data-field="activityCode"
        name="activityCode"
        :caption="$t('DxProductWarehouse_column_activityCode')"
        :allow-header-filtering="true"
        alignment="right"
        :allow-editing="false"
      />
      <DxColumn
        data-field="activity"
        name="activity"
        :caption="$t('DxProductWarehouse_column_activity')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="taskCode"
        name="taskCode"
        :caption="$t('DxProductWarehouse_column_taskCode')"
        :allow-header-filtering="true"
        :allow-editing="false"
        alignment="right"
      />
      <DxColumn
        data-field="task"
        name="task"
        :caption="$t('DxProductWarehouse_column_task')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="productName"
        name="productName"
        :caption="$t('DxProductWarehouse_column_productName')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="supervisor"
        name="brasupervisornd"
        :caption="$t('DxProductWarehouse_column_supervisor')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="quantity"
        name="productWarehouse"
        :caption="$t('DxProductWarehouse_column_productWarehouse')"
        :allow-header-filtering="false"
        data-type="number"
        alignment="right"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        data-field="warehouse.cost_center"
        name="cost_center"
        :caption="$t('DxProductWarehouse_column_cost_center')"
      >
        <DxLookup
          :data-source="costCentersAvailables"
          display-expr="name"
          value-expr="code"
        />
        <DxRequiredRule :message="$t('DxProductWarehouse_column_cost_center_required')" />
      </DxColumn>
      <DxColumn
        data-field="warehouse.warehouse"
        name="warehouse"
        :caption="$t('DxProductWarehouse_column_warehouse')"
      >
        <DxLookup
          :data-source="warehousesAvailables"
          display-expr="name"
          value-expr="code"
        />
        <DxRequiredRule :message="$t('DxProductWarehouse_column_warehouse_required')" />
      </DxColumn>
      <DxColumn
        v-if="keyName === 'productCollection'"
        data-field="statusCollection"
        name="statusCollection"
        :caption="$t('DxProductWarehouse_column_statusCollection')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        v-else
        data-field="statusReturned"
        name="statusReturned"
        :caption="$t('DxProductWarehouse_column_statusCollection')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxEditing,
  DxLookup,
  DxRequiredRule,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import { DxRangeRule } from 'devextreme-vue/validator';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxProductWarehouse',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    CalendarInitEndDate,
    DxEditing,
    DxRangeRule,
    DxLookup,
    DxRequiredRule,
  },
  props: {
    keyName: { type: String, default: '' },
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      productWarehouseFormated: [],
      supervisorsAvailables: [],
      costCentersAvailables: [],
      warehousesAvailables: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.supervisorsAvailables = await this.getSupervisors();
      this.costCentersAvailables = await this.getCostCenters();
      this.warehousesAvailables = await this.getWarehouses();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getDailyTasks(true, [], [], checkedInitEndDate.init, checkedInitEndDate.end, false, true);
      const dailyTasks = JSON.parse(xhr.response);
      for (let i = 0; i < dailyTasks.detail.length; i += 1) {
        for (let j = 0; j < dailyTasks.detail[i].tasks.length; j += 1) {
          if (dailyTasks.detail[i].status === 'COMPLETED') {
            for (let k = 0; k < dailyTasks.detail[i].tasks[j].product_performance.length; k += 1) {
              this.productWarehouseFormated.push({
                date: moment(dailyTasks.detail[i].tasks[j].date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                shortId: dailyTasks.detail[i].tasks[j].short_id,
                ID: dailyTasks.detail[i].tasks[j].id,
                activityCode: dailyTasks.detail[i].tasks[j].activity_code,
                activity: dailyTasks.detail[i].tasks[j].activity,
                taskCode: dailyTasks.detail[i].tasks[j].task_code,
                task: dailyTasks.detail[i].tasks[j].task,
                productName: this.getProductNameById(dailyTasks.detail[i].tasks[j].product_performance[k].item_id, dailyTasks.detail[i].tasks[j].products),
                productId: dailyTasks.detail[i].tasks[j].product_performance[k].item_id,
                supervisor: this.getNameSupervisorById(dailyTasks.detail[i].tasks[j].responsible),
                quantity: this.keyName === 'productCollection' ? dailyTasks.detail[i].tasks[j].product_performance[k].qty_collected : dailyTasks.detail[i].tasks[j].product_performance[k].qty_task,
                warehouse: this.keyName === 'productCollection'
                  ? {
                    warehouse: dailyTasks.detail[i].tasks[j].product_performance[k].warehouse.warehouse_out,
                    cost_center: dailyTasks.detail[i].tasks[j].product_performance[k].warehouse.cost_center_out,
                  }
                  : {
                    warehouse: dailyTasks.detail[i].tasks[j].product_performance[k].warehouse.warehouse_in,
                    cost_center: dailyTasks.detail[i].tasks[j].product_performance[k].warehouse.cost_center_in,
                  },
                statusCollection: dailyTasks.detail[i].tasks[j].product_performance[k].qty_collected_sent ? this.$t('DxProductWarehouse_column_statusConfirmed') : this.$t('DxProductWarehouse_column_statusNotConfirmed'),
                statusReturned: dailyTasks.detail[i].tasks[j].product_performance[k].qty_task_sent ? this.$t('DxProductWarehouse_column_statusConfirmed') : this.$t('DxProductWarehouse_column_statusNotConfirmed'),
              });
            }
          }
        }
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async updatedProduct(e) {
      const currentRow = e.data;
      const formatedRow = [];
      if (this.keyName === 'productCollection' && currentRow.statusCollection === this.$t('DxProductWarehouse_column_statusConfirmed')) {
        this.$f7.dialog.alert(this.$t('DxProductWarehouse_edit_validation'));
      } else if (this.keyName === 'returnedProduct' && currentRow.statusReturned === this.$t('DxProductWarehouse_column_statusConfirmed')) {
        this.$f7.dialog.alert(this.$t('DxProductWarehouse_edit_validation'));
      } else {
        this.$f7.preloader.show();
        try {
          formatedRow.push({
            ID: currentRow.id,
            product_id: currentRow.productId,
            amount: currentRow.quantity,
            cost_center: currentRow.warehouse.cost_center,
            warehouse: currentRow.warehouse.warehouse,
          });
          await Api.updateProductWarehouse(currentRow.ID, this.keyName === 'productCollection' ? { product_out: formatedRow } : { product_in: formatedRow });
        } catch (reason) {
          this.$f7.dialog.alert(reason); // TODO: Hay que dar una respuesta más detallada del error
        } finally {
          this.$f7.preloader.hide();
        }
      }
      this.$f7.views.main.router.navigate(`/${this.keyName}/`, { reloadCurrent: true });
    },
    // UTILITIES
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    async getCostCenters() {
      const xhr = await Api.getCostCenters();
      return JSON.parse(xhr.response).data;
    },
    async getWarehouses() {
      const xhr = await Api.getWarehouses();
      return JSON.parse(xhr.response).data;
    },
    getProductNameById(productId, products) {
      for (const product of products) {
        if (product.product.id === productId) {
          return product.product.description;
        }
      }
      return '';
    },
    getNameSupervisorById(supervisorId) {
      for (const supervisor of this.supervisorsAvailables) {
        if (supervisor.id === supervisorId) {
          return `${supervisor.name} ${supervisor.surname}`;
        }
      }
      return '';
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ProductWarehouse');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ProductWarehouse.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
