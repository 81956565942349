<template>
  <DxChart
    id="chart"
    :data-source="workerFormated"
    :title="this.$t('dxSideBySideBar_totalWorkerByActivity')"
    @pointClick="onPointClick"
    @legend-click="onLegendClick"
  >
    <DxCommonSeriesSettings
      argument-field="activity"
      type="bar"
      hover-mode="allArgumentPoints"
      selection-mode="allArgumentPoints"
    >
      <DxLabel :visible="true">
        <DxFormat
          :precision="0"
          type="fixedPoint"
        />
      </DxLabel>
    </DxCommonSeriesSettings>
    <DxValueAxis>
      <DxTitle :text="this.$t('dxSideBySideBar_Worker')" />
    </DxValueAxis>
    <DxSeries
      value-field="permanent"
      :name="this.$t('PERMANENT')"
    >
      <DxAggregation
        :enabled="true"
        method="sum"
      />
    </DxSeries>
    <DxSeries
      value-field="temporary"
      :name="this.$t('TEMPORARY')"
    >
      <DxAggregation
        :enabled="true"
        method="sum"
      />
    </DxSeries>
    <DxSeries
      value-field="contratist"
      :name="this.$t('CONTRATIST')"
    >
      <DxAggregation
        :enabled="true"
        method="sum"
      />
    </DxSeries>
    <DxLegend
      vertical-alignment="bottom"
      horizontal-alignment="center"
    />
    <DxArgumentAxis :aggregate-by-category="true" />
    <DxExport :enabled="true" />
    <DxScrollBar :visible="true" />
    <DxZoomAndPan argument-axis="both" />
  </DxChart>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLabel,
  DxFormat,
  DxLegend,
  DxExport,
  DxScrollBar,
  DxZoomAndPan,
  DxAggregation,
  DxArgumentAxis,
  DxValueAxis,
  DxTitle,
} from 'devextreme-vue/chart';
import { mapGetters } from 'vuex';
import Api from '../../services/Api';

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxLegend,
    DxExport,
    DxScrollBar,
    DxZoomAndPan,
    DxAggregation,
    DxArgumentAxis,
    DxValueAxis,
    DxTitle,
  },
  props: {
    reportingWorker: { type: Array, default: () => [] },
  },
  data() {
    return {
      workerFormated: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      for (const worker of this.reportingWorker) {
        let typeWorker = '';
        let totalHoursForMethod = '';
        let permanent = 0;
        let contratist = 0;
        let temporary = 0;
        for (let j = 0; j < worker.workers.length; j += 1) {
          typeWorker = worker.workers[j];
          switch (typeWorker.type) {
            case this.$t('PERMANENT'):
              permanent += 1;
              break;
            case this.$t('TEMPORARY'):
              temporary += 1;
              break;
            case this.$t('CONTRATIST'):
              contratist += 1;
              break;
            default:
              break;
          }
          totalHoursForMethod = worker.workers[j].working_time;
        }
        this.workerFormated.push({
          activity: worker.activity,
          permanent,
          contratist,
          temporary,
          totalHoursForMethod,
        });
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    onPointClick({ target }) {
      target.select();
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
  },
};
</script>
<style>
#chart {
    height: 440px;
}
</style>
