<template>
  <f7-page>
    <navbar :text="$t('planned_title')" />
    <DxPlannedVsReal />
  </f7-page>
</template>

<script>
import DxPlannedVsReal from '../../components/reporting/DxPlannedVsReal.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxPlannedVsReal,
    navbar,
  },
};
</script>

<style>

</style>