<template>
  <f7-page>
    <navbar :text="$t('FuelRegistration_title')" />
    <DxWarehouse />
  </f7-page>
</template>

<script>

import DxWarehouse from '../components/DxWarehouse.vue';
import navbar from '../components/NavBar.vue';

export default {
  components: {
    DxWarehouse,
    navbar,
  },
};
</script>

<style>

</style>
