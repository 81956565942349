<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-timeworked"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="worker-grid"
      :data-source="workerFormated"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxTimeWorked"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="20" />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="date"
        :caption="$t('DxWorker_date_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="workerName"
        :caption="$t('DxWorker_name_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="workerSurname"
        :caption="$t('DxWorker_workerSurname_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="idNumber"
        :caption="$t('DxWorker_idNumber_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="clockIn"
        :caption="$t('DxWorker_initDate_caption')"
        :allow-header-filtering="true"
        alignment="right"
      />
      <DxColumn
        data-field="clockOut"
        :caption="$t('DxWorker_endDate_caption')"
        :allow-header-filtering="true"
        alignment="right"
      />
      <DxColumn
        data-field="totalHoursForMethod"
        :caption="$t('DxWorker_totalHours_caption')"
        :allow-header-filtering="true"
        alignment="right"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxTimeWorked',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
  },
  data() {
    return {
      loaded: false,
      workerFormated: [],
      locationsTree: [],
      locationsTreeFormated: [],
      actualDate: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      keyName: 'timeWorked',
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getClockInOut(checkedInitEndDate.init, checkedInitEndDate.end);
      const reportingWorker = JSON.parse(xhr.response).data;
      for (let i = 0; i < reportingWorker.length; i += 1) {
        let workerName = '';
        let workerSurname = '';
        let idNumber = '';
        let clockOut = '';
        let clockIn = '';
        let totalHoursForMethod = '';
        workerName = `${reportingWorker[i].name}`;
        workerSurname = ` ${reportingWorker[i].surname}`;
        idNumber = reportingWorker[i].id_number;
        if (reportingWorker[i].clock_out !== null) {
          clockOut = reportingWorker[i].clock_out.split('.', 1);
        } else {
          clockOut = '';
        }
        clockIn = reportingWorker[i].clock_in.split('.', 1);
        totalHoursForMethod = reportingWorker[i].hours_worked;
        this.workerFormated.push({
          date: reportingWorker[i].date,
          workerName,
          workerSurname,
          idNumber,
          clockOut,
          clockIn,
          totalHoursForMethod,
        });
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ReportingTimeWorked');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReportingTimeWorked.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
