<template>
  <div>
    <f7-block>
      <div class="text-align-center">
        <img
          class="img_logo"
          :src="APP_LOGO"
          :alt="APPLICATION_NAME"
        >
      </div>
      <div
        name="txtDataAccess"
        class="label-text"
      >
        {{ $t("ChangePassword_txtDataAccess") }}
      </div>
      <f7-list form>
        <f7-list-input
          name="inpPassword"
          outline
          item-label
          type="email"
          :placeholder="$t('ChangePassword_inpEmail_placeholder')"
          clear-button
          max="255"
          required
          @input="email = $event.target.value"
        />
        <f7-list-input
          name="inpRepeatPassword"
          outline
          item-label
          type="email"
          :placeholder="$t('ChangePassword_inpRepeatEmail_placeholder')"
          clear-button
          max="255"
          required
          @input="email = $event.target.value"
        />
      </f7-list>
      <!-- Buttons -->
      <f7-block>
        <f7-row>
          <f7-col>
            <f7-button
              name="btnChangePassword"
              class="botonPrimary"
              col
              button
              button-large
              button-raised
              fill
              :disabled="btnDisable"
              @click="changePassword"
            >
              {{ $t("ChangePassword_btnChangePassword") }}
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-block>
  </div>
</template>

<script>

import { APPLICATION_NAME, APP_LOGO } from '../../js/constants';
import Api from '../../services/Api';

export default {
  name: 'ChangePasswordPage',
  data() {
    return {
      APPLICATION_NAME,
      APP_LOGO,
      email: '',
    };
  },
  computed: {
    btnDisable() {
      return !this.email;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
  },

  methods: {
    async changePassword() {
      const self = this;
      const router = self.$f7router;
      this.$f7.preloader.show();
      try {
        await Api.recoveryPassword({
          email: this.email,
        });
      } catch (reason) {
        this.$f7.dialog.alert(reason);
      }
      this.$f7.preloader.hide();
      router.navigate('/dashboard/', { reloadCurrent: true });
    },
    goBack() {
      const self = this;
      const router = self.$f7router;
      router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/accounts/recoveryPassword.scss";
</style>
