<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-daily-planning"
  >
    <DxDataGrid
      id="gridContainer"
      :data-source="detailPlanning"
      :remote-operations="true"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      key-expr="ID"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxPlannedVsReal"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="18" />
      <DxSearchPanel
        :visible="true"
        :width="400"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="Date"
        name="date"
        alignment="left"
        :width="300"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Activity"
        name="activity"
        alignment="left"
        :caption="$t('DxDailyPlanning_activity_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="activityCode"
        name="activityCode"
        :allow-header-filtering="false"
        :caption="$t('PlanningComponent_activitycode_caption')"
      />
      <DxColumn
        data-field="taskCode"
        name="taskCode"
        :allow-header-filtering="false"
        :caption="$t('PlanningComponent_taskCode_caption')"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :allow-header-filtering="false"
        alignment="left"
        :caption="$t('DxDailyPlanning_task_caption')"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        alignment="left"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_location_caption')"
      />
      <DxColumn
        data-field="goalFormated"
        name="goal"
        :allow-header-filtering="false"
        alignment="right"
        :caption="$t('DxMonthlyPlanning_goal_caption')"
      />
      <DxColumn
        data-field="realGoal"
        name="realGoal"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_realGoal_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="goalVsReal"
        name="goalVsReal"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_goalVsReal_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="cost"
        name="cost"
        :allow-header-filtering="false"
        :caption="`${$t('DxDailyPlanning_coste')} €`"
        alignment="right"
      />
      <DxColumn
        data-field="realCost"
        name="realCost"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_realCost')"
        alignment="right"
      />
      <DxColumn
        data-field="CostVsReal"
        name="CostVsReal"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_CostVsReal')"
        alignment="right"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import moment from 'moment';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import * as overlay from 'devextreme/ui/overlay';
import saveAs from 'file-saver';
import Api from '../../services/Api';

export default {
  name: 'DxDailyPlanning',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
  },
  data() {
    return {
      loaded: false,
      locationsTreeAux: [],
      detailPlanning: [],
      detailDailyTasks: [],
      finalLevelLocation: [],
      locationsTree: [],
      widthSearchPanel: 500,
      currentLevel2Name: '',
      level2Data: [],
      areaLevel2: 0,
      badgeId: '',
      versionId: '',
      permanent: 0,
      temporary: 0,
      contratist: 0,
      totalCount: 0,
      prueba: [],
    };
  },
  computed: {
    ...mapState('Prescription', ['prescription']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      this.locationsTree = await this.loadLocationsTree();
      let xhr = await Api.getMonthlyTasks(); // esta es la llamada para traer la sabana
      const { data } = JSON.parse(xhr.response);
      for (let i = 0; i < data.length; i += 1) {
        this.badgeId = data[i].id;
        for (let j = 0; j < data[i].version.length; j += 1) {
          this.versionId = data[i].version[j].id;
        }
      }
      const xhr2 = await Api.getMonthlyPlanning(this.badgeId, this.versionId);
      const planning = JSON.parse(xhr2.response);
      this.detailPlanning.length = 0;
      // esto es la estructura que hay que montar para mostrar la tabla MonthlyPlanning
      for (let j = 0; j < planning.task.length; j += 1) {
        this.locationsTreeAux = [...planning.task[j].locations];
        this.detailPlanning.push({
          badgeId: this.badgeId,
          versionId: this.versionId,
          ID: planning.task[j].id,
          Date: this.formattingDates(planning.task[j].dates),
          days: planning.task[j].days,
          Task: planning.task[j].task_name,
          taskName: planning.task[j].task,
          taskCode: planning.task[j].task_code,
          Activity: planning.task[j].activity,
          activityCode: planning.task[j].activity_code,
          goal: planning.task[j].goal,
          goalUnit: planning.task[j].goal_unit,
          locationLevel2: this.getLocationLevel2(),
          locations: planning.task[j].locations,
          temporary: planning.task[j].temporary,
          permanent: planning.task[j].permanent,
          contratist: planning.task[j].contratist,
          cost: parseFloat(planning.task[j].cost).toFixed(2),
          goalFormated: `${planning.task[j].goal} ${planning.task[j].goal_unit}`,
          realGoal: 0.00,
          goalVsReal: 0,
          realCost: 0.00,
          CostVsReal: 0,
        });
      }
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter['']);
      xhr = await Api.getDailyTasks(true, [], [], checkedInitEndDate.init, checkedInitEndDate.end, true);
      const dailyTasks = JSON.parse(xhr.response);
      this.detailDailyTasks.length = 0;
      for (let i = 0; i < dailyTasks.detail.length; i += 1) {
        for (let j = 0; j < dailyTasks.detail[i].tasks.length; j += 1) {
        //   const items = this.getItems(dailyTasks.detail[i].tasks[j].items_drivers);
          if (dailyTasks.detail[i].status === 'COMPLETED') {
            this.locationsTreeAux = [...dailyTasks.detail[i].tasks[j].location];
            this.detailDailyTasks.push({
              ID: dailyTasks.detail[i].tasks[j].id,
              Date: moment(dailyTasks.detail[i].tasks[j].date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
              Task: dailyTasks.detail[i].tasks[j].task,
              taskCode: dailyTasks.detail[i].tasks[j].task_code,
              Activity: dailyTasks.detail[i].tasks[j].activity,
              activityCode: dailyTasks.detail[i].tasks[j].activity_code,
              TaskStatus: dailyTasks.detail[i].tasks[j].status,
              Workers: dailyTasks.detail[i].tasks[j].workers,
              Attachments: dailyTasks.detail[i].tasks[j].attachments_vehicles,
              Tools: dailyTasks.detail[i].tasks[j].planner.tools,
              itemsTypes: dailyTasks.detail[i].tasks[j].planner.items,
              locationLevel2: this.getLocationLevel2(),
              Planner: dailyTasks.detail[i].tasks[j].planner,
              machinery: dailyTasks.detail[i].tasks[j].machinery_performance,
              products: dailyTasks.detail[i].tasks[j].products,
              productPerfor: dailyTasks.detail[i].tasks[j].product_performance,
              variety: dailyTasks.detail[i].tasks[j].variety,
            });
          }
        }
      }
      xhr = await Api.getAllCosts();
      const costs = JSON.parse(xhr.response).data;
      let time = 0;
      for (let i = 0; i < this.detailPlanning.length; i += 1) {
        this.totalPerformance = 0;
        this.totalCost = 0;
        for (let j = 0; j < this.detailPlanning[i].Date.length; j += 1) {
          for (let l = 0; l < this.detailPlanning[i].locationLevel2.length; l += 1) {
            for (let k = 0; k < this.detailDailyTasks.length; k += 1) {
              for (let n = 0; n < this.detailDailyTasks[k].locationLevel2.length; n += 1) {
                if (this.detailPlanning[i].Date[j] === this.detailDailyTasks[k].Date // esto quiiere decir que la fecha entra dentro del rango de fechas de la task de la sabana
                  && this.detailPlanning[i].taskCode === this.detailDailyTasks[k].taskCode // esto quiere decir que es la mima tarea
                  && this.detailDailyTasks[k].locationLevel2[n] === this.detailPlanning[i].locationLevel2[l]) {
                  for (let m = 0; m < this.detailDailyTasks[k].Workers.length; m += 1) { // entamos dentro de los empleados para calcular el goal y el coste
                    time = this.formattedHour(this.detailDailyTasks[k].Workers[m]);
                    this.totalPerformance += Number(this.detailDailyTasks[k].Workers[m].performance);
                    this.detailPlanning[i].realGoal = this.totalPerformance;
                    const typeWorker = this.detailDailyTasks[k].Workers[m].type;
                    time /= 60;
                    this.totalCost += Number(this.getCostByCode(costs, typeWorker)) * time;
                    this.detailPlanning[i].realCost = parseFloat(this.totalCost).toFixed(2);
                  }
                  for (let m = 0; m < this.detailDailyTasks[k].machinery.length; m += 1) {
                    const fuelConsumption = this.detailDailyTasks[k].machinery[m].fuel_consumption ? parseFloat(this.detailDailyTasks[k].machinery[m].fuel_consumption) : 0;
                    const fuelType = this.detailDailyTasks[k].machinery[m].fuel_type ? this.detailDailyTasks[k].machinery[m].fuel_type : 'GASOLINE';
                    this.totalCost += Number(this.getCostByCode(costs, fuelType)) * fuelConsumption;
                    this.detailPlanning[i].realCost = parseFloat(this.totalCost).toFixed(2);
                  }
                  for (let m = 0; m < this.detailDailyTasks[k].Tools.length; m += 1) {
                    const fuelConsumption = this.detailDailyTasks[k].Tools[m].fuel_consumption ? parseFloat(this.detailDailyTasks[k].Tools[m].fuel_consumption) : 0;
                    const fuelType = this.detailDailyTasks[k].Tools[m].fuel_type ? this.detailDailyTasks[k].Tools[m].fuel_type : 'GASOLINE';
                    this.totalCost += Number(this.getCostByCode(costs, fuelType)) * fuelConsumption;
                    this.detailPlanning[i].realCost = parseFloat(this.totalCost).toFixed(2);
                  }
                  for (let m = 0; m < this.detailDailyTasks[k].products.length; m += 1) {
                    const fuelConsumption = this.detailDailyTasks[k].productPerfor[m].qty_used ? parseFloat(this.detailDailyTasks[k].productPerfor[m].qty_used) : 0;
                    const fuelType = this.detailDailyTasks[k].products[m].product.item.subfamily ? this.detailDailyTasks[k].products[m].product.item.subfamily : '';
                    this.totalCost += Number(this.getCostByCode(costs, fuelType)) * fuelConsumption;
                    this.detailPlanning[i].realCost = parseFloat(this.totalCost).toFixed(2);
                  }
                  this.detailPlanning[i].goalVsReal = ((parseFloat(this.detailPlanning[i].realGoal).toFixed(2) / this.detailPlanning[i].goal) * 100).toFixed(2);
                  this.detailPlanning[i].CostVsReal = ((this.detailPlanning[i].realCost / this.detailPlanning[i].cost) * 100).toFixed(2);
                }
              }
            }
          }
        }
      }
    } catch (e) {
      console.log(e); // TODO: Visualizar correctamente este error. No incluir los 404
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    formattedHour(item) {
      const momentObj = moment();
      let end = (item.end_date) ? item.end_date : momentObj.format('YYYY-MM-DD HH:mm:ss');
      end = moment.utc(end);
      const ms = moment(end).diff(moment(item.init_date), 'minutes');
      return ms;
    },
    getCostByCode(costs, type) {
      for (let i = 0; i < costs.length; i += 1) {
        if (costs[i].code === type) return costs[i].price;
      }
      return 0;
    },
    calculateSum() {
      for (const iterator of this.detailPlanning) {
        this.totalCount += parseFloat(iterator.cost);
      }
    },
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    getLocationLevel2() {
      this.currentLevel2Name = '';
      this.level2Data = [];
      this.areaLevel2 = 0;
      for (let i = 0; i < this.locationsTree.length; i += 1) {
        if (this.locationsTreeAux.length === 0) {
          // Si no quedan ids en el array de parcelas de esta tarea
          break;
        }
        this.recursiveGetChildrens(this.locationsTree[i]);
      }
      return this.level2Data;
    },
    recursiveGetChildrens(items) {
      if (items.level === 2) {
        // Solo comprobamos los elementos con nivel === 2, sus hijos los comprobamos dentro
        this.currentLevel2Name = items.name;
      }
      if (items.children.length === 0) {
        // Si el nivel no tiene hijos, comprobamos si su id está en el array
        const check = this.locationsTreeAux.indexOf(items.bd_id);
        if (check !== -1) {
          const alreadyInserted = this.level2Data.indexOf(this.currentLevel2Name);
          if (alreadyInserted === -1) {
            // Está en el array de localizaciones y aun no lo hemos guardado
            this.level2Data.push(this.currentLevel2Name);
          }
          this.areaLevel2 += items.area;
          this.locationsTreeAux.splice(check, 1); // Elimino el id del array
        }
      } else if (this.locationsTreeAux.length !== 0) {
        // El nivel tiene hijos
        for (let j = 0; j < items.children.length; j += 1) {
          this.recursiveGetChildrens(items.children[j]);
        }
      }
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (locations.find((element) => element === item.bd_id)) {
          this.finalLevelLocation.push(item.name);
        }
      }
    },
    formattingDates(dates) {
      const formatedDates = [];
      for (const date of dates) {
        formatedDates.push(moment(date).format('DD-MM-YYYY'));
      }
      return formatedDates;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('PlannedVsReal');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PlannedVsReal.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('TaskBadget', ['setBadgedId', 'setVersionId', 'setTaskId', 'setActualTaskBadget']),
    ...mapActions('Prescription', ['setPrescription']),
  },
};
</script>
<style scoped>
.total-group-count{
    font-size: 16px;
    font-weight: 700;
    border: 1px solid rgba(133,103,103,0.2);
    margin-right: 10px;
    padding: 8px 12px;
    border-radius: 5px;
    background: #FBF7EF;
    color: #470000;
}
.total-group-count span {
  font-weight: 400;
  font-size: 13px;
}
</style>
