<template>
  <f7-page>
    <navbar :text="$t('ReportingLogs_navbar_title')" />
    <DxErrorLogs />
  </f7-page>
</template>

<script>
import DxErrorLogs from '../../components/reporting/DxLogs.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'Logs',
  components: {
    DxErrorLogs,
    navbar,
  },
};
</script>
