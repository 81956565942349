export default {
  namespaced: true,
  state: {
    name: '',
    surname: '',
    identification_number: '',
    group: '',
    status: '',
    role: '',
    type: '',
    drive: false,
    shift: '',
    email: '',
    password: '',
    type_identification: 'NFC',
    code_identification: '',
    employee: null,
  },

  getters: {
    name: (state) => state.name,
    surname: (state) => state.surname,
    identification_number: (state) => state.identification_number,
    group: (state) => state.group,
    status: (state) => state.status,
    role: (state) => state.role,
    type: (state) => state.type,
    drive: (state) => state.drive,
    shift: (state) => state.shift,
    email: (state) => state.email,
    password: (state) => state.password,
    type_identification: (state) => state.type_identification,
    code_identification: (state) => state.code_identification,
    employee: (state) => state.employee,
  },

  actions: {
    // Datos que seteamos de Create Employee.
    setName(context, value) {
      context.commit('setName', value);
    },
    setSurname(context, value) {
      context.commit('setSurname', value);
    },
    setIdentificationNumber(context, value) {
      context.commit('setIdentificationNumber', value);
    },
    setGroup(context, value) {
      context.commit('setGroup', value);
    },
    setStatus(context, value) {
      context.commit('setStatus', value);
    },
    setRole(context, value) {
      context.commit('setRole', value);
    },
    setType(context, value) {
      context.commit('setType', value);
    },
    setDriver(context, value) {
      context.commit('setDriver', value);
    },
    setShift(context, value) {
      context.commit('setShift', value);
    },
    setEmail(context, value) {
      context.commit('setEmail', value);
    },
    setPassword(context, value) {
      context.commit('setPassword', value);
    },
    setTypeIdentification(context, value) {
      context.commit('setTypeIdentification', value);
    },
    setCodeIndentification(context, value) {
      context.commit('setCodeIndentification', value);
    },
    setEmployee(context, value) {
      context.commit('setEmployee', value);
    },
  },
  mutations: {
    setName(state, value) {
      state.name = value;
    },
    setSurname(state, value) {
      state.surname = value;
    },
    setIdentificationNumber(state, value) {
      state.identification_number = value;
    },
    setGroup(state, value) {
      state.group = value;
    },
    setStatus(state, value) {
      state.status = value;
    },
    setRole(state, value) {
      state.role = value;
    },
    setType(state, value) {
      state.type = value;
    },
    setDriver(state, value) {
      state.drive = value;
    },
    setShift(state, value) {
      state.shift = value;
    },
    setEmail(state, value) {
      state.email = value;
    },
    setPassword(state, value) {
      state.password = value;
    },
    setTypeIdentification(state, value) {
      state.type_identification = value;
    },
    setCodeIndentification(state, value) {
      state.code_identification = value;
    },
    setEmployee(state, value) {
      state.employee = value;
    },
  },
};
